import React from 'react'
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm, AddBoxOutlined, Edit } from '@material-ui/icons'
import { rollup, kv } from '@blast-engine/utils'

import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './global-menu.scss'

export const GlobalMenu = fireworkConnect(props => { return({
  lunchGlobalMenu: entities.crafter.crafterData.lunchGlobalMenu.fetchLunchGlobalMenu({ crafterId: props.crafterId }),
  craftGlobalMenu: entities.crafter.crafterData.craftMenu.fetchCraftGlobalMenu({ crafterId: props.crafterId }),
  subGlobalMenu: entities.crafter.crafterData.subsGlobalMenu.fetchSubsGlobalMenu({ crafterId: props.crafterId })
})})(class GlobalMenu extends React.Component {
  state = {
    lunchName: '',
    lunchDesc: '',
    lunchNotesEnabled: false,
    lunchOptions: [],
    subName: '',
    subDesc: '',
    subNotesEnabled: false,
    subOptions: [],
    craftName: '',
    craftDesc: '',
    craftOptions: [],
    addSubModalVisible: false,
    editSubModalItem: null,
    addSubOptionModalVisible: false,
    editSubOptionModalItem: null,
    subEdit: null,
    addCategoryModalVisible: false,
    catEdit: null,
    itemOptionType: 'checkbox',
    itemOptionItems: [],
    itemOptionSelection: {},
    modalError: null
  }

  editItem() {
    const { areNotesEnabledForItem, editItemLabelValue, editItemModalItem } = this.state
    if (!editItemLabelValue || !editItemLabelValue.length) return this.setState({ modalError: 'Item Name Cannot be Empty' })
    const updates = []
    updates.push(editItemModalItem.updateLabel({ label: editItemLabelValue }))
    if (areNotesEnabledForItem) {
      updates.push(editItemModalItem.enableNotes())
    }
    if (areNotesEnabledForItem === false) {
      updates.push(editItemModalItem.disableNotes())
    }

    kernel.performUpdates(updates)
    this.setState({ areNotesEnabledForItem: null, editItemLabelValue: '', editItemModalItem: null, modalError: null })
  }

  addOptionsToItem() {
    const { craftGlobalMenu } = this.props
    const { itemOptionSelection, addOptionsToItemModalItem } = this.state
    
    const updates = kv(itemOptionSelection).map(({ k: optionId, v: isSelected }) => {
      if (isSelected) {
        return craftGlobalMenu.addOptionToItem({ itemId: addOptionsToItemModalItem.id(), optionId })
      }
      return craftGlobalMenu.removeOptionFromItem({ itemId: addOptionsToItemModalItem.id(), optionId })
    })

    kernel.performUpdates(updates)

    this.setState({ addOptionsToItemModalItem: null, itemOptionSelection: {} })

  }

  applyItemChanges() {
    const { itemBasic, craftGlobalMenu } = this.props
    const { openEditItemModal, itemLabel, imgUrl } = this.state

    if (!itemLabel || !itemLabel.length) return this.setState({ modalError: 'Item Name Cannot be Empty' })

    const update = itemBasic.updateLabel({ label: itemLabel })
    kernel.performUpdates([ update ])
    this.setState({ openEditItemModal: false, modalError: null })
  }

  toggleItemNotesFeature() {
    const { itemBasic } = this.props
    const update = itemBasic.notesAreEnabled() ? itemBasic.disableNotes() : itemBasic.enableNotes()
    kernel.performUpdates([ update ])
  }

  toggleIsOptionRequired(option) {
    const { craftGlobalMenu } = this.props
    kernel.performUpdates([ option.updateIsRequired({ isRequired: !option.isRequired() }) ])
  }

  submitLunch() {
    const { lunchGlobalMenu } = this.props
    const { lunchDesc, lunchNotesEnabled, lunchName, lunchOptions } = this.state
    const updates = []
    lunchOptions.forEach(option => 
      updates.push(lunchGlobalMenu.addOption({ type: option.optionType, label: option.optionLabel, items: option.optionItems }))
    )
    const optionIds = updates.map(i => Object.values(i)[0].id)
    updates.push(lunchGlobalMenu.addItem({ name: lunchName, optionIds, notesAreAllowed: lunchNotesEnabled, description: lunchDesc }))
    kernel.performUpdates(updates)
  }

  submitSub() {
    const { subGlobalMenu } = this.props
    const { subDesc, subNotesEnabled, subName, subOptions } = this.state
    const updates = []
    subOptions.forEach(option => 
      updates.push(subGlobalMenu.addOption({ type: option.optionType, label: option.optionLabel, items: option.optionItems }))
    )
    const optionIds = updates.map(i => Object.values(i)[0].id)
    updates.push(subGlobalMenu.addItem({ name: subName, optionIds, notesAreAllowed: subNotesEnabled, description: subDesc }))
    kernel.performUpdates(updates)
  }

  addItem() {
    const { craftGlobalMenu } = this.props
    const { newItemLabel, newItemCategory } = this.state
  
    if (!newItemLabel || !newItemCategory || !newItemCategory.id()) {
      return this.setState({ modalError: 'Item Name Cannot be Empty' })
    }

    const update = craftGlobalMenu.addItem({ name: newItemLabel, categoryId: newItemCategory.id() })
    kernel.performUpdates([update])
    this.setState({ addItemModalVisibility: false, newItemCategory: null, modalError: null })
  }

  async addOption() {
    const { craftGlobalMenu } = this.props
    const { newOptionLabel, itemOptionType, itemOptionItems } = this.state
    if (!newOptionLabel) return this.setState({ modalError: 'Option Label Cannot be Empty' })
    if (!itemOptionItems.length) return this.setState({ modalError: 'Please Add At Least One Option Item' })
    if (itemOptionItems.some(i => !i || ( i && !i.length )) ) return this.setState({ modalError: 'Option Item Names Cannot be Empty' })
    const update = craftGlobalMenu.addOption({ 
      label: newOptionLabel, 
      type: itemOptionType, 
      items: itemOptionItems 
    })
    
    await kernel.performUpdates([ update ])
    // const newOptionId = Object.values(update)[0].id

    // await kernel.performUpdates([ craftGlobalMenu.addOptionToItem({ itemId: itemToAttachTheOptionTo.id(), optionId: newOptionId }) ])
    this.setState({ addOptionModalVisible: false, newOptionLabel: '', itemOptionItems: [], itemToAttachTheOptionTo: null, modalError: null })
  }

  editOption() {
    const { editedOptionLabel, editedOptionType, editOptionModalOption } = this.state
    if (!editedOptionLabel || !editedOptionLabel.length) return this.setState({ modalError: 'Option Label Cannot be Empty' })
    const updates = []
    updates.push(editOptionModalOption.updateLabel({ label: editedOptionLabel }))
    updates.push(editOptionModalOption.updateType({ type: editedOptionType }))

    kernel.performUpdates(updates)

    this.setState({ editOptionModalOption: null,  editedOptionType: 'checkbox', editedOptionLabel: '', modalError: null })
  }

  createNewOptionItem() {
    const { newOptionItemModalOption, newOptionItemLabel } = this.state

    if (!newOptionItemLabel || !newOptionItemLabel.length) return this.setState({ modalError: 'Option Item Name Cannot be Empty' })

    kernel.performUpdates([ newOptionItemModalOption.addOptionItem({ label: newOptionItemLabel }) ])

    this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '', modalError: null })
  }

  editOptionItemLabel() {
    const { editOptionItemOption, editOptionItemModalOptionItemId, editedOptionItemLabel } = this.state

    if (!editedOptionItemLabel || !editedOptionItemLabel.length) return this.setState({ modalError: 'Option Item Name Cannot be Empty' })

    kernel.performUpdates([ editOptionItemOption.updateOptionItem({ label: editedOptionItemLabel, optionItemId: editOptionItemModalOptionItemId }) ])

    this.setState({ editOptionItemOption: null, editOptionItemModalOptionItemId: null, editedOptionItemLabel: '', modalError: null })

  }

  render() {
    const { lunchGlobalMenu, craftGlobalMenu, subGlobalMenu } = this.props
    const { itemOptionItems, itemOptionType, catEdit, addCategoryModalVisible, subEdit, lunchDesc, lunchNotesEnabled, lunchName, lunchOptions, subName, subDesc, subNotesEnabled, subOptions, craftName, craftDesc, craftOptions } = this.state
    window.gm = this
    if (!isReady(lunchGlobalMenu, craftGlobalMenu, subGlobalMenu)) return null

    const { Panel } = Collapse
    return (
      <div className='global-menu-container'>
        <Comps.GlobalMenuSubs 
          crafterId={this.props.crafterId}
          subGlobalMenu={subGlobalMenu} 

          addSubModalVisible={this.state.addSubModalVisible}
          closeAddSubModal={() => this.setState({ addSubModalVisible: false })}

          addSubOptionModalVisible={this.state.addSubOptionModalVisible}
          closeAddSubOptionModal={() => this.setState({ addSubOptionModalVisible: false })}

          editSubModalItem={this.state.subEdit}
          closeEditSubModal={() => this.setState({ subEdit: null })}
        />
        <Comps.GlobalMenuCategoryModals
          craftGlobalMenu={craftGlobalMenu}

          editCategoryModalItem={this.state.editCategoryModalItem}
          closeEditCategoryModal={() => this.setState({ editCategoryModalItem: null })}

          addCategoryModalVisible={this.state.addCategoryModalVisible}
          closeAddCategoryModal={() => this.setState({ addCategoryModalVisible: false })}

        />
        <div className='body'>

          <Modal
            title='Create New Option'
            visible={this.state.addOptionModalVisible}
            onOk={() => this.addOption()}
            onCancel={() => this.setState({ addOptionModalVisible: false })}
          >
            <div className='modal-body'>
              <TextField 
                required
                className='modal-section'
                variant='outlined'
                label='New Option Label'
                placeholder='Option Label'
                value={this.state.newOptionLabel}
                onChange={e => this.setState({ newOptionLabel: e.target.value })}
                onKeyPress={e => e.key === 'Enter' && this.addOption()}
              />
              <div className='refactor-me modal-section'>
                Option Type
                <FormControl>
                  <Select value={itemOptionType} onChange={e => {
                    this.setState({ itemOptionType: e.target.value })
                  }} >
                    <MenuItem value='checkbox'>Multi Option</MenuItem>
                    <MenuItem value='radio'>One Option</MenuItem>
                  </Select>
                  <FormHelperText>{itemOptionType === 'checkbox' ? 'Multiple Option Items Can Be Selected' : 'Only One Option Item Can Be Selected'}</FormHelperText>
                </FormControl>
              </div>
              <div className='modal-section'>
                {
                  itemOptionItems.map((item, index) => 
                    <div className='modal-section' key={index}>
                      <TextField 
                        required 
                        label='Option Item Name' 
                        variant='outlined' 
                        color='primary' 
                        value={item} 
                        onChange={e => {
                          const i = this.state.itemOptionItems
                          i[index] = e.target.value
                          this.setState({ itemOptionItems: i })
                        }} 
                        onKeyPress={e => e.key === 'Enter' && this.addOption()}
                      />
                    </div>
                  )
                }
              </div>
              <Button variant='outlined' color='primary' onClick={() => this.setState({ itemOptionItems: this.state.itemOptionItems.concat('') })}>Add New Option Item</Button>
              { this.state.modalError && 
                <div className='modal-error'>
                  {this.state.modalError}
                </div>
              }
            </div>
            
          </Modal>
          <Modal
            title='Create New Item'
            visible={this.state.addItemModalVisibility}
            onOk={() => this.addItem()}
            onCancel={() => this.setState({ addItemModalVisibility: false })}
          >
            <div className='modal-body'>
              <div className='modal-section'>
                <TextField 
                  placeholder='New Item Name' 
                  required 
                  style={{ width: '100%' }} 
                  variant='outlined' 
                  label='Item Name' 
                  value={this.state.newItemLabel} 
                  onChange={e => this.setState({ newItemLabel: e.target.value })} 
                  onKeyPress={e => e.key === 'Enter' && this.addItem()}
                />
              </div>
              { this.state.modalError && 
                <div className='modal-error'>
                  {this.state.modalError}
                </div>
              }
            </div>
          </Modal>

          <Modal
            title='Add Options To Item'
            visible={!!this.state.addOptionsToItemModalItem}
            onOk={() => this.addOptionsToItem()}
            onCancel={() => this.setState({ addOptionsToItemModalItem: null, itemOptionSelection: {} })}
          >
            <FormGroup row={true}>
              {
                craftGlobalMenu.allOptions().filter(i => i).map(optionBasic => (
                  <FormControlLabel
                    label={optionBasic.label()}
                    control={
                      <Checkbox
                        color='primary'
                        name={optionBasic.id()}
                        checked={!!this.state.itemOptionSelection[optionBasic.id()]}
                        onChange={e => {
                          const opts = this.state.itemOptionSelection
                          opts[optionBasic.id()] = e.target.checked
                          this.setState({ itemOptionSelection: opts })
                          // if (e.target.checked)
                          //   kernel.performUpdates([ craftGlobalMenu.addOptionToItem({ itemId: itemBasic.id(), optionId: optionBasic.id() }) ])
                          // else
                          //   kernel.performUpdates([ craftGlobalMenu.removeOptionFromItem({ itemId: itemBasic.id(), optionId: optionBasic.id() }) ])
                        }}
                      />
                    }
                  />
                ))
              }
            </FormGroup>
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </Modal>

          <Modal
            title='Edit Item'
            visible={!!this.state.editItemModalItem}
            onOk={() => this.editItem()}
            onCancel={() => this.setState({ editItemModalItem: null, editItemLabelValue: '' })}
          >
            <div className='modal-body'>
              <div className='modal-section'>
                <TextField 
                  placeholder='Item Name' 
                  required 
                  style={{ width: '100%' }} 
                  variant='outlined' 
                  label='Item Name' 
                  value={this.state.editItemLabelValue} 
                  onChange={e => this.setState({ editItemLabelValue: e.target.value })} 
                  onKeyPress={e => e.key === 'Enter' && this.editItem()}
                />
              </div>
              <div className='modal-section'>
                Notes are {this.state.areNotesEnabledForItem ? 'Enabled' : 'Disabled'} for this item
                <Button style={{ marginLeft: '10px' }} variant='outlined' color='primary' onClick={() => {
                  if (!this.state.editItemModalItem) return
                  this.setState({ areNotesEnabledForItem: !this.state.areNotesEnabledForItem })
                  
                }}>
                  {this.state.areNotesEnabledForItem ? 'Disable notes' : 'enable notes'}
                </Button>
              </div>
              { this.state.modalError && 
                <div className='modal-error'>
                  {this.state.modalError}
                </div>
              }

            </div>
          </Modal>
          <Modal
            title='Edit Option'
            visible={!!this.state.editOptionModalOption}
            onOk={() => this.editOption()}
            onCancel={() => this.setState({ editOptionModalOption: null, editedOptionLabel: '', editedOptionType: 'checkbox' })}
          >
            <div className='modal-body'>
              <TextField 
                required
                className='modal-section'
                variant='outlined'
                label='Option Label'
                placeholder='Option Label'
                value={this.state.editedOptionLabel}
                onChange={e => this.setState({ editedOptionLabel: e.target.value })}
                onKeyPress={e => e.key === 'Enter' && this.editOption()}
              />
              <div className='refactor-me modal-section'>
                Option Type
                <FormControl>
                  <Select value={this.state.editedOptionType} onChange={e => {
                    this.setState({ editedOptionType: e.target.value })
                  }} >
                    <MenuItem value='checkbox'>Multi Option</MenuItem>
                    <MenuItem value='radio'>One Option</MenuItem>
                  </Select>
                  <FormHelperText>{this.state.editedOptionType === 'checkbox' ? 'Multiple Option Items Can Be Selected' : 'Only One Option Item Can Be Selected'}</FormHelperText>
                </FormControl>
              </div>
              { this.state.modalError && 
                <div className='modal-error'>
                  {this.state.modalError}
                </div>
              }
            
            </div>
          </Modal>

          <Modal
            title='Create New Option Item'
            visible={!!this.state.newOptionItemModalOption}
            onOk={() => this.createNewOptionItem()}
            onCancel={() => this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '' })}
          >
            <div className='modal-body'>
              <TextField 
                required
                className='modal-section'
                variant='outlined'
                label='New Option Item Name'
                placeholder='Option Item Name'
                value={this.state.newOptionItemLabel}
                onChange={e => this.setState({ newOptionItemLabel: e.target.value })}
                onKeyPress={e => e.key === 'Enter' && this.createNewOptionItem()}
              />
              { this.state.modalError && 
                <div className='modal-error'>
                  {this.state.modalError}
                </div>
              }
            </div>
          </Modal>

          <Modal
            title='Edit Option Label'
            visible={!!this.state.editOptionItemModalOptionItemId}
            onOk={() => this.editOptionItemLabel()}
            onCancel={() => this.setState({ editOptionItemModalOptionItemId: null, editedOptionItemLabel: '' })}
          >
            <div className='modal-body'>
              <TextField 
                required
                className='modal-section'
                variant='outlined'
                placeholder='Option Item Name'
                label='Edit Option Item Name'
                value={this.state.editedOptionItemLabel}
                onChange={e => this.setState({ editedOptionItemLabel: e.target.value })}
                onKeyPress={e => e.key === 'Enter' && this.editOptionItemLabel()}
              />
              { this.state.modalError && 
                <div className='modal-error'>
                  {this.state.modalError}
                </div>
              }
            </div>
          </Modal>
          
        </div>



        <Collapse className='categories-and-options'>
          <Panel 
            header={
              <div className='categories-header each-header'>
                <div className='right-side'>
                  Categories and Items
                </div>
                <div className='left-side'>
                  <Button variant='text' color='primary' onClick={e => {e.stopPropagation(); this.setState({ addCategoryModalVisible: true })}}>
                    Create New Category
                  </Button>
                </div>
              </div>
            } 
            key='categories'
          >
            <Collapse className='categories'>
              {
                craftGlobalMenu.members.categories.asArray().filter(catBasic => !catBasic.isFakeDeleted()).map(categoryBasic => (
                  <Panel 
                    header={
                      <div className='category-panel-header each-header'>
                        <div classNam='right-side'>
                          CATEGORY: {categoryBasic.name()}
                        </div>
                        <div className='left-side'>
                          
                          <Button variant='text' color='primary' onClick={e => {e.stopPropagation(); this.setState({ editCategoryModalItem: categoryBasic })}}>
                            Edit Category Name
                          </Button>
                          <Button variant='text' color='primary' onClick={e => {e.stopPropagation(); this.setState({ addItemModalVisibility: true, newItemCategory: categoryBasic })}}>
                            Create New Item
                          </Button>
                          <Button variant='text' color='secondary' onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                            if (confirmed) {
                              kernel.performUpdates([ categoryBasic.fakeDelete() ])
                            }
                          }}>
                            Delete Category
                          </Button>
                        </div>
                      </div>
                    }
                    key={categoryBasic.id()}
                  >
                    <Collapse className='items'>
                      {
                        craftGlobalMenu.itemsForCategory({ categoryId: categoryBasic.id() }).map(itemBasic => (
                          <Panel 
                            header={
                              <div className='category-panel-header each-header'>
                                <div classNam='right-side'>
                                  ITEM: {itemBasic.name()}<span style={{ color: 'gray' }}>{' - '}Notes are {itemBasic.notesAreEnabled() ? 'Enabled' : 'Disabled'}</span>
                                </div>
                                <div className='left-side'>
                                  
                                  <Button variant='text' color='primary'
                                    onClick={e => {
                                      e.stopPropagation(); 
                                      this.setState({ editItemModalItem: itemBasic, editItemLabelValue: itemBasic.name(), areNotesEnabledForItem: itemBasic.notesAreEnabled() })
                                    }}
                                  >
                                    Edit Item
                                  </Button>
                                  <Button variant='text' color='primary' 
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.setState({ 
                                        addOptionsToItemModalItem: itemBasic, 
                                        areNotesEnabledForItem: itemBasic.notesAreEnabled(),
                                        itemOptionSelection: rollup(craftGlobalMenu.optionsForItem({ itemId: itemBasic.id() }).filter(o => !!o).map(option => option.id()))
                                      })
                                      // TODOOOO
                                      // this.setState({ editItemModalItem: itemBasic, editItemLabelValue: itemBasic.name() })
                                    }}
                                    >
                                    Add/Remove options
                                  </Button>
                                  <Button variant='text' color='secondary'
                                    onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                                      if (confirmed) {
                                        kernel.performUpdates([ itemBasic.fakeDelete() ])
                                      }
                                    }}
                                  >
                                    Delete Item
                                  </Button>
                                </div>
                              </div>
                            } 
                            key={itemBasic.id()}
                          >
                            <Collapse className='options'>
                              {
                                craftGlobalMenu.optionsForItem({ itemId: itemBasic.id() }).filter(o => !!o).map(optionBasic => (
                                  <Panel
                                    header={
                                      <div className='each-header'>
                                        <div className='right-side'>
                                          {optionBasic.label()} <span style={{ color: 'gray'  }}>{' - '}This option is {optionBasic.isRequired() ? '' : 'not'} required</span>
                                        </div>
                                        <div className='left-side'>
                                          <Button
                                            variant='text'
                                            color='primary'
                                            onClick={e => {
                                              e.stopPropagation()
                                              kernel.performUpdates([ 
                                                optionBasic.updateIsRequired({ isRequired: !optionBasic.isRequired() }) 
                                              ])
                                            }}
                                          >
                                            set as {optionBasic.isRequired() ? 'not' : ''} required
                                          </Button>
                                        </div>
                                      </div>
                                    }
                                    key={optionBasic.id()}
                                  >
                                    {
                                      craftGlobalMenu.optionItemsForOption({ optionId: optionBasic.id() }).map(optionItem => (
                                        
                                        <div className='each-option-item'>
                                          {optionItem.label}
                                        </div>
                                          
                                      ))
                                    }
                                  </Panel>
                                ))
                              }
                            </Collapse>
                          </Panel>
                        ))
                      }
                    </Collapse>
                    
                  </Panel>
                ))
              }
            </Collapse>
          </Panel>
          <Panel 
            header={
              <div className='each-header'>
                <div className='right-side'>
                  Item Options
                </div>
                <div className='left-side'>
                  <Button
                    variant='text'
                    color='primary'
                    onClick={e => {
                      e.stopPropagation()
                      this.setState({ addOptionModalVisible: true })
                    }}
                  >
                    Create new option
                  </Button>
                </div>
              </div>
            }
            key='options'
          >
            <Collapse className='options'>
              {
                craftGlobalMenu.allOptions().map(optionBasic => (
                  <Panel
                    header={
                      <div className='each-header'>
                        <div className='right-side'>
                          {optionBasic.name()}<span style={{ color: 'gray' }}>{' - '}{optionBasic.type() === 'checkbox' ? 'Multi Select Option' : 'One Select Option'}</span>
                        </div>
                        <div className='left-side'>
                          <Button
                            variant='text'
                            color='primary'
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({ editOptionModalOption: optionBasic, editedOptionLabel: optionBasic.name(), editedOptionType: optionBasic.type() })
                            }}
                          >
                            Edit Option
                          </Button>
                          <Button
                            variant='text'
                            color='primary'
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({ newOptionItemModalOption: optionBasic })
                            }}
                          >
                            create new option item
                          </Button>
                          <Button
                            variant='text'
                            color='secondary'
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                              if (confirmed) {
                                kernel.performUpdates([ optionBasic.fakeDeleteOption() ])
                              }
                            }}
                          >
                            delete option
                          </Button>
                        </div>
                      </div>
                    }
                    key={optionBasic.id()}
                    
                  >
                    {
                      craftGlobalMenu.optionItemsForOption({ optionId: optionBasic.id() }).map(optionItem => (                        
                        <div className='each-option-item each-header'>
                          <div className='right-side'>
                            {optionItem.label}
                          </div>
                          <div className='left-side'>
                            <Button
                              color='primary'
                              variant='text'
                              onClick={e => {
                                e.stopPropagation()
                                this.setState({ editOptionItemModalOptionItemId: optionItem.id, editedOptionItemLabel: optionItem.label, editOptionItemOption: optionBasic })
                              }}
                            >
                              Change Option Item Name
                            </Button>
                            <Button
                              color='secondary'
                              variant='text'
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                                if (confirmed) {
                                  kernel.performUpdates([ optionBasic.fakeDeleteOptionItem({ optionItemId: optionItem.id }) ])
                                }
                              }}
                            >
                              Delete Option Item
                            </Button>
                          </div>
                        </div>
                      ))
                    }
                  </Panel>
                ))
              }
            </Collapse>
            
          </Panel>
        </Collapse>












        {/* <Collapse className='cat-collapse'>
          {
            craftGlobalMenu.members.categories.asArray().map(catBasic => (
              <Panel 
                header={
                  <div className={`cat-panel-header`}>
                    CATEGORY: {catBasic.name()}
                  </div>
                } 
                key={catBasic.id()}
              >
                {
                  craftGlobalMenu.itemsForCategory({ categoryId: catBasic.id() }).map(itemBasic => (
                    <Collapse key={itemBasic.id()} className='item-collapse'>
                      
                      <Panel 
                        header={
                          <div 
                            className='item-panel-header'
                          >
                              <Checkbox
                                className='item-toggle-button each-toggle-button' 
                                checked={true}
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation(); 
                                  // if (!jobMenuManager.isItemPresentForJob({ itemId: itemBasic.id() })) {
                                  //   const optionsConfig = jobMenuManager.members.globalMenu.members.items.item(itemBasic.id()).optionIds()
                                  //     .map(optionId => {
                                  //       const optionItemIds = rollup(jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds())

                                  //       return ({
                                  //         [optionId]: {
                                  //           enabled: true,
                                  //           optionItemIds
                                  //         }
                                  //       })
                                  //     }).reduce((acc, optionConfig) => {
                                  //       const k = Object.keys(optionConfig)[0]
                                  //       acc[k] = optionConfig[k]
                                  //       return acc
                                  //     }, {})
                                      
                                    

                                  //   kernel.performUpdates([ jobMenuManager.members.jobMenu.add({ initArgs: { id: itemBasic.id(), optionsConfig }, newItemId: itemBasic.id() }) ])
                                  // }
                                  // else {
                                  //   kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id())._delete() ])
                                  // }
                                }}
                              />
                              ITEM: {itemBasic.name()}
                              
                              
                          </div>
                        }
                      >
                        {
                          itemBasic.optionIds().map(optionId => {
                            
                            const isOptionEnabled = jobMenuManager.members.jobMenu.item(itemBasic.id()) ? jobMenuManager.members.jobMenu.item(itemBasic.id()).isOptionPresent({ optionId }) : false
                            return (
                            <Collapse className='option-collapse'>
                              
                              <Panel 
                                header={
                                  <div 
                                    className='option-panel-header'
                                  >
                                    <Checkbox
                                      className='option-toggle-button each-toggle-button' 
                                      checked={isOptionEnabled}
                                      color='primary'
                                      onClick={e => {
                                        e.stopPropagation(); 
                                        const itemId = itemBasic.id()
                                        
                                        if (isOptionEnabled) {
                                          kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id()).removeOptionFromJob({ optionId }) ])
                                        }
                                        else {
                                          // item is enabled, but option is not
                                          if (jobMenuManager.members.jobMenu.item(itemBasic.id())) {
                                            
                                            const optionItemsForOption = jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds()
                                            const updates = optionItemsForOption.map(optionItemId => jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOptionItemForOption({ optionId, optionItemId }))
                                            updates.unshift(jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOption({ optionId }))
                                            
                                            kernel.performUpdates(updates)
                                          }
                                          else {
                                            // item is not enabled, and option is not enabled
                                            const updates = []
                                            const optionItemsForOption = rollup(jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds())
                                            const optionsConfig = { [optionId]: {
                                              enabled: true,
                                              optionItemIds: optionItemsForOption
                                            }}
                                            updates.push(jobMenuManager.members.jobMenu.add({ initArgs: { id: itemBasic.id(), optionsConfig }, newItemId: itemBasic.id() }))
                                            kernel.performUpdates(updates)
                                          } 
                                        }
                                      }}
                                    />
                                    OPTION: {jobMenuManager.members.globalMenu.members.options.item(optionId).label()}
                                  </div>
                                }
                              >
                                {
                                  jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds().map(optionItemId => {
                                    const isOptionItemEnabled = jobMenuManager.members.jobMenu.item(itemBasic.id()) ? jobMenuManager.members.jobMenu.item(itemBasic.id()).isOptionItemPresent({ optionId, optionItemId }) : false
                                    return (
                                    <div className='each-option-item'>
                                      <Checkbox
                                        className='option-item-toggle-button each-toggle-button' 
                                        checked={isOptionItemEnabled}
                                        color='primary'
                                        onClick={e => {
                                          e.stopPropagation(); 
                                          if (isOptionItemEnabled) {
                                            kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id()).removeOptionItemFromJob({ optionId, optionItemId }) ])
                                          }
                                          else {
                                            if (jobMenuManager.members.jobMenu.item(itemBasic.id())) {
                                              kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOption({ optionId }), jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOptionItemForOption({ optionId, optionItemId }) ])
                                            }
                                            else {
                                              const updates = []
                                              const optionsConfig = { [optionId]: {
                                                enabled: true,
                                                optionItemIds: {
                                                  [optionItemId]: true
                                                }
                                              }}
                                              updates.push(jobMenuManager.members.jobMenu.add({ initArgs: { id: itemBasic.id(), optionsConfig }, newItemId: itemBasic.id() }))
                                              kernel.performUpdates(updates)
                                            }
                                          }
                                          
                                        }}
                                      />
                                      {jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemLabel(optionItemId)}
                                    </div>
                                  )})
                                }
                               
                              </Panel>
                            </Collapse>
                          )})
                        }
                      </Panel>
                    </Collapse>
                  ))
                }
              </Panel>
            ))
          }
        </Collapse> */}







      </div>
    )
  }
})
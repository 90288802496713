import { createNodeClass } from '@blast-engine/firework'

export const CrafterBasic = createNodeClass({
  name: 'CrafterBasic',

  full: class {

    id() {
      return this._data('id')
    }

    name() {
      return this._data('name')
    }

    status() {
      return this._data('status')
    }
    
  },

  ref: class {

    initialize({ id, name, status }) {
      return this._update({ id, name, status })
    }

  }

})
import { keys } from '@blast-engine/utils'

import { Immutable } from './immutable'

//@todo: for some reason, cant import immutable from utils
//@todo: this should be a regular firework struct, but there's something wrong with struct methods

export class BasketItemBasicStruct extends Immutable {

  setCraftItemId(craftItemId) {
    return this.set({ craftItemId })
  }

  craftItemId() {
    return this.get('craftItemId')
  }

  numCopies() {
    return this.get('numberOfCopies') || 1
  }

  notes() {
    return this.get('notes') || ''
  }

  setNotes(notes) {
    return this.set({ notes })
  }

  canRemoveCopies() {
    return this.numCopies() >= 2
  }

  setNumCopies(numCopies) {
    return this.set({ numberOfCopies: numCopies })
  }

  _optionSelections(optionId) {
    return this.get('optionSelections', optionId)
  }

  selectionOptionIds() {
    return keys(this.get('optionSelections'))
  }

  selectionOptionItemIdsForOptionId(optId) {
    // console.log(this.get(`optionSelections.${optId}`))
    return keys(this.get(`optionSelections.${optId}`))
  }

  optionItemIsSelected(optionId, itemId) {
    return !!this.get(`optionSelections.${optionId}.${itemId}`)
  }

  setSelectedItem(optionId, itemId) {
    return this.set({ 
      optionSelections: {
        ...(this.get('optionSelections') || {}),
        [optionId]: { [itemId]: true }
      }
    })
  }

  addSelectedItem(optionId, itemId) {
    return this.set({ 
      optionSelections: {
        ...(this.get('optionSelections') || {}),
        [optionId]: {
          ...(this.get(`optionSelections.${optionId}`) || {}),
          [itemId]: true
        }
      }
    })
  }

  removeSelectedItem(optionId, itemId) {
    return this.set({ 
      optionSelections: {
        ...(this.get('optionSelections') || {}),
        [optionId]: {
          ...(this.get(`optionSelections.${optionId}`) || {}),
          [itemId]: null
        }
      }
    })
  }
}
import { CraftOrderList } from './craft-order.list'
import { CraftOrderSelection } from './craft-order.selection'

export const rootMethods = {

  craftOrdersRef({ crafterId, jobId, day }) {
    return this._spinoff(CraftOrderList.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/craftOrders/${day}`
    })
  },

  craftOrdersSelectionRef({ crafterId, jobId, day, orderIds = [] }) {
    return this._spinoff(CraftOrderSelection.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/craftOrders/${day}`,
      keys: orderIds
    })
  }

}
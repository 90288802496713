import { keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { LocationNames } from './location-names.list'

export const Locations = createAssemblyClass({
  name: 'Locations',
  memberModels: {
    locationNames: LocationNames.full()
  },
  portMethods: { },
  body: class {

    locationNames() {
      return this.members.locationNames.asArray().filter(ln => !ln.isFakeDeleted())
    }

    location({ locationId }) {
      const location = this.members.locationNames.item(locationId)
      if (!location || location.isFakeDeleted()) return null
      return location
    }

    addLocation({ name }) {
      return this.members.locationNames.add({ initArgs: { name } })
    }

    updateLocationName({ locationId, name }) {
      const location = this.members.locationNames.item(locationId)
      if (!location) return null
      return location._updateName({ name })
    }

    removeLocation({ locationId }) {
      const location = this.members.locationNames.item(locationId)
      if (!location) return null
      return location._fakeDelete()
    }

    // implementation of this can change
    locationsEnabled() {
      return !!this.locationNames().length
    }

  }
})
import React from 'react'
import {} from '@material-ui/core'
import {} from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'

import { Button } from './button'
import * as Comps from '@/components'

import './landing-page.scss'

export const LandingPage = responsiveComponent(class LandingPage extends React.Component {
  state = {
    isTop: true
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 50;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    })
  }

  render() {
    const { isMobile } = this.props
    const { isTop } = this.state
    
    if (isMobile) return (
      <div className='landing-page-container' >
        <Comps.NavbarMobile isTop={isTop} />
        <div className='body' style={{ marginTop: isTop ? '15vh' : '10vh' }}>
          <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '30px' }}>
            <Comps.MainCtaDesktop />
          </div>
          <Comps.MarketingMobile
            cta='Your health and safety is important to us.'
            tagline='Crafty Film ensures that there is minimal contact between your production crew and our craft crew.'
            mainImgSrc={require('../../assets/truck.png')}
          />
          <Comps.MarketingMobile
            cta='Ordering food is now at your fingertips.'
            tagline='Simply place an order on the mobile application and the food will be prepared and delivered!'
            mainImgSrc={require('../../assets/phone-burger.png')}
            reversed={true}
            
          />
          <Comps.MarketingMobile
            cta='Increased communication and efficiency.'
            tagline='Our web application enables the craft service provider and on-site crew to receive all orders placed through the mobile application. Easily manage all orders and inventory. Once the food is ready, user notifications are just a tap away!'
            mainImgSrc={require('../../assets/clip.png')}
          />
        </div>
        <Comps.FooterDesktop />
        
      </div>
    )

    return (
      <div className='landing-page-container' >
        <Comps.NavbarDesktop isTop={isTop} />
        <div className='body' style={{ marginTop: isTop ? '25vh' : '10vh' }}>
          <Comps.MainCtaDesktop />
          <Comps.MarketingDesktop
            cta='Your health and safety is important to us.'
            tagline='Crafty Film ensures that there is minimal contact between your production crew and our craft crew.'
            mainImgSrc={require('../../assets/truck.png')}
          />
          <Comps.MarketingDesktop
            cta='Ordering food is now at your fingertips.'
            tagline='Simply place an order on the mobile application and the food will be prepared and delivered!'
            mainImgSrc={require('../../assets/phone-burger.png')}
            reversed={true}
            
          />
          <Comps.MarketingDesktop
            cta='Increased communication and efficiency.'
            tagline='Our web application enables the craft service provider and on-site crew to receive all orders placed through the mobile application. Easily manage all orders and inventory. Once the food is ready, user notifications are just a tap away!'
            mainImgSrc={require('../../assets/clip.png')}
          />
        </div>
        <Comps.FooterDesktop />
        
      </div>
    )
  }
})
import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { craftMenu } from './craft-global-menu'
import { job } from './job'

export const BasketItemComplete = createAssemblyClass({
  name: 'BasketItemComplete',
  memberModels: {
    craftGlobalMenu: craftMenu.CrafterCraftGlobalMenu,
    item: job.jobData.basket.BasketItemBasic.full()
  },
  portMethods: {
    item: [
      'id',
      'selectionOptionIds',
      'numCopies'
    ]
  },
  body: class {

    globalItem() {
      const { craftGlobalMenu, item } = this.members
      return craftGlobalMenu.item(item.craftItemId())
    }

    name() {
      return this.globalItem().name()
    }

    selectionOptionIds() {
      const { craftGlobalMenu, item } = this.members
      return item.selectionOptionIds()
    }

    selectionOptionLabel(selectionOptionId) {
      return this.members.craftGlobalMenu.option(selectionOptionId).label()
    }

    selectedOptionItemLabelsForOption(selectionOptionId) {
      const selectionOptionItemIds = this.members.item.selectionOptionItemIdsForOptionId(selectionOptionId)
      const option = this.members.craftGlobalMenu.option(selectionOptionId)
      return selectionOptionItemIds.map(id => option.optionItemLabel(id))
    }
    
  }
})
import { createNodeClass } from '@blast-engine/firework'

export const ActiveCrewOnsiteUser = createNodeClass({
  name: 'ActiveCrewOnsiteUser',

  full: class {

    id() {
      return this._data('id')
    }

  },

  ref: class {

    initialize({ id }) {
      return this._update({ id })
    }

  }

})
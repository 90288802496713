import React from 'react'
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Select, FormControl, MenuItem, FormHelperText } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm, AddBoxOutlined } from '@material-ui/icons'
import { keys } from '@blast-engine/utils'

import * as Comps from '@/components'

import './global-menu-category-modals.scss'

export const GlobalMenuCategoryModals = class GlobalMenuCategoryModals extends React.Component {
  state = {
    categoryLabel: '',
    editCategoryLabel: '',
  }

  addCategory() {
    const { craftGlobalMenu } = this.props
    const { categoryLabel } = this.state
    if (!categoryLabel  || !categoryLabel.length) return this.setState({ error: 'Category Label Cannot be Empty' })
    const update = craftGlobalMenu.addCategory({ name: categoryLabel })
    kernel.performUpdates([ update ])

    this.props.closeAddCategoryModal()
  }

  componentDidUpdate(prevProps) {
    const { editCategoryModalItem } = this.props
    if (prevProps.editCategoryModalItem !== this.props.editCategoryModalItem) {
      if (editCategoryModalItem) {
        this.setState({ editCategoryLabel: editCategoryModalItem.name() || '' })
      }
      else {
        this.setState({ editCategoryLabel: '', error: null })
      }
      
    }
  }

  editCategory() {
    const { craftGlobalMenu, editCategoryModalItem } = this.props
    const { editCategoryLabel } = this.state
    if (!editCategoryLabel || !editCategoryLabel.length) return this.setState({ error: 'Category Label Cannot be Empty' }) 
    const update = editCategoryModalItem.updateName({ name: editCategoryLabel })
    kernel.performUpdates([ update ])
    
    this.setState({ editCategoryLabel: '', error: null })
    this.props.closeEditCategoryModal()
  }

  render() {
    const { craftGlobalMenu, 
      closeAddCategoryModal, addCategoryModalVisible,
      closeEditCategoryModal, editCategoryModalItem,
    } = this.props
    const { editCategoryLabel, categoryLabel } = this.state

    const { Panel } = Collapse
    
    return (
      <>
        <Modal
          visible={addCategoryModalVisible}
          title='Add Category'
          onOk={() => this.addCategory()}
          onCancel={() => closeAddCategoryModal()}
          okText='Add'
        >
          <div className='modal-body'>
            <TextField 
              placeholder='Category Name' 
              required 
              className='modal-section' 
              variant='outlined' 
              label='Category Name' 
              color='primary' 
              value={categoryLabel}
              onChange={e => this.setState({ categoryLabel: e.target.value })} 
              onKeyPress={e => e.key === 'Enter' && this.addCategory()}
            />
            { this.state.error && 
              <div className='modal-error'>
                {this.state.error}
              </div>
            }
          </div>
        </Modal>
        
        <Modal
          visible={!!editCategoryModalItem}
          title='Edit Category'
          onOk={() => this.editCategory()}
          onCancel={() => closeEditCategoryModal()}
          okText='Apply Changes'
        >
          <div className='modal-body'>
            <TextField 
              placeholder='Category Label' 
              required 
              className='modal-section' 
              variant='outlined' 
              value={editCategoryLabel} 
              label='Category Name' 
              color='primary' 
              onChange={e => this.setState({ editCategoryLabel: e.target.value })} 
              onKeyPress={e => e.key === 'Enter' && this.editCategory()}
            />
            { this.state.error && 
              <div className='modal-error'>
                {this.state.error}
              </div>
            }
          </div>
        </Modal>
      </>
    )
  }
}
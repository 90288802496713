import { LunchGlobalBasicList } from './lunch-global-basic.node.list'
import { LunchGlobalOptionList } from './lunch-global-option.node.list'

export const rootMethods = {

  lunchGlobalItemsRef({ crafterId }) {
    return this._spinoff(LunchGlobalBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/lunchGlobalMenu/items`
    })
  },

  lunchGlobalOptionsRef({ crafterId }) {
    return this._spinoff(LunchGlobalOptionList.ref(), {
      path: `crafter/crafterData/${crafterId}/lunchGlobalMenu/options`
    })
  }

}
import React from 'react'
import { isReady } from '@blast-engine/firework'
import {} from '@material-ui/core'
import {} from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './caterer-printable-meals.scss'

export const CatererPrintableMeals = fireworkConnect(props => ({

}))(class CatererPrintableMeals extends React.Component {
  state = {}

  render() {
    const {} = this.props
    const {} = this.state

    // if (!isReady()) return null
    
    return (
      <div className='caterer-printable-meals-container'>
        hello from caterer-printable-meals.js
      </div>
    )
  }
})
import { JobBasic } from './job-basic.node'
import { JobBasicList } from './job-basic.node.list'
import { fetchJobBasic } from './job-basic.node.fetcher'
import { fetchJobBasicList } from './job-basic.node.list.fetcher'
import { jobData } from './jobData'
import { rootMethods } from './root-methods'

export const job = {
  JobBasic,
  JobBasicList,
  fetchJobBasic,
  fetchJobBasicList,
  jobData,
  rootMethods
}
import { Invite } from './invite.node'
import { Invites } from './invites.list'

export const rootMethods = {

  inviteRef({ userId }) {
    return this._spinoff(Invite.ref(), {
      path:`invites/${userId}`
    })
  },

  invitesListRef() {
    return this._spinoff(Invites.ref(), {
      path: `invites`
    })
  }

}
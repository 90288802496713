import React, { useState } from 'react'
import LogRocket from 'logrocket'
import { isReady } from '@blast-engine/firework'
import { Icon, Button, Input, Radio, CircularProgress, Checkbox } from '@material-ui/core'
import { Card, List } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import * as entities from '@crafty/entities'
import { fireworkConnect, kernel, Root } from '@/kernel.singleton'

export const CraftItemPage = fireworkConnect(({ me, currentJob, itemId }) => ({
  jobCraft: entities.crafter.crafterData.fetchJobCraftMenuManager({ 
    crafterId: currentJob.crafterId(),
    jobId: currentJob.id()
  }),
  basket: entities.crafter.crafterData.fetchBasketComplete({
    crafterId: currentJob.crafterId(),
    jobId: currentJob.id(),
    userId: me.userId()
  })
}))(class CraftItemPage extends React.PureComponent {
  state = { 
    itemId: null, 
    currentStep: 'adding'
  }

  // componentDidMount() {
  //   this.componentDidUpdate()
  // }

  async componentDidUpdate(prevProps) {
    const { itemId } = this.state
    if (itemId && prevProps.itemId !== itemId) {
      this.setState({ itemId })
    }
  }
  
  // async componentDidUpdate() {
  //   const { 
  //     // props
  //     me, currentJob, itemId,
  //     // data
  //     jobCraft, basket
  //   } = this.props

  //   const {
  //     imgUrl
  //   } = this.state

  //   if (!isReady(me, currentJob, jobCraft, basket)) return null

  //   if (imgUrl) return

  //   const item = jobCraft.item(itemId)
  //   if (!item) return 

  //   const ref = kernel.firebase.storage().ref(`image/craft_item/${itemId}`)
  //   this.setState({ imgUrl: await ref.getDownloadURL() })
  // }

  render() {
    
    const { 
      // props
      me, currentJob, itemId,
      goToBasket = () => null,
      goToCraft = () => null,
      // data
      jobCraft, basket
    } = this.props

    const {
      // imgUrl,
      currentStep,
      basketItem = new entities.crafter.crafterData.job.jobData.basket
        .BasketItemBasicStruct(null)
        .setCraftItemId(itemId)
    } = this.state

    LogRocket.log({ 
      event: 'CraftItemPage render()',  
      props: { jobCraft, basket, itemId, currentJob, me }, 
      state: { currentStep, basketItem } 
    })

    if (!isReady(me, currentJob, jobCraft, basket)) return null

    const item = jobCraft.item(itemId)

    const bag = me.currentBag() || {}
    
    const incrementNumCopies = () => {
      this.setState({
        basketItem: basketItem.setNumCopies(basketItem.numCopies() + 1)
      })
    }

    const decrementNumCopies = () => {
      if (basketItem.canRemoveCopies())
        this.setState({
          basketItem: basketItem.setNumCopies(basketItem.numCopies() - 1)
        })
    }

    const toggleOptionItemSelect = (optionId, optionItemId) => {
      // debugger
      const isSelected = basketItem.optionItemIsSelected(optionId, optionItemId)
      this.setState({
        basketItem: isSelected
          ? basketItem.removeSelectedItem(optionId, optionItemId)
          : basketItem.addSelectedItem(optionId, optionItemId)
      })
    }

    const switchOptionItemSelect = (optionId, optionItemId) => {
      this.setState({
        basketItem: basketItem.setSelectedItem(optionId, optionItemId)
      })
    }

    const submit = async () => {
      this.setState({ currentStep: 'submitting' })
      await kernel.performUpdates([ basket.addOrderItem({ basketItem }) ])
      setTimeout(() => {
        this.setState({ currentStep: 'added' })
      }, 300)
    }

    const reset = async () => {
      this.setState({ 
        currentStep: 'adding',
        basketItem: new entities.crafter.crafterData.job.jobData.basket
          .BasketItemBasicStruct(null)
          .setCraftItemId(itemId)
      })
    }

    if (!item) 
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
          <div style={{ fontSize: 23, paddingTop: 25 }}>Invalid Item</div>
          <Button style={{ color: 'blue' }} onClick={goToCraft}>Find some items</Button>
        </div>
      )

    return (
      <div className='craft-item-page-container'  style={{ height: '100%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <div className='body' style={{ flex: 1, overflow: 'scroll' }}>
          {
            item.description() ? (
              <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 25, paddingRight: 25, borderColor: 'rgb(235 234 234)', borderBottomWidth: 1, borderBottomStyle: 'solid' }}> 
                {/* <div style={{ marginBottom: 5, fontSize: 24, fontWeight: '600' }}>{item.name()}</div>  */}
                <div>{item.description()}</div>
              </div>
            ) : null
          }
          <Card 
            bordered={false}
            bodyStyle={{ paddingTop: 10 }}
          >
            {
              item.enabledOptionIds().filter(oid => !jobCraft.isOptionFakeDeleted({ optionId: oid })).length ? (
                item.enabledOptionIds().filter(oid => !jobCraft.isOptionFakeDeleted({ optionId: oid }))
                  .map(optionId => { 
                    return (
                      <div key={optionId}>
                        <List
                          header={<div style={{ fontSize: 18, fontWeight: '700' }}>{ item.optionLabel(optionId) }</div>}
                          dataSource={item.enabledOptionItemIds({ optionId }).filter(oiid => !jobCraft.isOptionItemFakeDeleted({ optionId, optionItemId: oiid }))}
                          renderItem={optionItemId => {
                            return (
                              <Button
                                key={optionItemId} 
                                style={{ width: '100%', paddingRight: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', fontWeight: 'normal', textTransform: 'none' }}
                                onClick={() => {
                                  if (['radio'].includes(item.optionType(optionId)))
                                    switchOptionItemSelect(optionId, optionItemId)
                                  if (['checkbox'].includes(item.optionType(optionId)))
                                    toggleOptionItemSelect(optionId, optionItemId)
                                }}
                              >
                                {
                                  ['radio'].includes(item.optionType(optionId)) ? (
                                    <Radio 
                                      disabled={currentStep !== 'adding'}
                                      checked={basketItem.optionItemIsSelected(optionId, optionItemId)} 
                                    />
                                  ) : ['checkbox'].includes(item.optionType(optionId)) ? (
                                    <Checkbox 
                                      disabled={currentStep !== 'adding'}
                                      checked={basketItem.optionItemIsSelected(optionId, optionItemId)}
                                    />
                                  ) : null
                                }
                                <div>{item.optionItemLabel({ optionId, optionItemId })}</div>  
                              </Button>
                            )
                          }}
                        />
                      </div>
                    )
                  })
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
                  <div style={{ fontSize: 15, paddingTop: 25, textAlign: 'center' }}>Add this item by clicking on "ADD TO BASKET".</div>
                </div>
              )
            }
            {
              item.notesAreEnabled() ? (
                <div>
                  <div style={{ fontSize: 18, fontWeight: '700' }}>Notes</div>
                  <TextArea
                    autoSize={false}
                    style={{ resize: 'none' }}
                    rows={3}
                    disabled={currentStep !== 'adding'}
                    value={basketItem.notes()}
                    onChange={e => this.setState({ basketItem: basketItem.setNotes(e.target.value) })}
                  />
                </div>
              ) : null
            }
          </Card>
        </div>
        <div style={{ height: '10vh', flexGrow: 0, flexShrink: 0, flexBasis: '10vh', borderColor: 'lightgray', borderTopWidth: 1, borderTopStyle: 'solid', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {
            currentStep === 'adding' ? (
            // false ? (
              <div style={{ height: '100%', display: 'flex', flexDirection: 'row', padding: 12, justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ paddingRight: 14 }}>
                    <Button disabled={!basketItem.canRemoveCopies()} onClick={decrementNumCopies}>
                      <Icon>remove_circle_outline</Icon>
                    </Button>
                  </div>
                  <div style={{ paddingRight: 14 }}>
                    <div style={{ fontSize: 25 }}>{basketItem.numCopies()}</div>
                  </div>
                  <div style={{ paddingRight: 20 }} onClick={incrementNumCopies}>
                    <Button onClick={incrementNumCopies}>
                      <Icon>add_circle_outline</Icon>
                    </Button>
                  </div>
                </div>
                <div style={{ paddingRight: 14 }}>
                  <Button onClick={submit} style={{ color: 'blue' }}>
                    <div >Add to Basket</div>
                  </Button>
                </div>
              </div>
            ) : currentStep === 'submitting' ? (
            // ) : false ? (
              <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress/>
              </div>
            ) : (
              <div style={{ paddingLeft: 25, paddingRight: 10, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontSize: 30, display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'green' }}>
                  <Icon>check</Icon>
                </div>
                <div style={{ display: 'flex', fontSize: 10, flexDirection: 'row', alignItems: 'center', color: 'blue' }}>
                  <Button onClick={reset}><div>Add another</div></Button>
                  <Button onClick={goToBasket}><div>Go to Basket</div></Button>
                </div>
              </div>
            )
          } 
        </div>
      </div>
    )
  }
})

import { createNodeClass } from '@blast-engine/firework'
import { rollup } from '@blast-engine/utils'

export const LunchGlobalBasic = createNodeClass({
  name: 'LunchGlobalBasic',

  full: class {

    id() {
      return this._data('id')
    }

    name() {
      return this._data('name')
    }

    description() {
      return this._data('description')
    }

    notesAreAllowed() {
      const naa = this._data('notesAreAllowed')
      if (typeof naa === 'boolean') return naa
      else return true
    }

    optionIds() {
      return this._data('optionIds')
    }

  },

  ref: class {

    initialize({ id, optionIds /* string[] */, notesAreAllowed /* bool */, description, name }) {
      if (!name) throw Error('lunch global item needs name')
      return this._update({ 
        id, 
        optionIds: optionIds ? rollup(optionIds) : null, 
        notesAreAllowed: notesAreAllowed === undefined ? null : notesAreAllowed, 
        description: description || null, 
        name 
      })
    }

  }

})
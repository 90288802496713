import { createListClass } from '@blast-engine/firework'
import { SubOrder } from './sub-order.node'

export const SubOrderList = createListClass({
  name: 'SubOrderList',
  itemModel: SubOrder,
  full: class {
    asArray() {
      return this.itemsKV
        .filter(({ v }) => !!v.data.userId)
        .map(({ k, v }) => v)
    }
  }
})
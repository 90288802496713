import React from 'react'
import LogRocket from 'logrocket'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

export const CraftPage = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    config: entities.fetchConfig(),
    jobCraft: entities.crafter.crafterData.fetchJobCraftMenuManager({ 
      jobId: currentJob.id(), 
      crafterId: currentJob.crafterId() 
    })
  }))(
class CraftPage extends React.PureComponent {

  state = {jobsWithExclusiveBackgroundMenu:undefined}
  componentDidMount(){
    let jobsWithExclusiveBackgroundMenuDataRef = kernel.firebase.database().ref('jobsWithExclusiveBackgroundMenu')
    jobsWithExclusiveBackgroundMenuDataRef.on("value", (snapshot) => {
      // This callback function will be called whenever the data at the specified location changes
      // You can access the data from the 'snapshot' object
      var data = snapshot.val();
      this.setState({...this.state,jobsWithExclusiveBackgroundMenu:data})
      // Process the data as needed
      console.log(data);
    });
  }
  render() {
 
    console.log("this.state.jobsWithExclusiveBackgroundMenu",this.state.jobsWithExclusiveBackgroundMenu)
    const { 
      // props
      me, currentJob, 
      
      goToCategory = () => null,
      // data
      jobCraft,
      config


    } = this.props

    LogRocket.log({ 
      event: 'CraftPage render()',  
      props: { jobCraft }, 
      state: {} 
    })

    if (!isReady(jobCraft, config)) return null
    if(this.state.jobsWithExclusiveBackgroundMenu === undefined) return null
    let jobsWithExclusiveBackgroundMenu = this.state.jobsWithExclusiveBackgroundMenu
    if(jobsWithExclusiveBackgroundMenu == null) jobsWithExclusiveBackgroundMenu = {}

    
    let jobsWithExclusiveBackgroundMenuDataArray = Object.keys(jobsWithExclusiveBackgroundMenu);
    
    const enabledCategories = jobCraft.enabledCategories()
    console.log("enabledCategories",enabledCategories)
    return (
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column', padding: 10, overflow: 'scroll' }}>
      {
        (enabledCategories.length && !currentJob.craftPaused()) ? (
          jobCraft.enabledCategories()
            .sort((c1, c2) => (
              jobCraft.priorityForCategory({ categoryId: c1.id() }) < jobCraft.priorityForCategory({ categoryId: c2.id() }) ? -1 : 1
            ))
            .filter(c => {

              debugger
              for (let index = 0; index < jobsWithExclusiveBackgroundMenuDataArray.length; index++) {
                const element = jobsWithExclusiveBackgroundMenuDataArray[index];
                if (currentJob.id() === element) {
                  if (me.department() === 'Background') {
                    if (c.name() === 'Background Menu') return true
                    else return false
                  } else {
                    if (c.name() === 'Background Menu') return false
                  }
                } 
              }

              if (currentJob.id() === '8bit') {
                if ([ 'Todays Specials', 'Sandwiches' ].includes(c.name())) {
                  if (
                    [
                      'BUSGONMSqSey4fG2aiv3ULye8FU2', 
                      'T3SybdPgl1auUN4tNYhkU5YvPk83',
                      '4EoCwFuWFnd87TPaEfxzX7JtHx02',
                      'cMLaA77uraavlrdBeHQ6ug8epLT2',
                      '0L8MqxsaMJSbrphaUUwoyueiPU33',
                      'yBI8Xsq2B4NWnncKWdxIbUIqvqQ2'
                    ].includes(me.userId())
                  ) return false
                }
              }

              if (currentJob.id() === 'MOK') {
                if (['Locations', 'Catering', 'Background', 'Transport'].includes(me.department())) {
                  if (c.name() === 'Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'OF') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'BACKGROUND ONLY Menu') return true
                  else return false
                }
              } 

        

              if (currentJob.id() === 'OF') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'BACKGROUND ONLY Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'LGA') {
                if (['Health and Safety', 'Background'].includes(me.department())) {
                  if (c.name() === 'Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'TLS') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'RUBY') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'Background Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'POP') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'Background Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'ES2') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'Background Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'TM') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'Background Menu') return true
                  else return false
                }
              } 

              if (currentJob.id() === 'MMNC') {
                if (['Background'].includes(me.department())) {
                  if (c.name() === 'Background Menu') return true
                  else return false
                }
              } 

              if (['LGA', 'KITH'].includes(currentJob.id())) {
                if (me.department() === 'Background') {
                  if (c.name() === 'Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Menu') return false
                }
              }

              if (currentJob.id() === 'W3') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              }  
              
              if (currentJob.id() === 'OBE') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              }     

              if (currentJob.id() === 'PPS1') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              }     

              if (currentJob.id() === 'W5') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              }     

              if (currentJob.id() === 'FN') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              }     

              if (currentJob.id() === 'CRE') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              } 

              if (currentJob.id() === 'CRES3') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              } 

              if (currentJob.id() === 'DOM') {
                if (me.department() === 'Background') {
                  if (c.name() === 'Background Menu') return true
                  else return false
                } else {
                  if (c.name() === 'Background Menu') return false
                }
              } 

              if (currentJob.id() === 'YLM') {
                if (me.department() === 'Background') {
                  if (c.id() === '-MIF2w9OwF_fcMOVhwc5') return true
                } else {
                  if (c.id() !== '-MIF2w9OwF_fcMOVhwc5') return true
                }
                return false
              }

              if (currentJob.id() === 'GW7') {
                if (c.id() === '-MNzdSNh2cPaYi4_ILRz') {
                  if (
                    [
                      'Cast/Talent', 
                      'Director/Producer', 
                      'Production'
                    ].includes(me.department())
                  ) return true

                  if (
                    [
                      'dQQp7rkM7udCJ7luBboe9Tz4wrU2',
                      'xPnkoYnxwAZvGeS5s15glfvTMiN2'
                    ].includes(me.userId())
                  ) return true

                  return false
                }
              }

              if (config.get(`jobs.${currentJob.id()}.exclusiveCategories.${c.id()}`)) {
                if (config.get(`jobs.${currentJob.id()}.exclusiveCategories.${c.id()}.departments.${me.department().toLowerCase().replace(/\W/g, '')}`))
                  return true
                if (config.get(`jobs.${currentJob.id()}.exclusiveCategories.${c.id()}.individuals.${me.userId()}`))
                  return true
                return false
              }
              
              return true
            })
            .map(category => {
              const numItems = jobCraft.numEnabledItemsForCategory(category.id())
              const numItemsMessage = numItems === 1 
                ? `1 item available`
                : `${numItems} items available`
              return (
                <Card bodyStyle={{ padding: 15 }} style={{ marginBottom: 10 }} onClick={() => goToCategory(category.id())} key={category.id()}>
                  <div style={{ fontSize: 20, fontWeight: '700' }}>{category.name()}</div>
                  <div style={{ color: 'gray' }}>{numItemsMessage}</div>
                </Card> 
              )
            })
        ) : (
          <>
          <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
            <div style={{ fontSize: 23, paddingTop: 25 }}>Hang tight!</div>
            <div style={{ fontSize: 19, paddingTop: 10 }}>We will be back shortly... {!['AROFFICE', 'AR2020'].includes(this.props.currentJob.id()) && 'just preparing the truck to service you.'}</div>
          </div>
          {
            (enabledCategories.length && currentJob.id() === 'V1') ? (
              <div style={{ fontSize: 19, paddingTop: 10, fontWeight: 'bold' }}>Hot and cold drinks are always available at the window.</div>
            ) : null
          }
          </>
        )
      }
      </div>
    )
  }
}))
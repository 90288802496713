import React from 'react'
import { withRouter } from 'react-router-dom'
import { isReady } from '@blast-engine/firework'
import { Button, TextField } from '@material-ui/core'
import { List } from 'antd'
import { Link } from 'react-router-dom'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-on-site-job-list.scss'

export const CraftOnSiteJobList = withRouter(fireworkConnect(props => ({
  jobList: entities.crafter.crafterData.job.fetchJobBasicList({ crafterId: props.crafterId })
}))(class CraftOnSiteJobList extends React.Component {
  state = {
    error: null,
    jobId: ''
  }

  render() {
    const { jobList, history } = this.props
    const { error, jobId } = this.state

    if (!isReady(jobList)) return null
    const jobCodes = jobList.asArray().map(jobBasic => jobBasic.id())

    const tryToFindJob = () => {
      if (jobCodes.includes(jobId)) {
        history.push(`/craft-on-site/jobs/${jobId}`)
      } else {
        this.setState({ error: 'Job Not Found' })
      }
    }

    return (
      <div className='craft-on-site-job-list-container'>
        <img className='background-img' src={require('../../assets/bg.png')} />
          <div className='container'>
            <div className='find-job-text'>FIND JOB</div>
            <div className='input-sections'>
              <TextField 
                onChange={e => this.setState({ jobId: e.target.value })}
                className='input'
                placeholder="Enter your job code"
                label='Job Code'
                variant='filled'
                value={jobId}
                onKeyPress={e => e.key === 'Enter' && tryToFindJob()} 
              />
            </div>
            <Button variant='contained' className='submit-button' onClick={tryToFindJob}>Submit</Button>
            {error ? <div className='error-section'>{error}</div> : null}
          </div>
        {/* <List
          header={<div className='header'>active jobs</div>}
          bordered
          dataSource={jobList.asArray().filter(j => j.status() === 'active')}
          locale={{
            emptyText: 
              <div className='no-jobs-found'>
                No Active Jobs Found. <br />
                Please Contact Your Manager To Activate A Job.
              </div>
          }}
          renderItem={jobBasic => (
            <Link to={`/craft-on-site/jobs/${jobBasic.id()}`} className='each-job'>
              <span className='job-name'>{jobBasic.name()}</span>
              <span className='job-day-num'>{' - '}Job is in Day {Number(jobBasic.currentDay())+1}</span>
            </Link>
          )}
        /> */}
      </div>
    )
  }
}))
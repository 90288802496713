import { ActiveCrewOnsite } from './active-crew-onsite.list'

export const rootMethods = {

  activeCrewOnsiteRef({ crafterId }) {
    return this._spinoff(ActiveCrewOnsite.ref(), {
      path: `crafter/crafterData/${crafterId}/activeCrewOnsite`
    })
  }

}
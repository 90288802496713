import { keys, values } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const CraftGlobalOptionsBasic = createNodeClass({
  name: 'CraftGlobalOptionsBasic',

  full: class {

    id() {
      return this._data('id')
    }

    type() {
      return this._data('type')
    }

    items() {
      return this._data('items') || {}
    }

    label() {
      return this._data('label')
    }

    name() {
      return this.label()
    }

    isRequired() {
      return this._data('isRequired')
    }

    updateIsRequired({ isRequired }) {
      return this._update({ isRequired })
    } 

    updateLabel({ label }) {
      return this._update({ label })
    }

    updateType({ type }) {
      return this._update({ type })
    }

    delete() {
      return this._delete()
    }

    optionItemIds() {
      return values(this._data('items')).filter(optionItem => !optionItem._deleted).map(optionItem => optionItem.id)
    }

    optionItemLabel(optItemId) {
      const optItem = this._data('items')[optItemId]
      if (!optItem) return '(none)'
      return optItem.label
    }

    addOptionItem({ label }) {
      const newItemId = this._fbService.newKey(this._strPath('items'))
      return this._update({ 
        [`items/${newItemId}`]: { id: newItemId, label }
      })
    }

    optionItem({ optionItemId }) {
      return this._data(`items.${optionItemId}`)
    }

    updateOptionItem({ label, optionItemId }) {
      const oldItem = this.optionItem({ optionItemId })
      return this._update({
        [`items/${optionItemId}`]: { 
          ...oldItem,
          id: optionItemId, 
          label 
        }
      })
    }

    removeOptionItem({ optionItemId }) {
      return this._update({
        [`items/${optionItemId}`]: null
      })
    }

    fakeDeleteOption() {
      return this._update({ _deleted: true })
    }

    isOptionFakeDeleted() {
      return !!this._data('_deleted')
    }

    fakeDeleteOptionItem({ optionItemId }) {
      return this._update({ [`items/${optionItemId}/_deleted`]: true })
    }

    isOptionItemFakeDeleted({ optionItemId }) {
      return !!this._data(`items.${optionItemId}._deleted`)
    }

  },

  ref: class {

    initialize({ id, type, label, items = [] }) {
      const itms = items.reduce((acc, item) => {
        const key = this._fbService.newKey(this._strPath('items'))
        acc[key] = {
          id: key,
          label: item
        }
        return acc
      }, {})
      return this._update({ id, type, label, items: itms })
    }

  }

})
import { set, get, v } from '@blast-engine/utils'
import { createGTStateModule, generateActions, generateGetters, generateGetter } from '@blast-engine/gt-store'
import { gts } from '@/gt-store.service'

const baseM = createGTStateModule('base')

const queries = {}
const transitions = {}

transitions.update = baseM.transition(({ state, args }) => {
  const update = args
  return set(state, update)
})

queries.currentOrders = baseM.query({
  type: 'current-orders',
  deriveValue: ({ state }) => {
    return v(get(state, 'craftOrders'))
  }
})

queries.get = baseM.query({
  type: 'getter-query',
  argsAreEquivalent: (args1, args2) => {
    return args1 === args2
  },
  deriveValue: ({ state, args }) => {
    const path = typeof args === 'string' ? args : ''
    return get(state, path)
  }
})

transitions.populateJobMenu = baseM.transition(({ state, args }) => {
  const { jobMenu } = args
  return set(state, { jobMenu  })
})

const getters = generateGetters(queries, gts)
const actions = generateActions(transitions, gts)
const getModuleState = generateGetter(baseM.moduleStateF, gts)

export { baseM, queries, transitions, getters, actions, getModuleState }
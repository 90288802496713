import { keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { subsGlobalMenu } from './subs-global-menu'
import { job } from './job'
import { user } from '../../user'

export const DayJobBreakfastMenuDayComplete = createAssemblyClass({
  name: 'DayJobBreakfastMenuDayComplete',
  memberModels: {
    jobBasic: job.JobBasic.full(),
    subGlobalMenu: subsGlobalMenu.CrafterSubsGlobalMenu,
    breakfastMenuItemListForDay: job.jobData.BreakfastMenuItemList,
    mePrivate: user.UserPrivate.full(),
    breakfastOrdersForDayRef: job.jobData.breakfastOrders.BreakfastOrdersForJobDay.ref()
  },
  portMethods: {

  },
  body: class {

    globalMenu() {
      return this.members.subGlobalMenu
    }

    jobMenu() {
      return this.members.breakfastMenuItemListForDay
    }

    enabledBreakfastIds() {
      const { breakfastMenuItemListForDay } = this.members
      return breakfastMenuItemListForDay.asArray()
        .filter(breakfastItem => breakfastItem.enabled())
        .map(breakfastItem => breakfastItem.id())
    }

    enabledBreakfastOptionIds(breakfastId) {
      const { breakfastMenuItemListForDay } = this.members
      const breakfastItem = breakfastMenuItemListForDay.item(breakfastId)
      return breakfastItem.enabledOptionIds()
    }

    enabledOptionItemIds({ breakfastId, optId }) {
      const { breakfastMenuItemListForDay, subGlobalMenu } = this.members
      const optionItems = subGlobalMenu.option(optId).items()
      const optionItemIds = keys(optionItems)
      const jobBreakfastItem = breakfastMenuItemListForDay.item(breakfastId)
      return optionItemIds
        .filter(optionItemId => jobBreakfastItem.isOptionItemEnabled({ 
          optionId: optId, 
          optionItemId 
        }))
    }

    breakfastOptionName(optId) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optId).label()
    }

    breakfastName(subId) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.subName(subId)
    }

    breakfastStatus(breakfastId) {
      const { breakfastMenuItemListForDay } = this.members
      const jobBreakfastItem = breakfastMenuItemListForDay.item(breakfastId)
      return jobBreakfastItem.status()
    }

    optionType(optionId){
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optionId).type()
    }

    optionItemLabel({ optionId, optionItemId }) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optionId).itemLabel(optionItemId)
    }

    orderBreakfast({ breakfastId, optionSelections = null, notes = '' }) {
      const { jobBasic, breakfastOrdersForDayRef, mePrivate } = this.members
      return [
        mePrivate.addJobBreakfastOrderForDay({ 
          jobId: jobBasic.id(), 
          day: jobBasic.currentDay(), 
          breakfastId 
        }),
        breakfastOrdersForDayRef.addBreakfastOrder({ 
          breakfastId, 
          userId: mePrivate.id(), 
          optionSelections,
          notes
        })
      ] 
    }

  }
})
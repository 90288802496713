import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'


import './forgot-password.scss'

import { fireworkConnect, kernel } from '@/kernel.singleton'

export const ForgotPassword = responsiveComponent(fireworkConnect(() => ({
  me: entities.user.fetchMe()
}))(
class ForgotPassword extends React.Component {

  state = {
    done: false,
    email: '',
    password: '',
    error: null
  }

  render() {
    const { me, isMobile } = this.props
    const { email, password, error, done } = this.state

    if (!me) return null
    // if (!me.isAnon()) return <Redirect to='/app' />

    const tryToSendEmail = async () => {
      await this.setState({ done: null, error: null })
      kernel.firebase.auth().sendPasswordResetEmail(email)
        .then(() => this.setState({ done: true }))
        .catch((res) => this.setState({ error: res.message }))
    }

    return (
      <div className='login-section-comp'>
        <img className='background-img' src={require('../../assets/bg.png')} />
        
        <div className='body'>
          <Card
            title={
              <div className='modal-header-section'>
                <div className='login'>Forgot Password</div>
                <img src={require('../../assets/Logo.png')} className='logo' />
              </div>
            }
            style={{ width: isMobile ? '80vw' : '30vw', transition: '1s' }}
          >
            <TextField 
              variant='outlined' 
              label='Email Address' 
              required
              placeholder='Email Address' 
              value={email} 
              onChange={(e) => this.setState({ email: e.target.value })}
              type='email' 
              style={{ width: '100%' }}
              onKeyPress={e => e.key === 'Enter' && tryToSendEmail()}
            />
            { error && <div className='error'>{error}</div> }
            { done && <div style={{ color: 'green' }}>Email sent. Follow link in email.</div> }
            <Button
              className={`button-custom-class${isMobile ? ' mobile' : ''}`}
              variant='contained'
              onClick={tryToSendEmail}
            >
              Submit
            </Button>
            <div className='forgot-pw-section' style={{ paddingTop: '10px' }}>
              <Link className='signup' to="/login">Go back to Login</Link>
            </div>
            <div className='signup-section' >
              Don't have an account? <Link className='signup' to="/signup">Sign up</Link>
            </div>

          </Card>
        </div>
        
      </div>
    )
  }
}))
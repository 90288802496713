import { TextNotifRequestList } from './text-notif-request.list'

export const rootMethods = {

  textNotifRequestsRef() {
    return this._spinoff(TextNotifRequestList.ref(), {
      path: `textNotifRequest`
    })
  }

}
import { CraftyData } from './crafty-data.node'

export const rootMethods = {

  craftyDataRef() {
    return this._spinoff(CraftyData.ref(), {
      path: `crafty`
    })
  }

}
import { createNodeClass } from '@blast-engine/firework'
import { kv, get, set, keys, objMap } from '@blast-engine/utils'

import { BreakfastOrder } from './breakfast-order.node'

export const AllBreakfastOrders = createNodeClass({
  name: 'AllBreakfastOrders',

  full: class {

    ordersForDay(dayNum) {
      return this._data(dayNum)
    }

    // @todo: firework: firebase will return arrays if the keys are numbers. stop this from happening.
    order({ userId, breakfastId, dayNum }) {
      if (!this.data) return undefined
      return get(this.data[dayNum], `orders.${breakfastId}.${userId}`)
    }

    order_({ userId, breakfastId, dayNum }) {
      return this._spinoff(BreakfastOrder.full(), {
        path: this._path([ dayNum, 'orders', breakfastId, userId ]),
        data: this.order({ userId, breakfastId, dayNum })
      })
    }
    
    orderIsPlaced({ userId, breakfastId, dayNum }) {
      return !!this.order({ userId, breakfastId, dayNum })
    }

    orderPickupTime({ userId, breakfastId, dayNum }) {
      const o = this.order({ userId, breakfastId, dayNum })
      if (!o) return
      return o.pickupTime()
    }

    addBreakfastOrder({ dayNum, breakfastId, userId, optionSelections, notes }) {
      return this._update({
        [`${dayNum}/orders/${breakfastId}/${userId}`]: {
          _: true,
          breakfastId, 
          userId,
          optionSelections,
          notes
        }
      })
    }

    removeBreakfastOrder({ dayNum, breakfastId, userId }) {
      return this._update({
        [`${dayNum}/orders/${breakfastId}/${userId}`]: null
      })
    }

    _waitingForParts() {
      return false
    }

  },

  ref: class {


  }

})
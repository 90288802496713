import { SubOrder } from './sub-order.node'
import { SubOrdersForJobDay } from './sub-orders-for-job-day.node'
import { fetchSubOrder } from './sub-order.node.fetcher'
import { AllSubOrders } from './all-sub-orders.node'
import { rootMethods } from './root-methods'

export const subOrders = {
  SubOrder,
  SubOrdersForJobDay,
  AllSubOrders,
  fetchSubOrder,
  rootMethods
}
import { createFetcher } from '@blast-engine/firework'

import { Locations } from './locations.assembly'

export const fetchLocations = createFetcher({
  name: 'fetch-locations'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'locationNames',
    query: ({ root }) => root.crafterJobLocationNames({ crafterId, jobId }).query()
  }],
  final: {
    take: [ 'locationNames' ],
    instantiate: ({ root, locationNames }) => root._spinoff(Locations, {
      members: { locationNames }
    })
  }
}))
import { createNodeClass } from '@blast-engine/firework'
import { kv } from '@blast-engine/utils'

import { Basket } from '../basket/basket.node'

export const CraftOrder = createNodeClass({
  name: 'CraftOrder',

  full: class {

    id() {
      return this._data('id')
    }

    userId() {
      return this._data('userId')
    }

    status() {
      return this._data('status') || 'waiting'
    }

    setStatusAsReady() {
      return this._update({ status: 'ready-for-pickup' })
    }

    setStatusAsDone() {
      return this._update({ status: 'done' })
    }

    isPending() {
      return ['waiting', 'pending'].includes(this.status())
    }
    
    isReadyForPickup() {
      return ['ready-for-pickup'].includes(this.status())
    }

    isDone() {
      return ['ready', 'done'].includes(this.status())
    }

    isActive() {
      return (this.isPending() || this.isReadyForPickup())
    }

    doneTimestamp() {
      return this._data('doneTimestamp')
    }

    updateDoneTimestamp() {
      return this._update({ doneTimestamp: Date.now() })
    }

    basket() {
      return this._spinoff(Basket, {
        data: this._data('basketData'),
        path: this._path('basketData')
      })
    }

    timestamp() {
      return this._data('timestamp')
    }

  },

  ref: class {

    initialize({ id, userId, basket, ...more }) {
      if (!basket || !basket.isLoaded()) throw new Error('needs basket')
      return this._update({ 
        ...more,
        id, 
        status: 'waiting', 
        userId, 
        basketData: basket.data, 
        timestamp: Date.now()
      })
    }

  }

})
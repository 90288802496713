import { createNodeClass } from '@blast-engine/firework'

export const UserEmail = createNodeClass({
  name: 'UserEmail',

  struct: class {

    id() {
      return this._data('userId')
    }

    userId() {
      return this.id()
    }
    
  },

  ref: class {

    initialize({ id }) {
      return this._update({ userId: id })
    }

  },

  full: class { 

  }
})
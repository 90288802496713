import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { BasketItemComplete } from './basket-item-complete.assembly'
import { craftMenu } from './craft-global-menu'
import { job } from './job'
import { user } from '../../user'

export const BasketComplete = createAssemblyClass({
  name: 'BasketComplete',
  memberModels: {
    job: job.JobBasic.full(),
    craftGlobalMenu: craftMenu.CrafterCraftGlobalMenu,
    craftOrders: job.jobData.craftOrders.CraftOrderList.ref(),
    basket: job.jobData.basket.Basket.full(),
    userBasic: user.UserBasic.ref(),
    userPrivate: user.UserPrivate.ref()
  },
  portMethods: {
    basket: [ 'addOrderItem', 'hasItems' ]
  },
  body: class {

    notesAreEnabled() {
      return !!this.members.job.notesForBasketAreEnabled()
    }

    enableNotes() {
      return this.members.job.enableNotesForBasket()
    }

    disableNotes() {
      return this.members.job.disableNotesForBasket()
    }

    items() {
      const itemBasics = this.members.basket.items()
      return itemBasics.map(item => this._spinoff(BasketItemComplete, {
        members: { item, craftGlobalMenu: this.members.craftGlobalMenu }
      }))
    }

    submitOrder() {
      const { job, craftOrders, basket, userPrivate, userBasic } = this.members
      const newOrderId = this._fb.newKey()
      // debugger
      if (!basket.hasItems()) return []
      return [ 
        craftOrders.add({ 
          newItemId: newOrderId, 
          initArgs: { 
            basket, 
            userId: userPrivate.id(),
            userName: userBasic.username(),
            userDept: userBasic.department(),
            userProvidedPhoneNumber: userBasic.hasProvidedPhoneNumber()
          } 
        }), 
        basket.clear(),
        userPrivate.addJobOrderForDay({
          jobId: job.id(),
          day: job.currentDay(),
          orderId: newOrderId
        })
      ]
    }

    removeItem(itemId) {
      return this.members.basket.removeOrderItem(itemId)
    }

    notes() {
      return this.members.basket.notes()
    }

    addNotes({ notes = '' }) {
      return this.members.basket.addNotes({ notes })
    }
    
  }
})
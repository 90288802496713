import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField, Icon, CircularProgress, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'
import { Card } from 'antd'
import LogRocket from 'logrocket'
import { responsiveComponent } from '@blast-engine/responsive-component'
import MuiPhoneNumber from 'material-ui-phone-number'
import { isReady } from '@blast-engine/firework'
import { wait } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { sendTextMessage } from '@/text-messages.service'

import './user-info-request.scss'

export const Department = 
  responsiveComponent(
  fireworkConnect(() => ({
    me: entities.user.fetchMe()
  }))(
class Department extends React.PureComponent {

  state = { dept: null, other: '' }

  render() {
    const { isMobile, me } = this.props
    const { dept, other } = this.state 
    
    const getDept = () => {
      if (dept && dept !== 'Other') return dept
      else if (dept === 'Other' && other) return other
      return null
    }

    const tryToSubmit = async () => {
      kernel.performUpdates([ me.setDepartment(getDept()) ])
    }

    if (!isReady(me)) return null

    return (
      <div className='login-section-comp'>
        <img className='background-img' src={require('../../../assets/bg.png')} />
        
        <div className='body'>
          <Card
            title={
              <div className='modal-header-section'>
                <div className='login'>choose department</div>
              </div>
            }
            style={{ width: isMobile ? '80vw' : '30vw', transition: '1s' }}
          >
            <div>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-helper-label">Choose Department</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  placeholder='Department'
                  value={dept}
                  onChange={e => this.setState({ dept: e.target.value })}
                >
                  <MenuItem value={'Director/Producer'}>Director/Producer</MenuItem>
                  <MenuItem value={'Assistant Director'}>Assistant Director</MenuItem>
                  <MenuItem value={'Production'}>Production</MenuItem>
                  <MenuItem value={'Post-Production'}>Post-Production</MenuItem>
                  <MenuItem value={'Casting'}>Casting</MenuItem>
                  <MenuItem value={'Cast/Talent'}>Cast/Talent</MenuItem>
                  <MenuItem value={'Background'}>Background</MenuItem>
                  <MenuItem value={'Publicity'}>Publicity</MenuItem>
                  <MenuItem value={'Hair and Make-up'}>Hair and Make-up</MenuItem>    
                  <MenuItem value={'Costumes'}>Costumes</MenuItem>   
                  <MenuItem value={'Camera'}>Camera</MenuItem> 
                  <MenuItem value={'Sound'}>Sound</MenuItem>   
                  <MenuItem value={'Stand in'}>Sound</MenuItem>   
                  <MenuItem value={'Lighting'}>Lighting</MenuItem> 
                  <MenuItem value={'Grips'}>Grips</MenuItem>   
                  <MenuItem value={'Props'}>Props</MenuItem>   
                  <MenuItem value={'Transport'}>Transport</MenuItem>   
                  <MenuItem value={'Craft'}>Craft</MenuItem>   
                  <MenuItem value={'Art'}>Art</MenuItem>   
                  <MenuItem value={'Continuity'}>Continuity</MenuItem>
                  <MenuItem value={'Music'}>Music</MenuItem>     
                  <MenuItem value={'VFX'}>VFX</MenuItem>     
                  <MenuItem value={'Construction'}>Construction</MenuItem>    
                  <MenuItem value={'Health and Safety'}>Health and Safety</MenuItem> 
                  <MenuItem value={'Locations'}>Locations</MenuItem>     
                  <MenuItem value={'Accounting'}>Accounting</MenuItem>    
                  <MenuItem value={'Other'}>Other</MenuItem>                 
                </Select>
                <FormHelperText>What is your department?</FormHelperText>
              </FormControl>
              {
                dept === 'Other' ? (
                  <FormControl style={{ width: '100%', paddingTop: 40 }}>
                    <TextField placeholder={'Please Specify'} value={other} onChange={e => this.setState({ other: e.target.value })}/>
                  </FormControl>
                ) : null
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 40 }}>
              <Button 
                variant='contained' 
                color='primary'
                disabled={!getDept()}
                onClick={tryToSubmit}
              >
                Submit
              </Button>
            </div>
          </Card>
        </div>
        
      </div>
    )
  }
}))
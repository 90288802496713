import { createFetcher } from '@blast-engine/firework'

import { CrafterLunchGlobalMenu } from './crafter-lunch-global-menu-manager.assembly'

export const fetchLunchGlobalMenu = createFetcher({
  name: 'fetch-lunch-global-menu'
}, ({ crafterId }) => ({
  steps: [{
    name: 'items',
    query: ({ root }) => root.lunchGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'options',
    query: ({ root }) => root.lunchGlobalOptionsRef({ crafterId }).query()
  }],
  final: {
    take: [ 'items', 'options' ],
    instantiate: ({ items, options, root }) => 
      root._spinoff(CrafterLunchGlobalMenu, { 
        members: { items, options }
      })
  }
}))
import { createFetcher } from '@blast-engine/firework'

import { CrafterBreakfastGlobalMenu } from './crafter-breakfast-global-menu-manager.assembly'

export const fetchBreakfastGlobalMenu = createFetcher({
  name: 'fetch-breakfast-global-menu'
}, ({ crafterId }) => ({
  steps: [{
    name: 'items',
    query: ({ root }) => root.breakfastGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'options',
    query: ({ root }) => root.breakfastGlobalOptionsRef({ crafterId }).query()
  }],
  final: {
    take: [ 'items', 'options' ],
    instantiate: ({ items, options, root }) => 
      root._spinoff(CrafterBreakfastGlobalMenu, { 
        members: { items, options }
      })
  }
}))
import { createFetcher } from '@blast-engine/firework'

import { BasketComplete } from './basket-complete.assembly'
import { craftMenu } from './craft-global-menu'

export const fetchBasketComplete = createFetcher({
  name: 'fetch-basket-complete'
}, ({ crafterId, jobId, userId }) => ({
  steps: [{
    name: 'userBasic',
    query: ({ root }) => root.userBasicRef({ userId }).query()
  }, {
    name: 'userPrivate',
    query: ({ root }) => root.userPrivateRef({ userId }).query()
  }, {
    name: 'job',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  },{
    name: 'globalCategories',
    query: ({ root }) => root.craftGlobalCategoryBasicListRef({ crafterId }).query()
   }, {
    name: 'globalItems',
    query: ({ root }) => root.craftGlobalItemBasicListRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.craftGlobalOptionsBasicListRef({ crafterId }).query()
  }, {
    name: 'basket',
    query: ({ root }) => root.basketRef({ crafterId, jobId, userId }).query()
  }],
  final: {
    take: [ 'userPrivate', 'job', 'globalCategories', 'globalItems', 'userBasic', 'globalOptions', 'basket' ],
    instantiate: ({ userPrivate, job, globalCategories, globalItems, userBasic, globalOptions, basket, root }) => 
      root._spinoff(BasketComplete, { 
        members: { 
          craftGlobalMenu: root._spinoff(craftMenu.CrafterCraftGlobalMenu, {
            members: {
              categories: globalCategories, 
              items: globalItems, 
              options: globalOptions,
            }
          }),
          basket,
          craftOrders: root.craftOrdersRef({ crafterId, jobId, day: job.currentDay() }),
          userPrivate,
          userBasic,
          job
        }
      })
  }
}))
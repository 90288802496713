import { keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { subsGlobalMenu } from './subs-global-menu'
import { job } from './job'
import { user } from '../../user'

export const JobSubOrdersComplete = createAssemblyClass({
  name: 'JobSubOrdersComplete',
  memberModels: {
    jobBasic: job.JobBasic.full(),
    subGlobalMenu: subsGlobalMenu.CrafterSubsGlobalMenu,
    subMenuItemListForDay: job.jobData.SubMenuItemList,
    userBasics: user.UserBasicList.full(),
    subOrdersForDay: job.jobData.subOrders.SubOrdersForJobDay.full(),
  },
  portMethods: {},
  body: class {
    isMealPickedUp({ userId, subOrderId }) {
      return this.members.subOrdersForDay.isOrderPickedup({
        userId,
        subId: subOrderId,
      })
    }

    markMealAsPickedUp({ userId, subOrderId }) {
      return this.members.subOrdersForDay.setOrderAsPickedUp({
        userId,
        subId: subOrderId,
      })
    }

    markMealAsNotPickedUp({ userId, subOrderId }) {
      return this.members.subOrdersForDay.setOrderAsNotPickedUp({
        userId,
        subId: subOrderId,
      })
    }

    perms({ filterPickupTimes = [] } = {}) {
      // debugger
      const activeSubOrders = this.activeSubOrders({ filterPickupTimes })
      const duplicatePerms = activeSubOrders.reduce((acc, activeOrder) => {
        if (activeOrder.length === 1) {
          // if (!activeOrder[0].userBasic.data) return acc
          acc.push({
            orderNotes: activeOrder[0].subOrderItem.notes(),
            pickupTime: activeOrder[0].subOrderItem.pickupTime(),
            usernames: [activeOrder[0].userBasic.username()],
            users: [
              {
                username: activeOrder[0].userBasic.username(),
                department: activeOrder[0].userBasic.department() || '',
                userBasic: activeOrder[0].userBasic,
              },
            ],
            orderSubId: activeOrder[0].order.id(),
            orderType: activeOrder[0].orderType,
            orderName: activeOrder[0].order.name(),
            orderOptions: activeOrder[0].options.map((option) => ({
              optionName: option.option.label(),
              optionItems: option.selectedOptionItems,
            })),
          })
          return acc
        }

        for (let i = 0; i < activeOrder.length; i++) {
          let userNames = [activeOrder[i].userBasic.username()]
          // if (!activeOrder[i].userBasic.username()) return acc
          let users = [
            {
              username: activeOrder[i].userBasic.username(),
              department: (activeOrder[i].userBasic.department() || '').trim(),
              userBasic: activeOrder[i].userBasic,
            },
          ]
          const o1Name = activeOrder[i].order.name()
          const o1Options = activeOrder[i].options.map((option) => ({
            optionName: option.option.label(),
            optionItems: option.selectedOptionItems,
          }))
          const o1Notes = activeOrder[i].subOrderItem.notes()
          for (let j = i + 1; j < activeOrder.length; j++) {
            const o2Name = activeOrder[j].order.name()
            const o2Options = activeOrder[j].options.map((option) => ({
              optionName: option.option.label(),
              optionItems: option.selectedOptionItems,
            }))
            const o2Notes = activeOrder[j].subOrderItem.notes()
            if (
              o2Name === o1Name &&
              !o2Notes &&
              !o1Notes &&
              JSON.stringify(o2Options) === JSON.stringify(o1Options)
            ) {
              // if (!activeOrder[j].userBasic.username()) return acc
              userNames.push(activeOrder[j].userBasic.username())
              const exists = acc.find(
                (i) =>
                  i.orderName === o1Name &&
                  !i.orderNotes &&
                  !o1Notes &&
                  JSON.stringify(i.orderOptions) === JSON.stringify(o1Options)
              )
              if (!exists) {
                users.push({
                  username: activeOrder[j].userBasic.username(),
                  department: (
                    activeOrder[j].userBasic.department() || ''
                  ).trim(),
                  userBasic: activeOrder[j].userBasic,
                })
              }
            }
          }
          const exists = acc.find(
            (i) =>
              i.orderName === o1Name &&
              !i.orderNotes &&
              !o1Notes &&
              JSON.stringify(i.orderOptions) === JSON.stringify(o1Options)
          )
          if (exists) {
            // debugger
            exists.usernames = [...new Set(exists.usernames.concat(userNames))]
            // exists.users = [...new Set(exists.users.concat(users))]
          } else
            acc.push({
              usernames: userNames,
              users,
              orderName: o1Name,
              orderNotes: o1Notes,
              orderType: activeOrder[i].orderType,
              orderSubId: activeOrder[i].order.id(),
              orderOptions: o1Options,
            })
        }
        return acc
      }, [])

      return [
        ...new Set(duplicatePerms.map((i) => JSON.stringify(i))),
      ].map((i) => JSON.parse(i))
    }

    permsWithPickupTimes({ filterPickupTimes = [] } = {}) {
      // debugger
      const activeSubOrders = this.activeSubOrders({ filterPickupTimes })
      const duplicatePerms = activeSubOrders.reduce((acc, activeOrder) => {
        if (activeOrder.length === 1) {
          // if (!activeOrder[0].userBasic.data) return acc
          acc.push({
            orderNotes: activeOrder[0].subOrderItem.notes(),
            pickupTime: activeOrder[0].subOrderItem.pickupTime(),
            usernames: [activeOrder[0].userBasic.username()],
            users: [
              {
                username: activeOrder[0].userBasic.username(),
                department: activeOrder[0].userBasic.department() || '',
                userBasic: activeOrder[0].userBasic,
              },
            ],
            orderSubId: activeOrder[0].order.id(),
            orderType: activeOrder[0].orderType,
            orderName: activeOrder[0].order.name(),
            orderOptions: activeOrder[0].options.map((option) => ({
              optionName: option.option.label(),
              optionItems: option.selectedOptionItems,
            })),
          })
          return acc
        }

        for (let i = 0; i < activeOrder.length; i++) {
          let userNames = [activeOrder[i].userBasic.username()]
          // if (!activeOrder[i].userBasic.username()) return acc
          let users = [
            {
              username: activeOrder[i].userBasic.username(),
              department: (activeOrder[i].userBasic.department() || '').trim(),
              userBasic: activeOrder[i].userBasic,
            },
          ]
          const o1Name = activeOrder[i].order.name()
          const o1Options = activeOrder[i].options.map((option) => ({
            optionName: option.option.label(),
            optionItems: option.selectedOptionItems,
          }))
          const o1Notes = activeOrder[i].subOrderItem.notes()
          const o1pickupTime = activeOrder[i].subOrderItem.pickupTime()
          for (let j = i + 1; j < activeOrder.length; j++) {
            const o2Name = activeOrder[j].order.name()
            const o2Options = activeOrder[j].options.map((option) => ({
              optionName: option.option.label(),
              optionItems: option.selectedOptionItems,
            }))
            const o2Notes = activeOrder[j].subOrderItem.notes()
            const o2pickupTime = activeOrder[j].subOrderItem.pickupTime()
            if (
              o2Name === o1Name &&
              !o2Notes &&
              !o1Notes &&
              JSON.stringify(o2Options) === JSON.stringify(o1Options)
            )
              if (
                o2Name === o1Name &&
                !o2pickupTime &&
                !o1pickupTime &&
                JSON.stringify(o2Options) === JSON.stringify(o1Options)
              ) {
                // if (!activeOrder[j].userBasic.username()) return acc
                userNames.push(activeOrder[j].userBasic.username())
                const exists = acc.find(
                  (i) =>
                    i.orderName === o1Name &&
                    !i.orderNotes &&
                    !o1Notes &&
                    !o1pickupTime &&
                    JSON.stringify(i.orderOptions) === JSON.stringify(o1Options)
                )
                if (!exists) {
                  users.push({
                    username: activeOrder[j].userBasic.username(),
                    department: (
                      activeOrder[j].userBasic.department() || ''
                    ).trim(),
                    userBasic: activeOrder[j].userBasic,
                  })
                }
              }
          }

          const exists = acc.find(
            (i) =>
              i.orderName === o1Name &&
              !i.pickupTime &&
              !o1pickupTime &&
              JSON.stringify(i.orderOptions) === JSON.stringify(o1Options)
          )
          if (exists) {
            // debugger
            exists.usernames = [...new Set(exists.usernames.concat(userNames))]
            // exists.users = [...new Set(exists.users.concat(users))]
          } else
            acc.push({
              usernames: userNames,
              users,
              orderName: o1Name,
              orderNotes: o1Notes,
              pickupTime: o1pickupTime,
              orderType: activeOrder[i].orderType,
              orderSubId: activeOrder[i].order.id(),
              orderOptions: o1Options,
            })
        }
        return acc
      }, [])

      return [
        ...new Set(duplicatePerms.map((i) => JSON.stringify(i))),
      ].map((i) => JSON.parse(i))
    }

    activeSubOrders({ filterPickupTimes = [] } = {}) {
      const {
        userBasics,
        subGlobalMenu,
        subOrdersForDay,
        subMenuItemListForDay,
      } = this.members

      return subMenuItemListForDay.asArray().map((subMenuItem) =>
        subOrdersForDay
          .subOrders({ subId: subMenuItem.id() })
          .asArray()
          .filter(
            (subOrderItem) =>
              subOrderItem.status() === 'waiting' || !subOrderItem.status()
          )
          .filter(
            (soi) =>
              !filterPickupTimes.length ||
              filterPickupTimes.includes(soi.pickupTime())
          )
          .map((subOrderItem) => ({
            userBasic: userBasics.item(subOrderItem.userId()) || {
              username: () => '',
              department: () => '',
            },
            order: subGlobalMenu.members.items.item(subOrderItem.subId()),
            subOrderItem,
            orderType: subMenuItem.label(),
            options: subOrderItem.selectionOptionIds().map((optionId) => ({
              option: subGlobalMenu.members.options.item(optionId),
              selectedOptionItems: subOrderItem.selectionOptionItemIdsForOptionId(
                optionId
              ),
            })),
          }))
      )
    }

    enabledSubIds() {
      const { subMenuItemListForDay } = this.members
      return subMenuItemListForDay
        .asArray()
        .filter((subItem) => subItem.enabled())
        .map((subItem) => subItem.id())
    }

    enabledSubOptionIds(subId) {
      const { subMenuItemListForDay } = this.members
      const subItem = subMenuItemListForDay.item(subId)
      return subItem.enabledOptionIds()
    }

    enabledOptionItemIds({ subId, optId }) {
      const { subMenuItemListForDay, subGlobalMenu } = this.members
      const optionItems = subGlobalMenu.option(optId).items()
      const optionItemIds = keys(optionItems)
      const jobSubItem = subMenuItemListForDay.item(subId)
      return optionItemIds.filter((optionItemId) =>
        jobSubItem.isOptionItemEnabled({
          optionId: optId,
          optionItemId,
        })
      )
    }

    subOptionName(optId) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optId).label()
    }

    subName(subId) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.subName(subId)
    }

    ordersForSub({ subId }) {
      const { subOrdersForDay } = this.members
      return subOrdersForDay.subOrders({ subId })
    }
  },
})

import { craftMenu } from './craft-global-menu'
import { subsGlobalMenu } from './subs-global-menu'
import { breakfastGlobalMenu } from './breakfast-global-menu'
import { lunchGlobalMenu } from './lunch-global-menu'
import { crafterMember } from './members'
import { job } from './job'
import { activeCrewOnSite } from './active-crew-onsite'
import { BasketComplete } from './basket-complete.assembly'
import { DayJobSubMenuDayComplete } from './job-subs-menu-day.assembly'
import { fetchBasketComplete } from './basket-complete.assembly.fetcher'
import { fetchJobCraftMenuManager } from './job-craft-menu-manager.assembly.fetcher'
import { fetchCraftOrdersComplete } from './orders-complete.assembly.fetcher'
import { fetchJobSubMenuDayComplete } from './job-subs-menu-day.assembly.fetcher'

import { JobOrdersComplete } from './job-orders.complete'
import { fetchJobOrdersComplete } from './job-orders.complete.fetcher'

import { JobSubOrdersComplete } from './job-sub-orders.assembly'
import { fetchJobSubOrdersComplete } from './job-sub-orders.assembly.fetcher'

import { JobBreakfastOrdersComplete } from './job-breakfast-orders.assembly'
import { fetchJobBreakfastOrdersComplete} from './job-breakfast-orders.assembly.fetcher'

import { DayJobBreakfastMenuDayComplete } from './job-breakfast-menu-day.assembly'
import { fetchJobBreakfastMenuDayComplete } from './job-breakfast-menu-day.assembly.fetcher'

import { fetchJobCateringComplete } from './job-catering.assembly.fetcher'
import { JobCatering } from './job-catering.assembly'

export const crafterData = {
  crafterMember,
  job,
  craftMenu,
  subsGlobalMenu,
  breakfastGlobalMenu,
  lunchGlobalMenu,
  activeCrewOnSite,
  BasketComplete,
  fetchBasketComplete,
  fetchJobCraftMenuManager,
  fetchCraftOrdersComplete,

  JobOrdersComplete,
  fetchJobOrdersComplete,
  DayJobSubMenuDayComplete,
  fetchJobSubMenuDayComplete,

  DayJobBreakfastMenuDayComplete,
  fetchJobBreakfastMenuDayComplete,

  JobSubOrdersComplete,
  fetchJobSubOrdersComplete,

  JobBreakfastOrdersComplete,
  fetchJobBreakfastOrdersComplete,

  fetchJobCateringComplete,
  JobCatering
}
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField, Icon, CircularProgress, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'
import { Card } from 'antd'
import LogRocket from 'logrocket'
import { responsiveComponent } from '@blast-engine/responsive-component'
import MuiPhoneNumber from 'material-ui-phone-number'
import { isReady } from '@blast-engine/firework'
import { wait } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { sendTextMessage } from '@/text-messages.service'

import './user-info-request.scss'

export const BlockUser = 
  responsiveComponent(
  fireworkConnect(() => ({
    me: entities.user.fetchMe()
  }))(
class BlockUser extends React.PureComponent {

  state = {}

  render() {
    const { me, currentJob, renderContent, isMobile } = this.props
    const {} = this.state 
    
    if (!isReady(me, currentJob)) return null

    if (!(currentJob.id() === 'SK' && me.department() === 'Background'))
      return renderContent()

    return (
      <div className='login-section-comp'>
        <img className='background-img' src={require('../../../assets/bg.png')} />
        
        <div className='body'>
          <Card
            title={
              <div className='modal-header-section'>
                <div className='login'>No Access</div>
              </div>
            }
            style={{ width: isMobile ? '80vw' : '30vw', transition: '1s' }}
          >
            <div>
            <div style={{ paddingBottom: 19 }}>The Crafty app has been disabled for background for this job ({ currentJob.name() }).</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', paddingTop: 40 }}>
              <Button 
                variant='contained' 
                color='primary'
                onClick={() => kernel.logout()}
              >
                Log Out
              </Button>
              <Button 
                variant='contained' 
                color='primary'
                onClick={() => kernel.performUpdates(me.setCurrentJob(null))}
              >
                Exit Job
              </Button>
            </div>
          </Card>
        </div>
        
      </div>
    )
  }
}))
import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import {
  Select,
  Button,
  MenuItem,
  TextField,
  Input,
  Accordion,
  AccordionSummary,
  Typography,
  Card,
  AccordionDetails,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import LogRocket from 'logrocket'
import { Modal } from 'antd'

import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'
import { get, values } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

export const SubsPage = responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    jobCatering: entities.crafter.crafterData.fetchJobCateringComplete({
      crafterId: currentJob.crafterId(),
      jobId: currentJob.id(),
      userId: me.userId(),
    }),
    jobSubOrderComplete: entities.crafter.crafterData.fetchJobSubOrdersComplete(
      {
        crafterId: currentJob.crafterId(),
        jobId: currentJob.id(),
        day: currentJob.currentDay(),
      }
    ),
  }))(
    class SubsPage extends React.PureComponent {
      state = {}

      render() {
        const {
          // props
          me,
          currentJob,
          goToItem = () => null,
          // data
          jobCatering,
          jobSubOrderComplete,
        } = this.props
        const { clearOrderConfirm } = this.state

        if (!isReady(jobCatering)) return null

        LogRocket.log({
          event: 'SubsPage render()',
          props: { jobCatering },
          state: {},
        })

        const submitRemoveOrder = async ({ dayNum, subIds }) => {
          const cs = this.state.currentlySubmitting || []
          this.setState({
            currentlySubmitting: cs.concat({ dayNum }),
          })
          LogRocket.log({
            event: 'SubItemsPage submitRemoveOrder()',
            props: { dayNum, subIds },
            state: {},
          })
          await kernel.performUpdates(
            jobCatering.removeSubOrder({ dayNum, subIds })
          )
          setTimeout(() => {
            this.setState({
              currentlySubmitting: cs.filter((d) => d.dayNum !== dayNum),
            })
          }, 300)
        }

        window.jobCatering = jobCatering

        return (
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              padding: 10,
              overflow: 'scroll',
            }}
          >
            {/* Disabled Days */}
            {['JR1', 'JR2', 'MOK'].includes(currentJob.id()) &&
              jobCatering
                .allDays()
                .filter((day) => !day.canOrderCatering() && !day.canViewDay())
                .map((day, i) => {
                  const isToday = i === 0
                  const isTomorrow = i === 1
                  const jobSubs = day.subMenu().asArray().reverse()
                  const hasSubs = !!jobSubs.length
                  if (jobSubs.every((sub) => !sub.enabled())) return null

                  const ordersPlaced = jobSubs
                    .filter((sub) =>
                      jobCatering.members.subOrders.orderIsPlaced({
                        subId: sub.id(),
                        userId: me.userId(),
                        dayNum: day.dayNum(),
                      })
                    )
                    .map((sub) =>
                      jobCatering.members.subOrders.order_({
                        subId: sub.id(),
                        userId: me.userId(),
                        dayNum: day.dayNum(),
                      })
                    )

                  const placedOrder = ordersPlaced[0]

                  return (
                    <Card
                      style={{
                        height: '52px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        background: 'lightgray',
                      }}
                      key={day.dayNum()}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: '0 18px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '18px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Day {parseInt(day.dayNum()) + 1}{' '}
                          <span
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 12,
                            }}
                          >
                            {
                              // isToday
                              // ? 'Today'
                              /* : */ day.date()
                                ? new Date(day.date()).toLocaleDateString()
                                : null
                            }
                          </span>
                        </div>

                        <div style={{ display: 'flex' }}>
                          {jobSubs
                            .filter(
                              (subMenuItem) => !subMenuItem.isFakeDeleted()
                            )
                            .map((jobSub) => {
                              const subGlobal = jobCatering.globalItem(
                                jobSub.id()
                              )
                              const ordered = jobCatering.members.subOrders.orderIsPlaced(
                                {
                                  subId: jobSub.id(),
                                  userId: me.userId(),
                                  dayNum: day.dayNum(),
                                }
                              )

                              if (!ordered) return null

                              const order = jobCatering.members.subOrders.order_(
                                {
                                  subId: jobSub.id(),
                                  userId: me.userId(),
                                  dayNum: day.dayNum(),
                                }
                              )

                              const name =
                                (jobSubOrderComplete &&
                                  jobSubOrderComplete.members.subGlobalMenu
                                    .members.items.items[order.subId()] &&
                                  jobSubOrderComplete.members.subGlobalMenu
                                    .members.items.items[order.subId()].data
                                    .name) ||
                                '(deleted)'

                              return (
                                <div
                                  style={{
                                    width: '65vw',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {name}
                                  </div>
                                  <div style={{ color: 'green' }}>
                                    {`pickup at ${order.pickupTime()}`}
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </Card>
                  )
                })}

            {/* Enabled Days */}
            {['JR1', 'JR2', 'MOK'].includes(currentJob.id()) &&
              jobCatering
                .allDays()
                .filter((day) => day.canOrderCatering())
                .map((day, i) => {
                  const isToday = i === 0
                  const isTomorrow = i === 1
                  const jobSubs = day.subMenu().asArray().reverse()
                  const hasSubs = !!jobSubs.length
                  if (jobSubs.every((sub) => !sub.enabled())) return null

                  const ordersPlaced = jobSubs
                    .filter((sub) =>
                      jobCatering.members.subOrders.orderIsPlaced({
                        subId: sub.id(),
                        userId: me.userId(),
                        dayNum: day.dayNum(),
                      })
                    )
                    .map((sub) =>
                      jobCatering.members.subOrders.order_({
                        subId: sub.id(),
                        userId: me.userId(),
                        dayNum: day.dayNum(),
                      })
                    )

                  const placedOrder = ordersPlaced[0]

                  const hasPendingSelections = !placedOrder
                  const hasSelectedPickupTime =
                    placedOrder &&
                    (!day.hasPickupTimes() ||
                      ordersPlaced.every((o) => !!o.pickupTime()))

                  const allowMulti = ['Demo123', 'BMF'].includes(
                    currentJob.id()
                  )

                  return (
                    <Accordion key={day.dayNum()}>
                      <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '18px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Day {parseInt(day.dayNum()) + 1}{' '}
                            <span
                              style={{
                                marginLeft: 10,
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                            >
                              {
                                // isToday
                                // ? 'Today'
                                /* : */ day.date()
                                  ? new Date(day.date()).toLocaleDateString()
                                  : null
                              }
                            </span>
                          </div>
                          <div
                            style={{
                              width: '80px',
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'flex-end',
                            }}
                          >
                            {hasPendingSelections ? (
                              <span style={{ color: 'blue' }}>Choose Meal</span>
                            ) : hasSubs ? (
                              !hasSelectedPickupTime ? (
                                <span style={{ color: 'blue' }}>
                                  Choose Time
                                </span>
                              ) : (
                                <span style={{ color: 'green' }}>
                                  {`pickup at ${placedOrder.pickupTime()}`}
                                </span>
                              )
                            ) : (
                              <span style={{}}>Nothing Here</span>
                            )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        {placedOrder && !hasSelectedPickupTime ? (
                          <div
                            style={{
                              display: 'flex',
                              paddingBottom: '20px',
                              paddingLeft: '20px',
                            }}
                          >
                            <div>When are you picking up?</div>
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="demo-mutiple-chip"
                              style={{ width: '100%' }}
                              value={placedOrder.pickupTime()}
                              onChange={async (e) => {
                                kernel.performUpdates(
                                  placedOrder.setPickupTime(e.target.value)
                                )
                              }}
                              input={<Input />}
                              renderValue={(selected) => <div>{selected}</div>}
                            >
                              {day.pickupTimes().map((name) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        ) : (
                          <div
                            style={{ display: 'flex', paddingBottom: '20px' }}
                          >
                            <List style={{ paddingTop: 0, width: '100%' }}>
                              {jobSubs
                                .filter(
                                  (subMenuItem) => !subMenuItem.isFakeDeleted()
                                )
                                .map((jobSub) => {
                                  const subGlobal = jobCatering.globalItem(
                                    jobSub.id()
                                  )
                                  const ordered = jobCatering.members.subOrders.orderIsPlaced(
                                    {
                                      subId: jobSub.id(),
                                      userId: me.userId(),
                                      dayNum: day.dayNum(),
                                    }
                                  )
                                  const order = jobCatering.members.subOrders.order_(
                                    {
                                      subId: jobSub.id(),
                                      userId: me.userId(),
                                      dayNum: day.dayNum(),
                                    }
                                  )
                                  return (
                                    <ListItem
                                      button
                                      onClick={() =>
                                        (hasPendingSelections || allowMulti) &&
                                        jobSub.enabled() &&
                                        goToItem({
                                          dayNum: day.dayNum(),
                                          subId: jobSub.id(),
                                        })
                                      }
                                      style={{
                                        background: !jobSub.enabled()
                                          ? '#dfdfdf'
                                          : undefined,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <ListItemIcon>
                                          {!jobSub.enabled() ? (
                                            <Icon style={{ color: 'gray' }}>
                                              close
                                            </Icon>
                                          ) : ordered ? (
                                            <Icon style={{ color: 'green' }}>
                                              checkmark
                                            </Icon>
                                          ) : (
                                            <Icon
                                              style={{
                                                color:
                                                  hasPendingSelections ||
                                                  allowMulti
                                                    ? 'blue'
                                                    : 'gray',
                                              }}
                                            >
                                              select_all
                                            </Icon>
                                          )}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={jobSub.label()}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          minWidth: '80px',
                                          paddingLeft: '13px',
                                          fontSize: '13px',
                                        }}
                                      >
                                        {!ordered || !day.hasPickupTimes() ? (
                                          '(' + subGlobal.name() + ')'
                                        ) : order.pickupTime() ? (
                                          <>
                                            <strong
                                              style={{
                                                color: 'green',
                                                paddingRight: 5,
                                              }}
                                            >
                                              {`pickup at ${order.pickupTime()}`}
                                            </strong>
                                            <span>
                                              {'(' + subGlobal.name() + ')'}
                                            </span>
                                          </>
                                        ) : (
                                          <span style={{ color: 'blue' }}>
                                            {' '}
                                            select time{' '}
                                          </span>
                                        )}
                                      </div>
                                    </ListItem>
                                  )
                                })}
                              <div
                                style={{
                                  paddingTop: '20px',
                                  paddingBottom: '10px',
                                }}
                              >
                                {!(hasPendingSelections || allowMulti) ? (
                                  <Button
                                    style={{ width: '100%', color: 'red' }}
                                    onClick={() =>
                                      this.setState({
                                        clearOrderConfirm: day.dayNum(),
                                      })
                                    }
                                  >
                                    Change or cancel Order
                                  </Button>
                                ) : null}
                              </div>
                              <Modal
                                title="Clear order?"
                                visible={clearOrderConfirm === day.dayNum()}
                                onOk={async () => {
                                  await submitRemoveOrder({
                                    dayNum: day.dayNum(),
                                    subIds: jobSubs.map((jobSub) =>
                                      jobSub.id()
                                    ),
                                  })
                                  this.setState({ clearOrderConfirm: null })
                                }}
                                onCancel={() =>
                                  this.setState({ clearOrderConfirm: null })
                                }
                              >
                                <div>
                                  This will clear your order, you can then place
                                  a new one.
                                </div>
                              </Modal>
                            </List>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )
                })}

            {!['JR1', 'JR2', 'MOK'].includes(currentJob.id()) &&
              jobCatering
                .allDays()
                .filter((day) => day.canOrderCatering())
                .map((day, i) => {
                  const isToday = i === 0
                  const isTomorrow = i === 1
                  const jobSubs = day.subMenu().asArray().reverse()
                  const hasSubs = !!jobSubs.length
                  if (jobSubs.every((sub) => !sub.enabled())) return null

                  const ordersPlaced = jobSubs
                    .filter((sub) =>
                      jobCatering.members.subOrders.orderIsPlaced({
                        subId: sub.id(),
                        userId: me.userId(),
                        dayNum: day.dayNum(),
                      })
                    )
                    .map((sub) =>
                      jobCatering.members.subOrders.order_({
                        subId: sub.id(),
                        userId: me.userId(),
                        dayNum: day.dayNum(),
                      })
                    )

                  const placedOrder = ordersPlaced[0]

                  const hasPendingSelections = !placedOrder
                  const hasSelectedPickupTime =
                    placedOrder &&
                    (!day.hasPickupTimes() ||
                      ordersPlaced.every((o) => !!o.pickupTime()))

                  const allowMulti = ['Demo123', 'BMF'].includes(
                    currentJob.id()
                  )

                  return (
                    <Accordion key={day.dayNum()}>
                      <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '18px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Day {parseInt(day.dayNum()) + 1}{' '}
                            <span
                              style={{
                                marginLeft: 10,
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                            >
                              {
                                // isToday
                                // ? 'Today'
                                /* : */ day.date()
                                  ? new Date(day.date()).toLocaleDateString()
                                  : null
                              }
                            </span>
                          </div>
                          <div
                            style={{
                              width: '80px',
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'flex-end',
                            }}
                          >
                            {hasPendingSelections && (
                              <span style={{ color: 'blue' }}>Choose Meal</span>
                            )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        {placedOrder && !hasSelectedPickupTime ? (
                          <div
                            style={{
                              display: 'flex',
                              paddingBottom: '20px',
                              paddingLeft: '20px',
                            }}
                          >
                            <div>When are you picking up?</div>
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="demo-mutiple-chip"
                              style={{ width: '100%' }}
                              value={placedOrder.pickupTime()}
                              onChange={async (e) => {
                                kernel.performUpdates(
                                  placedOrder.setPickupTime(e.target.value)
                                )
                              }}
                              input={<Input />}
                              renderValue={(selected) => <div>{selected}</div>}
                            >
                              {day.pickupTimes().map((name) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        ) : (
                          <div
                            style={{ display: 'flex', paddingBottom: '20px' }}
                          >
                            <List style={{ paddingTop: 0, width: '100%' }}>
                              {jobSubs
                                .filter(
                                  (subMenuItem) => !subMenuItem.isFakeDeleted()
                                )
                                .map((jobSub) => {
                                  const subGlobal = jobCatering.globalItem(
                                    jobSub.id()
                                  )
                                  const ordered = jobCatering.members.subOrders.orderIsPlaced(
                                    {
                                      subId: jobSub.id(),
                                      userId: me.userId(),
                                      dayNum: day.dayNum(),
                                    }
                                  )
                                  const order = jobCatering.members.subOrders.order_(
                                    {
                                      subId: jobSub.id(),
                                      userId: me.userId(),
                                      dayNum: day.dayNum(),
                                    }
                                  )
                                  return (
                                    <ListItem
                                      button
                                      onClick={() =>
                                        (hasPendingSelections || allowMulti) &&
                                        jobSub.enabled() &&
                                        goToItem({
                                          dayNum: day.dayNum(),
                                          subId: jobSub.id(),
                                        })
                                      }
                                      style={{
                                        background: !jobSub.enabled()
                                          ? '#dfdfdf'
                                          : undefined,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <ListItemIcon>
                                          {!jobSub.enabled() ? (
                                            <Icon style={{ color: 'gray' }}>
                                              close
                                            </Icon>
                                          ) : ordered ? (
                                            <Icon style={{ color: 'green' }}>
                                              checkmark
                                            </Icon>
                                          ) : (
                                            <Icon
                                              style={{
                                                color:
                                                  hasPendingSelections ||
                                                  allowMulti
                                                    ? 'blue'
                                                    : 'gray',
                                              }}
                                            >
                                              select_all
                                            </Icon>
                                          )}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={jobSub.label()}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          minWidth: '80px',
                                          paddingLeft: '13px',
                                          fontSize: '13px',
                                        }}
                                      >
                                        {!ordered || !day.hasPickupTimes() ? (
                                          '(' + subGlobal.name() + ')'
                                        ) : order.pickupTime() ? (
                                          <>
                                            <strong
                                              style={{
                                                color: 'green',
                                                paddingRight: 5,
                                              }}
                                            >
                                              {`pickup at ${order.pickupTime()}`}
                                            </strong>
                                            <span>
                                              {'(' + subGlobal.name() + ')'}
                                            </span>
                                          </>
                                        ) : (
                                          <span style={{ color: 'blue' }}>
                                            {' '}
                                            select time{' '}
                                          </span>
                                        )}
                                      </div>
                                    </ListItem>
                                  )
                                })}
                              <div
                                style={{
                                  paddingTop: '20px',
                                  paddingBottom: '10px',
                                }}
                              >
                                {!(hasPendingSelections || allowMulti) ? (
                                  <Button
                                    style={{ width: '100%', color: 'red' }}
                                    onClick={() =>
                                      this.setState({
                                        clearOrderConfirm: day.dayNum(),
                                      })
                                    }
                                  >
                                    Change or cancel Order
                                  </Button>
                                ) : null}
                              </div>
                              <Modal
                                title="Clear order?"
                                visible={clearOrderConfirm === day.dayNum()}
                                onOk={async () => {
                                  await submitRemoveOrder({
                                    dayNum: day.dayNum(),
                                    subIds: jobSubs.map((jobSub) =>
                                      jobSub.id()
                                    ),
                                  })
                                  this.setState({ clearOrderConfirm: null })
                                }}
                                onCancel={() =>
                                  this.setState({ clearOrderConfirm: null })
                                }
                              >
                                <div>
                                  This will clear your order, you can then place
                                  a new one.
                                </div>
                              </Modal>
                            </List>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
          </div>
        )
      }
    }
  )
)

import { createFetcher } from '@blast-engine/firework'

export const fetchJobCrewUsersRef = createFetcher({
  name: 'fetch-job-crew-users-ref'
}, ({ crafterId, jobId }) => ({
  steps: [],
  final: {
    take: [],
    instantiate: ({ root }) => root.jobCrewUsersRef({ crafterId, jobId })
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchTextNotifListRef = createFetcher({
  name: 'fetch-text-notif-list-ref'
}, () => ({
  steps: [],
  final: {
    take: [],
    instantiate: ({ root }) => root.textNotifRequestsRef()
  }
}))
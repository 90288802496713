import React, { useState } from 'react'
import { isReady } from '@blast-engine/firework'
import { List, Card } from 'antd'

import * as entities from '@crafty/entities'
import { fireworkConnect, kernel } from '@/kernel.singleton'

import './craft-category-page.ui.scss'

export const CraftCategoryPage = fireworkConnect(({ me, currentJob, categoryId }) => ({
  jobCraft: entities.crafter.crafterData.fetchJobCraftMenuManager({ 
    jobId: currentJob.id(), 
    crafterId: currentJob.crafterId() 
  })
}))(class CraftCategoryPage extends React.PureComponent {
  state = {
    imgUrls: {}
  }

  componentDidMount() {
    this.componentDidUpdate()
  }

  async componentDidUpdate() {
    const { 
      // props
      me, currentJob, itemId,
      // data
      jobCraft, basket
    } = this.props

    if (!isReady(me, currentJob, jobCraft)) return

    jobCraft.enabledItemsForCategory()
      .forEach(async item => {

        if (this.state.imgUrls[item.id()] !== undefined) return

        const ref = kernel.firebase.storage().ref(`image/craft_item/${item.id()}`)
        
        let url = null
        try { url = await ref.getDownloadURL() } 
        catch (e) { }

        this.setState({ 
          imgUrls: { 
            ...this.state.imgUrls, 
            [item.id()]: url
          }
        })
      })

  }

  render() {
    const { 
      // props
      me, currentJob, categoryId, goToItem,
      // data 
      jobCraft,
    } = this.props
    const { } = this.state

    if (!isReady(jobCraft)) return null

    return (
      <div className='craft-category-page-container' style={{ padding: 10, height: '100%', overflow: 'scroll' }}>
        {/* <List
          header={null}
          dataSource={jobCraft.enabledItemsForCategory(categoryId)}
          renderItem={item => (
            <List.Item key={item.id()} className='each-item' onClick={() => goToItem(item.id())}>
              {item.name()}
            </List.Item>
          )}
        /> */}
        {
          jobCraft.enabledItemsForCategory(categoryId)
            .map(item => (
              <Card bodyStyle={{ padding: 15 }} style={{ marginBottom: 10 }} key={item.id()} onClick={() => goToItem(item.id())}>
                <div key={item.id()}>
                  <div style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                    <div style={{ flex: 1, fontSize: 20 }}>{jobCraft.itemNameForItemId({ itemId: item.id() })}</div>
                    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                      { 
                        this.state.imgUrls[item.id()]
                          ? <div 
                            source={this.state.imgUrls[item.id()]} 
                            height={80}
                            width={80}
                            style={{ resizeMode: 'cover', backgroundColor: 'red' }}
                          /> 
                          : null 
                      }
                    </div>
                  </div>
                </div> 
              </Card>
            ))
        }
      </div>
    )
  }
})

import React from 'react'
import { Redirect, Link, Route } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'

import { LoginGuard } from './login-guard'

// import './login.scss'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { CurrentJobRouter } from './current-job.router'
import { AppContentRouter } from './content-router'
import { UserInfoRequestRouter } from './user-info-request-router'
import { PingDaemon } from './ping-daemon'
import { ErrorBoundary } from './error-boundary'
import { ConnectivityGuard } from './connectivity-router'
import { BlockUser } from './user-info-requests/block-user'

export const AppRouter = 
  responsiveComponent(
  fireworkConnect(() => ({
    me: entities.user.fetchMe()
  }))(
class AppRouter extends React.Component {

  state = {}

  render() {
    const { isMobile } = this.props

    // if (!isMobile) return <div> only mobile </div>

    return (
      <ErrorBoundary>
        <ConnectivityGuard
          renderContent={() => (
        <LoginGuard
          renderContent={({ me }) => (
        <UserInfoRequestRouter 
          me={me}
          renderContent={() => (
        <CurrentJobRouter 
          me={me}
          renderContent={({ currentJob }) => (
        <BlockUser
          me={me}
          currentJob={currentJob}
          renderContent={() => (
        <>
          <PingDaemon me={me} currentJob={currentJob}/>
          <AppContentRouter 
            me={me} 
            currentJob={currentJob}
          />
        </>
        )} /> )} /> )} /> 
        )} /> )} />
      </ErrorBoundary>
    )
  }
}))
import { createFetcher } from '@blast-engine/firework'
import { Me } from './me.assembly'

export const fetchMe = createFetcher({
  name: 'fetch-me'
}, () => ({
  steps: [{
    name: 'myUserPrivate',
    requires: [ 'auth' ],
    query: ({ root, auth }) => 
      root.userPrivateRef({ userId: auth.uid() }).query()
  }, {
    name: 'myUserBasic',
    requires: [ 'auth' ],
    query: ({ root, auth }) => 
      root.userBasicRef({ userId: auth.uid() }).query()
  }, {
    name: 'myInvites',
    requires: [ 'auth' ],
    query: ({ root, auth }) => root.inviteRef({ userId: auth.uid() }).query()
  }],
  final: {
    take: [ 'auth', 'myUserPrivate', 'myUserBasic', 'myInvites' ],
    instantiate: ({ root, myUserPrivate, myUserBasic, myInvites, auth }) => 
      root._spinoff(Me, {
        members: {
          auth,
          meBasic: myUserBasic,
          mePrivate: myUserPrivate,
          userEmailListRef: root.userEmailListRef(),
          myInvites
        }
      })
  }
}))
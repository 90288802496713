import { createNodeClass } from '@blast-engine/firework'
import { k, kv, rollup } from '@blast-engine/utils'

// import { ROOT_ID } from './root-user.config'

export const Invite = createNodeClass({
  name: 'Invite',

  full: class { 
    
    id() {
      return this.userId()
    }

    isCraftyInvite() {
      return !this._data('crafterId')
    }

    crafterId() {
      return this._data('crafterId')
    }

    userId() {
      return this._data('userId')
    }

    removeInvite() {
      return this._delete()
    }

    role() {
      return this._data('role')
    }

  },

  ref: class {

    initialize({ userId, crafterId = null, role }) {
      return this._update({ userId, crafterId, role })
    }

  }
})
import { createNodeClass } from '@blast-engine/firework'
import { kv, keys } from '@blast-engine/utils'

import { BasketItemBasic } from './basket-item-basic.node'

export const Basket = createNodeClass({
  name: 'Basket',

  full: class {

    id() {
      this._data('id')
    }

    userId() {
      return this.id()
    }

    clear() {
      return this._update({
        items: null,
        notes: null
      })
    }

    addOrderItem({ 
      basketItem // BasketItemStruct
    }) {
      const orderItems = this._data('items')
      const newOrderItemId = this._fbService.newKey(this._strPath('items'))
      const updatedOrderItems = { 
        ...orderItems, 
        [newOrderItemId]: { 
          id: newOrderItemId,
          ...basketItem._raw
        }
      }
      return this._update({ items: updatedOrderItems })
    }

    notes() {
      return this._data('notes')
    }

    locationId() {
      return this._data('locationId')
    }

    updateLocationId({ locationId }) {
      return this._update({ locationId })
    }

    addNotes({ notes }) {
      return this._update({ notes })
    }

    items() {
      return kv(this._data('items'))
        .map(({ k: itemId, v: itemData }) => this._spinoff(BasketItemBasic, {
          path: this._path(`items/${itemId}`),
          data: itemData
        }))
    }

    hasItems() {
      return !!keys(this._data('items')).length
    }

    removeOrderItem(itemId) {
      return this._update({ [`items/${itemId}`]: null })
    }

  },

  ref: class {

    initialize({ id }) {
      return this._update({ id })
    }

  }

})
import React from 'react'
import { Link } from 'react-router-dom'
import {} from '@material-ui/core'
import {} from 'antd'

import { kernel } from '@/kernel.singleton'

import * as Comps from '@/components'

import './navbar-desktop.scss'

export const NavbarDesktop = class Navbar extends React.Component {
  state = {}

  render() {
    const { isTop, shouldShowLogout, shouldHideLogin, shouldShowCatererToggles, shouldShowCraftOfficeToggles, shouldShowCraftOnsiteToggles } = this.props
    const {} = this.state
    
    return (
      <div className={`navbar-desktop-container${isTop ? ' isTop' : ' isScrolling'}`}>
        <Link to='/' className='logo-section'>
          <img className={`logo${isTop ? ' isTop' : ' isScrolling'}`} src={require('../../../assets/Logo.png')} />
        </Link>
        <div style={{ display: 'flex', flex: 1 }} />
        {
          (!shouldHideLogin) && 
            <Link to='/login' className='login-side'>
              <div className='login-icon-section'>
                <img className={`login${isTop ? ' isTop' : ' isScrolling'}`} src={require('../../../assets/login.png')} />
              </div>
              <div className={`login-button${isTop ? ' isTop' : ' isScrolling'}`}>
                login
              </div>
            </Link>
        }
        {
          shouldShowCraftOfficeToggles && 
            <div className='tabs-section'>
              <Link onClick={() => this.setState({ activeTab: 'users' })} className={`each-tab${window.location.pathname.includes('users') ? ' active' : ''}`} to='/craft-office/users'>Users</Link>
              <Link onClick={() => this.setState({ activeTab: 'menu' })} className={`each-tab${window.location.pathname.includes('menu') ? ' active' : ''}`} to='/craft-office/menu'>Menu</Link>
              <Link onClick={() => this.setState({ activeTab: 'jobs' })} className={`each-tab${window.location.pathname.includes('jobs') ? ' active' : ''}`} to='/craft-office/jobs'>Jobs</Link>
              
            </div>
        }
        {
          shouldShowCraftOnsiteToggles &&
            <div className='tabs-section'>
              {/* <Link onClick={() => this.setState({ activeTab: 'menu' })} className={`each-tab${window.location.pathname.includes('menu') ? ' active' : ''}`} to='/craft-on-site/menu'>Menu</Link> */}
              <Link onClick={() => this.setState({ activeTab: 'jobs' })} className={`each-tab${window.location.pathname.includes('jobs') ? ' active' : ''}`} to='/craft-on-site/jobs'>Jobs</Link>
            </div>
        }
        {
          shouldShowCatererToggles &&
            <div className='tabs-section'>
              {/* <Link onClick={() => this.setState({ activeTab: 'menu' })} className={`each-tab${window.location.pathname.includes('menu') ? ' active' : ''}`} to='/craft-on-site/menu'>Menu</Link> */}
              <Link onClick={() => this.setState({ activeTab: 'jobs' })} className={`each-tab${window.location.pathname.includes('jobs') ? ' active' : ''}`} to='/caterer/jobs'>Jobs</Link>
            </div>
        }
        {
          shouldShowLogout && <div className='tabs-section'><Link onClick={() => kernel.logout()} className={`each-tab`} >Logout</Link></div>
        }
        
      </div>
    )
  }
}
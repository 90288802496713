import { ActiveCrewOnsiteUser } from './active-crew-onsite-user.node'
import { ActiveCrewOnsite } from './active-crew-onsite.list'

import { fetchActiveCrewOnsite } from './active-crew-onsite.list.fetcher'
import { fetchActiveCrewOnsiteRef } from './active-crew-onsite.list.ref.fetcher'

import { rootMethods } from './root-methods'

export const activeCrewOnSite = {
  ActiveCrewOnsiteUser,
  ActiveCrewOnsite,

  fetchActiveCrewOnsite,
  fetchActiveCrewOnsiteRef,

  rootMethods
}
import { keys, set, get } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const LocationName = createNodeClass({
  name: 'LocationName',

  full: class {

    name() {
      return this._data('name')
    }

    _updateName({ name }) {
      return this._update({ name })
    }

    id() {
      return this._data('id')
    }

    isFakeDeleted() {
      return !!this._data('isFakeDeleted')
    }

    _fakeDelete() {
      return this._update({ isFakeDeleted: true })
    }

  },

  ref: class {

    initialize({ 
      id, 
      name  
    }) {
      return this._update({ 
        id, 
        name
      })
    }

  },

})
import { keys, set, get } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const JobCraftMenuItem = createNodeClass({
  name: 'JobCraftMenuItem',

  struct: class {

    id() {
      return this._data('id')
    }

    editedItemLabel() {
      return this._data('editedItemLabel')
    }

    editItemLabel({ label }) {
      return this._update({ editedItemLabel: label })
    }

    itemOptionIds() {
      return Object.keys(this.optionsConfig() || {})
    }

    itemOptionItemIds({ optionId }) {
      return Object.keys(get(this.optionsConfig(), `${optionId}.optionItemIds`) || {})
    }

    enabled() {
      return !!this._data('enabled')
    }

    optionsConfig() {
      return this._data('optionsConfig') || {}
    }

    enable() {
      return this._update({ enabled: true })
    }

    disable() {
      return this._update({ enabled: false })
    }

    removeOptionFromJob({ optionId }) {
      return this._update({ [`optionsConfig/${optionId}`]: null })
    }

    removeOptionItemFromJob({ optionId, optionItemId }) {
      return this._update({ [`optionsConfig/${optionId}/optionItemIds/${optionItemId}`]: null })
    }

    disableOption({ optionId }) {
      const options = this.optionsConfig()
      options[optionId] = {
        ...options[optionId],
        enabled: false
      }
      return this._update({ [`optionsConfig/${optionId}/enabled`]: false })
      return this._update({ optionsConfig: options })
    }

    enableOption({ optionId }) {
      const options = this.optionsConfig()
      options[optionId] = {
        ...options[optionId],
        enabled: true
      }
      return this._update({ [`optionsConfig/${optionId}/enabled`]: true })
      // return this._update({ optionsConfig: options })
    }

    enabledOptionIds() {
      const optIds = keys(this.optionsConfig())

      return optIds.filter(optionId => this.isOptionEnabled({ optionId }))
    }

    isOptionEnabled({ optionId }) {
      return (this.optionsConfig()[optionId] || {})['enabled']
    }

    isOptionPresent({ optionId }) {
      return !(((this.optionsConfig()[optionId] || {})['enabled']) === undefined)
    }

    disableOptionItemForOption({ optionItemId, optionId }) {
      const options = this.optionsConfig()
      const o = set(options, { [`${optionId}.optionItemIds.${optionItemId}`]: false })
      return this._update({ optionsConfig: o })
    }

    enableOptionItemForOption({ optionItemId, optionId }) {
      const options = this.optionsConfig()
      const o = set(options, { [`${optionId}.optionItemIds.${optionItemId}`]: true })
      // options[optionId]['optionItemIds'][optionItemId] = true
      return this._update({ optionsConfig: o })
    }

    isOptionItemEnabled({ optionId, optionItemId }) {
      return !!((this.optionsConfig()[optionId] || {})['optionItemIds'] || {})[optionItemId]
    }

    isOptionItemPresent({ optionId, optionItemId }) {
      return !(((this.optionsConfig()[optionId] || {})['optionItemIds'] || {})[optionItemId] === undefined)
    }

    enabledOptionItemIds({ optionId }) {
      const optItemIds = keys(this.optionsConfig()[optionId]['optionItemIds'])
      if (!optItemIds) return []
      return optItemIds
        .filter(optionItemId => this.isOptionItemEnabled({ optionId, optionItemId }))
    }

    // optionsConfig = {
    //   optionId: {
    //       enabled: true,
    //       optionItemIds: {
    //           optionItemId: true
    //       }
    //   }
    // }

    notesAreAllowed() {
      return this._data('allowNotes') || false
    }


  },

  ref: class {

    initialize({ 
      id, 
      optionsConfig = {},
      enabled = true,
      allowNotes = false,  
    }) {
      return this._update({ 
        id, 
        optionsConfig,
        enabled,
        allowNotes
      })
    }

  },

  full: class { 

  }

})
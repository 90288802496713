import { createFetcher } from '@blast-engine/firework'

import { DayJobBreakfastMenuDayComplete } from './job-breakfast-menu-day.assembly'
import { subsGlobalMenu } from './subs-global-menu'
import { breakfastOrders } from './job/jobData/breakfast-orders'

export const fetchJobBreakfastMenuDayComplete = createFetcher({
  name: 'fetch-job-breakfast-menu-day-complete'
}, ({ crafterId, jobId, day, userId }) => ({
  steps: [{
    name: 'jobBasic',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  }, {  
    name: 'dayJobMenu',
    query: ({ root }) => root.crafterJobBreakfastMenuForDay({ crafterId, jobId, day }).query()
  }, {
    name: 'globalItems',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }, {
    name: 'mePrivate',
    query: ({ root }) => root.userPrivateRef({ userId }).query()
  }],
  final: {
    take: [ 'jobBasic', 'mePrivate', 'dayJobMenu', 'globalOptions', 'globalItems' ],
    instantiate: ({ jobBasic, mePrivate, dayJobMenu, globalOptions, globalItems, root }) => (
      root._spinoff(DayJobBreakfastMenuDayComplete, { 
        members: { 
          breakfastMenuItemListForDay: dayJobMenu,
          jobBasic,
          mePrivate,
          breakfastOrdersForDayRef: root.breakfastOrdersRef({ crafterId, jobId, day }),
          subGlobalMenu: root._spinoff(subsGlobalMenu.CrafterSubsGlobalMenu, {
            members: {
              items: globalItems, 
              options: globalOptions 
            }
          })
        }
      })
    )
  }
}))
import { createNodeClass } from '@blast-engine/firework'
import { rollup } from '@blast-engine/utils'

export const SubGlobalOption = createNodeClass({
  name: 'SubGlobalOption',

  full: class {

    id() {
      return this._data('id')
    }

    items() {
      return this._data('items')
    }

    fakeDeleteOption() {
      return this._update({ _deleted: true })
    }

    fakeDeleteOptionItem({ itemId }) {
      return this._update({ [`items/${itemId}/_deleted`]: true })
    }

    isOptionFakeDeleted() {
      return !!this._data('_deleted')
    }

    isOptionItemFakeDeleted({ itemId }) {
      return !!this._data(`items.${itemId}._deleted`)
    }

    updateType({ type }) {
      return this._update({ type })
    }
    
    updateLabel({ label }) {
      return this._update({ label })
    }

    addItem({ label }) {
      return this._update({ [`items/${label}`]: true })
    }
    
    item(itemId) {
      return this._data(`items.${itemId}`)
    }

    itemLabel(itemId) {
      const item = this.item(itemId)
      if (!item) return null
      if (typeof item !== 'object') return itemId
      return item.label
    }

    label() {
      return this._data('label')
    }

    type() {
      return this._data('type')
    }

  },

  ref: class {

    initialize({ id, label, type, items }) {
      return this._update({ id, label, type, items: rollup(items) })
    }

  }

})
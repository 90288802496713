import React, { useState } from 'react'
import { DatePicker, message } from 'antd'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { Button, TextField } from '@material-ui/core'

import { user } from '@crafty/entities';
import { kernel, fireworkConnect } from '@/kernel.singleton';
import './signup.scss';

export const Signup = withRouter(responsiveComponent(fireworkConnect(props => ({
  me: entities.user.fetchMe({ keepOldResultUntilNew: true })
}))(class Signup extends React.PureComponent {
  state = {}
  
  render() {
    const { me, fb, isMobile } = this.props
    const { 
      username = '', 
      email = '', 
      password = '',
      waiting = false,
      error = null
    } = this.state

    if (!me) return null
    if (!me.isAnonymous()) 
      return <Redirect to='/' />

    const handleUsernameChangeEvent = event => 
      this.setState({ username: event.target.value })

    const handleEmailChangeEvent = event => 
      this.setState({ email: event.target.value })
    
    const handlePasswordChangeEvent = event => 
      this.setState({ password: event.target.value })

    const tryToSignUp = async () => { 
      this.setState({ waiting: true })
      if (!this.state.username) return this.setState({ error: 'Please enter a Username' })
      try { 
        await fb.signup({ email, password }) 
        await kernel.doAction(user.registerUser, { username, password, email, id: me.userId() })
        await this.setState({ waiting: false })
        this.props.history.push('/registered')
      } catch (e) { 
        let errorMessage = e.message
        if (e.message.includes('The password is invalid or the user does not have a password'))
          errorMessage = 'The password is invalid'
        if (e.message.includes('The email address is already in use by another account'))
          errorMessage = 'The email address is already in use by another account.'
        if (e.message.includes('The email address is badly formatted'))
          errorMessage = 'The email address is badly formatted.'
        this.setState({ error: errorMessage, waiting: false }) 
      }
    }

    return (
      <div className='signup-section-comp'>
        <img className='background-img' src={require('../../assets/bg.png')} />
        
        <div className='body'>
          <Card
            title={
              <div className='modal-header-section'>
                <div className='signup'>Sign up</div>
                <img src={require('../../assets/Logo.png')} className='logo' />
              </div>
            }
            style={{ width: isMobile ? '80vw' : '30vw', transition: '1s' }}
          >
            <TextField 
              variant='outlined' 
              required
              label='Name' 
              placeholder='User Name' 
              value={username} 
              onChange={(e) => this.setState({ username: e.target.value })}
              type='text' 
              style={{ width: '100%' }}
              onKeyPress={e => e.key === 'Enter' && tryToSignUp()}
            />
            <TextField 
              variant='outlined' 
              required
              label='Email Address' 
              placeholder='Email Address' 
              value={email} 
              onChange={(e) => this.setState({ email: e.target.value })}
              type='email' 
              style={{ width: '100%', marginTop: '3vh' }}
              onKeyPress={e => e.key === 'Enter' && tryToSignUp()}
            />
            <TextField 
              variant='outlined' 
              required
              label='Password' 
              placeholder='Password' 
              value={password} 
              onChange={(e) => this.setState({ password: e.target.value })}
              type='password' 
              style={{ width: '100%', marginTop: '3vh' }}
              onKeyPress={e => e.key === 'Enter' && tryToSignUp()}
            />
            { error && <div className='error'>{error}</div> }
            <Button
              className={`button-custom-class${isMobile ? ' mobile' : ''}`}
              variant='contained'
              onClick={() => {
                tryToSignUp()
              }}
            >
              Submit
            </Button>
            <div className='login-section' >
              Already have an account? <Link className='login' to="/login">Sign in</Link>
            </div>

          </Card>
        </div>
        
      </div>
    )
  }
})))
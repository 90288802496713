import { createFetcher } from '@blast-engine/firework'

import { CraftOrdersComplete } from './orders-complete.assembly'
import { craftMenu } from './craft-global-menu'

export const fetchCraftOrdersComplete = createFetcher({
  name: 'fetch-craft-orders-complete'
}, ({ crafterId, jobId, userId }) => ({
  steps: [{
    name: 'userPrivate',
    query: ({ root }) => root.userPrivateRef({ userId }).query()
  }, {
    name: 'job',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  }, {
    name: 'craftOrders',
    requires: [ 'job', 'userPrivate' ],
    query: ({ job, userPrivate, root }) => root.craftOrdersSelectionRef({ 
      crafterId, jobId, 
      day: job.currentDay(), 
      orderIds: userPrivate.jobOrderIdsForDay({ 
        crafterId: job.crafterId(), 
        jobId: job.id(), 
        day: job.currentDay() 
      }) 
    }).query()
  }, {
    name: 'globalCategories',
    query: ({ root }) => root.craftGlobalCategoryBasicListRef({ crafterId }).query()
   }, {
    name: 'globalItems',
    query: ({ root }) => root.craftGlobalItemBasicListRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.craftGlobalOptionsBasicListRef({ crafterId }).query()
  }],
  final: {
    take: [ 'globalCategories', 'globalItems', 'globalOptions', 'craftOrders' ],
    instantiate: ({ globalCategories, globalItems, globalOptions, craftOrders, root }) => 
      root._spinoff(CraftOrdersComplete, { 
        members: { 
          craftOrders,
          craftGlobalMenu: root._spinoff(craftMenu.CrafterCraftGlobalMenu, {
            members: {
              categories: globalCategories, 
              items: globalItems, 
              options: globalOptions
            }
          })
        }
      })
  }
}))
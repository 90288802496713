import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Select, Button, MenuItem, TextField, Input, Accordion, AccordionSummary, Typography, AccordionDetails, Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import LogRocket from 'logrocket'
import { Card, Modal } from 'antd'

import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'
import { get, values } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

export const BreakfastPage = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    jobCatering: entities.crafter.crafterData.fetchJobCateringComplete({
      crafterId: currentJob.crafterId(),
      jobId: currentJob.id(),
      userId: me.userId()
    })
  }))(
class BreakfastPage extends React.PureComponent {

  state = {}

  render() {
    const { 
      // props
      me, currentJob, 
      goToItem = () => null,
      // data
      jobCatering,
    } = this.props
    const {
      clearOrderConfirm
    } = this.state

    if (!isReady(jobCatering)) return null

    LogRocket.log({ 
      event: 'BreakfastPage render()',  
      props: { jobCatering }, 
      state: {} 
    })

    const submitRemoveOrder = async ({ dayNum, breakfastIds }) => {
      const cs = this.state.currentlySubmitting || []
      this.setState({ 
        currentlySubmitting: cs.concat({ dayNum })
      })
      LogRocket.log({ 
        event: 'BreakfastItemsPage submitRemoveOrder()',  
        props: { dayNum, breakfastIds }, 
        state: {} 
      })
      console.log({ dayNum, breakfastIds })
      console.log(jobCatering.removeBreakfastOrder({ dayNum, breakfastIds }))
      await kernel.performUpdates(jobCatering.removeBreakfastOrder({ dayNum, breakfastIds }))
      setTimeout(() => {
        this.setState({ 
          currentlySubmitting: cs.filter(d => d.dayNum !== dayNum)
        })
      }, 300)
    }

    window.jobCatering = jobCatering

    return (
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column', padding: 10, overflow: 'scroll' }}>
        {
                                (() => { 
                                  if (window.f === undefined) window.f = false
                                  else if (window.f === false) window.f = true
                                  return true
                                })() &&
          jobCatering.allDays().filter(day => day.canOrderCatering()).map((day, i) => {
            const isToday = i === 0
            const isTomorrow = i === 1
            const jobBreakfast = day.breakfastMenu().asArray().reverse()
            const hasBreakfast = !!jobBreakfast.length
            if (jobBreakfast.every(breakfast => !breakfast.enabled())) return null

            
            const ordersPlaced = jobBreakfast.filter(breakfast => 
              jobCatering.members.breakfastOrders.orderIsPlaced({ 
                breakfastId: breakfast.id(), 
                userId: me.userId(), 
                dayNum: day.dayNum() 
              })
            ).map(breakfast => 
              jobCatering.members.breakfastOrders.order_({ 
                breakfastId: breakfast.id(), 
                userId: me.userId(), 
                dayNum: day.dayNum() 
              })  
            )

            const placedOrder = ordersPlaced[0]

            const hasPendingSelections = !placedOrder
            const hasSelectedPickupTime = placedOrder && (!day.hasBreakfastPickupTimes() || ordersPlaced.every(o => !!o.pickupTime()))
            
            const allowMulti = currentJob.id() === 'Demo123'

            return (
              <Accordion key={day.dayNum()}>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }}>
                      Day {parseInt(day.dayNum()) + 1} {' '} 
                      <span style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 12 }}>
                        {
                          // isToday 
                            // ? 'Today' 
                            /* : */ day.date() 
                              ? (new Date(day.date())).toLocaleDateString()
                              : null
                        }
                      </span>
                    </div>
                    <div style={{ width: '80px', fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'flex-end' }}>
                      {
                        hasPendingSelections 
                          ? <span style={{ color: 'blue' }}>Choose Meal</span>
                          : hasBreakfast 
                            ? !hasSelectedPickupTime 
                              ? <span style={{ color: 'blue' }}>Choose Time</span>
                              : <span style={{ color: 'green' }}>OK</span>
                            : <span style={{ }}>Nothing Here</span>
                      }
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                {
                  (false && placedOrder && !hasSelectedPickupTime) ? (
                    <div style={{ display: 'flex', paddingBottom: '20px', paddingLeft: '20px' }}>
                      <div>when are you picking up?</div>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        style={{ width: '100%' }}
                        value={placedOrder.pickupTime()}
                        onChange={async e => {
                          kernel.performUpdates(placedOrder.setPickupTime(e.target.value))
                        }}
                        input={<Input />}
                        renderValue={(selected) => (
                          <div>
                            {selected}
                          </div>
                        )}
                      >
                        {day.breakfastPickupTimes().map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                      <List style={{ paddingTop: 0, width: '100%' }}>
                        {
                          jobBreakfast.filter(breakfastMenuItem => !breakfastMenuItem.isFakeDeleted()).map(jobBreakfast => {
                            const breakfastGlobal = jobCatering.globalItem(jobBreakfast.id())
                            const ordered = jobCatering.members.breakfastOrders.orderIsPlaced({ breakfastId: jobBreakfast.id(), userId: me.userId(), dayNum: day.dayNum() })
                            const order = jobCatering.members.breakfastOrders.order_({ breakfastId: jobBreakfast.id(), userId: me.userId(), dayNum: day.dayNum() })
                            if (!window.f) {
                              window.r = window.r || []
                              window.r.push({ 
                                jobBreakfast,
                                breakfastGlobal,
                                ordered,
                                breakfastId: jobBreakfast.id(), 
                                userId: me.userId(), 
                                dayNum: day.dayNum(),
                                breakfastOrders: jobCatering.members.breakfastOrders
                              })
                            }
                            return (
                              <ListItem 
                                button 
                                onClick={() => jobBreakfast.enabled() && goToItem({ dayNum: day.dayNum(), breakfastId: jobBreakfast.id() })} 
                                style={{ 
                                  background: !jobBreakfast.enabled() ? '#dfdfdf' : undefined, 
                                  width: '100%', 
                                  display: 'flex', 
                                  justifyContent: 'flex-start' 
                                }}>  
                                <div  style={{ display: 'flex', alignItems: 'center' }}>
                                  <ListItemIcon>
                                    {
                                      !jobBreakfast.enabled()
                                        ? <Icon style={{ color: 'gray' }}>close</Icon> 
                                        : ordered 
                                          ? <Icon style={{ color: 'green' }}>checkmark</Icon>
                                          : <Icon style={{ color: (hasPendingSelections || allowMulti) ? 'blue' : 'gray' }}>select_all</Icon>
                                    }
                                  </ListItemIcon>
                                  <ListItemText primary={jobBreakfast.label()} />
                                </div>
                                <div style={{ minWidth: '80px', paddingLeft: '13px', fontSize: '13px' }}>
                                  {
                                    (
                                      (!ordered || !day.hasBreakfastPickupTimes())
                                        ? ('(' + breakfastGlobal.name()  + ')') 
                                        : order.pickupTime() 
                                          ? <span style={{ color: 'green' }}>{`pickup at ${order.pickupTime()}`}</span>
                                          : <span style={{ color: 'blue' }}> select time </span>
                                    )
                                  }
                                </div>
                              </ListItem>
                            )
                          })
                        }
                        <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
                          { 
                            !(hasPendingSelections || allowMulti) ? (
                              <Button 
                                style={{ width: '100%', color: 'red' }}
                                onClick={() => this.setState({ clearOrderConfirm: day.dayNum() })}
                              >
                                Change or cancel Order
                              </Button>
                            ) : null
                          }
                        </div>
                        <Modal
                          title='Clear order?'
                          visible={clearOrderConfirm === day.dayNum()}
                          onOk={
                            async () => {
                              await submitRemoveOrder({ 
                                dayNum: day.dayNum(), 
                                breakfastIds: jobBreakfast.map(jobBreakfast => jobBreakfast.id()) 
                              })
                              this.setState({ clearOrderConfirm: null })
                            }
                          }
                          onCancel={() => this.setState({ clearOrderConfirm: null })}
                        >
                          <div>This will clear your order, you can then place a new one.</div>
                        </Modal>
                      </List>
                    </div>
                  )
                }
                </AccordionDetails>
              </Accordion>
            )
          })
        }
      </div>
    )
  }
}))
import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { BasketItemComplete } from './basket-item-complete.assembly'
import { craftMenu } from './craft-global-menu'
import { job } from './job'

export const CraftOrdersComplete = createAssemblyClass({
  name: 'CraftOrdersComplete',
  memberModels: {
    craftGlobalMenu: craftMenu.CrafterCraftGlobalMenu,
    craftOrders: job.jobData.craftOrders.CraftOrderSelection.full(),
  },
  portMethods: {
  
  },
  body: class {

    orderIds() {
      return this.members.craftOrders.keys
    }

    readyForPickup() {
      return this.members.craftOrders.asArray().filter(o => o.isReadyForPickup())
    }

    pending() {
      return this.members.craftOrders.asArray().filter(o => o.isPending())
    }

  }
})
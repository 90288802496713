import { keys, set, get } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const PausedService = createNodeClass({
  name: 'PausedService',

  full: class {

    getAllItems() {
      return this._data()
    }

    setAllItems(items) {
      return this._update(items)
    }

  }

})
import { keys } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

import { BasketItemBasicStruct } from './basket-item-basic.struct'

export const BasketItemBasic = createNodeClass({
  name: 'BasketItemBasic',

  full: class {

    // @todo: clean up this model
    _constructor({ data }) {
      this._struct = new BasketItemBasicStruct(data)
    }

    id() {
      return this._struct._raw.id
    }

    notes() {
      return this._struct._raw.notes
    }

    craftItemId() {
      return this._struct.craftItemId()
    }

    selectionOptionIds() {
      return this._struct.selectionOptionIds()
    }

    selectionOptionItemIdsForOptionId(optId) {
      return this._struct.selectionOptionItemIdsForOptionId(optId)
    }

    numCopies() {
      return this._struct.numCopies()
    }

  },

  ref: class {

    // initialize({ id, basketItemStructs = [] }) {
    //   return this._update({
    //     id,
    //     basketItems: basketItemStructs.map(bis => bis._raw)
    //   })
    // }

  }

})
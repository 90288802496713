import { createFetcher } from '@blast-engine/firework'

import { DayJobSubMenuDayComplete } from './job-subs-menu-day.assembly'
import { subsGlobalMenu } from './subs-global-menu'
import { subOrders } from './job/jobData/sub-orders'
import { JobCatering } from './job-catering.assembly'

export const fetchJobCateringComplete = createFetcher({
  name: 'fetch-job-catering-complete'
}, ({ crafterId, jobId, userId }) => ({
  steps: [{
    name: 'jobBasic',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  }, {  
    name: 'jobDays',
    query: ({ root }) => root.crafterJobDays({ crafterId, jobId }).query()
  }, {
    name: 'globalSubItems',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'globalSubOptions',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }, {
    name: 'mePrivate',
    query: ({ root }) => root.userPrivateRef({ userId }).query()
  }, {
    name: 'subOrders',
    query: ({ root }) => root.allSubOrdersRef({ crafterId, jobId  }).query()
  }, {
    name: 'breakfastOrders',
    query: ({ root }) => root.allBreakfastOrdersRef({ crafterId, jobId  }).query()
  }],
  final: {
    take: [ 'jobBasic', 'mePrivate', 'jobDays', 'globalSubOptions', 'globalSubItems', 'subOrders', 'breakfastOrders' ],
    instantiate: ({ jobBasic, mePrivate, jobDays, globalSubOptions, globalSubItems, subOrders, breakfastOrders, root }) => (
      root._spinoff(JobCatering, { 
        members: { 
          jobDays,
          jobBasic,
          mePrivate,
          subOrders,
          breakfastOrders,
          subGlobalMenu: root._spinoff(subsGlobalMenu.CrafterSubsGlobalMenu, {
            members: {
              items: globalSubItems, 
              options: globalSubOptions 
            }
          })
        }
      })
    )
  }
}))
import { createNodeClass } from '@blast-engine/firework'
import { kv, get, set } from '@blast-engine/utils'

import { BreakfastOrder } from './breakfast-order.node'
import { BreakfastOrderList } from './breakfast-order.list'

export const BreakfastOrdersForJobDay = createNodeClass({
  name: 'BreakfastOrdersForJobDay',

  full: class {

    breakfastOrders({ breakfastId }) {
      return this._spinoff(BreakfastOrderList.full(), {
        data: this._data(['orders', breakfastId]),
        path: this._path(['orders', breakfastId])
      })
    }

    subOrders({ subId }) {
      return this.breakfastOrders({ breakfastId: subId })
    }

    _waitingForParts() {
      return false
    }

    setBreakfastOrderAsDone({ breakfastId }) {
      const breakfastList = this.breakfastOrders({ breakfastId })
      return breakfastList.asArray().map(breakfast => breakfast.setStatusAsDone())
    }

    setSubOrderAsDone({ subId }) {
      return this.setBreakfastOrderAsDone({ breakfastId: subId })
    }

    setOrderAsPickedUp({ breakfastId, subId, userId }) {
      return this._update({ [`orders/${breakfastId || subId}/${userId}/pickedup`]: true })
    }

    setOrderAsNotPickedUp({ breakfastId, subId, userId }) {
      return this._update({ [`orders/${breakfastId || subId}/${userId}/pickedup`]: null })
    }

    isOrderPickedup({ breakfastId, subId, userId }) {
      
      return this._data(`orders.${breakfastId || subId}.${userId}.pickedup`)
    }
  },

  ref: class {

    addBreakfastOrder({ breakfastId, userId, optionSelections }) {
      return this._update({
        [`orders/${breakfastId}/${userId}`]: {
          _: true,
          breakfastId, 
          userId,
          optionSelections
        }
      })
    }

    breakfastOrderRef({ breakfastId, userId }) {
      return this._spinoff(BreakfastOrder.ref(), {
        path: this._path(['orders', breakfastId, userId])
      })
    }

  }

})
import { basket } from './basket'  
import { SubMenuItemList, fetchSubMenuItemList } from './sub-menu'
import { BreakfastMenuItemList, fetchBreakfastMenuItemList } from './breakfast-menu'
import { JobCraftMenu, JobCraftMenuItem, fetchJobCraftMenu, JobCraftCategories } from './craft-menu'
import { craftOrders } from './craft-orders'
import { subOrders } from './sub-orders'
import { breakfastOrders } from './breakfast-orders'
import { fetchCrafterJobDays } from './days.fetcher'
import { JobDays } from './days.list'
import { JobCrewUsers } from './job-crew-users.node'
import { fetchJobCrewUsersRef } from './job-crew-users.node.ref.fetcher'
import { fetchJobCrewUsers } from './job-crew-users.assembly.fetcher'
import { rootMethods } from './root-methods'
import { pausedService } from './paused-service'
import { LocationNames, fetchLocationNames, Locations, fetchLocations } from './locations'
import * as breakfastPreorders from './breakfast-preorders'

export const jobData = {
  basket,
  craftOrders,
  subOrders,
  breakfastOrders,
  pausedService,

  JobDays,
  
  JobCraftMenu,
  fetchJobCraftMenu,
  JobCraftMenuItem,
  JobCraftCategories,

  SubMenuItemList,
  fetchSubMenuItemList,
  BreakfastMenuItemList, 
  fetchBreakfastMenuItemList,

  fetchCrafterJobDays,
  
  JobCrewUsers,
  fetchJobCrewUsersRef,
  fetchJobCrewUsers,

  Locations,
  LocationNames,
  fetchLocations,
  fetchLocationNames,

  breakfastPreorders,

  rootMethods
}
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import LogRocket from 'logrocket'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import {} from '@/connectivity.service'
import * as entities from '@crafty/entities'
import { ConnectivityDaemon } from '../connectivity.daemon'
import { DisconnectedScreen } from './disconnected.screen'
import { connectionStatus } from '../../connectivity.service'

export const ConnectivityGuard = 
  fireworkConnect(() => ({
    me: entities.user.fetchMe({ keepOldResultUntilNew: true })
  }))(
class ConnectivityGuard extends React.PureComponent {

  state = { isConnected: true }
  
  componentDidMount() {
    connectionStatus.subscribe(status => {
      if (this.state.isConnected !== status.isConnected) {
        this.setState({ isConnected: status.isConnected })      
      }
    })
  }

  render() {
    const { me, renderContent } = this.props
    const { isConnected } = this.state

    if (!me) return null

    return (
      <>
        <ConnectivityDaemon myId={me.userId()}/>
        {
          isConnected 
            ? renderContent({ me }) 
            : <DisconnectedScreen/>
        }
      </>
    )
  }
})
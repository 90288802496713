import { createNodeClass } from '@blast-engine/firework'
import { kv, get, set, keys } from '@blast-engine/utils'


import { Basket } from '../basket/basket.node'

export const BreakfastOrder = createNodeClass({
  name: 'BreakfastOrder',

  full: class {

    _waitingForParts() {
      return false
    }

    pickupTime() {
      return this._data('pickupTime')
    }

    setPickupTime(pickupTime) {
      return this._update({ pickupTime })
    }

    id() {
      return this._data('breakfastId')
    }

    breakfastId() {
      return this.id()
    }

    subId() {
      return this.id()
    }

    notes() {
      return this._data('notes') || ''
    }

    userId() {
      return this._data('userId')
    }

    status() {
      return this._data('status')
    }

    setStatusAsDone() {
      return this._update({ status: 'ready' })
    }

    timestamp() {
      return this._data('timestamp')
    }

    selectionOptionIds() {
      return keys(this._data('optionSelections'))
    }
  
    selectionOptionItemIdsForOptionId(optId) {
      return keys(this._data(`optionSelections.${optId}`))
    }
  
    optionItemIsSelected(optionId, itemId) {
      return !!this._data(`optionSelections.${optionId}.${itemId}`)
    }
  
    setSelectedItem(optionId, itemId) {
      return this._update({ 
        optionSelections: {
          ...(this._data('optionSelections') || {}),
          [optionId]: { [itemId]: true }
        }
      })
    }
  
    addSelectedItem(optionId, itemId) {
      return this._update({ 
        optionSelections: {
          ...(this._data('optionSelections') || {}),
          [optionId]: {
            ...(this._data(`optionSelections.${optionId}`) || {}),
            [itemId]: true
          }
        }
      })
    }
  
    removeSelectedItem(optionId, itemId) {
      return this._update({ 
        optionSelections: {
          ...(this._data('optionSelections') || {}),
          [optionId]: {
            ...(this._data(optionId) || {}),
            [itemId]: null
          }
        }
      })
    }

  },

  ref: class {

    initialize({ id, userId, notes = '' }) {
      return this._update({ 
        id, 
        status: 'waiting', 
        userId, 
        notes,
        timestamp: Date.now() 
      })
    }

  }

})
import React from 'react'
import ReactDOM from 'react-dom'
import LogRocket from 'logrocket'

import 'antd/dist/antd.css'
import './index.css'

import * as serviceWorker from './serviceWorker'

import { kernel } from './kernel.singleton'
import { App } from './components'
import { ErrorBoundary } from './components/actual-app/error-boundary'
import * as state from './state'
import { gtstore } from './gt-store.service'

window.gtstore = gtstore
window.gts = gtstore
window.state = state

// LogRocket.init(process.env.LOGROCKET_ID)
LogRocket.track = () => null
LogRocket.log = () => null
LogRocket.captureException = () => null
LogRocket.captureMessage = () => null
LogRocket.error = () => null
LogRocket.identify = () => null

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField, Drawer, Icon } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { Card, List } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

import './header.connected.scss'

export const Header = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    jobCraft: entities.crafter.crafterData.fetchJobCraftMenuManager({ 
      jobId: currentJob.id(), 
      crafterId: currentJob.crafterId() 
    })
  }))(
class Header extends React.Component {

  state = { drawerOpen: false }

  componentDidMount() {
    window.header = this
  }

  render() {
    const { 
      me, currentJob, pageTitle, pageData, jobCraft,
      showBack, goBack
    } = this.props
    const { drawerOpen } = this.state

    const jobMenu = jobCraft
    if (!isReady(jobCraft)) return null

    const exitJob = () => kernel.performUpdates(me.setCurrentJob(null))
    const logOut = () => kernel.logout()

    let title = pageTitle
    if (title === '<craft-category>')
      title = jobCraft.members.globalMenu.members.categories.item(pageData.categoryId).name()
    if (title === '<craft-item>')
      title = jobMenu.itemNameForItemId({ itemId: pageData.itemId }) //jobCraft.members.globalMenu.members.items.item(pageData.itemId).name()

    return (
      <div className='header-container'>
        <div className='left-side'>

          {
            showBack 
              ? (
                <Button className='back-button' onClick={goBack}>
                  <Icon>arrow_back</Icon>
                </Button>
              ) : null
          }

          { 
            title 
              ? <div className='page-title'>{ title }</div>
              : <img style={{ paddingLeft: 15 }} src={require('../../assets/Logo.png')} className='logo-image' />
          }

        </div>
        <div className='right-side'>
          <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingRight: 13, alignItems: 'flex-end' }}>
            {/* <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{currentJob.id()}</div> */}
            <div style={{ fontSize: '4vw' }}>{currentJob.name()}</div>
          </div>
          <Menu fontSize='large' className='menu-icon' onClick={() => this.setState({ drawerOpen: true })}></Menu>
          <Drawer className='drawer-section' anchor={'left'} open={drawerOpen} onClose={() => this.setState({ drawerOpen: false })}>
            <List style={{ display: 'flex', flexDirection: 'column' }}>
              <List.Item><Button className='each-drawer-menu-button' style={{ width: '70vw' }} onClick={exitJob}>exit job</Button></List.Item>
              <List.Item><Button className='each-drawer-menu-button' style={{ width: '70vw' }} onClick={logOut}>log out</Button></List.Item>
            </List>
          </Drawer>
        </div>
      </div>
    )
  }
}))
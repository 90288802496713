import { BreakfastOrder } from './breakfast-order.node'
import { BreakfastOrdersForJobDay } from './breakfast-orders-for-job-day.node'
import { fetchBreakfastOrder } from './breakfast-order.node.fetcher'
import { AllBreakfastOrders } from './all-breakfast-orders.node'
import { rootMethods } from './root-methods'

export const breakfastOrders = {
  BreakfastOrder,
  BreakfastOrdersForJobDay,
  AllBreakfastOrders,
  fetchBreakfastOrder,
  rootMethods
}
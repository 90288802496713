import { keys, set, get } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const JobCraftCategories = createNodeClass({
  name: 'JobCraftCategories',

  full: class {

    priority({ categoryId }) {
      return parseInt(this._data(`${categoryId}.priority`) || 100)
    }

    setPriority({ categoryId, priority = 100 }) {
      return this._update({ [`${categoryId}/priority`]: parseInt(priority) })
    }

  }

})
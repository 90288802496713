import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { isReady } from '@blast-engine/firework'
import { Icon, Button, Input, Radio, CircularProgress, List } from '@material-ui/core'
import { Card, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import * as entities from '@crafty/entities'
import { fireworkConnect, kernel } from '@/kernel.singleton'
import { job } from '@crafty/entities/src/crafter/crafter-data/job'

import './basket-page.scss'

export const BasketPage = withRouter(fireworkConnect(({ me, currentJob }) => ({
  config: entities.fetchConfig(),
  basket: entities.crafter.crafterData.fetchBasketComplete({
    crafterId: currentJob.crafterId(),
    jobId: currentJob.id(),
    userId: me.userId()
  }),
  jobCraftMenu: entities.crafter.crafterData.job.jobData.fetchJobCraftMenu({ crafterId: currentJob.crafterId(), jobId: currentJob.id() }),
  locations: entities.crafter.crafterData.job.jobData.fetchLocations({ crafterId: currentJob.crafterId(), jobId: currentJob.id() }),
}))(class BasketPage extends React.PureComponent {
  state = { orderSubmissionStatus: 'not-started', notes: null }

  render() {
    
    const { 
      // props
      me, currentJob, allCraft,
      goToCraft = () => null,
      goToOrders = () => null,
      // data
      basket, jobCraftMenu,
      locations,
      config
    } = this.props
    const { 
      currentStep = 'not-submitted',
      notes = ''
    } = this.state

    if (!isReady(me, currentJob, basket, jobCraftMenu, locations, config)) return null
    window.b = this

    let notesEnabledExclusively = false
    if (config.get(`jobs.${currentJob.id()}.exclusiveNotes`)) {
      notesEnabledExclusively = (
        config.get(`jobs.${currentJob.id()}.exclusiveNotes.individuals.${me.userId()}`) ||
        config.get(`jobs.${currentJob.id()}.exclusiveNotes.departments.${me.department().toLowerCase().replace(/\W/g, '')}`)
      )
    }

    const removeBasketItem = async (itemId) => {
      await kernel.performUpdates([ basket.removeItem(itemId) ])
    }

    const submit = async () => {
      if (locations.locationsEnabled() && !basket.members.basket.locationId()) {
        this.setState({ shouldLocationSectionBlink: true })
        setTimeout(() => this.setState({ shouldLocationSectionBlink: false }), 4000)
        return
      }
      
      this.setState({ currentStep: 'submitting' })
      await kernel.performUpdates(basket.submitOrder())
      setTimeout(() => {
        this.setState({ currentStep: 'submitted', notes: '' })
      }, 300)
      setTimeout(() => this.props.history.push('/app/orders'), 800)
    }

    const basketItemCompleteArray = basket.items()
    basketItemCompleteArray.forEach(basketItemComplete => {
      const jobItem = jobCraftMenu.item(basketItemComplete.globalItem().id())
      if (!jobItem || !(jobItem.enabled())) {
        kernel.performUpdates([ basket.removeItem(basketItemComplete.id()) ])
      }
    })

   
    if (basket.members.basket.locationId() && !locations.location({ locationId: basket.members.basket.locationId()})) {
      kernel.performUpdates([ basket.members.basket.updateLocationId({ locationId: null }) ])
    }
    
debugger
    return (
      <div className='basket-page-container' style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%' }}>
        {
          currentJob.craftPaused() ? (
            <div style={{ padding: '15px', fontSize: '15px' }}>
              Craft services are currently paused, we are preparing the truck to serve you.
            </div>
          ) : (
            <>
              <div style={{ padding: 10, flex: 1, overflow: 'scroll' }}>
                {
                  (true && basket.hasItems())
                    ? basket.items()
                      .map(basketItem => (
                        <>
                          <Card key={basketItem.id()} style={{ marginBottom: 10 }}>
                            <div style={{ paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                              <div style={{ fontSize: 23 }}>
                                <span style={{ fontWeight: '600' }}>{jobCraftMenu.item(basketItem.members.item.craftItemId()) && jobCraftMenu.item(basketItem.members.item.craftItemId()).editedItemLabel() ? jobCraftMenu.item(basketItem.members.item.craftItemId()).editedItemLabel() : basketItem.name()}</span> 
                                <span style={{ paddingLeft: 10, fontSize: 23 }}>x{basketItem.numCopies()}</span>
                              </div>
                              <div style={{ fontSize: 15, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                <Icon style={{ color: 'red' }} onClick={() => removeBasketItem(basketItem.id())}>delete</Icon>
                              </div>
                            </div>
                            {
                              basketItem.selectionOptionIds()
                                .map(optId => (
                                  <div style={{ flexDirection: 'row' }}>
                                    <span>{basketItem.selectionOptionLabel(optId)}: </span>
                                    <span style={{ fontWeight: "700" }}>{basketItem.selectedOptionItemLabelsForOption(optId).join(', ')}</span>
                                  </div>
                                ))
                            }
                          </Card>
                          
                        </>
                      ))
                    : (
                      <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 23, paddingTop: 25 }}>Basket is empty</div>
                        <Button style={{ color: 'blue' }} onClick={goToCraft}>Find some items</Button>
                      </div>
                    )
                }
              </div>
              {
                basket.hasItems() && locations.locationsEnabled()
                ?
                  <div className={`location-section ${this.state.shouldLocationSectionBlink ? 'blink' : ''}`}>
                    Pickup Location: <Select
                      placeholder="Select Food Pickup Location"
                      onChange={value => {
                        this.setState({ selectedLocationId: value })
                        kernel.performUpdates([ basket.members.basket.updateLocationId({ locationId: value }) ])
                      }}
                      value={basket.members.basket.locationId()}
                      className='select-section'
                    >
                      {
                        locations.locationNames().map(locationName => (
                          <Select.Option key={locationName.id()} value={locationName.id()}>
                            {locationName.name()}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </div>
                : <></>
              }
              {
                (basket.hasItems() && (basket.notesAreEnabled() || notesEnabledExclusively)) ?
                  <div style={{ margin: '0 3vw' }}>
                    <div style={{ fontSize: 18, fontWeight: '700' }}>Notes</div>
                    <TextArea
                      autoSize={false}
                      style={{ resize: 'none' }}
                      rows={3}
                      value={notes}
                      onChange={e => {this.setState({ notes: e.target.value }); kernel.performUpdates([ basket.members.basket.addNotes({ notes: e.target.value }) ])}}
                    />
                  </div>
                : null
              }
              {
                (
                  (basket.hasItems() && currentStep === 'not-submitted')
                    || currentStep === 'submitting'
                    || currentStep === 'submitted'
                ) ? (
                  <div style={{ display: 'flex', height: '9vh', flexGrow: 0, flexShrink: 0, flexBasis: '9vh', borderColor: 'rgb(236, 234, 234)', borderTopWidth: 1, borderTopStyle: 'solid', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
                    {
                      basket.hasItems() && currentStep === 'not-submitted' ? (
                      // false ? (
                        <Button onClick={submit} style={{ color: 'blue' }}>Submit Order</Button>
                      ) : currentStep === 'submitting' ? (
                      // ) : false ? (
                        <CircularProgress/>
                      ) : currentStep === 'submitted' ? (
                        <div style={{ width: '100%', paddingLeft: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Icon style={{ fontSize: 26, display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'green' }}>check</Icon>
                          <Button onClick={goToOrders}>Go to Orders</Button>
                        </div>
                      ) : null
                    } 
                  </div>
                ) : null
              }
            </>
          ) 
        }   
      </div>
    )
  }
}))
import { keys } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const CraftGlobalItemBasic = createNodeClass({
  name: 'CraftGlobalItemBasic',

  struct: class {

    id() {
      return this._data('id')
    }

    categoryId() {
      return this._data('categoryId')
    }

    name() {
      return this._data('name')
    }

    label() {
      return this.name()
    }

    description() {
      return this._data('description')
    }

    notesAreEnabled() {
      return this._data('notesAreEnabled')
    }

    updateCategoryId({ categoryId }) {
      return this._update({ categoryId })
    }

    enableNotes() {
      return this._update({ notesAreEnabled: true })
    }

    disableNotes() {
      return this._update({ notesAreEnabled: false })
    }

    notes() {
      return this._data('notes')
    }

    updateNotes({ notes }) {
      return this._update({ notes })
    }

    optionsList() {
      return this._data('optionsList') || {}
    }

    optionIds() {
      return keys(this.optionsList())
    }

    addOption({ optionId, isRequired }) {
      return this._update({ [`optionsList/${optionId}/isRequired`]: !!isRequired })
    }

    removeOption({ optionId }) {
      return this._update({ [`optionsList/${optionId}`]: null })
    }

    updateLabel({ label }) {
      return this._update({ name: label })
    }

    fakeDelete() {
      return this._update({ _deleted: true })
    }

    isFakeDeleted() {
      return !!this._data('_deleted')
    }

  },

  ref: class {

    initialize({ id, name, categoryId }) {
      return this._update({ id, name, categoryId })
    }

  },

  full: class { 

  }

})
import { UserBasic } from './user-basic.node'
import { UserBasicList } from './user-basic.list'
import { UserBasicSelection } from './user-basic.selection'
import { fetchUserBasic } from './user-basic.node.fetcher'
import { fetchUserBasicListRef } from './user-basic.list.ref.fetcher'
import { fetchUserBasicList } from './user-basic.list.fetcher'

import { UserPrivate } from './user-private.node' 
import { UserPrivateList } from './user-private.list'
import { fetchUserPrivateListRef } from './user-private.list.ref.fetcher'

import { UserEmailList } from './user-email.list'
import { fetchUserEmailListRef } from './user-email.list.fetcher'

import { Me } from './me.assembly'
import { fetchMe } from './me.fetcher'
import { rootMethods } from './root-methods'
import * as meActions from './me.actions'
import { registerUser } from './register-user.action'

export const user = { 
  UserBasic, 
  UserBasicList,
  UserBasicSelection,
  fetchUserBasic,
  fetchUserBasicList,
  fetchUserBasicListRef,

  UserEmailList,
  fetchUserEmailListRef,

  UserPrivate,
  UserPrivateList,
  fetchUserPrivateListRef,

  Me,
  fetchMe,
  registerUser,
  ...meActions, 
  
  rootMethods
}
import { NotificationsQueue } from './notifications-queue.list'
import { NotificationsQueueItem } from './notifications-queue-item.node'

export const rootMethods = {

  notificationsQueueRef() {
    return this._spinoff(NotificationsQueue.ref(), {
      path: `notifications_queue`
    })
  }

}
import { createFetcher } from '@blast-engine/firework'

export const fetchInvitesList = createFetcher({
  name: 'fetch-invites-list'
}, () => ({
  steps: [{
    name: 'list',
    requires: [ ],
    query: ({ root }) => 
      root.invitesListRef().query()
  }],
  final: {
    take: [],
    instantiate: ({ list }) => list
  }
}))
import { createNodeClass } from '@blast-engine/firework'

export const JobRegistryItem = createNodeClass({
  name: 'JobRegistryItem',

  full: class {

    jobId() {
      return this._data('id')
    }

    crafterId() {
      return this._data('crafterId')
    }

    name() {
      return this._data('name')
    }

    jobStatuses() {
      return [
        'not-started',
        'active',
        'finished'
      ]
    }

  },

  ref: class {

    initialize({ id, crafterId, name }) {
      return this._update({ id, crafterId, name })
    }

  }

})
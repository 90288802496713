import React from 'react'
import { isReady } from '@blast-engine/firework'
import {} from '@material-ui/core'
import {} from 'antd'
import { Switch, Route, Redirect, Link } from 'react-router-dom'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './caterer.scss'

export const Caterer = fireworkConnect(props => ({

}))(class Caterer extends React.Component {
  state = {}

  render() {
    const {} = this.props
    const {} = this.state

    // if (!isReady()) return null
    
    return (
      <div style={{ width: '100%', height: '100%' }} className='caterer-container'>
        <Switch>
          <Route path='/caterer/jobs/:jobId' render={(props) => 
            <Comps.CatererJob crafterId={this.props.crafterId} jobId={props.match.params.jobId} />}
          />
          <Route path="/caterer/jobs">
            <Comps.CatererJobList crafterId={this.props.crafterId} />
          </Route>
          <Route path='/caterer/printableMeals/:jobId/:dayNum' render={(props) => 
            <Comps.CraftOfficePrintableMeals crafterId={this.props.crafterId} jobId={props.match.params.jobId} dayNum={props.match.params.dayNum} />}
          />
        </Switch>
      </div>
    )
  }
})
import { createFetcher } from '@blast-engine/firework'

export const fetchInvite = createFetcher({
  name: 'fetch-invite'
}, ({ userId }) => ({
  steps: [{
    name: 'invite',
    query: ({ root }) => root.inviteRef({ userId }).query()
  }],
  final: {
    take: [ 'invite' ],
    instantiate: ({ invite }) => invite
  }
}))
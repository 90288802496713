import { keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { subsGlobalMenu } from './subs-global-menu'
import { job } from './job'
import { user } from '../../user'

export const JobCatering = createAssemblyClass({
  name: 'JobCatering',
  memberModels: {
    jobBasic: job.JobBasic.full(),
    subGlobalMenu: subsGlobalMenu.CrafterSubsGlobalMenu,
    jobDays: job.jobData.JobDays.full(),
    mePrivate: user.UserPrivate.full(),
    subOrders: job.jobData.subOrders.AllSubOrders.full(),
    breakfastOrders: job.jobData.breakfastOrders.AllBreakfastOrders.full()
  },
  portMethods: {
    subGlobalMenu: [{ method: 'item', rename: 'globalItem' }]
  },
  body: class {

    daysFromToday() {
      const { jobDays, jobBasic } = this.members
      return jobDays.asArray()
        .filter(day => parseInt(day.dayNum()) >= parseInt(jobBasic.currentDay()))
    }

    allDays() {
      const { jobDays } = this.members
      return jobDays.asArray()
    }

    subItem({ subId, dayNum }) {
      const { jobDays } = this.members
      const day = jobDays.item(dayNum)
      return day.subMenu().item(subId)
    }

    breakfastItem({ breakfastId, dayNum }) {
      const { jobDays } = this.members
      const day = jobDays.item(dayNum)
      return day.breakfastMenu().item(breakfastId)
    }

    globalItem(subId) {
      return this.members.subGlobalMenu.item(subId)
    }

    enabledSubOptionIds({ subId, dayNum }) {
      return this.subItem({ subId, dayNum }).enabledOptionIds()
    }

    enabledBreakfastOptionIds({ breakfastId, dayNum }) {
      return this.breakfastItem({ breakfastId, dayNum }).enabledOptionIds()
    }

    enabledOptionItemIds({ subId, optId, dayNum }) {
      const { subGlobalMenu } = this.members
      const optionItems = subGlobalMenu.option(optId).items()
      const optionItemIds = keys(optionItems)
      const jobSubItem = this.subItem({ subId, dayNum })
      return optionItemIds
        .filter(optionItemId => jobSubItem.isOptionItemEnabled({ 
          optionId: optId, 
          optionItemId 
        }))
    }

    enabledBreakfastOptionItemIds({ breakfastId, optId, dayNum }) {
      const { subGlobalMenu } = this.members
      const optionItems = subGlobalMenu.option(optId).items()
      const optionItemIds = keys(optionItems)
      const jobBreakfastItem = this.breakfastItem({ breakfastId, dayNum })
      return optionItemIds
        .filter(optionItemId => jobBreakfastItem.isOptionItemEnabled({ 
          optionId: optId, 
          optionItemId 
        }))
    }

    optionType(optionId){
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optionId).type()
    }

    optionItemLabel({ optId, optionItemId }) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optId).itemLabel(optionItemId)
    }

    orderSub({ userId, dayNum, subId, optionSelections = null, notes = '' }) {
      const { jobBasic, subOrders, mePrivate } = this.members
      return [
        mePrivate.addJobSubOrderForDay({ 
          jobId: jobBasic.id(), 
          day: jobBasic.currentDay(), 
          subId 
        }),
        subOrders.addSubOrder({ 
          dayNum,
          subId, 
          userId, 
          optionSelections,
          notes
        })
      ] 
    }

    orderBreakfast({ dayNum, breakfastId, optionSelections = null, notes = '' }) {
      const { jobBasic, breakfastOrders, mePrivate } = this.members
      return [
        mePrivate.addJobBreakfastOrderForDay({ 
          jobId: jobBasic.id(), 
          day: jobBasic.currentDay(), 
          breakfastId 
        }),
        breakfastOrders.addBreakfastOrder({ 
          dayNum,
          breakfastId, 
          userId: mePrivate.id(), 
          optionSelections,
          notes
        })
      ] 
    }

    removeSubOrder({ dayNum, subIds }) {
      const { jobBasic, subOrders, mePrivate } = this.members
      return [
        mePrivate.removeJobSubOrderForDay({ 
          jobId: jobBasic.id(), 
          day: jobBasic.currentDay()
        }),
        ...subIds.map(subId => subOrders.removeSubOrder({ 
          dayNum, 
          subId, 
          userId: mePrivate.id()
        }))
      ] 
    }

    removeBreakfastOrder({ dayNum, breakfastIds }) {
      const { jobBasic, breakfastOrders, mePrivate } = this.members
      return [
        mePrivate.removeJobBreakfastOrderForDay({ 
          jobId: jobBasic.id(), 
          day: jobBasic.currentDay()
        }),
        ...breakfastIds.map(breakfastId => breakfastOrders.removeBreakfastOrder({ 
          dayNum, 
          breakfastId, 
          userId: mePrivate.id()
        }))
      ] 
    }
  }
})
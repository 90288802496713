import { CraftyData } from './crafty-data.node'
import { fetchCraftyData} from './crafty-data.node.fetcher'
import { rootMethods } from './root-methods'
import { craftyDataExampleData1 } from './crafty-data.node.test-data'

export const crafty = {
  CraftyData,
  fetchCraftyData,
  craftyDataExampleData1,
  rootMethods
}
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import LogRocket from 'logrocket'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

export const LoginGuard = 
  fireworkConnect(() => ({
    me: entities.user.fetchMe({ keepOldResultUntilNew: true })
  }))(
class LoginGuard extends React.PureComponent {

  state = { didIdentify: false }

  // componentDidUpdate() {
  //   const { me } = this.props
  //   const { didIdentify } = this.state
  //   if (!isReady(me)) return
  //   if (!me.isAnon() && !didIdentify) {
  //     LogRocket.identify(me.userId(), { 
  //       name: me.username(), 
  //       email: me.email() 
  //     })
  //     this.state.didIdentify = true
  //   }
  // }

  render() {
    const { me, renderContent } = this.props
    const { } = this.state

    LogRocket.log({ 
      event: 'LoginGuard render()',  
      props: { me }, 
      state: {} 
    })

    if (!me) return null
    if (me.isAnon()) return <Redirect to='/login' />

    return renderContent({ me })
  }
})
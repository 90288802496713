import React from 'react'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { isReady } from '@blast-engine/firework'
import * as Comps from '@/components'

import './crafty.scss'

export const Crafty = fireworkConnect(props => ({
  craftyManager: entities.fetchCraftyManager()
}))(class Crafty extends React.Component {
  state = { newUserEmail: '' }

  addUser() {
    const { craftyManager } = this.props
    const { newUserEmail } = this.state
    const userId = craftyManager.getUserIdFromEmail({ email: newUserEmail })
    if (!userId) return console.warn(`user id not found for email ${newUserEmail}`)

    const update = [craftyManager.inviteUserToCrafty({ userId })]
    kernel.performUpdates(update)
  }

  render() {
    const { craftyManager } = this.props
    const { newUserEmail } = this.state
    
    if (!isReady(craftyManager)) return null
    
    return (
      <div className='crafty-container'>
        <div>
          <div>all users</div>
          <div>
            {
              craftyManager.members.memberBasics.asArray().map(userBasic => (
                <div key={userBasic.id()}>
                  {userBasic.username()}
                </div>
              ))
            }
          </div>
        </div>
        <div>
          <div>add user</div>
          <input value={newUserEmail} placeholder='new user email' onChange={e => this.setState({ newUserEmail: e.target.value })} />
          <button onClick={() => this.addUser()}>add</button>
        </div>
      </div>
    )
  }
})
import { createFetcher } from '@blast-engine/firework'

export const fetchJobBasic = createFetcher({
  name: 'fetch-job-basic'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'basic',
    requires: [ ],
    query: ({ root }) => 
      root.crafterJobBasicRef({ crafterId, jobId }).query()
  }],
  final: {
    take: ['basic'],
    instantiate: ({ basic }) => basic
  }
}))
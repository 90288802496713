import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { DatePicker, message } from 'antd'
import LogRocket from 'logrocket'

import { fireworkConnect } from '@/kernel.singleton'
// import { Header } from './header.ui'
import './app.css'
// import { CraftItemsGlobal } from './craft-global.ui'

import * as Comps from '.'
import { AppRouter } from './actual-app'
import { BigLoading } from './big-loading'
import { currentVersion } from '../_current_version'

export const App = fireworkConnect(props => ({
  me: entities.user.fetchMe({ keepOldResultUntilNew: true }),
  // craftyManager: entities.fetchCraftyManager
}))(class App extends React.PureComponent {

  state = { currentJob: null, currentPage: null, didIdentify: false }

  componentDidUpdate() {
    const { me } = this.props
    const { didIdentify } = this.state
    if (!isReady(me)) return
    if (!me.isAnon() && !didIdentify) {
      LogRocket.identify(me.userId(), { 
        name: me.username(), 
        email: me.email() 
      })
      this.state.didIdentify = true
    }
  }

  async componentDidMount() {
    kernel.firebase.database().ref('/__current_version__').on('value', (snapshot) => {
      // debugger
      const nextVersion = snapshot.val()
      if (!nextVersion || !nextVersion.length) return
      
      if (!currentVersion || !currentVersion[process.env.CURRENT_CONTEXT_NAME] || !currentVersion[process.env.CURRENT_CONTEXT_NAME].length) return

      console.log('current version: ', currentVersion[process.env.CURRENT_CONTEXT_NAME])
      if (nextVersion !== currentVersion[process.env.CURRENT_CONTEXT_NAME]) {
        if (process.env.NODE_ENV !== 'development') {
          window.location.reload(true)
          console.log('DID UPDATE RELOAD')
          LogRocket.log('track: DID UPDATE RELOAD')
          LogRocket.track('DID UPDATE RELOAD')
        }
        else {
          console.error('--------> PLEASE PULL! YOURE ONE OR MORE COMMITS BEHIND <--------')
        }
      }
    })
    
  }

  redirectUserToLogin() {
    if (window.location.pathname.includes('registered')) return;
    if (window.location.pathname.includes('privacy')) return;
    if (window.location.pathname.includes('terms')) return;
    if (this.props.me.isAnon() && window.location.pathname !== '/' && !(window.location.href.includes('login') || window.location.href.includes('signup')))
      return <Redirect to="/login" />
    return null
  }

  redirectHandler() {
    const { me } = this.props
    // debugger
    if (window.location.pathname.includes('registered')) return
    if (me.isCrafterOffice() && window.location.pathname.includes('/craft-office')) return
    if (me.isCrafterMember() && window.location.pathname.includes('/craft-on-site')) return
    if (me.isCraftyMember() && window.location.pathname.includes('/admin')) return
    if (me.isCaterer() && window.location.pathname.includes('/caterer')) return
    if (window.location.pathname.includes('privacy')) return

    if (me.isCrafterOffice() && (window.location.pathname === '/' || window.location.pathname === '/craft-office' || !window.location.pathname.includes('craft-office'))) {
      return <Redirect to='/craft-office/users' />
    }
    if (me.isCrafterMember() && (window.location.pathname === '/' || window.location.pathname === '/craft-on-site' || !window.location.pathname.includes('craft-on-site'))) {
      return <Redirect to='/craft-on-site/jobs' />
    }
    if (me.isCaterer() && (window.location.pathname === '/' || window.location.pathname === '/caterer' || !window.location.pathname.includes('caterer'))) {
      return <Redirect to='/caterer/jobs' />
    }
    if (me.isCraftyMember() && (window.location.pathname === '/' || !window.location.pathname.includes('admin'))) {
      return <Redirect to='/admin' />
    }
    return <Redirect to='/app' />
  }

  redirectUserToOriginalUrl() {
    const { me } = this.props
    if (this.props.me.isAnon()) return null
    if (window.location.pathname.includes('registered')) return null
    
    if (this.state.oldURl) {
      const url = this.state.oldURl
      
      if (me.isCrafterOffice() && !url.includes('craft-office')) return this.redirectHandler()
      if (me.isCrafterMember() && !url.includes('craft-on-site')) return this.redirectHandler()
      if (me.isCraftyMember() && !url.includes('admin')) return this.redirectHandler()
      if (me.isCrewOnSite() && !url.includes('app')) return this.redirectHandler()
      if (me.isCaterer() && !url.includes('caterer')) return this.redirectHandler()

      return <Redirect to={url} />
    }
    return null
  }

  acceptInvite() {

  }

  render() {
    const { me } = this.props
    const { currentPage } = this.state

    window.me = me
    
    if (!me) return <BigLoading/>
    
    if (this.props.me.isAnon() && window.location.pathname !== '/' && !(window.location.href.includes('login') || window.location.href.includes('signup'))) {
      const oldURl = window.location.pathname
      if (oldURl.includes('login') || oldURl.includes('signup')) return
      this.setState({ oldURl })
    }

    // TODO: refactor this! this will always happen for everyone!
    // it should only happen upon login
    if (!me.isAnon()) kernel.performUpdates([ this.props.me.addUserToEmailList() ])
    
    return (
      <Router>
        {/* <Comps.UserFill_daemon /> */}
        {/* <Comps.Header /> */}

        { !me.isAnon() && kernel.auth() && kernel.auth().userId() && this.redirectHandler() }
        { this.redirectUserToLogin() }
        { !me.isAnon() && me.isCrafterOffice() && !window.location.pathname.includes('printable') && <Comps.NavbarDesktop shouldShowLogout={true} shouldHideLogin={true} shouldShowCraftOfficeToggles={me.isCrafterOffice()} />}
        { !me.isAnon() && me.isCrafterMember() && !window.location.pathname.includes('printable') && <Comps.NavbarDesktop shouldShowLogout={true} shouldHideLogin={true} shouldShowCraftOnsiteToggles={me.isCrafterMember()} />}
        { !me.isAnon() && me.isCaterer() && !window.location.pathname.includes('printable') && <Comps.NavbarDesktop shouldShowLogout={true} shouldHideLogin={true} shouldShowCatererToggles={me.isCaterer()} />}
        { this.redirectUserToOriginalUrl() }
        { me.members.myInvites.crafterId() && kernel.performUpdates(me.acceptInvite()) }
        { me.members.myInvites.userId() && kernel.performUpdates(me.acceptInvite()) }
        <div style={{ 
          width: '100%', 
          // height: '100%',
          marginTop: me.isAnon() ? '0' : ( me.isCrewOnSite() ? '0' : '10vh' ), 
          height: me.isAnon() ? '100%' : (me.isCrewOnSite() ? '100%' : 'calc(100%-10vh)') 
        }}>
          <Switch>
            <Route path='/registered'>
              <Redirect to={'/app'} />
              {/* <Comps.Registered_daemon 
                ignore={() => this.setState({ shouldIgnoreRedirectHandler: true })} 
              /> */}
            </Route>
            <Route path="/app">
              <AppRouter />
            </Route>            
            <Route path="/login">
              <Comps.Login />
            </Route>
            <Route path="/signup">
              <Comps.Signup />
            </Route>
            <Route path="/forgot-password">
              <Comps.ForgotPassword />
            </Route>
            <Route path="/admin">
              <Comps.CraftyAdmin />
            </Route>
            <Route path='/craft-on-site'>
              <Comps.CraftOnSite crafterId={this.props.me.members.mePrivate.crafterId()} />
            </Route>
            <Route path='/caterer'>
              <Comps.Caterer crafterId={this.props.me.members.mePrivate.crafterId()} />
            </Route>
            <Route path="/craft-office">
              <Comps.CraftOffice />
            </Route>
            <Route path='/privacy-policy'>
              <Comps.PrivacyPolicy />
            </Route>
            <Route path='/terms-and-conditions'>
              <Comps.TermsAndConditions />
            </Route>
            <Route path='/' exact>
              {
                me.members.auth.isAnon() ? 
                  <Comps.LandingPage />
                :
                  <Comps.Home me={me} />
              }
              
            </Route>
            {/* <Route path="/craft">
              <Comps.CraftOnSite />
            </Route> */}
            {/* <Route path="/">
              <Comps.Home />
            </Route> */}
          </Switch>
        </div>
      </Router>
    )
  }

})
import { createAssemblyClass } from '@blast-engine/firework'

import { SubGlobalBasicList } from './sub-global-basic.node.list'
import { SubGlobalOptionList } from './sub-global-option.node.list'
import { k } from '@blast-engine/utils'

export const CrafterSubsGlobalMenu = createAssemblyClass({
  name: 'CrafterSubsGlobalMenu',
  memberModels: {
    items: SubGlobalBasicList.full(),
    options: SubGlobalOptionList.full()
  },
  portMethods: {
    
  },
  body: class {

    addOptionToMeal({ itemId, optionId }) {
      const item = this.item(itemId)
      if (!item) return null
      return item.addOption({ optionId })
    }

    removeOptionFromMeal({ itemId, optionId }) {
      const item = this.item(itemId)
      if (!item) return null
      return item.removeOption({ optionId })
    }

    getAllItems() {
      return this.members.items.asArray().filter(item => !item.isFakeDeleted())
    }

    getAllOptions() {
      return this.members.options.asArray().filter(option => !option.isOptionFakeDeleted())
    }

    getOptionsForItem({ itemId }) {
      const { items, options } = this.members
      const optionIds = k(items.item(itemId).optionIds())
      return optionIds.filter(optionId => !this.isOptionFakeDeleted({ optionId })).map(optionId => options.item(optionId))
    }

    getOptionItemsForOption({ optionId }) {
      const { items, options } = this.members
      const option = options.item(optionId)
      const optionItemIds = k(option.items())
      return optionItemIds.filter(itemId => !option.isOptionItemFakeDeleted({ itemId })).map(optionItemId => ({
        id: optionItemId,
        label: option.itemLabel(optionItemId)
      }))
    }

    fakeDeleteMeal({ itemId }) {
      const item = this.item(itemId)
      if (!item) return null
      return item.fakeDelete()
    }

    fakeDeleteOption({ optionId }) {
      const option = this.option(optionId)
      if (!option) return null
      return option.fakeDeleteOption()
    }

    fakeDeleteOptionItem({ optionId, optionItemId }) {
      const option = this.option(optionId)
      if (!option) return null
      return option.fakeDeleteOptionItem({ itemId: optionItemId })
    }

    isMealFakeDeleted({ itemId }) {
      const item = this.item(itemId)
      if (!item) return null
      return item.isFakeDeleted()
    }

    isOptionFakeDeleted({ optionId }) {
      const option = this.option(optionId)
      if (!option) return null
      return option.isOptionFakeDeleted()
    }

    isOptionItemFakeDeleted({ optionId, optionItemId }) {
      const option = this.option(optionId)
      if (!option) return null
      return option.isOptionItemFakeDeleted({ itemId: optionItemId })
    }

    option(optionId) {
      return this.members.options.item(optionId)
    }

    item(itemId) {
      return this.members.items.item(itemId)
    }

    subName(subId) {
      const { items } = this.members
      return items.item(subId).name()
    }

    addItem({ 
      // required
      name,
      // optional
      optionIds, notesAreAllowed, description
    }) {
      return this.members.items.add({ 
        initArgs: { name, optionIds, notesAreAllowed, description } 
      })
    }
    
    addOption({ 
      // required
      type, label, items
    }) {
      return this.members.options.add({ 
        initArgs: { type, label, items } 
      })
    }

    getItemFromId({ itemId }) {
      return this.members.items.item(itemId)
    }

    updateItem({ itemId, updatedLabel, updatedDescription }) {
      const item = this.item(itemId)
      if (!item) return null
      return [ item.updateName({ name: updatedLabel }), item.updateDescription({ description: updatedDescription })]
    }

  }
})
import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'
import { UserBasic } from './user-basic.node'
import { UserPrivate } from './user-private.node'
import { UserEmailList } from './user-email.list'
import { invites } from '../invites'

export const Me = createAssemblyClass({
  name: 'Me',
  memberModels: {
    auth: AuthStruct,
    meBasic: UserBasic.full(),
    mePrivate: UserPrivate.full(),
    userEmailListRef: UserEmailList.ref(),
    myInvites: invites.Invite.full()
  },
  portMethods: {
    auth: [
      'isAnonymous',
      'isAnon',
      'userId',
      'email'
    ],
    meBasic: [
      'setUsername',
      'username',
      'department',
      'setDepartment'
    ],
    mePrivate: [
      'currentJob',
      'setCurrentJob',
      'currentBag',
      'setCurrentBag',
      'jobSubOrdersForDay',
      'jobSubOrdered',
      'phoneNumber',
      // 'setPhoneNumber',
      'phoneNumberAwaitingVerification',
      'setPhoneNumberAwaitingVerification',
      'shouldAskForPhoneNumber',
      'lastTimeOptedOutOfPhoneNumber',
      'setLastTimeOptedOutOfPhoneNumber',
      'setOptedOutOfPhoneNumber',
      'optedOutOfPhoneNumber'
    ]
  },
  body: class {

    // for when we wipe db
    needsFill(){
      const { meBasic } = this.members
      return meBasic.isEmpty()
    }

    setPhoneNumber(phoneNumber) {
      const { mePrivate, meBasic } = this.members
      return [
        mePrivate.setPhoneNumber(phoneNumber),
        meBasic.setHasProvidedPhoneNumber(true)
      ]
    }

    isCraftyMember() {
      return !!(this.members.mePrivate.role() || '').includes('crafty')
    }

    isCrafterOffice() {
      return !!(this.members.mePrivate.role() || '').includes('office')
    }

    isCrafterMember() {
      return !!(this.members.mePrivate.role() || '').includes('on-site')
    }

    isCrewOnSite() {
      return !!(this.members.mePrivate.role() || '').includes('crew') || !this.members.mePrivate.role()
    }

    isCaterer() {
      return !!(this.members.mePrivate.role() || '').includes('caterer')
    }

    acceptInvite() {
      const { mePrivate, myInvites } = this.members
      
      return [
        mePrivate.setRole({ role: myInvites.role() }),
        mePrivate.setCrafterId({ crafterId: myInvites.crafterId() }),
        myInvites.removeInvite()
      ]
    }

    rejectInvite() {
      return this.members.myInvites.removeInvite()
    }

    addPushNotifToken({ token }) {
      return this.members.mePrivate.setPushNotifToken({ token })
    }

    addUserToEmailList() {
      const { auth, userEmailListRef } = this.members
      if (!auth.email()) return null
      const email = auth.email() || ''
      const id = auth.uid()
      const encodedEmail = email.replace(/\./g, '__dot__')
      return userEmailListRef.add({ initArgs: { id }, newItemId: encodedEmail })
    }

    // for when we wipe db
    fill() {
      const { meBasic, auth, userEmailListRef } = this.members
      const email = auth.email()
      const id = auth.uid()
      const encodedEmail = email.replace(/\./g, '__dot__')
      return [
        meBasic.initialize({ id, username: email.split('@')[0] }),
        userEmailListRef.add({ initArgs: { id }, newItemId: encodedEmail })
      ]
    }
    
  }
})
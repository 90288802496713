import { keys } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const UserPrivate = createNodeClass({
  name: 'UserPrivate',

  full: class {

    setPushNotifToken({ token }) {
      return this._update({ [`fbTokens/${token}`]: true })
    }

    role() {
      return this._data('role')
    }

    id() {
      return this._data('userId')
    }

    optedOutOfPhoneNumber() {
      return this._data('optedOutOfPhoneNumber') || false
    }

    setOptedOutOfPhoneNumber(bool) {
      return this._update({ optedOutOfPhoneNumber: bool })
    }

    shouldAskForPhoneNumber(currentTimestamp) {
      if (this.phoneNumber()) return false
      const last = this.lastTimeOptedOutOfPhoneNumber()
      return ((currentTimestamp - last) > 2 * 24 * 60 * 60 * 1000)
    }

    lastTimeOptedOutOfPhoneNumber() {
      return this._data('lastTimeOptedOutOfPhoneNumber') || 0
    }

    setLastTimeOptedOutOfPhoneNumber(timestamp) {
      return this._update({ lastTimeOptedOutOfPhoneNumber: timestamp })
    }

    phoneNumber() {
      return this._data('phoneNumber')
    }

    setPhoneNumber(phoneNumber) {
      return this._update({ phoneNumber })
    }

    phoneNumberAwaitingVerification() {
      return this._data('phoneNumberAwaitingVerification')
    }

    setPhoneNumberAwaitingVerification(phoneNumber) {
      return this._update({ phoneNumberAwaitingVerification: phoneNumber })
    }

    email() {
      return this._data('email')
    }

    userId() {
      return this.id()
    }

    crafterId() {
      return this._data('crafterId')
    }

    jobSubOrdersForDay({ jobId, day }) {
      return keys(this._data(`subOrders.${jobId}.${day}`))
    }

    jobBreakfastOrdersForDay({ jobId, day }) {
      return keys(this._data(`breakfastOrders.${jobId}.${day}`))
    }

    jobSubOrdered({ jobId, day, subId }) {
      return this.jobSubOrdersForDay({ jobId, day }).includes(subId)
    }

    jobBreakfastOrdered({ jobId, day, subId }) {
      return this.jobBreakfastOrdersForDay({ jobId, day }).includes(subId)
    }

    addJobSubOrderForDay({ jobId, day, subId }) {
      return this._update({
        subOrders: {
          ...(this._data(['subOrders']) || {}),
          [jobId]: {
            ...(this._data(['subOrders', jobId]) || {}),
            [day]: {
              ...(this._data(['subOrders', jobId, day]) || {}),
              [subId]: true
            }
          }
        }
      })
    }

    addJobBreakfastOrderForDay({ jobId, day, subId }) {
      return this._update({
        breakfastOrders: {
          ...(this._data(['breakfastOrders']) || {}),
          [jobId]: {
            ...(this._data(['breakfastOrders', jobId]) || {}),
            [day]: {
              ...(this._data(['breakfastOrders', jobId, day]) || {}),
              [subId]: true
            }
          }
        }
      })
    }

    removeJobSubOrderForDay({ jobId, day }) {
      return this._update({
        [`subOrders/${jobId}/${day}`]: null
      })
    }

    removeJobBreakfastOrderForDay({ jobId, day }) {
      return this._update({
        [`breakfastOrders/${jobId}/${day}`]: null
      })
    }

    addJobOrderForDay({ jobId, day, orderId }) {
      return this._update({
        orders: {
          ...(this._data(['orders']) || {}),
          [jobId]: {
            ...(this._data(['orders', jobId]) || {}),
            [day]: {
              ...(this._data(['orders', jobId, day]) || {}),
              [orderId]: true
            }
          }
        }
      })
    }

    jobOrderIdsForDay({ jobId, day }) {
      return keys(this._data(['orders', jobId, day]))
    }

    setRole({ role }) {
      return this._update({ role })
    }

    setCrafterId({ crafterId }) {
      return this._update({ crafterId })
    }

    currentJob() { 
      return this._data('currentJob') || null
    }

    expoToken() {
      return this._data('expoToken')
    }

    updateExpoToken({ expoToken }) {
      return this._update({ expoToken })
    }

    /**
     * @param job: { crafterId, jobId }
     */
    setCurrentJob(job) { 
      if (!job) 
        return [ 
          this._update({ 
            currentJob: null,
            crafterId: null,
            jobId: null,
            role: null
          })
        ]
      return [
        this._update({ currentJob: job }),
        this._update({ crafterId: job.crafterId }),
        this._update({ jobId: job.jobId }),
        this._update({ role: 'crew' })
      ]
    }

    currentBag() {
      return this._data('bag')
    }

    setCurrentBag(bag) {
      return this._update({ bag })
    }

  },
  
  ref: class {

    initialize({ email, id }) {
      return this._update({ userId: id, email })
    }

  }
})
import { keys } from '@blast-engine/utils'
import { createNodeClass } from '@blast-engine/firework'

export const Config = createNodeClass({
  name: 'Config',

  full: class {

    get(path) {
      return this._data(path)
    }

    set(path, data) {
      const _path = path.replaceAll('.', '/')
      return this._update({ [_path]: data })
    }

  },
  
  ref: class {

    initialize({ }) {
      return this._update({ })
    }

  }
})
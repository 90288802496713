import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField, Select, MenuItem, Input, Radio, Checkbox, CircularProgress, Icon } from '@material-ui/core'
import { Card, List } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'
import { get } from '@blast-engine/utils'
import LogRocket from 'logrocket'
import TextArea from 'antd/lib/input/TextArea'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

export const BreakfastItemPage = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    jobCatering: entities.crafter.crafterData.fetchJobCateringComplete({
      crafterId: currentJob.crafterId(),
      jobId: currentJob.id(),
      userId: me.userId()
    }),
    breakfastMenu: entities.crafter.crafterData.fetchJobBreakfastMenuDayComplete({
      crafterId: currentJob.crafterId(),
      jobId: currentJob.id(),
      day: currentJob.currentDay(),
      userId: me.userId()
    })
  }))(
class BreakfastItemPage extends React.PureComponent {

  state = {}

  render() {
    const { 
      // props
      me, currentJob, breakfastId, dayNum,
      goToCatering = () => null,
      // data
      breakfastMenu, jobCatering
    } = this.props
    const {
      optionSelections = {},
      currentStep = 'adding',
      notes = ''
    } = this.state

    if (!isReady(breakfastMenu, jobCatering)) return null

    const day = jobCatering.members.jobDays.item(dayNum)
    
    const selectOptionItem = ({ optionId, optionItemId }) => {
      const optionType = breakfastMenu.optionType(optionId)
      const currentSelectedItemsForOption = optionSelections[optionId] || {}
      const shouldRemovePrevious = ['radio'].includes(optionType)
      this.setState({
        optionSelections: {
          ...optionSelections,
          [optionId]: {
            ...(shouldRemovePrevious ? {} : currentSelectedItemsForOption),
            [optionItemId]: true
          }
        }
      })
    }

    const unselectOptionItem = ({ optionId, optionItemId }) => {
      const optionType = breakfastMenu.optionType(optionId)
      const currentSelectedItemsForOption = optionSelections[optionId] || {}
      const shouldAllow = !['radio'].includes(optionType)
      if (shouldAllow)
        this.setState({
          optionSelections: {
            ...optionSelections,
            [optionId]: {
              ...currentSelectedItemsForOption,
              [optionItemId]: null
            }
          }
        })
    }

    const optionItemIsSelected = ({ optionId, optionItemId }) => 
      !!get(optionSelections, `${optionId}.${optionItemId}`)

    const toggleOptionItemSelect = ({ optionId, optionItemId }) => {
      const isSelected = optionItemIsSelected({ optionId, optionItemId })
      if (isSelected) unselectOptionItem({ optionId, optionItemId })
      else selectOptionItem({ optionId, optionItemId })
    }

    const submit = async () => {
      this.setState({ currentStep: 'submitting' })
      LogRocket.log({ 
        event: 'BreakfastItemPage submit()',  
        props: { dayNum, breakfastId, optionSelections, notes }, 
        state: {} 
      })
      await kernel.performUpdates(jobCatering.orderBreakfast({ dayNum, breakfastId, optionSelections, notes }))
      setTimeout(() => {
        this.setState({ currentStep: 'added' })
      }, 300)
    }

    const hasMadeSelection = jobCatering.members.breakfastOrders.orderIsPlaced({ 
      breakfastId: breakfastId, 
      userId: me.userId(), 
      dayNum: dayNum
    })

    const globalItem = jobCatering.globalItem(breakfastId)
    const order = jobCatering.members.breakfastOrders.order_({ 
      breakfastId: breakfastId, 
      userId: me.userId(), 
      dayNum: dayNum
    })

    console.log({ order })

    const hasSelectedPickupTime = day.hasBreakfastPickupTimes() && !!order.pickupTime()

    window.z = { jobCatering, breakfastMenu }
    LogRocket.log({ 
      event: 'BreakfastItemPage render()',  
      props: { breakfastId, dayNum, jobCatering }, 
      state: this.state 
    })

    return (
      <div style={{ height: '100%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <div>
            <div style={{ fontSize: 19, paddingTop: 10, paddingLeft: 25, paddingRight: 25 }}>
              {globalItem.name()}
            </div>
            {
              globalItem.description() ? (
                <div style={{ paddingTop: 10, paddingLeft: 25, paddingRight: 25 }}> 
                  {/* <div style={{ marginBottom: 5, fontSize: 24, fontWeight: '600' }}>{item.name()}</div>  */}
                  <div>{globalItem.description()}</div>
                </div>
              ) : null
            }
            </div>
          <Card 
            bordered={false}
          >
            {
              jobCatering.enabledBreakfastOptionIds({ breakfastId, dayNum })
                .map(optId => (
                  <div key={optId}>
                    {
                      <List
                        header={<div style={{ fontSize: 18, fontWeight: '700' }}>{ breakfastMenu.breakfastOptionName(optId) }</div>}
                      >
                        {
                          jobCatering.enabledBreakfastOptionItemIds({ breakfastId, optId, dayNum })
                            .map(optionItemId => (
                              <Button
                                key={optionItemId} 
                                style={{ width: '100%', paddingRight: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', fontWeight: 'normal', textTransform: 'none' }}
                                onClick={() => {
                                  if (['radio'].includes(jobCatering.optionType(optId)))
                                    toggleOptionItemSelect({ optionId: optId, optionItemId })
                                  if (['checkbox'].includes(jobCatering.optionType(optId)))
                                    toggleOptionItemSelect({ optionId: optId, optionItemId })
                                }}
                              >
                                {
                                  ['radio'].includes(jobCatering.optionType(optId)) ? (
                                    <Radio 
                                      disabled={hasMadeSelection || currentStep === 'added'}
                                      checked={hasMadeSelection ? order.optionItemIsSelected(optId, optionItemId) : optionItemIsSelected({ optionId: optId, optionItemId })} 
                                    />
                                  ) : ['checkbox'].includes(jobCatering.optionType(optId)) ? (
                                    <Checkbox 
                                      disabled={hasMadeSelection || currentStep === 'added'}
                                      checked={hasMadeSelection ? order.optionItemIsSelected(optId, optionItemId) : optionItemIsSelected({ optionId: optId, optionItemId })}
                                    />
                                  ) : null
                                }
                                <span>{jobCatering.optionItemLabel({ optId, optionItemId })}</span>
                              </Button>
                            ))
                        }
                      </List>
                    }
                  </div>
                ))
            }
            <div>
              <div style={{ fontSize: 18, fontWeight: '700' }}>Notes</div>
              <TextArea
                autoSize={false}
                style={{ resize: 'none' }}
                rows={3}
                value={notes}
                onChange={e => this.setState({ notes: e.target.value })}
              />
            </div>
          </Card>
        </div>        
        <div style={{ minHeight: hasMadeSelection ? '20vh' : '0', display: 'flex', justifyContent: 'stretch', alignItems: 'center', flexGrow: 0, flexShrink: 0, flexBasis: '9vh', borderColor: 'lightgray', borderTopWidth: 1, borderTopStyle: 'solid', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {
            // false ? (
            !hasMadeSelection && currentStep === 'adding' ? (
              <div style={{ height: '100%', display: 'flex', flexDirection: 'row', padding: 12, justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'blue' }}>
                  <Button onClick={submit} style={{ color: 'blue' }}> Place Order </Button>
                </div>
              </div>
            // ) : false ? (
            ) : !hasMadeSelection && currentStep === 'submitting' ? (
              <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '7px' }}>
                <CircularProgress/>
              </div>
            ) : (
              (!day.breakfastPickupTimes().length || hasSelectedPickupTime) ? (
                <div style={{ paddingTop: 15, paddingBottom: 15, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', fontSize: 10, flexDirection: 'row', alignItems: 'center', color: 'blue' }}>
                    <Button onClick={goToCatering}>Place Another Order</Button>
                  </div>
                  <div style={{ fontSize: 30, display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'green' }}>
                    <Icon>check</Icon>
                    <span style={{ fontSize: 18, paddingLeft: 10 }}>Order Placed</span>
                  </div>
                </div>
              ) : (
                <div style={{ width: '100%', padding: '10px' }}>
                  <div>when are you picking up?</div>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    style={{ width: '100%' }}
                    value={order.pickupTime()}
                    onChange={async e => {
                      console.log('xxxxxxxxx')
                      console.log({ value: e.target.value, order, update: order.setPickupTime(e.target.value)  })
                      kernel.performUpdates([ order.setPickupTime(e.target.value) ])
                    }}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div>
                        {selected}
                      </div>
                    )}
                  >
                    {day.breakfastPickupTimes().map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )
            )
          } 
        </div>
      </div>
    )
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchLocationNames = createFetcher({
  name: 'fetch-location-names'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'list',
    requires: [ ],
    query: ({ root }) => 
      root.crafterJobLocationNames({ crafterId, jobId }).query()
  }],
  final: {
    take: [ 'list' ],
    instantiate: ({ list }) => list
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchBreakfastPreorderss = createFetcher({
  name: 'fetch-breakfast-preorderss'
}, ({ crafterId, jobId, dayString }) => ({
  steps: [{
    name: 'preorders',
    query: ({ root }) => root.breakfastPreorders({ crafterId, jobId, dayString }).query()
  }],
  final: {
    take: [ 'preorders' ],
    instantiate: ({ preorders }) => preorders
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchUserEmailListRef = createFetcher({
  name: 'fetch-user-email-list-ref'
}, () => ({
  steps: [],
  final: {
    take: [],
    instantiate: ({ root }) => root.userEmailListRef()
  }
}))
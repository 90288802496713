import { createFetcher } from '@blast-engine/firework'

export const fetchSubOrder = createFetcher({
  name: 'fetch-sub-order',
  debug: true
}, ({ crafterId, jobId, day, subId, userId }) => ({
  steps: [{  
    name: 'order',
    query: ({ root }) => root
      .subOrdersRef({ crafterId, jobId, day })
      .subOrderRef({ subId, userId })
      .query()
  }],
  final: {
    take: ['order'],
    instantiate: ({ order }) => order
  }
}))
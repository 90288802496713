import { SubMenuItemList } from './sub-menu'
import { BreakfastMenuItemList } from './breakfast-menu'
import { JobCraftMenu } from './craft-menu'
import { JobDays } from './days.list'
import { JobCrewUsers } from './job-crew-users.node'
import { JobCraftCategories } from './craft-menu/job-craft-menu-categories.node'
import { LocationNames } from './locations'
import * as breakfastPreorders from './breakfast-preorders'

export const rootMethods = {

  ...breakfastPreorders.rootMethods,


  crafterJobDays({ crafterId, jobId }) {
    return this._spinoff(JobDays.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/days`
    })
  },

  jobCrewUsersRef({ crafterId, jobId }) {
    return this._spinoff(JobCrewUsers.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/crewUsers`
    })
  },

  crafterJobSubMenuForDay({ crafterId, jobId, day }) {
    return this._spinoff(SubMenuItemList.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/days/${day}/subMenu`
    })
  },

  crafterJobBreakfastMenuForDay({ crafterId, jobId, day }) {
    return this._spinoff(BreakfastMenuItemList.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/days/${day}/breakfastMenu`
    })
  },

  crafterJobLocationNames({ crafterId, jobId }) {
    return this._spinoff(LocationNames.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/locations/locationNames`
    })
  },

  crafterJobCraftMenu({ crafterId, jobId }) {
    return this._spinoff(JobCraftMenu.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/craftMenu`
    })
  },

  crafterJobCraftCategories({ crafterId, jobId }) {
    return this._spinoff(JobCraftCategories.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/craftMenuCategories`
    })
  }

}
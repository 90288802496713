  
import React from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'
import { CraftOfficePrintableBreakfast } from './craft-office-printable-breakfast'

import './craft-office.scss'

export const CraftOffice = fireworkConnect(props => ({
  me: entities.user.fetchMe()
}))(class CraftOffice extends React.Component {

  render() {

    if (!isReady(this.props.me)) return null
    const crafterId = this.props.me.members.mePrivate.crafterId()
    return (
      <div className='craft-office-container'>
        <div className='sidebar-container-craft'>
          {/* <Comps.Sidebar /> */}
        </div>
        <div className='main-section'>
          <Switch>
            <Route path="/craft-office/menu/subs">
              {/* <Comps.SubsGlobal /> */}
            </Route>
            <Route path="/craft-office/menu/craft">
              {/* <Comps.CraftItemsGlobal /> */}
            </Route>
            <Route path="/craft-office/menu/lunch">
              {/* <Comps.CraftItemsGlobal /> */}
            </Route>
            <Route path='/craft-office/users'>
              <Comps.CraftOfficeManageUsers crafterId={crafterId} />
            </Route>
            <Route path="/craft-office/menu">
              <Comps.GlobalMenu crafterId={crafterId}/>
            </Route>
            <Route path='/craft-office/printableMeals/:jobId/meals/:dayNum/:time' render={(props) => 
              <Comps.CraftOfficePrintableMeals crafterId={crafterId} pickupTime={props.match.params.time} jobId={props.match.params.jobId} dayNum={props.match.params.dayNum} />}
            />
            <Route path='/craft-office/printableMeals/:jobId/meals/:dayNum' render={(props) => 
              <Comps.CraftOfficePrintableMeals crafterId={crafterId} jobId={props.match.params.jobId} dayNum={props.match.params.dayNum} />}
            />
            <Route path='/craft-office/printableMeals/:jobId/breakfast/:dayNum/:pickupTime' render={(props) => 
              <CraftOfficePrintableBreakfast crafterId={crafterId} pickupTime={props.match.params.pickupTime} jobId={props.match.params.jobId} dayNum={props.match.params.dayNum} />}
            />
            <Route path='/craft-office/printableMeals/:jobId/breakfast/:dayNum' render={(props) => 
              <CraftOfficePrintableBreakfast crafterId={crafterId} jobId={props.match.params.jobId} dayNum={props.match.params.dayNum} />}
            />
            <Route path="/craft-office/jobs/:jobId/meals" render={(props) => 
              <Comps.CraftOfficeJobMeals crafterId={crafterId} jobId={props.match.params.jobId} />
            }/>
            <Route path="/craft-office/jobs/:jobId" render={(props) => 
              <Comps.CraftOfficeJob crafterId={crafterId} jobId={props.match.params.jobId} />
            }/>
            <Route path="/craft-office/jobs">
              <Comps.OfficeJobsList crafterId={crafterId} />
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
})
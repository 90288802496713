import { createNodeClass } from '@blast-engine/firework'
import { kv, get, set, keys, objMap } from '@blast-engine/utils'

import { SubOrder } from './sub-order.node'

export const AllSubOrders = createNodeClass({
  name: 'AllSubOrders',

  full: class {

    ordersForDay(dayNum) {
      return this._data(dayNum)
    }

    // @todo: firework: firebase will return arrays if the keys are numbers. stop this from happening.
    order({ userId, subId, dayNum }) {
      if (!this.data) return undefined
      return get(this.data[dayNum], `orders.${subId}.${userId}`)
    }

    order_({ userId, subId, dayNum }) {
      return this._spinoff(SubOrder.full(), {
        path: this._path([ dayNum, 'orders', subId, userId ]),
        data: this.order({ userId, subId, dayNum })
      })
    }
    
    orderIsPlaced({ userId, subId, dayNum }) {
      return !!this.order({ userId, subId, dayNum })
    }

    orderPickupTime({ userId, subId, dayNum }) {
      const o = this.order({ userId, subId, dayNum })
      if (!o) return
      return o.pickupTime()
    }

    addSubOrder({ dayNum, subId, userId, optionSelections, notes }) {
      return this._update({
        [`${dayNum}/orders/${subId}/${userId}`]: {
          _: true,
          subId, 
          userId,
          optionSelections,
          notes
        }
      })
    }

    removeSubOrder({ dayNum, subId, userId }) {
      return this._update({
        [`${dayNum}/orders/${subId}/${userId}`]: null
      })
    }

    _waitingForParts() {
      return false
    }

  },

  ref: class {


  }

})
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Checkbox } from '@material-ui/core'
import { Divider } from 'antd'
import { kv } from '@blast-engine/utils'
import ReactToPdf from 'react-to-pdf'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-printable-meals.scss'

export const CraftOfficePrintableBreakfast = fireworkConnect(props => ({
  job: entities.crafter.crafterData.job.fetchJobBasic({ crafterId: props.crafterId, jobId: props.jobId }),
  days: entities.crafter.crafterData.job.jobData.fetchCrafterJobDays({ crafterId: props.crafterId, jobId: props.jobId }),
  jobSubOrderComplete: entities.crafter.crafterData.fetchJobBreakfastOrdersComplete({ crafterId: props.crafterId, jobId: props.jobId, day: props.dayNum })
}))(class CraftOfficePrintableMeals extends React.Component {
  state = {
    shouldRun: true,
    selectedTimes: []
  }

  // componentDidUpdate() {
  //   const { jobSubOrderComplete, job } = this.props
  //   if (isReady(jobSubOrderComplete) && !this.state.haveRun) {
  //     const pickedupOrders = 
  //     this.setState({ haveRun: true })
  //   }
  // }

  ref = React.createRef()

  componentWillMount() {
    if (this.props.pickupTime) this.state.selectedTimes.push(this.props.pickupTime)
  }

  render() {
    const { jobSubOrderComplete, job, days } = this.props
    const { selectedTimes } = this.state

    if (!isReady(jobSubOrderComplete, days)) return <h1>loading..</h1>
    window.a = jobSubOrderComplete
    
    const _p = jobSubOrderComplete.perms({ filterPickupTimes: selectedTimes })

    const p = _p.reduce((acc, order) => {
      if (!acc[order.orderName]) acc[order.orderName] = { numOrders: 0 }
      acc[order.orderName]['numOrders'] += order.usernames.length
      if (order.orderNotes && order.orderNotes.length > 0 && !acc[order.orderName]['notes']) acc[order.orderName]['notes'] = []
      if (order.orderNotes && order.orderNotes.length > 0) acc[order.orderName]['notes'].push(order.orderNotes)
      return acc
    }, {})

    const salads = _p.reduce((acc, order) => {
      const saladOption = order.orderOptions.find(o => o.optionName.toLocaleLowerCase().includes('salad'))
      if (saladOption) {
        if (!acc[saladOption.optionItems[0]]) acc[saladOption.optionItems[0]] = 0
        acc[saladOption.optionItems[0]] = acc[saladOption.optionItems[0]] + order.usernames.length
      }
      return acc
    }, {})
    
    const departments = _p.reduce((acc, order) => {
      order.users.forEach(user => {
        // @TODO: This is an error hack!
        //     it might be producing a wrong result
        if (!user.userBasic.data) return 
        const dep = user.department
        if (!acc[dep]) acc[dep] = { accNumber: 0, usernames: [] }
        if (acc[dep]['usernames'].find(u => u === user.username)) return
        acc[dep]['accNumber']++
        acc[dep]['usernames'].push({ 
          userId: user.userBasic.data.userId, 
          orderSubId: order.orderSubId, username: user.username, notes: order.orderNotes, orderName: order.orderName, orderOptions: order.orderOptions })
      })
      return acc
    }, {})

    const markPersonAsDone =  (userId, orderSubId) => {
      kernel.performUpdates([ jobSubOrderComplete.markMealAsPickedUp({ userId, subOrderId: orderSubId }) ])
    }

    const markPersonAsUndone = (userId, orderSubId) => {
      kernel.performUpdates([ jobSubOrderComplete.markMealAsNotPickedUp({ userId, subOrderId: orderSubId }) ])
    }

    window.dept = kv(departments)

    const day = days.item(this.props.dayNum)

    window.day = day

    return (
      <div className='craft-office-printable-meals-container'>
        <div className='navbar'>
          <div className='logo-part'>
            <img className='logo' src={require('../../assets/Logo.png')} />
          </div>
          <div className='job-part'>
            { job.name() } - Day {Number(this.props.dayNum) + 1}
          </div>
        </div>
        {
          day.hasBreakfastPickupTimes() ? (
            <div>
              <span>filter by pickup time: </span>
              {
                day.breakfastPickupTimes().map(pt => (
                  <span 
                    style={{ padding: '10px', fontWeight: 'bold', color: selectedTimes.includes(pt) ? 'blue' : 'black' }} 
                    onClick={() => {
                      if (selectedTimes.includes(pt)) this.setState({ selectedTimes: selectedTimes.filter(st => pt !== st) })
                      else this.setState({ selectedTimes: selectedTimes.concat(pt) })
                    }}
                  >
                    {pt}
                  </span>
                ))
              }
            </div>
          ) : null   
        }
        <Divider orientation='center' className='divider meals-divider'>Accumulated Meals</Divider>
        <div className='meals-section each-section'>
          <div className='headers'>
            <div className='meals section-name'>
              Meals
            </div>
            <div className='num-orders'>
              Number of Orders
            </div>
            <div className='num-orders'>
              Special Requests
            </div>
          </div>
          <div className='body'>
            {
              kv(p).map(({ k, v }, index) => (
                <div key={k} className='row'>
                  <div key={k} className='section-cell meals-cell'>
                    {k}
                  </div>
                  <div className='num-orders-cell'>
                    {v.numOrders}
                  </div>
                  <div className='notes-cell'>
                    {(v.notes || []).map((note, index) => <div key={note}>{index+1}. {note}</div>)}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <Divider orientation='center' className='divider'>Accumulated Salads</Divider>
        <div className='salad-section each-section'>
          <div className='headers'>
            <div className='salads section-name'>
              Salads
            </div>
            <div className='num-orders'>
              Number of Orders
            </div>
          </div>
          <div className='body'>
            {
              kv(salads).map(({ k, v }, index) => (
                <div key={k} className='row'>
                  <div key={k} className='section-cell'>
                    {k}
                  </div>
                  <div className='num-orders-cell'>
                    {v}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <br />
        <Divider orientation='center' className='divider'>Accumulated Meals by Department</Divider>
        <div className='acc-meal-by-dept each-section'>
          <div className='headers'>
            <div className='each-header total-num-header'>
              # Orders
            </div>
            <div className='each-header dept-header'>
              Department
            </div>
            <div className='usernames-header each-header'>
              Names
            </div>
            <div className='meals-header each-header'>
              Meals
            </div>
            <div className='notes-header each-header'>
              Notes
            </div>
          </div>
          <div className='body'>
            {
              kv(departments)
              .sort(({ k }, { k: k2 }) => k.toUpperCase() > k2.toUpperCase() ? 1 : -1)
              .map(({ k: depName, v: info }) => (
                <div key={depName} className='each-row'>
                  
                  <div className='num-orders'>{info.accNumber}</div>
                  <div className='rest'>
                    {
                      info.usernames.map((username) => {
                        const isOrderPickedUp = jobSubOrderComplete.isMealPickedUp({ userId: username.userId, subOrderId: username.orderSubId })
                        return (
                        <div key={username.userId + username.orderSubId} className='internal-row'>
                          <div className='dept'>{depName}</div>
                          <div className={`names ${isOrderPickedUp  && 'pickedup-order'}`}>
                            <Checkbox 
                              className='hidden-print' 
                              checked={!!isOrderPickedUp}
                              onChange={() => {
                                if (isOrderPickedUp) {
                                  kernel.performUpdates([ jobSubOrderComplete.markMealAsNotPickedUp({ userId: username.userId, subOrderId: username.orderSubId }) ])
                                }
                                else {
                                  kernel.performUpdates([ jobSubOrderComplete.markMealAsPickedUp({ userId: username.userId, subOrderId: username.orderSubId }) ])
                                }
                              }}
                            />
                            {username.username || ''}
                          </div>
                          <div className={`meals ${isOrderPickedUp  && 'pickedup-order'}`}>
                            {username.orderName || ''} - {' '}
                            {
                              username.orderOptions.map(option => (
                                <span className='each-option' key={option.optionName}>
                                  <span className='option-name'>{option.optionName}:{' '}</span>
                                  <span className='option-items'>
                                    {
                                      option.optionItems.map(item => (
                                        <span key={item} className='each-option-item' style={{ paddingRight: '5px' }}>
                                          {item}
                                        </span>
                                      ))
                                    }
                                  </span>
                                </span>
                              ))
                            }
                          </div>
                          <div className={`notes ${isOrderPickedUp  && 'pickedup-order'}`}>{username.notes || ''}</div>
                        </div>
                      )})
                    }
                  </div>
                  
                </div>
              ))
            }
          </div>
        </div>
        <br />
        {/* <Divider orientation='center' className='divider'>Meals Breakdown</Divider>
        <div className='specific'>
          <div className='headers'>
            <div className='each-header meals-header'>
              Meals
            </div>
            <div className='each-header names-header'>
              Names
            </div>
            <div className='each-header notes-header'>
              Notes
            </div>
          </div>
          <div className='body'>
            {
              jobSubOrderComplete.perms().map(perm => (
                <div className='each-row'>
                  <div className='meal-cell each-cell'>
                    {perm.orderName} ( x{perm.usernames.length} ){ perm.orderOptions.length ? ' - ' : ''}
                    {
                      perm.orderOptions.reverse().map(o => (
                        <div className='options'>
                          <div className='option-name'>
                            {o.optionName}:
                            {' '}
                          </div>
                          <div className='option-items'>
                            {
                              o.optionItems.map((item, index) => (
                                <div className='each-option-item'>
                                  {item} { index !== o.optionItems.length - 1 && <span>, </span> }
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div className='username-cell each-cell'>
                    {
                      perm.usernames.map((username, index) => {
                        const department = (perm.users.find(i => i.username === username) || { department: '' }).department
                        return <div key={username} className='each-username'>{index+1}. {username} { department.length ? `(${department})` : ''}</div>
                      })
                    }
                  </div>
                  <div className='note-cell each-cell'>
                    {perm.orderNotes}
                  </div>
                </div>
              ))
            }
          </div>
        </div> */}
        
      </div>
    )
  }
})
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import {} from '@material-ui/core'
import {} from 'antd'

import * as Comps from '@/components'

import './home.scss'

export const Home = class Home extends React.Component {
  state = {}

  redirectHandler() {
    const { me } = this.props
    if (me.isCrafterOffice() && (window.location.pathname === '/' || window.location.pathname === '/craft-office' || !window.location.pathname.includes('craft-office'))) {
      return <Redirect to='/craft-office/users' />
    }
    if (me.isCrafterMember() && (window.location.pathname === '/' || window.location.pathname === '/craft-on-site' || !window.location.pathname.includes('craft-on-site'))) {
      return <Redirect to='/craft-on-site/jobs' />
    }
    if (me.isCraftyMember() && (window.location.pathname === '/' || !window.location.pathname.includes('admin'))) {
      return <Redirect to='/admin' />
    }
    return null
  }

  render() {
    const { me } = this.props
    const {} = this.state
    
    return (
      <div className='home-container'>
        { me.isCraftyMember() && <div><Link to='/admin'>admin</Link></div>}
        { me.isCrafterOffice() && <div><Link to='/craft-office'>craft office</Link></div>}
        { me.isCrafterMember() && <div><Link to='/craft-on-site'>craft onsite</Link></div>}
        { this.redirectHandler() }
      </div>
    )
  }
}
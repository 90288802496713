import { createFetcher } from '@blast-engine/firework'

export const fetchJobBasicList = createFetcher({
  name: 'fetch-job-basic-list'
}, ({ crafterId }) => ({
  steps: [{
    name: 'list',
    requires: [ ],
    query: ({ root }) => 
      root.crafterJobBasicListRef({ crafterId }).query()
  }],
  final: {
    take: [ 'list' ],
    instantiate: ({ list }) => list
  }
}))
import { createNodeClass } from '@blast-engine/firework'
import { kv, get, set, keys } from '@blast-engine/utils'

export const JobCrewUsers = createNodeClass({
  name: 'JobCrewUsers',

  full: class {

    recentUserIds(howLong = (4 * 24 * 60 * 60 * 1000)) {
      const now = Date.now()
      return kv(this._data('users'))
        .filter(({ v: timestamp }) => (now - timestamp) < howLong)
        .map(({ k: userId }) => userId)
    }

    allUserIds() {
      return kv(this._data('users'))
        .map(({ k: userId }) => userId)
    }

  },

  ref: class {

    ping(userId) {
      return this._update({ [`users/${userId}`]: Date.now() })
    }

  }

})
import { Basket } from './basket.node'
import { BasketList } from './basket.list'

export const rootMethods = {

  basketRef({ crafterId, jobId, userId }) {
    return this._spinoff(Basket.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/basket/${userId}`
    })
  },

  basketListRef({ crafterId, jobId }) {
    return this._spinoff(BasketList.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/basket`
    })
  }

}
import React from 'react'
import {} from '@material-ui/core'
import {} from 'antd'

import * as Comps from '@/components'

import './marketing-desktop.scss'

export const MarketingDesktop = class MarketingDesktop extends React.Component {
  state = {}

  render() {
    const { cta, tagline, mainImgSrc, buttons = [], reversed } = this.props
    const {} = this.state
    
    return (
      <div className={`marketing-desktop-container${reversed ? ' reversed' : ''}`}>
        <div className='text-side' style={{ 
          paddingRight: reversed ? 0 : '6vw',
          paddingLeft: reversed ? '6vw' : 0
        }}>
          <div className='cta'>
            {cta}
          </div>
          <div className='tagline'>
            {tagline}
          </div>
          <div className='buttons-section'>
            {buttons && buttons}
          </div>
        </div>
        <div className='image-side'>
          <img className='main-img' src={mainImgSrc} />
        </div>
      </div>
    )
  }
}
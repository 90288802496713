import { createListClass } from '@blast-engine/firework'
import { JobDay } from './day.node'

export const JobDays = createListClass({
  name: 'JobDay',
  itemModel: JobDay,
  full: class {
    createDay() {
      const nextDayNum = this.asArray().length
      return this.add({ 
        newItemId: '' + nextDayNum,  
        initArgs: { dayNum: '' + nextDayNum }
      })
    }
  }
})
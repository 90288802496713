import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, TextField, Select, MenuItem } from '@material-ui/core'
import { Modal } from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './global-craft-menu.scss'

export const GlobalCraftMenu = fireworkConnect(props => ({
  craftGlobalMenu: entities.crafter.crafterData.craftMenu.fetchCraftGlobalMenu({ crafterId: props.me.members.mePrivate.crafterId() })
}))(class GlobalCraftMenu extends React.Component {
  state = {
    categoryName: '',
    itemName: '',
    itemCat: '',
    addOptionModalVisible: false,
    newOptionLabel: '',
    addItemModalVisibility: false,
    newItemLabel: ''
  }

  addCategory() {
    const { me, craftGlobalMenu } = this.props
    const { categoryName } = this.state

    const update = craftGlobalMenu.members.categories.add({ initArgs: { name: categoryName } })
    kernel.performUpdates([ update ])
  }

  addItem() {
    const { craftGlobalMenu } = this.props
    const { itemCat, itemName, newItemLabel } = this.state

    const update = craftGlobalMenu.addItem({ name: newItemLabel, categoryId: itemCat })
    kernel.performUpdates([update])
    this.setState({ addItemModalVisibility: false })
  }

  addOption() {
    const { me, craftGlobalMenu } = this.props
    const update = craftGlobalMenu.addOption({ label: this.state.newOptionLabel, type: 'checkbox' })
    kernel.performUpdates([update])
    this.setState({ addOptionModalVisible: false })
  }

  render() {
    const { me, craftGlobalMenu } = this.props
    const { newItemLabel, addItemModalVisibility, newOptionLabel, categoryName, itemName, itemCat, addOptionModalVisible } = this.state

    if (!isReady(craftGlobalMenu)) return null
    window.armin = craftGlobalMenu.members.options.asArray()
    return (
      <div className='global-craft-menu-container'>
        <Modal
          title='Create New Option'
          visible={addOptionModalVisible}
          onOk={() => this.addOption()}
          onCancel={() => this.setState({ addOptionModalVisible: false })}
        >
          <TextField 
            required
            variant='outlined'
            label='Label'
            value={newOptionLabel}
            onChange={e => this.setState({ newOptionLabel: e.target.value })}
          />
        </Modal>
        <Modal
          title='Add New Item'
          visible={addItemModalVisibility}
          onOk={() => this.addItem()}
          onCancel={() => this.setState({ addItemModalVisibility: false })}
        >
          <TextField required variant='outlined' label='Label' value={newItemLabel} onChange={e => this.setState({ newItemLabel: e.target.value })} />
          <Select value={itemCat} onChange={e => this.setState({ itemCat: e.target.value })}>
            {
              craftGlobalMenu.members.categories.asArray().map(categoryBasic => 
                <MenuItem key={categoryBasic.id()} value={categoryBasic.id()} name={categoryBasic.name()}>{categoryBasic.name()}</MenuItem>
              )
            }
          </Select>
        </Modal>
        <div>
          <div>category</div>
          <div>list: </div>
          {
            craftGlobalMenu.members.categories.asArray().map(categoryBasic => <div key={categoryBasic.id()}>{categoryBasic.name()}</div>)
          }
          <div>add category:</div>
          <input value={categoryName} onChange={e => this.setState({ categoryName: e.target.value })} placeholder='category name' />
          <button onClick={() => this.addCategory()}>add category</button>
        </div>
        <div>
          <div>item</div>
          <div>list:</div>
          {
            craftGlobalMenu.members.items.asArray().map(itemBasic => <div key={itemBasic.id()}>
              <Comps.CraftOfficeItem craftGlobalMenu={craftGlobalMenu} itemBasic={itemBasic} />
            </div>)
          }
          <Button color='primary' variant='contained' onClick={() => this.setState({ addItemModalVisibility: true })}>ADD ITEM</Button>
          {/* <div>add item:</div>
          <input value={itemName} onChange={e => this.setState({ itemName: e.target.value })} placeholder='item name' />
          <select value={itemCat} onChange={e => this.setState({ itemCat: e.target.value })}>
            {
              craftGlobalMenu.members.categories.asArray().map(categoryBasic => 
                <option key={categoryBasic.id()} value={categoryBasic.name()} name={categoryBasic.name()}>{categoryBasic.name()}</option>
              )
            }
          </select> */}
          {/* TODOOOOOOOOOOOOO */}

          {/* <button onClick={() => this.addItem()}>add item</button> */}
        </div>

        <div>
          <div>options</div>
          <div>list:</div>
          {
            craftGlobalMenu.members.options.asArray().map(optionBasic => (
              <div key={optionBasic.id()}>
                <Comps.CraftOption optionBasic={optionBasic} />
              </div>
            ))
          }
          <Button variant='contained' color='primary' onClick={() => this.setState({ addOptionModalVisible: true })} >ADD OPTION</Button>

        </div>

      </div>
    )
  }
})
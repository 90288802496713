import { JobRegistryItem } from './job-registry-item.node'
import { JobRegistry } from './job-registry.list'
import { fetchJobRegistry } from './job-registry.list.fetcher'
import { rootMethods } from './root-methods'

export const jobRegistry = {
  JobRegistryItem,
  JobRegistry,
  fetchJobRegistry,
  rootMethods
}
import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField, Icon, CircularProgress } from '@material-ui/core'
import { Card } from 'antd'
import LogRocket from 'logrocket'
import { responsiveComponent } from '@blast-engine/responsive-component'
import MuiPhoneNumber from 'material-ui-phone-number'
import { isReady } from '@blast-engine/firework'
import { wait } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { sendTextMessage } from '@/text-messages.service'

import './user-info-request.scss'

export const OfferTextNotifications = responsiveComponent(fireworkConnect(() => ({
  me: entities.user.fetchMe(),
  textNotifsListRef: entities.fetchTextNotifListRef()
}))(
class OfferTextNotifications extends React.Component {

  state = {
    phoneNumber: '',
    confirmationCodeSending: false,
    confirmationCodeSendError: null,
    confirmationCodeSent: false,

    confirmationCode: '',
    confirmationCodeVerifying: false,
    confirmationCodeVerifyError: null,
    confirmationCodeVerified: false,
  }

  render() {
    const { 
      me, 
      isMobile, 
      textNotifsListRef
    } = this.props

    const { 
      phoneNumber, 
      confirmationCodeSending,
      confirmationCodeSendError,
      confirmationCodeSent,

      confirmationCode,
      confirmationCodeVerifying,
      confirmationCodeVerifyError,
      confirmationCodeVerified
    } = this.state

    if (!isReady(me, textNotifsListRef)) return null
    // if (!me.isAnon()) return <Redirect to='/app' />

    const tryToSendConfirmationCode = async () => {

      if (me.phoneNumber()) return

      await this.setState({
        confirmationCodeSending: true,
        confirmationCodeSendError: null,
        confirmationCodeSent: false,
      })

      LogRocket.log({ event: '[OfferTextNotifications] try to send confirmation code', me })
  
      await kernel.performUpdates([
        me.setPhoneNumberAwaitingVerification(phoneNumber)
      ])

      let response
      let error
      try {
        response = await sendTextMessage({
          number: phoneNumber,
          text: 'Your confirmation code is 5137'
        })
      } catch (e) { error = e }


      if (!error && response.success)
        this.setState({
          confirmationCodeSending: false,
          confirmationCodeSendError: null,
          confirmationCodeSent: true,
        })
      else 
        this.setState({
          confirmationCodeSending: false,
          confirmationCodeSendError: 'Something went wrong, check the number.',
          confirmationCodeSent: false,
        })
    }

    const tryToVerifyConfirmationCode = async () => {
      if (me.phoneNumber()) return

      await this.setState({
        confirmationCodeVerifying: true,
        confirmationCodeVerifyError: null,
        confirmationCodeVerified: false,
      })

      LogRocket.log({ event: '[OfferTextNotifications] try to verify confirmation code', me })
        
      if (confirmationCode === '5137') {
        await wait(2000)

        this.setState({
          confirmationCodeVerifying: false,
          confirmationCodeVerifyError: null,
          confirmationCodeVerified: true,
        })

        await wait(300)

        await kernel.performUpdates([
          ...me.setPhoneNumber(me.phoneNumberAwaitingVerification()),
          me.setPhoneNumberAwaitingVerification(null)
        ])
      }

      else {
        await wait(2000)

        this.setState({
          confirmationCodeVerifying: false,
          confirmationCodeVerifyError: 'Verification code incorrect.',
          confirmationCodeVerified: false,
        })
      }
      
    }

    return (
      <div className='login-section-comp'>
        <img className='background-img' src={require('../../../assets/bg.png')} />
        
        <div className='body'>
          <Card
            title={
              <div className='modal-header-section'>
                <div className='login'>Receive text notifications</div>
              </div>
            }
            style={{ width: isMobile ? '80vw' : '30vw', transition: '1s' }}
            bodyStyle={{ paddingTop: 8 }}
          >
            <div style={{ paddingBottom: 15 }}>We will only send you messages regarding your orders, as well as craft and catering notices for your job.</div>
            <MuiPhoneNumber 
              defaultCountry={'ca'}
              style={{ width: '100%' }}
              value={phoneNumber}
              onChange={phoneNumber => this.setState({ phoneNumber })}
            />
            <div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {
                confirmationCodeSending ? (
                  <CircularProgress/>
                ) : (
                  <Button
                    className={`button-custom-class${isMobile ? ' mobile' : ''}`}
                    variant='contained'
                    onClick={tryToSendConfirmationCode}
                  >
                    Send Confirmation Code
                  </Button>
                )
              }
            </div>
            {
              confirmationCodeSendError ? (
                <div style={{ color: 'red', paddingTop: 4 }}>{confirmationCodeSendError}</div>
              ) : null
            }
            {
              confirmationCodeSent ? (
                <div style={{ color: 'green', paddingTop: 4, display: 'flex', alignItems: 'center' }}>
                  <Icon style={{ color: 'green', fontSize: 18, marginRight: 10 }}>checkmark</Icon> 
                  Code sent. Input it below.
                </div>
              ) : null
            }
            <TextField
              placeholder='Confirmation Code'
              style={{ width: '100%', marginTop: '40px' }}
              value={confirmationCode}
              onChange={e => this.setState({ confirmationCode: e.target.value })}
            />
            <div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {
                confirmationCodeVerifying ? (
                  <CircularProgress/>
                ) : (
                  <Button
                    className={`button-custom-class${isMobile ? ' mobile' : ''}`}
                    variant='contained'
                    onClick={tryToVerifyConfirmationCode}
                  >
                    Verify
                  </Button>
                )
              }
            </div>
            {
              confirmationCodeVerifyError ? (
                <div style={{ color: 'red', paddingTop: 4 }}>{confirmationCodeVerifyError}</div>
              ) : null
            }
            {
              confirmationCodeVerified ? (
                <div style={{ color: 'green', paddingTop: 4, display: 'flex', alignItems: 'center' }}>
                  <Icon style={{ color: 'green', fontSize: 18, marginRight: 10 }}>checkmark</Icon> 
                  Verified
                </div>
              ) : null
            }
            <div style={{ width: '100%', marginTop: '28px', display: 'flex' }}>
              <Button 
                style={{ margin: 0, padding: 0 }} 
                primary                
                onClick={() => kernel.performUpdates([ 
                  me.setOptedOutOfPhoneNumber(true),
                  me.setLastTimeOptedOutOfPhoneNumber(Date.now())
                ])}
              >not now, thanks</Button>
            </div>

          </Card>
        </div>
        
      </div>
    )
  }
}))
import { keys, kv } from '@blast-engine/utils'

import { Immutable } from './immutable'

export class BreakfastDayMenuOptionItem extends Immutable {
 
  static create(raw) {
    return new BreakfastDayMenuItem(raw)
  }

  setName(name) {
    this.set({ name })
  }

}

export class BreakfastDayMenuOption extends Immutable {

  static create(raw) {
    return new BreakfastDayMenuOption(raw)
  }

  nextRawOptionItemId() {
    return this.get('nextRawOptionItemId') || 0
  }

  incrementNextOptionItemId() {
    const noti = this.nextRawOptionItemId()
    return this.set({ nextRawOptionItemId: noti + 1 })
  }

  nextOptionItemId() {
    return `option_${this.nextRawOptionItemId()}`
  }

  addOptionItem(optionItem) {
    const optId = this.nextOptionItemId()
    return this.incrementNextOptionItemId()
      .set({ optionItems: { [optId]: optionItem.raw() } })
  }

  name() {
    return this._get('name')
  }

  setName(name) {
    return this.set({ name })
  }

}

export class BreakfastDayMenuItem extends Immutable {

  static create(raw) {
    return new BreakfastDayMenuItem(raw)
  }

  nextRawOptionId() {
    return this.get('nextRawOptionId') || 0
  }

  incrementNextOptionId() {
    const noti = this.nextRawOptionId()
    return this.set({ nextRawOptionId: noti + 1 })
  }

  nextOptionId() {
    return `option_${this.nextRawOptionId()}`
  }

  name() {
    return this._get('name')
  }

  setName(name) {
    return this._set({ name })
  }

  setOption(id, option) {
    return this.set({ 
      options: { 
        ...this.get('options'),
        [id]: option.raw() 
      } 
    })
  }

  addOption(option) {
    const optId = this.nextOptionId()
    return this.incrementNextOptionId()
      .set({ options: { [optId]: option.raw() } })
  }

  options() {
    return kv(this.get('options'))
      .map(({ k: id, v: raw }) => ({
        id, 
        item: BreakfastDayMenuOption.create(raw)
      }))
  }

}

export class BreakfastDayMenu extends Immutable {

  static create(raw) {
    return new BreakfastDayMenu(raw)
  }

  nextRawItemId() {
    return this.get('nextRawItemId') || 0
  }

  incrementNextItemId() {
    const noti = this.nextRawItemId()
    return this.set({ nextRawItemId: noti + 1 })
  }

  nextItemId() {
    return `item_${this.nextRawItemId()}`
  }

  setItem(id, item) {
    return this.set({ 
      items: { 
        ...this.get('items'),
        [id]: item.raw() 
      } 
    })
  }
  
  addItem(item) {
    const itemId = this.nextItemId()
    return this.incrementNextItemId()
      .set({ 
        items: { 
          ...this.get('items'),
          [itemId]: item.raw() 
        } 
      })
  }

  items() {
    return kv(this.get('items'))
      .map(({ k: id, v: raw }) => ({
        id, 
        item: BreakfastDayMenuItem.create(raw)
      }))
  }

  item(itemId) {
    return this.get('items') ? BreakfastDayMenuItem.create(this.get('items')[itemId]) : undefined
  }

}
import { createNodeClass } from '@blast-engine/firework'
import { k, kv, rollup } from '@blast-engine/utils'

// import { ROOT_ID } from './root-user.config'

export const CraftyData = createNodeClass({
  name: 'CraftyData',

  full: class { 
    
    members() {
      return this._data('members')
    }

    memberIds() {
      return k(this.members())
    }

    isCraftyMember(userId) {
      return !!this.members()[userId]
    } 

    addMember(userBasic) {
      const members = this.members()
      const updatedMembers = { ...members, [userBasic.id()]: { type: 'member' } }
      return this._update({ members: updatedMembers })
    }

    removeMember(userId) {
      const members = this.members()
      const updatedMembers = { ...members, [userId]: null }
      return this._update({ members: updatedMembers })
    }

  }
})
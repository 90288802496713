import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { crafty } from './crafty'
import { crafter } from './crafter'
import { user } from './user'
import { invites } from './invites'

export const CraftyManager = createAssemblyClass({
  name: 'CraftyManager',
  memberModels: {
    craftyData: crafty.CraftyData.full(),
    allCrafterBasics: crafter.CrafterBasicList.full(),
    memberBasics: user.UserBasicSelection.full(),
    invitesListRef: invites.Invites.ref(),
    userEmails: user.UserEmailList.ref(),
  },
  portMethods: {
    craftyData: [
      'isCraftyMember'
    ]
  },
  body: class {
  
    crafters() {
      return this.members.allCrafterBasics.asArray()
    }

    getUserIdFromEmail({ email }) {
      const encoded = email.replace(/\./g, '__dot__')
      const user = this.members.userEmails.item(encoded)
      if (!user) return null
      return user.id()
    }

    addCrafter({ name }) {
      return this.members.allCrafterBasics.add({ initArgs: { name, status: 'ok' } })
    }

    inviteUserToCrafter({ crafterId, userId, role = 'member' }) {
      return this.members.invitesListRef.add({ initArgs: { userId, crafterId, role }, newItemId: userId })
    }

    inviteUserToCrafty({ userId, role = 'crafty-member' }) {
      return this.members.invitesListRef.add({ initArgs: { userId, role }, newItemId: userId })
    }

    crafterRoles() {
      return [
        'crafter-on-site',
        'crafter-office',
        'crafter-caterer'
      ]
    }
    
  }
})
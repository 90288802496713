import React from 'react'
import { isReady } from '@blast-engine/firework'
import { TextField, Button, Fab } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { Modal, List, Divider } from 'antd'
import { Switch, Route, Redirect, Link } from 'react-router-dom'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'
import notifSound from '../../assets/notif.mp3'
import './craft-on-site.scss'
import { BreakfastOrdersPage } from './breakfast-orders-page'

export const CraftOnSite = fireworkConnect(props => ({
  jobList: entities.crafter.crafterData.job.fetchJobBasicList({ crafterId: props.crafterId })
}))(class CraftOnSite extends React.Component {

  render() {
    const { jobList } = this.props

    if (!isReady(jobList)) return null

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Switch>
          <Route path="/craft-on-site/jobs/:jobId" render={(props) => 
            <Comps.CraftOnSiteJob crafterId={this.props.crafterId} jobId={props.match.params.jobId} />}
          />
          <Route path='/craft-on-site/menu/:jobId' render={(props) => 
            <Comps.CraftOnSiteMenu crafterId={this.props.crafterId} />}
          />

          <Route path='/craft-on-site/breakfast/:jobId' render={(props) => 
            <BreakfastOrdersPage crafterId={this.props.crafterId} jobId={props.match.params.jobId} />}
          />

          <Route path="/craft-on-site/jobs">
            <Comps.CraftOnSiteJobList crafterId={this.props.crafterId} />
          </Route>
          
          <Route path='/craft-on-site' exact>
            <Link to='/craft-on-site/jobs'>Jobs</Link>
          </Route>
          
        </Switch>
        
        {/* { window.location.pathname === '/craft' && <Redirect to="/craft-on-site/jobs" /> } */}
        
      </div>
    )
  }
})
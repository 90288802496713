import React from 'react'
import {} from '@material-ui/core'
import {} from 'antd'

import * as Comps from '@/components'

import './button.scss'

export const Button = class Button extends React.Component {
  state = {}

  render() {
    const { imgSrc, buttonText, onClick = () => null, containerStyle = {}, imgStyle = {}, imgContainerStyle = {}, buttonStyle = {} } = this.props
    const {} = this.state
    
    return (
      <div className='button-container' style={containerStyle}>
        <div className='image-section' style={imgContainerStyle}>
          <img className='image' src={imgSrc} style={imgStyle}/>
        </div>
        <div className='button' style={buttonStyle} onClick={() => onClick()}>{buttonText}</div>
      </div>
    )
  }
}
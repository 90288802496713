import React from 'react'
import LogRocket from 'logrocket'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    LogRocket.captureException(error, { extra: errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
          <div style={{ paddingBottom: '150px' }}>
            <h1>Something went wrong!</h1>
            <div style={{ marginBottom: '20px' }}>We're sorry about this, we've logged the error and our developers are working to resolve it.</div>
            <h2>Please refresh the page.</h2>
            <div>If the issue persists, please contact us at <span style={{ color: 'blue' }}>admin@crafty.film</span></div>
          </div>
        </div>
      )
    }

    return this.props.children 
  }
}
import * as firebase from 'firebase'
import LogRocket from 'logrocket'

import * as u from '@blast-engine/utils'

import { boot, createFireworkConnect, isReady } from '@blast-engine/firework'
import * as entities from '@crafty/entities'
import { crafter } from '@crafty/entities'

firebase.initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID
})

export const kernel = boot({ Root: entities.Root, firebase })
export const fireworkConnect = createFireworkConnect({ kernel })

kernel.subscribeToAuth(auth => {
  const rawAuth = kernel.firebase.auth()
  LogRocket.log({ 
    event: 'AUTH_UPDATED', 
    authStruct: auth,
    authStructIsReady: isReady(auth),
    rawAuth: !rawAuth ? '' + rawAuth : typeof rawAuth
  })
})

const pu = kernel.performUpdates.bind(kernel)
kernel.performUpdates = (updateOrUpdates) => {
  if (!Array.isArray(updateOrUpdates)) updateOrUpdates = [ updateOrUpdates ]
  return pu(updateOrUpdates)
}

window.kernel = kernel
window.entities = entities
window.isReady = isReady

const _createMeal = [
    {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/subsGlobalMenu/items/-MMveZnoxPaBLlhqM_wC": {
      "id": "-MMveZnoxPaBLlhqM_wC",
      "optionIds": null,
      "notesAreAllowed": null,
      "description": "Served with Rice and Market Vegetables",
      "name": "Grilled Chicken",
      "_": true
    }
  }
]

const _createOption = [
  {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/subsGlobalMenu/options/-MMveqWlWQkuLPCVXu4L": {
      "id": "-MMveqWlWQkuLPCVXu4L",
      "label": "g",
      "type": "radio",
      "items": {
        "g1": true,
        "g2": true
      },
      "_": true
    }
  }
]

const _createDayForJob = [
  {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/job/jobData/KC2020/days/52": {
      "id": "52",
      "dayNum": "52",
      "_": true
    }
  }
]

const _disableDayForJob = [
  {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/job/jobData/KC2020/days/52": {
      "canOrderCatering": false,
      "_": true
    }
  }
]

const _setDateForJobDay = [
  {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/job/jobData/KC2020/days/52": {
      "date": 1606504380000,
      "_": true
    }
  }
]

const _setMealForDay = [
  {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/job/jobData/KC2020/days/52/subMenu/-MMveZnoxPaBLlhqM_wC": {
      "id": "-MMveZnoxPaBLlhqM_wC",
      "label": "Lunch",
      "itemId": "-MMveZnoxPaBLlhqM_wC",
      "status": "waiting",
      "enabled": true,
      "_": true
    }
  }
]

const _setOptionForMeal = [
  {
    "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/job/jobData/KC2020/days/52/subMenu/-MMveZnoxPaBLlhqM_wC": {
      "optionsConfig/-MMvajlAyhHO3Cw9syMy/enabled": true,
      "_": true
    }
  }
]

const db = kernel.firebase.database()
const set = async ({ path, data }) => { console.log({ path, data }); return db.ref(path).set(data) }
const get = async (path) => (await db.ref(path).once('value')).val() 
const key = db.ref('/x').push().key

const createGlobalMeal = async ({ crafterId, mealId, name, description }) => {
  return set({
    path: `/crafter/crafterData/${crafterId}/subsGlobalMenu/items/${mealId}`,
    data: {
      "optionIds": null,
      "notesAreAllowed": null,
      "description": description,
      "name": name,
      "_": true
    }
  })
}

const createGlobalOption = async ({ crafterId, optionId, items, label, type = 'radio' }) => {
  const itemsObj = u.rollup(items)
  return set({
    path: `crafter/crafterData/${crafterId}/subsGlobalMenu/options/${optionId}`,
    data: {
      "id": optionId,
      "label": label,
      "type": type,
      "items": itemsObj,
      "_": true
    }
  })
}

const createNewDayForJob = async ({ crafterId, jobId, date }) => {
  const daysForJob = await get(`crafter/crafterData/${crafterId}/job/jobData/${jobId}/days`)
  const lastDay = Object.keys(daysForJob).sort().pop()
  const nextDay = (parseInt(lastDay) + 1) + ''
  const dateTimestamp = moment(date).valueOf()
  await set({
    path: "crafter/crafterData/-MFup5kL5ORdDQ-WRC9t/job/jobData/KC2020/days/52",
    data: {
      "id": nextDay,
      "canOrderCatering": false,
      "date": dateTimestamp,
      "dayNum": nextDay,
      "_": true
    }
  })
}

window.lunch = {
  createGlobalMeal,
  createGlobalOption,
  createNewDayForJob
}
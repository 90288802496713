import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm, AddBoxOutlined, Edit } from '@material-ui/icons'
import { rollup, kv } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-on-site-menu.scss'

export const CraftOnSiteMenu = fireworkConnect(props => ({
  craftGlobalMenu: entities.crafter.crafterData.craftMenu.fetchCraftGlobalMenu({ crafterId: props.crafterId }),
  jobMenuManager: entities.crafter.crafterData.fetchJobCraftMenuManager({ crafterId: props.crafterId, jobId: props.jobId }),
}))(class CraftOnSiteMenu extends React.Component {
  state = {}

  addOptionsToItemForJob() {
    const { craftGlobalMenu } = this.props
    const { itemOptionSelection, addOptionsToItemModalItem } = this.state
    
    const updates = kv(itemOptionSelection).map(({ k: optionId, v: isSelected }) => {
      if (isSelected) {
        return craftGlobalMenu.addOptionToItem({ itemId: addOptionsToItemModalItem.id(), optionId })
      }
      return craftGlobalMenu.removeOptionFromItem({ itemId: addOptionsToItemModalItem.id(), optionId })
    })

    kernel.performUpdates(updates)

    this.setState({ addOptionsToItemModalItem: null, itemOptionSelection: {} })
  }

  addItem() {
    const { craftGlobalMenu } = this.props
    const { newItemLabel, newItemCategory } = this.state
  
    if (!newItemLabel || !newItemCategory || !newItemCategory.id()) {
      return this.setState({ modalError: 'Item Name Cannot be Empty' })
    }

    const update = craftGlobalMenu.addItem({ name: newItemLabel, categoryId: newItemCategory.id() })
    kernel.performUpdates([update])
    this.setState({ addItemModalVisibility: false, newItemCategory: null, modalError: null })
  }

  async addOption() {
    const { craftGlobalMenu } = this.props
    const { newOptionLabel, itemOptionType, itemOptionItems } = this.state
    if (!newOptionLabel) return this.setState({ modalError: 'Option Label Cannot be Empty' })
    if (!itemOptionItems.length) return this.setState({ modalError: 'Please Add At Least One Option Item' })
    if (itemOptionItems.some(i => !i || ( i && !i.length )) ) return this.setState({ modalError: 'Option Item Names Cannot be Empty' })
    const update = craftGlobalMenu.addOption({ 
      label: newOptionLabel, 
      type: itemOptionType, 
      items: itemOptionItems 
    })
    
    await kernel.performUpdates([ update ])
    // const newOptionId = Object.values(update)[0].id

    // await kernel.performUpdates([ craftGlobalMenu.addOptionToItem({ itemId: itemToAttachTheOptionTo.id(), optionId: newOptionId }) ])
    this.setState({ addOptionModalVisible: false, newOptionLabel: '', itemOptionItems: [], itemToAttachTheOptionTo: null, modalError: null })
  }

  createNewOptionItem() {
    const { newOptionItemModalOption, newOptionItemLabel } = this.state

    if (!newOptionItemLabel || !newOptionItemLabel.length) return this.setState({ modalError: 'Option Item Name Cannot be Empty' })

    kernel.performUpdates([ newOptionItemModalOption.addOptionItem({ label: newOptionItemLabel }) ])

    this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '', modalError: null })
  }


  render() {
    const { craftGlobalMenu, jobMenuManager } = this.props
    const {} = this.state

    if (!isReady(craftGlobalMenu, jobMenuManager)) return null
    
    const { Panel } = Collapse
    return (
      <div className='craft-on-site-menu-container'>
        hello from craft-on-site-menu.js
      </div>
    )
  }
})
import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import {} from 'antd'

import * as entities from '@crafty/entities'
import { fireworkConnect, kernel } from '@/kernel.singleton'

import './find-job.ui.scss'

export const FindJob = fireworkConnect(props => ({
  me: entities.user.fetchMe({ keepOldResultUntilNew: true }),
  jobRegistry: entities.jobRegistry.fetchJobRegistry({ keepOldResultUntilNew: true })
}))(class FindJob extends React.PureComponent {
  state = {}
  render() {
    const { me, fb, jobRegistry } = this.props
    const { 
      jobId = '',
      waiting = false,
      error = null
    } = this.state

    if (!me) return <div>LOADING</div>

    const tryToFindJob = async () => {
      this.setState({ waiting: true })
      if (!this.state.jobId) return this.setState({ error: 'Job Code Cannot be Empty' })
      const jobRegistration = jobRegistry.item(jobId)
      if (!jobRegistration) this.setState({ waiting: false, error: 'job not found' })
      try {
        const job = await kernel.snap(entities.crafter.crafterData.job.fetchJobBasic({ 
          crafterId: jobRegistration.crafterId(),
          jobId 
        }))
        if (job.isEmpty()) return this.setState({ waiting: false, error: 'job not found' })
        else if (job.status() === 'not-started') return this.setState({ waiting: false, error: 'this job is not active yet, check back soon' })
        else if (job.status() === 'finished') return this.setState({ waiting: false, error: 'this job is finished, are you sure this is the correct job id?' })
        else if (job.status() !== 'active') return this.setState({ waiting: false, error: 'there is a problem with this job, please contact support' })
        else kernel.performUpdates(
          me.setCurrentJob({
            jobId: job.id(),
            crafterId: job.crafterId()
          }) 
        )
      }
      catch (e) {
        console.warn(e)
      }
      
      
    }

    return (
      <div className='find-job-container'>
        <img className='background-img' src={require('../../../assets/bg.png')} />
        <div className='container'>
          <div className='find-job-text'>FIND JOB</div>
          <div className='input-sections'>
            <TextField 
              onChange={e => this.setState({ jobId: e.target.value })}
              className='input'
              placeholder="Enter your job code"
              label='Job Code'
              variant='filled'
            />
          </div>
          <Button variant='contained' className='submit-button' onClick={tryToFindJob}>Submit</Button>
          {error ? <div className='error-section'>{error}</div> : null}
        </div>
        
        {/* <div className='' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>Logged in as <div style={{ fontWeight: "700" }}>{me.username()}</div></div>
          <Button><div>log out</div></Button>
        </div> */}
        {/* <div style={{ paddingTop: '50px' }}>

          {
            waiting ? <div>loading job..</div> : (
              <>
                
              </>
            )
          }
        </div> */}
      </div>
    )
  }
})

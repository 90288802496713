import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import LogRocket from 'logrocket'

import './app.css'

export class BigLoading extends React.PureComponent {

  render() {
    return (
      <div style={{ position: 'fixed', background: 'white', zIndex: '99999', height: '100%', padding: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {/* <div style={{ width: "100%" }}>
          <img style={{ width: "100%" }} src={require('../assets/Logo.png')} />
        </div> */}
        <div>
          <img style={{ width: "100%" }} src={require('../assets/spinningwheel.gif')} />
        </div>
      </div>
    )
  }

} 
import { createFetcher } from '@blast-engine/firework'

export const fetchJobRegistry = createFetcher({
  name: 'fetch-job-registry'
}, () => ({
  steps: [{
    name: 'registry',
    query: ({ root }) => root.jobRegistryRef().query()
  }],
  final: {
    take: [ 'registry' ],
    instantiate: ({ registry }) => registry
  }
}))
import { createNodeClass } from '@blast-engine/firework'
import { rollup } from '@blast-engine/utils'

export const SubGlobalBasic = createNodeClass({
  name: 'SubGlobalBasic',

  full: class {

    id() {
      return this._data('id')
    }

    name() {
      return this._data('name')
    }

    updateName({ name }) {
      return this._update({ name })
    }

    updateDescription({ description }) {
      return this._update({ description })
    }

    description() {
      return this._data('description')
    }

    notesAreAllowed() {
      return this._data('notesAreAllowed')
    }

    optionIds() {
      return this._data('optionIds')
    }

    addOption({ optionId, isRequired = false }) {
      return this._update({ [`optionIds/${optionId}`]: {
        id: optionId,
        isRequired
      } })
    }

    fakeDelete() {
      return this._update({ _deleted: true })
    }

    isFakeDeleted() {
      return !!this._data('_deleted')
    }

    removeOption({ optionId }) {
      return this._update({ [`optionIds/${optionId}`]: null })
    }

  },

  ref: class {

    initialize({ id, optionIds /* string[] */, notesAreAllowed /* bool */, description, name }) {
      if (!name) throw Error('sub global item needs name')
      return this._update({ 
        id, 
        optionIds: optionIds ? (Array.isArray(optionIds) ? rollup(optionIds) : optionIds) : null, 
        notesAreAllowed: notesAreAllowed === undefined ? null : notesAreAllowed, 
        description: description || null, 
        name 
      })
    }

  }

})
import React from 'react'
import LogRocket from 'logrocket'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { connectionStatus } from '@/connectivity.service'
import './app.css'

export class ConnectivityDaemon extends React.PureComponent {

  lastSent
  lastReceived
  pingInterval
  checkInterval
  lastEventTimestamp

  componentDidMount() {
    const { myId } = this.props
    window.connectivityDaemon = this

    kernel.firebase.database().ref(".info/connected")
      .on("value", snap => {
        this.lastEventTimestamp = Date.now()
        connectionStatus.emit({
          isConnected: snap.val(),
          timestamp: this.lastEventTimestamp,
          source: 'firebase .info/connected'
        })
      })

    this.lastSent = Date.now()
    this.lastReceived = Date.now()
    this.pingInterval = setInterval(async () => {
      const timeStamp = Date.now()
      try {
        const pingRef = kernel.firebase.database().ref(`ping/${myId}`)
        this.lastSent = timeStamp
        await pingRef.set(timeStamp)
        const resultValue = (await pingRef.once('value')).val()
        if (resultValue > this.lastReceived) this.lastReceived = resultValue
      } catch (e) {
        LogRocket.log('!!!! connectivity ping error')
        LogRocket.error(e)
      }
    }, 800)

    this.checkInterval = setInterval(async () => {
      if ((Date.now() - this.lastEventTimestamp) < 10 * 1000) return
      if ((this.lastReceived + (5 * 1000)) < this.lastSent) {
        connectionStatus.emit({
          isConnected: false,
          source: 'too long since last ping'
        })
        const db = kernel.firebase.database()
        db.goOffline()
        setTimeout(() => db.goOnline(), 200)
      }
    }, 2000)

  }

  render() {
    return null
  }

} 

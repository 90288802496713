import { rollup } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { CraftGlobalOptionsBasicList } from './craft-global-options'
import { CraftGlobalItemBasicList } from './craft-global-item-basic'
import { CraftGlobalCategoryBasicList } from './craft-global-category'
import { CrafterCraftGlobalMenuItem } from './crafter-craft-global-menu-item.assembly'

export const CrafterCraftGlobalMenu = createAssemblyClass({
  name: 'CrafterCraftGlobalMenu',
  memberModels: {
    categories: CraftGlobalCategoryBasicList.full(),
    items: CraftGlobalItemBasicList.full(),
    options: CraftGlobalOptionsBasicList.full()
  },
  portMethods: {
  },
  body: class {

    fakeDeleteCategory({ categoryId }) {
      return this.members.categories.item(categoryId).fakeDelete()
    }

    fakeDeleteItem({ itemId }) {
      return this.members.items.item(itemId).fakeDelete()
    }

    fakeDeleteOption({ optionId }) {
      return this.members.options.item(optionId).fakeDelete()
    }

    fakeDeleteOptionItem({ optionId, optionItemId }) {
      return this.members.options.item(optionId).fakeDeleteOptionItem({ optionItemId })
    }

    item(itemId) {
      const { items, options, categories } = this.members
      const itemBasic = items.item(itemId)
      if (!itemBasic) return undefined
      const category = categories.item(itemBasic.categoryId())
      if (!category) return null
      if (category.isFakeDeleted() || itemBasic.isFakeDeleted()) return null
      // should i take care of fake delete here too??
      return this._spinoff(CrafterCraftGlobalMenuItem, {
        members: { item: itemBasic, category, allOptions: options } 
      })
    }

    itemsForCategory({ categoryId }) {
      return this.members.items.asArray().filter(itemBasic => this.isItemInCategory({ itemId: itemBasic.id(), categoryId })).filter(itemBasic => !itemBasic.isFakeDeleted())
    }

    categoryNameForItem({ itemId }) {
      const itemCatId = this.members.items.item(itemId).categoryId()
      return this.members.categories.item(itemCatId).name()
    }

    isItemInCategory({ itemId, categoryId }) {
      return this.members.items.item(itemId).categoryId() === categoryId
    }

    option(optionId) {
      return this.members.options.item(optionId)
    }

    allCategories() {
      return this.members.categories.asArray().filter(categoryBasic => !categoryBasic.isFakeDeleted())
    }

    allItems() {
      const { items } = this.members
      return items.asArray()
        .map(item => this.item(item.id()))
        .filter(itemBasic => !itemBasic.isFakeDeleted())
    }

    allOptions() {
      const { options } = this.members
      return options.asArray().filter(optionBasic => !optionBasic.isOptionFakeDeleted())
    }

    addCategory({ name }) {
      return this.members.categories.add({ initArgs: { name } })
    }

    addItem({ name, categoryId }) {
      return this.members.items.add({ initArgs: { name, categoryId } })
    }
    
    addOption({ type, label, items = [] }) {
      return this.members.options.add({ initArgs: { type, label, items } })
    }

    addOptionItemToOption({ optionId, label }) {
      const option = this.members.options.item(optionId)
      return option.addOptionItem({ label })
    }

    optionsForItem({ itemId }) {
      const item = this.members.items.item(itemId)
      const optionIds = item.optionIds()
      return optionIds.map(optionId => this.members.options.item(optionId))
      //.filter(optionBasic => !optionBasic.isOptionFakeDeleted())
    }

    optionItemsForOption({ optionId }) {
      const option = this.members.options.item(optionId)
      if (!option) return []
      return option.optionItemIds()
      // .filter(optionItemId => !option.isOptionItemFakeDeleted({ optionItemId }))
      .map(optionItemId => option.optionItem({ optionItemId }))
    }

    addOptionToItem({ itemId, optionId }) {
      return this.members.items.item(itemId).addOption({ optionId })
    }

    removeOptionFromItem({ itemId, optionId }) {
      return this.members.items.item(itemId).removeOption({ optionId })
    }

    addItemToCategory({ itemId, categoryId }) {
      return this.members.items.item(itemId).updateCategoryId({ categoryId })
    }

  }
})
import { JobBasic } from './job-basic.node'
import { JobBasicList } from './job-basic.node.list'

export const rootMethods = {

  crafterJobBasicRef({ crafterId, jobId }) {
    return this._spinoff(JobBasic.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobBasic/${jobId}`
    })
  },

  crafterJobBasicListRef({ crafterId }) {
    return this._spinoff(JobBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobBasic`
    })
  }

}
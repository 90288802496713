import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Link } from 'react-router-dom'
import { Chip, TextField, MenuItem, Input, Button, Fab, Icon, FormControl, InputLabel, Select, CircularProgress, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { Add as AddIcon, AutorenewOutlined, DoneAll, ExpandMore } from '@material-ui/icons'
import { Modal, List, Divider, Card, Collapse, DatePicker } from 'antd'
import { kv } from '@blast-engine/utils'
import LogRocket from 'logrocket'
import moment from 'moment'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'
import { sendTextMessage } from '@/text-messages.service'
import notifSoundMP3 from '../../assets/notif.mp3'
import './craft-on-site-job.scss'
import TextArea from 'antd/lib/input/TextArea'

const notifSound = new Audio(notifSoundMP3)

window.moment = moment

let didEnableSound = false
window.addEventListener('click', () => { 
  if (!didEnableSound) {
    notifSound.play() 
    notifSound.pause() 
    didEnableSound = true
  }
})

export const BreakfastOrdersPage = fireworkConnect(props => ({
  jobOrderComplete: entities.crafter.crafterData.fetchJobOrdersComplete({ crafterId: props.crafterId, jobId: props.jobId }),
  breakfastPreordersMenu: entities.crafter.crafterData.job.jobData.breakfastPreorders.fetchBreakfastPreorders({ crafterId: props.crafterId, jobId: props.jobId }),
  breakfastPreorders: entities.crafter.crafterData.job.jobData.breakfastPreorders.fetchBreakfastPreorderss({ crafterId: props.crafterId, jobId: props.jobId, dayString: moment().format("dddd MMM Do, YYYY") }),
}))(class BreakfastOrdersPage extends React.Component {

  state = {}

  render() {

    const {
      openAddPreorderMenuModal,
      newPreorderMenuDayString,
      newPreorderDayMenu,
      newPreorderDayMenuTimes,
    } = this.state
    
    const { 
      jobOrderComplete, 
      breakfastPreordersMenu,
      breakfastPreorders,
    } = this.props

    if (
      !isReady(
        jobOrderComplete, 
        breakfastPreordersMenu, 
        breakfastPreorders,
      )
    ) return <h1>loading..</h1>

    const { 
      BreakfastDayMenu, 
      BreakfastDayMenuItem
    } = entities.crafter.crafterData.job.jobData.breakfastPreorders

    const allUserBasics = jobOrderComplete.members.userBasics
    const todayDayString = moment().format("dddd MMM Do, YYYY")
    const npdm = BreakfastDayMenu.create(newPreorderDayMenu)
    
    return (
      <div>
        <div>
          <div className='left'>
            <Divider orientation='left' className='divider-text'>Breakfast Preorders For Today</Divider>
          </div>
          {
            kv(breakfastPreorders.groupedPreordersByPickupTime()).map(({ k:timeString, v:group }) => {
              const dayMenuNode = breakfastPreordersMenu.menuForDay(todayDayString)
              const dayMenu = dayMenuNode.menu()
              return (
                <div key={timeString} style={{ padding: '5px' }}>
                  <div> time: {timeString} {}</div>
                  <div> summation: </div>
                  <div style={{ paddingLeft: '10px' }}>
                    {
                      kv(group.summation).map(({ k:itemId, v:num }) => { 
                        const item = dayMenu.item(itemId)
                        if (!item) return null
                        return (
                          <div key={itemId}>
                            <div> {item.name()}: {num}</div>
                          </div>
                        ) 
                      })
                    }
                  </div>
                  <div> orders: </div>
                  <div style={{ paddingLeft: '10px' }}>
                    {
                      kv(group.orders).map(({ k:userId, v:lineItems }) => { 
                        const ub = allUserBasics.item(userId)
                        if (!ub) return null
                        return (
                          <div key={userId}>
                            <div> {ub.username()} ({ub.department()}) </div>
                            <div style={{ paddingLeft: '10px' }}>
                            {
                              kv(lineItems).map(({ k:itemId, v:num }) => { 
                                const item = dayMenu.item(itemId)
                                if (!item) return null
                                return (
                                  <div key={itemId}>
                                    <div> {item.name()}: {num}</div>
                                  </div>
                                ) 
                              })
                            }
                            </div>
                          </div>
                        ) 
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className='item-sec'>
          <div className='left'>
            <Divider orientation='left' className='divider-text'>Breakfast Preorders Menu</Divider>
          </div>
          <div className='right'>
            <Button 
              variant='contained' color='primary' className='each-button'
              onClick={() => this.setState({ openAddPreorderMenuModal: true })}
            >
              add breakfast day
            </Button>
          </div>
        </div>
        <Modal
          title='Preorder menu for day'
          visible={openAddPreorderMenuModal}
          onOk={
            async () => {
              await kernel.performUpdates(breakfastPreordersMenu.upsertMenuForDay({ 
                dayString: newPreorderMenuDayString,
                times: newPreorderDayMenuTimes,
                menu: npdm
              }))
              this.setState({ 
                openAddPreorderMenuModal: false,
                newPreorderDayMenu: {}
              })
            }
          }
          onCancel={() => this.setState({ openAddPreorderMenuModal: false })}
        >
          <div>
            <DatePicker
              onChange={d => d && this.setState({ newPreorderMenuDayString: d.format("dddd MMM Do, YYYY") })}
            />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                style={{ width: '100%' }}
                multiple
                value={newPreorderDayMenuTimes || []}
                onChange={e => {
                  console.log(e.target.value)
                  this.setState({ newPreorderDayMenuTimes: e.target.value })
                }}
                input={<Input />}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={value}/>
                    ))}
                  </div>
                )}
              >
                {[ 
                  '0:00', 
                  '0:30',
                  '1:00', 
                  '1:30',
                  '2:00', 
                  '2:30',
                  '3:00', 
                  '3:30',
                  '4:00', 
                  '4:30',
                  '5:00', 
                  '5:30',
                  '6:00', 
                  '6:30',
                  '7:00', 
                  '7:30',
                  '8:00', 
                  '8:30',
                  '9:00', 
                  '9:30',
                  '10:00', 
                  '10:30',
                  '11:00', 
                  '11:30',
                  '12:00', 
                  '12:30',
                  '13:00', 
                  '13:30',
                  '14:00', 
                  '14:30',
                  '15:00', 
                  '15:30',
                  '16:00', 
                  '16:30',
                  '17:00', 
                  '17:30',
                  '18:00',
                  '18:30', 
                  '19:00',
                  '19:30', 
                  '20:00',
                  '20:30', 
                  '21:00',
                  '21:30', 
                  '22:00',
                  '22:30', 
                  '23:00',
                  '23:30'
                ].map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <button 
                onClick={() => {
                  const newItem = BreakfastDayMenuItem.create().setName('new item')
                  this.setState({ newPreorderDayMenu: npdm.addItem(newItem).raw() })
                }}
              >
                add item
              </button>
            </div>
            <div>
            {
              npdm.items()
                .map(({ id, item }) => (
                  <div key={id} style={{ paddingLeft: '20px' }}>
                    <div>
                      <input 
                        value={item.name()} 
                        onChange={
                          async e => {
                            this.setState({ 
                              newPreorderDayMenu: npdm.setItem(id, item.setName(e.target.value)).raw()
                            })
                          }
                        }
                      />
                    </div>
                  </div>
                ))
            }
            </div>
          </div>
        </Modal>
        <div style={{ padding: 30 }}>
          {
            breakfastPreordersMenu.days().map(dayString => {
              const dayMenuNode = breakfastPreordersMenu.menuForDay(dayString)
              const dayMenu = dayMenuNode.menu()
              return (
                <Accordion key={dayString}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <label>{dayString}</label>
                      <Button 
                        onClick={
                          async () => kernel.performUpdates([
                            breakfastPreordersMenu.removeMenuForDay({ dayString })
                          ])
                        }
                      > 
                        remove 
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div style={{ width: '100%'}}>
                        {
                          dayMenuNode.times()
                            .map((timeString) => (
                              <Chip key={timeString} value={timeString} label={timeString} style={{ marginRight: '5px' }}/>
                            ))
                        }
                      </div>
                      <div style={{ width: '100%'}}>
                        {
                          dayMenu.items()
                            .map(({ id, item }) => (
                              <div key={id} style={{ paddingLeft: '20px' }}>
                                {item.name()} 
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </div>
      </div>
    )
  }
})
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button } from '@material-ui/core'
import {} from 'antd'
import { Link } from 'react-router-dom'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './caterer-job.scss'

export const CatererJob = fireworkConnect(props => ({
  jobDays: entities.crafter.crafterData.job.jobData.
    fetchCrafterJobDays({
      crafterId: props.crafterId,
      jobId: props.jobId
    }),
}))(class CatererJob extends React.Component {
  state = {}

  render() {
    const { jobDays, jobId } = this.props
    const {} = this.state

    if (!isReady(jobDays)) return null
    window.jobDays = jobDays
    return (
      <div className='caterer-job-container'>
        <div className='list'>
          {
            jobDays.asArray().reverse().map(jobDay => (
              <div key={jobDay.dayNum()} className='each-row'>
                <div className='left-side'>
                  <div className='day-num-section'>Day Number {Number(jobDay.dayNum())+1}</div>
                  {jobDay.date() && <div className='date-section'>{' - '}{(new Date(jobDay.date())).toDateString()}</div>}
                </div>
                <div className='right-side'>
                  <Link target='_blank' to={`/caterer/printableMeals/${jobId}/${Number(jobDay.dayNum())}`}>View Orders</Link>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
})
import { createFetcher } from '@blast-engine/firework'

export const fetchUserBasicListRef = createFetcher({
  name: 'fetch-user-basic-list-ref'
}, () => ({
  steps: [],
  final: {
    take: [],
    instantiate: ({ root }) => root.userBasicListRef()
  }
}))
import { createNodeClass } from '@blast-engine/firework'

export const NotificationsQueueItem = createNodeClass({
  name: 'NotificationsQueueItem',

  struct: class {

    id() {
      return this._data('id')
    }
    
  },

  ref: class {

    initialize({ id, type, args }) {
      return this._update({ id, type, args })
    }

  }
})
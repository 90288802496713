import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm, AddBoxOutlined, Edit } from '@material-ui/icons'
import { rollup, kv } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { gtstore } from '@/gt-store.service'
import * as Comps from '@/components'
import * as state from '@/state'

import './craft-on-site-menu.scss'

export const PendingOrders = gtstore.connect(props => ({
  orders: state.queries.currentOrders()
}))(class PendingOrders extends React.PureComponent {
  state = {}

  render() {
    const { orders } = this.props
    const {} = this.state

    if (!orders) return <h1>loading orders</h1>

    return (
      <div className='craft-on-site-menu-container'>
        <h2>PENDING ORDERS</h2>
        <div>
          {
            orders.map(order => {
              <div> {order.id} </div>
            })
          }
        </div>
      </div>
    )
  }
})
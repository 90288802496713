import { createFetcher } from '@blast-engine/firework'

export const fetchCrafterMemberBasic = createFetcher({
  name: 'fetch-crafter-member-basic'
}, ({ crafterMemberId }) => ({
  steps: [{
    name: 'basic',
    requires: [ ],
    query: ({ root }) => 
      root.crafterMemberBasicRef({ crafterMemberId }).query()
  }],
  final: {
    take: [],
    instantiate: ({ basic }) => basic
  }
}))
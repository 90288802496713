import { createFetcher } from '@blast-engine/firework'

import { JobSubOrdersComplete } from './job-sub-orders.assembly'
import { subsGlobalMenu } from './subs-global-menu'
import { subOrders } from './job/jobData/sub-orders'

export const fetchJobSubOrdersComplete = createFetcher({
  name: 'fetch-job-sub-Orders-complete'
}, ({ crafterId, jobId, day }) => ({
  steps: [{
    name: 'jobBasic',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  }, {  
    name: 'dayJobMenu',
    query: ({ root }) => root.crafterJobSubMenuForDay({ crafterId, jobId, day }).query()
  }, {
    name: 'globalItems',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }, {
    name: 'crewUsers',
    query: ({ root }) => root.jobCrewUsersRef({ crafterId, jobId }).query()
  }, {
    name: 'userBasics',
    requires: [ 'crewUsers' ],
    query: ({ root, crewUsers }) => root.userBasicSelectionRef({ userIds: crewUsers.allUserIds() }).query()
  }, {
    name: 'subOrdersForDay',
    query: ({ root }) => root.subOrdersRef({ crafterId, jobId, day }).query()
  }],
  final: {
    take: [ 'subOrdersForDay', 'jobBasic', 'userBasics', 'dayJobMenu', 'globalOptions', 'globalItems' ],
    instantiate: ({ subOrdersForDay, jobBasic, userBasics, dayJobMenu, globalOptions, globalItems, root }) => (
      root._spinoff(JobSubOrdersComplete, { 
        members: { 
          subMenuItemListForDay: dayJobMenu,
          jobBasic,
          userBasics,
          subOrdersForDay,
          subGlobalMenu: root._spinoff(subsGlobalMenu.CrafterSubsGlobalMenu, {
            members: {
              items: globalItems, 
              options: globalOptions 
            }
          })
        }
      })
    )
  }
}))
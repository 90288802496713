import React from 'react'
import { isReady } from '@blast-engine/firework'
import { FormControlLabel, FormGroup, Button, Checkbox, Input, TextField, FormControl, Select, MenuItem, FormHelperText } from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'
import { List, Collapse, Modal } from 'antd'
import { kv, rollup } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './job-craft-items.scss'

export const JobCraftItems = fireworkConnect(props => ({
  jobBasic: entities.crafter.crafterData.job.fetchJobBasic({ crafterId: props.crafterId, jobId: props.jobId }),
  jobMenuManager: entities.crafter.crafterData.fetchJobCraftMenuManager({ crafterId: props.crafterId, jobId: props.jobId }),
  craftGlobalMenu: entities.crafter.crafterData.craftMenu.fetchCraftGlobalMenu({ crafterId: props.crafterId }),
}))(class JobCraftItems extends React.Component {
  state = {
    newItemLabel: '',
    newItemCategory: null,
    addItemModalVisibility: false,
    itemOptionSelection: {}
  }

  async addItem() {
    const { craftGlobalMenu, jobMenuManager, jobBasic } = this.props
    const { newItemLabel, newItemCategory } = this.state
    
    if (!newItemLabel || !newItemLabel.length || !newItemCategory || !newItemCategory.id()) {
      return this.setState({ modalError: 'Item Name Cannot be Empty' })
    }

    const update = craftGlobalMenu.addItem({ name: newItemLabel, categoryId: newItemCategory.id() })
    const newItemId = Object.values(update || {})[0].id
    
    await kernel.performUpdates([ update ])

    const addItemToJobUpdate = jobMenuManager.addItemToJobMenu({ globalCraftItemId: newItemId })

    await kernel.performUpdates([ addItemToJobUpdate ])

    this.setState({ addItemModalVisibility: false, newItemLabel: '', newItemCategory: null, modalError: null })
  }

  async addOptionsToItem() {
    const { craftGlobalMenu, jobMenuManager } = this.props
    const { itemOptionSelection, addOptionsToItemModalItem } = this.state
    
    const updates = kv(itemOptionSelection).map(({ k: optionId, v: isSelected }) => {
      if (isSelected) {
        return craftGlobalMenu.addOptionToItem({ itemId: addOptionsToItemModalItem.id(), optionId })
      }
      return craftGlobalMenu.removeOptionFromItem({ itemId: addOptionsToItemModalItem.id(), optionId })
    })

    await kernel.performUpdates(updates)

    const nus = kv(itemOptionSelection).map(({ k: optionId, v: isSelected }) => {
      if (isSelected) {
        return jobMenuManager.addOptionToJobMenu({ itemId: addOptionsToItemModalItem.id(), optionId })
      }
      return jobMenuManager.removeOptionFromJobMenu({ itemId: addOptionsToItemModalItem.id(), optionId })
    })
    
    await kernel.performUpdates(nus)

    this.setState({ addOptionsToItemModalItem: null, itemOptionSelection: {} })

  }

  async addOption() {
    const { craftGlobalMenu, jobMenuManager } = this.props
    const { newOptionLabel, itemOptionType, itemOptionItems, itemToAttachTheOptionTo } = this.state
    
    if (!newOptionLabel) return this.setState({ modalError: 'Option Label Cannot be Empty' })
    if (!itemOptionItems.length) return this.setState({ modalError: 'Please Add At Least One Option Item' })
    if (itemOptionItems.some(i => !i || ( i && !i.length )) ) return this.setState({ modalError: 'Option Item Names Cannot be Empty' })
    const update = craftGlobalMenu.addOption({ 
      label: newOptionLabel, 
      type: itemOptionType, 
      items: itemOptionItems 
    })

    await kernel.performUpdates([ update ])

    const newOptionId = Object.values(update || {})[0].id

    const optionItemIds = Object.keys(Object.values(update || {})[0].items || {})

    await kernel.performUpdates([ craftGlobalMenu.addOptionToItem({ itemId: itemToAttachTheOptionTo.id(), optionId: newOptionId }) ])

    await kernel.performUpdates([ 
      jobMenuManager.members.jobMenu.item(itemToAttachTheOptionTo.id()).enableOption({ optionId: newOptionId }) 
    ])

    const nu = []

    optionItemIds.forEach(optionItemId => nu.push(jobMenuManager.members.jobMenu.item(itemToAttachTheOptionTo.id()).enableOptionItemForOption({ optionId: newOptionId, optionItemId })))

    await kernel.performUpdates(nu)

    this.setState({ addOptionModalVisible: false, newOptionLabel: '', itemOptionItems: [], itemToAttachTheOptionTo: null, modalError: null })
  }

  async addOptionItem() {
    const { jobMenuManager } = this.props
    const { newOptionItemModalOption, newOptionItemLabel, newOptionItemModalItem } = this.state

    if (!newOptionItemLabel || !newOptionItemLabel.length) return this.setState({ modalError: 'Option Item Name Cannot be Empty' })

    const update = newOptionItemModalOption.addOptionItem({ label: newOptionItemLabel })
    
    // debugger
    await kernel.performUpdates([ update ])

    const optionItemId = Object.values(Object.values(update || {})[0] || {})[0].id

    const nu = jobMenuManager.addOptionItemToJobMenu({ 
      itemId: newOptionItemModalItem.id(), 
      optionId: newOptionItemModalOption.id(),
      optionItemId
    })

    await kernel.performUpdates([ nu ])

    this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '', modalError: null })
  }

  editItem() {
    const { editItem, editItemLabel } = this.state
    const { jobMenuManager } = this.props
    if (!editItem || !editItem.id() || !editItemLabel || !editItemLabel.length) return this.setState({ modalError: 'Item Label Cannot be Empty' })
    kernel.performUpdates([ jobMenuManager.members.jobMenu.item(editItem.id()).editItemLabel({ label: editItemLabel }) ])
    this.setState({ editItem: null, editItemLabel: null, modalError: null })
  }

  render() {
    const { jobMenuManager, craftGlobalMenu, jobBasic } = this.props
    const { addItemModalVisibility } = this.state

    if (!isReady(jobMenuManager, craftGlobalMenu, jobBasic)) return null
    window.jmm = jobMenuManager
    
    const { Panel } = Collapse
    return (
      <div className='job-craft-items-container' style={{ background: jobBasic.craftPaused() ? 'lightgray' : null }}>

        <Modal
          title='Edit Item Name'
          visible={!!this.state.editItem}
          onOk={() => this.editItem()}
          onCancel={() => this.setState({ modalError: null, editItem: null })}
        >
          <div className='modal-body'>
            <div className='modal-section'>
              <TextField 
                placeholder='Item Name' 
                required 
                style={{ width: '100%' }} 
                variant='outlined' 
                label='Item Name' 
                value={this.state.editItemLabel} 
                onChange={e => this.setState({ editItemLabel: e.target.value })} 
                onKeyPress={e => e.key === 'Enter' && this.editItem()}
              />
            </div>
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
        </Modal>

        <Modal
          title='Add Options To Item'
          visible={!!this.state.addOptionsToItemModalItem}
          onOk={() => this.addOptionsToItem()}
          onCancel={() => this.setState({ addOptionsToItemModalItem: null, itemOptionSelection: {} })}
        >
          <FormGroup row={true}>
            {
              craftGlobalMenu.allOptions().filter(i => i).map(optionBasic => (
                <FormControlLabel
                  label={optionBasic.label()}
                  control={
                    <Checkbox
                      color='primary'
                      name={optionBasic.id()}
                      checked={!!this.state.itemOptionSelection[optionBasic.id()]}
                      onChange={e => {
                        const opts = this.state.itemOptionSelection
                        opts[optionBasic.id()] = e.target.checked
                        this.setState({ itemOptionSelection: opts })
                        // if (e.target.checked)
                        //   kernel.performUpdates([ craftGlobalMenu.addOptionToItem({ itemId: itemBasic.id(), optionId: optionBasic.id() }) ])
                        // else
                        //   kernel.performUpdates([ craftGlobalMenu.removeOptionFromItem({ itemId: itemBasic.id(), optionId: optionBasic.id() }) ])
                      }}
                    />
                  }
                />
              ))
            }
          </FormGroup>
          { this.state.modalError && 
            <div className='modal-error'>
              {this.state.modalError}
            </div>
          }
        </Modal>


        <Modal
          title='Create New Item'
          visible={addItemModalVisibility}
          onOk={e => this.addItem()}
          onCancel={() => this.setState({ addItemModalVisibility: false, newItemLabel: '', newItemCategory: null, modalError: null })}
        >
          <div className='modal-body'>
            <div className='modal-section'>
              <TextField 
                placeholder='New Item Name' 
                required 
                style={{ width: '100%' }} 
                variant='outlined' 
                label='Item Name' 
                value={this.state.newItemLabel} 
                onChange={e => this.setState({ newItemLabel: e.target.value })} 
                onKeyPress={e => e.key === 'Enter' && this.addItem()}
              />
            </div>
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
        </Modal>


        <Modal
          title='Create New Option'
          visible={this.state.addOptionModalVisible}
          onOk={() => this.addOption()}
          onCancel={() => this.setState({ addOptionModalVisible: false })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='New Option Label'
              placeholder='Option Label'
              value={this.state.newOptionLabel}
              onChange={e => this.setState({ newOptionLabel: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.addOption()}
            />
            <div className='refactor-me modal-section'>
              Option Type
              <FormControl>
                <Select value={this.state.itemOptionType} onChange={e => {
                  this.setState({ itemOptionType: e.target.value })
                }} >
                  <MenuItem value='checkbox'>Multi Option</MenuItem>
                  <MenuItem value='radio'>One Option</MenuItem>
                </Select>
                <FormHelperText>{this.state.itemOptionType === 'checkbox' ? 'Multiple Option Items Can Be Selected' : 'Only One Option Item Can Be Selected'}</FormHelperText>
              </FormControl>
            </div>
            <div className='modal-section'>
              {
                (this.state.itemOptionItems || []).map((item, index) => 
                  <div className='modal-section' key={index}>
                    <TextField 
                      required 
                      label='Option Item Name' 
                      variant='outlined' 
                      color='primary' 
                      value={item} 
                      onChange={e => {
                        const i = this.state.itemOptionItems
                        i[index] = e.target.value
                        this.setState({ itemOptionItems: i })
                      }} 
                      onKeyPress={e => e.key === 'Enter' && this.addOption()}
                    />
                  </div>
                )
              }
            </div>
            <Button variant='outlined' color='primary' onClick={() => this.setState({ itemOptionItems: this.state.itemOptionItems.concat('') })}>Add New Option Item</Button>
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
          
        </Modal>



        <Modal
          title='Create New Option Item'
          visible={!!this.state.newOptionItemModalOption}
          onOk={() => this.addOptionItem()}
          onCancel={() => this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '' })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='New Option Item Name'
              placeholder='Option Item Name'
              value={this.state.newOptionItemLabel}
              onChange={e => this.setState({ newOptionItemLabel: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.addOptionItem()}
            />
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
        </Modal>



        <Collapse className='cat-collapse' style={{ background: jobBasic.craftPaused() ? 'lightgray' : null }}>
          {
            jobMenuManager.members.globalMenu.members.categories.asArray()
              .sort((c1, c2) => (
                jobMenuManager.priorityForCategory({ categoryId: c1.id() }) < jobMenuManager.priorityForCategory({ categoryId: c2.id() }) ? -1 : 1
              ))
              .map(catBasic => (
              jobMenuManager.jobMenuItemsForCategory({ categoryId: catBasic.id() }).length && <Panel 
                disabled={jobBasic.craftPaused() ? true : false}
                header={
                  <div className={`cat-panel-header ${jobMenuManager.areAllItemsForCategoryDisabled({ categoryId: catBasic.id()}) ? 'disabled' : 'enabled'}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: 20 }}>
                      CATEGORY: {catBasic.name()}
                    </div>
                    <div>
                      <Button
                        color='primary'
                        variant='text'
                        color={!jobBasic.craftPaused() ? 'primary' : ''}
                        onClick={e => {
                          e.stopPropagation()
                          if (jobBasic.craftPaused()) return
                          this.setState({ addItemModalVisibility: true, newItemCategory: catBasic })
                        }}
                      >
                        Create Item
                      </Button>
                      <Button 
                        disable={jobBasic.craftPaused()}
                        onClick={e => {
                          e.stopPropagation(); 
                          if (jobBasic.craftPaused()) return
                          kernel.performUpdates( jobMenuManager.disableAllItemsForCategory({ categoryId: catBasic.id() }) )}
                        } 
                        className='cat-disable-button each-toggle-button' 
                        variant='contained' 
                        color={!jobBasic.craftPaused() ? 'primary' : ''}
                      >
                        Disable
                      </Button>
                      <Button 
                        disable={jobBasic.craftPaused()}
                        onClick={e => {
                          e.stopPropagation(); 
                          if (jobBasic.craftPaused()) return
                          kernel.performUpdates( jobMenuManager.enableAllItemsForCategory({ categoryId: catBasic.id() }) )}
                        } 
                        className='cat-enable-button each-toggle-button' 
                        variant='contained' 
                        color={!jobBasic.craftPaused() ? 'primary' : ''}
                      >
                        enable
                      </Button>
                      <span style={{ color: 'black' }}>
                        <span style={{ paddingLeft: 20, paddingRight: 10 }}>priority:</span>
                        <Input
                          disabled={jobBasic.craftPaused()}
                          type='number'
                          placeholder={jobMenuManager.priorityForCategory({ categoryId: catBasic.id() }) }
                          onClick={e => e.stopPropagation()}
                          onBlur={e => kernel.performUpdates([ jobMenuManager.setPriorityForCategory({ categoryId: catBasic.id(), priority: e.target.value || 0 }) ])}
                        />
                      </span>
                    </div>
                  </div>
                } 
                key={catBasic.id()}
              >
                {
                  jobMenuManager.jobMenuItemsForCategory({ categoryId: catBasic.id() }).map(jobMenuItemComplete => (
                    <Collapse key={jobMenuItemComplete.id()} className='item-collapse'>
                      
                      <Panel 
                        header={
                          <div 
                            className='item-panel-header each-panel-header'
                          >
                            <div>
                              <Checkbox
                                className='item-toggle-button each-toggle-button' 
                                checked={jobMenuItemComplete.members.jobMenuItem.enabled()}
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation(); 
                                  kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem[`${ jobMenuItemComplete.members.jobMenuItem.enabled() ? 'disable' : 'enable' }`]() ])
                                }}
                              />
                              ITEM: {jobMenuManager.itemNameForItemId({ itemId: jobMenuItemComplete.id() })}
                              {/* ITEM: {jobMenuManager.members.globalMenu.members.items.item(jobMenuItemComplete.id()).name()} */}
                              
                            </div>
                            <div>
                              {/* <Button
                                variant='text'
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation()
                                  this.setState({ editItem: jobMenuManager.members.globalMenu.members.items.item(jobMenuItemComplete.id()), modalError: null, 
                                    editItemLabel: jobMenuManager.itemNameForItemId({ itemId: jobMenuItemComplete.id() }) })
                                }}
                              >
                                Rename Item
                              </Button> */}
                              <Button
                                variant='text'
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation()
                                  const craftGlobalItemBasic = jobMenuManager.members.globalMenu.members.items.item(jobMenuItemComplete.id())
                                  const jobItem = jobMenuManager.item(craftGlobalItemBasic.id())
                                  if (!jobItem) return null
                                  const item = jobItem.members.jobMenuItem
                                  const enabledOptionIds = rollup(item.enabledOptionIds() || [])
                                  this.setState({ 
                                    addOptionsToItemModalItem: craftGlobalItemBasic,  
                                    itemOptionSelection: enabledOptionIds
                                  })
                                }}
                              >
                                Add/remove options
                              </Button>
                              <Button
                                variant='text'
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation()
                                  this.setState({ itemOptionItems: [], addOptionModalVisible: true, itemToAttachTheOptionTo: jobMenuManager.members.globalMenu.members.items.item(jobMenuItemComplete.id()) })
                                }}
                              >
                                create Option
                              </Button>
                              <Button
                                variant='text'
                                color='secondary'
                                onClick={e => {
                                  e.stopPropagation()
                                  const confirmed = window.confirm('Are you sure you want to delete this item from your menu?')
                                  if (confirmed) {
                                    kernel.performUpdates([ jobMenuManager.removeItemFromJobMenu({ globalCraftItemId: jobMenuItemComplete.id() }) ])
                                  }
                                }}
                              >
                                Remove Item
                              </Button>
                            </div>
                            
                              
                              
                          </div>
                        }
                      >
                        {
                          jobMenuManager.itemOptionIds({ itemId: jobMenuItemComplete.id() }).map(optionId => {
                            const isOptionEnabled = jobMenuItemComplete.members.jobMenuItem.enabled() && jobMenuItemComplete.members.jobMenuItem.isOptionEnabled({ optionId })
                            return (
                            <Collapse className='option-collapse'>
                              
                              <Panel 
                                header={
                                  <div 
                                    className='each-panel-header option-panel-header'
                                  >
                                    <div>
                                      <Checkbox
                                        className='option-toggle-button each-toggle-button' 
                                        checked={isOptionEnabled}
                                        color='primary'
                                        onClick={e => {
                                          e.stopPropagation(); 
                                          if (isOptionEnabled) {
                                            kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem.disableOption({ optionId }) ])
                                          }
                                          else {
                                            kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem.enable(), jobMenuItemComplete.members.jobMenuItem.enableOption({ optionId }) ])
                                          }
                                          
                                        }}
                                      />
                                      OPTION: {jobMenuManager.members.globalMenu.members.options.item(optionId).label()}
                                    </div>
                                    <div>
                                      <Button
                                        variant='text'
                                        color='primary'
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.setState({ 
                                            newOptionItemModalOption: jobMenuManager.members.globalMenu.members.options.item(optionId),
                                            newOptionItemModalItem: jobMenuItemComplete.members.jobMenuItem
                                          })
                                        }}
                                      >
                                        Create option item
                                      </Button>
                                      <Button
                                        variant='text'
                                        color='secondary'
                                        onClick={e => {
                                          e.stopPropagation()
                                          const confirmed = window.confirm('Are you sure you want to delete this item from your menu?')
                                          if (confirmed) {
                                            kernel.performUpdates([ jobMenuManager.removeOptionFromJobMenu({ itemId: jobMenuItemComplete.id(), optionId }) ])
                                          }
                                        }}
                                      >
                                        remove option
                                      </Button>
                                    </div>
                                    
                                  </div>
                                }
                              >
                                {
                                  jobMenuManager.itemOptionItemIds({ itemId: jobMenuItemComplete.id(), optionId }).filter(i => i).map(optionItemId => {
                                    const isOptionItemEnabled = jobMenuItemComplete.members.jobMenuItem.enabled() && jobMenuItemComplete.members.jobMenuItem.isOptionEnabled({ optionId }) && jobMenuItemComplete.members.jobMenuItem.isOptionItemEnabled({ optionId, optionItemId })
                                    return (
                                    <div className='each-option-item each-panel-header'>
                                      <div>
                                        <Checkbox
                                          className='option-item-toggle-button each-toggle-button' 
                                          checked={isOptionItemEnabled}
                                          color='primary'
                                          onClick={e => {
                                            e.stopPropagation(); 
                                            
                                            if (isOptionItemEnabled) {
                                              kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem.disableOptionItemForOption({ optionId, optionItemId }) ])
                                            }
                                            else {
                                              if (isOptionEnabled) {
                                                kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem.enableOptionItemForOption({ optionId, optionItemId }) ])
                                              }
                                              else {
                                                if (jobMenuItemComplete.members.jobMenuItem.enabled()) {
                                                  // enable option and option item
                                                  kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem.enableOption({ optionId }), jobMenuItemComplete.members.jobMenuItem.enableOptionItemForOption({ optionId, optionItemId }) ])
                                                }
                                                else {
                                                  kernel.performUpdates([ jobMenuItemComplete.members.jobMenuItem.enable(), jobMenuItemComplete.members.jobMenuItem.enableOption({ optionId }), jobMenuItemComplete.members.jobMenuItem.enableOptionItemForOption({ optionId, optionItemId }) ])
                                                  // enable item, option and option item
                                                }
                                              }
                                            }
                                          }}
                                        />
                                        {jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemLabel(optionItemId)}
                                      </div>
                                      <Button
                                        variant='text'
                                        color='secondary'
                                        onClick={e => {
                                          e.stopPropagation()
                                          const confirmed = window.confirm('Are you sure you want to delete this item from your menu?')
                                          if (confirmed) {
                                            kernel.performUpdates([ jobMenuManager.removeOptionItemFromJobMenu({ itemId: jobMenuItemComplete.id(), optionId, optionItemId }) ])
                                          }
                                        }}
                                      >
                                        Remove option item
                                      </Button>
                                      
                                    </div>
                                  )})
                                }
                               
                              </Panel>
                            </Collapse>
                          )})
                        }
                      </Panel>
                    </Collapse>
                  ))
                }
              </Panel>
            ))
          }
        </Collapse>
        {/* <List
          bordered
          dataSource={jobMenuManager.members.jobMenu.asArray()}
          style={{ marginBottom: '40px' }}
          renderItem={jobMenuItem => (
            <div className='each-craft-item'>
              <div className='item-name'>
                {jobMenuManager.members.globalMenu.members.items.item(jobMenuItem.id()).name()}
              </div>
              <Button 
                variant='outlined' 
                color='primary' 
                className={`button ${jobMenuItem.enabled() ? 'enabled' : 'disabled'}`}
                onClick={() => kernel.performUpdates([ jobMenuItem.enabled() ? jobMenuItem.disable() : jobMenuItem.enable() ])}
              >
                {jobMenuItem.enabled() ? 'Disable' : 'Enable'}
              </Button>
            </div>
          )}
        /> */}
      </div>
    )
  }
})
import { JobBreakfastPreorderMenu } from './job-breakfast-preorder-menu.node'
import { JobBreakfastPreorder } from './job-breakfast-preorder.node'
import { JobBreakfastPreorders } from './job-breakfast-preorders.node'

export const rootMethods = {

  breakfastPreorderMenu({ crafterId, jobId }) {
    return this._spinoff(JobBreakfastPreorderMenu.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/breakfastPreorderMenu`
    })
  },

  breakfastPreorderForUserAndDay({ crafterId, jobId, dayString, userId }) {
    return this._spinoff(JobBreakfastPreorder.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/breakfastPreorders/${dayString}/${userId}`
    })
  },

  breakfastPreorders({ crafterId, jobId, dayString }) {
    return this._spinoff(JobBreakfastPreorders.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/breakfastPreorders/${dayString}`
    })
  }

}
import { createNodeClass } from '@blast-engine/firework'

export const JobBasic = createNodeClass({
  name: 'JobBasic',

  full: class {

    id() {
      return this._data('id')
    }

    craftPaused() {
      return this._data('craftPaused')
    }

    toggleCraftPaused(bool) {
      return this._update({ craftPaused: bool })
    }

    name() {
      return this._data('name')
    }

    currentDay() {
      return '' + (this._data('day') || 0)
    }

    incrementDay() {
      const currentDay = parseInt(this.currentDay())
      return this._update({ day: currentDay + 1 })
    }

    decrementDay() {
      const currentDay = parseInt(this.currentDay())
      if (currentDay === 0) return {}
      return this._update({ day: currentDay - 1 })
    }

    finishJob() {
      return this._update({ status: 'finished' })
    }

    crafterId() {
      return this._data('crafterId')
    }

    status() {
      return this._data('status')
    }

    notesForBasketAreEnabled() {
      return this._data('notesForBasketAreEnabled')
    }

    enableNotesForBasket() {
      return this._update({ notesForBasketAreEnabled: true })
    }

    disableNotesForBasket() {
      return this._update({ notesForBasketAreEnabled: false })
    }

    jobStatuses() {
      return [
        'not-started',
        'active',
        'finished'
      ]
    }
    
    updateStatus({ status }) {
      return this._update({ status })
    }

  },

  ref: class {

    initialize({ id, name, crafterId, status }) {
      return this._update({ id, name, crafterId, status, day: '0' })
    }

  }

})
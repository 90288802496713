import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { craftMenu } from './craft-global-menu'
import { job } from './job'
import { JobCraftMenuItemComplete } from './job-craft-menu-item-complete.assembly'

export const JobCraftMenuManager = createAssemblyClass({
  name: 'JobCraftMenuManager',
  memberModels: {
    globalMenu: craftMenu.CrafterCraftGlobalMenu,
    jobMenu: job.jobData.JobCraftMenu,
    jobMenuCategories: job.jobData.JobCraftCategories.full(),
    pausedService: job.jobData.pausedService.PausedService.full()
  },
  portMethods: {

  },
  body: class {

    itemExists({ itemId }) {
      const { globalMenu } =  this.members
      return !!globalMenu.members.items.item(itemId)
    }

    itemNameForItemId({ itemId }) {
      const { globalMenu, jobMenu } =  this.members
      if (!jobMenu.item(itemId) || !jobMenu.item(itemId).editedItemLabel || !jobMenu.item(itemId).editedItemLabel()) {
        return globalMenu.members.items.item(itemId).name()
      }
      return jobMenu.item(itemId).editedItemLabel() ? jobMenu.item(itemId).editedItemLabel() : globalMenu.members.items.item(itemId).name()
    }

    populatePausedService() {
      return this.members.pausedService.setAllItems(this.members.jobMenu.data)
    }

    priorityForCategory({ categoryId }) {
      const { jobMenuCategories } = this.members
      return jobMenuCategories.priority({ categoryId })
    }

    setPriorityForCategory({ categoryId, priority }) {
      const { jobMenuCategories } = this.members
      return jobMenuCategories.setPriority({ categoryId, priority })
    }

    isItemEnabledForJob({ itemId }) {
      const item = this.members.jobMenu.item(itemId)
      return item ? item.enabled() : false
    }

    isItemPresentForJob({ itemId }) {
      const item = this.members.jobMenu.item(itemId)
      return !(item === undefined)
    }

    enabledCategories() {
      const { globalMenu } = this.members
      const items = this.jobMenuItems({ onlyEnabled: true })
      return globalMenu.allCategories()
        .filter(category => items.some(item => item.categoryId() === category.id()))
    }

    disableAllItemsForCategory({ categoryId }) {
      const allItemsUnderCategory = this.jobMenuItemsForCategory({ categoryId })
      return allItemsUnderCategory.map(jobMenuItemComplete => jobMenuItemComplete.members.jobMenuItem.disable())
    }

    areAllItemsForCategoryDisabled({ categoryId }) {
      const allItemsUnderCategory = this.jobMenuItemsForCategory({ categoryId })
      return !allItemsUnderCategory.map(jobMenuItemComplete => jobMenuItemComplete.members.jobMenuItem.enabled()).filter(i => i).length
    }

    enableAllItemsForCategory({ categoryId }) {
      const allItemsUnderCategory = this.jobMenuItemsForCategory({ categoryId })
      return allItemsUnderCategory.map(jobMenuItemComplete => jobMenuItemComplete.members.jobMenuItem.enable())
    }

    enableAllCategories() {
      return [].concat(...this.members.globalMenu.allCategories().filter(cat => !cat.isFakeDeleted()).map(cat => this.enableAllItemsForCategory({ categoryId: cat.id() })))
    }

    disableAllCategories() {
      return [].concat(...this.members.globalMenu.allCategories().filter(cat => !cat.isFakeDeleted()).map(cat => this.disableAllItemsForCategory({ categoryId: cat.id() })))
    }

    enabledItemsForCategory(categoryId) {
      return this.jobMenuItems({ onlyEnabled: true })
        .filter(item => item.categoryId() === categoryId)
    }

    numEnabledItemsForCategory(categoryId) {
      return this.enabledItemsForCategory(categoryId).length
    }

    item(itemId) {
      if (!this.itemsCache) this.itemsCache = {}
      if (this.itemsCache[itemId]) return this.itemsCache[itemId]
      const { globalMenu, jobMenu } = this.members
      const globalMenuItem = globalMenu.item(itemId)
      if (!globalMenuItem) return undefined
      const jobMenuItem = jobMenu.item(itemId)
      if (!jobMenuItem) return null
      this.itemsCache[itemId] = this._spinoff(JobCraftMenuItemComplete, {
        members: { jobMenuItem, globalMenuItem }
      })
      return this.itemsCache[itemId]
    }

    jobMenuItemsForCategory({ categoryId }) {
      if (!this.jmifcCache) this.jmifcCache = {}
      if (this.jmifcCache[categoryId]) return this.jmifcCache[categoryId]
      const allItems = this.jobMenuItems()
      this.jmifcCache[categoryId] = allItems.filter(jobMenuItem => this.members.globalMenu.isItemInCategory({ itemId: jobMenuItem.id(), categoryId }))
      return this.jmifcCache[categoryId]
    }

    jobMenuItems({ onlyEnabled = false } = {}) {
      const { jobMenu, globalMenu } = this.members

      return jobMenu.asArray()
        .filter(jobMenuItem => !onlyEnabled || jobMenuItem.enabled())    
        .map(jobMenuItem => this.item(jobMenuItem.id()))
        .filter(item => !!item)
        .filter(i => !globalMenu.members.items.item(i.id()).isFakeDeleted())
    }

    globalMenuSelections() {
      const { globalMenu, jobMenu } = this.members
      return globalMenu.allItems()
        .map(globalItem => {
          const jobItem = jobMenu.item(globalItem.id())
          return {
            globalItem,
            jobItem, 
            isInJobMenu: !!jobItem,
          }
        }) 
    }

    removeItemFromJobMenu({ globalCraftItemId }) {
      return this.members.jobMenu.item(globalCraftItemId)._delete()
    }

    addOptionToJobMenu({ itemId, optionId }) {
      // debugger
      return this.members.jobMenu.item(itemId).enableOption({ optionId })
    }

    addOptionItemToJobMenu({ itemId, optionId, optionItemId }) {
      
      return this.members.jobMenu.item(itemId).enableOptionItemForOption({ optionId, optionItemId })
    }

    removeOptionFromJobMenu({ itemId, optionId }) {
      return this.members.jobMenu.item(itemId).removeOptionFromJob({ optionId })
    }

    removeOptionItemFromJobMenu({ itemId, optionId, optionItemId }) {
      return this.members.jobMenu.item(itemId).removeOptionItemFromJob({ optionId, optionItemId })
    }

    addItemToJobMenu({ 
      globalCraftItemId, 
      optionsConfig = {},
      enabled = true,
      allowNotes = false
    }) {
      return this.members.jobMenu.add({
        newItemId: globalCraftItemId,
        initArgs: {
          optionsConfig,
          enabled,
          allowNotes
        }
      })
    }

    isOptionFakeDeleted({ optionId }) {
      const option = this.members.globalMenu.members.options.item(optionId)
      // TODO: should it return null?
      if (!option) return true

      return option.isOptionFakeDeleted()
    }

    isOptionItemFakeDeleted({ optionId, optionItemId }) {
      const option = this.members.globalMenu.members.options.item(optionId)
      // TODO: should it return null?
      if (!option) return true

      return option.isOptionItemFakeDeleted({ optionItemId })

    }

    itemOptionItemIds({ itemId, optionId }) {
      const { jobMenu, globalMenu } = this.members
      
      const jobMenuItem = jobMenu.item(itemId)
      if (!jobMenuItem) return []
      const optionItemIds = jobMenuItem.itemOptionItemIds({ optionId })
      
      return optionItemIds.filter(optionItemId => !this.isOptionItemFakeDeleted({ optionId, optionItemId }))

    }

    itemOptionIds({ itemId }) {
      const { jobMenu, globalMenu } = this.members
      
      const jobMenuItem = jobMenu.item(itemId)
      if (!jobMenuItem) return []
      const optionIds = jobMenuItem.itemOptionIds()
      
      return optionIds.filter(optionId => !this.isOptionFakeDeleted({ optionId }))

    }

  }
})
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Link } from 'react-router-dom'
import { TextField, Button, Fab, Icon, FormControl, InputLabel, Select, CircularProgress  } from '@material-ui/core'
import { Divider, Card, Collapse } from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-job-meals.scss'

export const CraftOfficeJobMeals = fireworkConnect(props => ({
  job: entities.crafter.crafterData.job.fetchJobBasic({ crafterId: props.crafterId, jobId: props.jobId }),
  jobMenuManager: entities.crafter.crafterData.fetchJobCraftMenuManager({ crafterId: props.crafterId, jobId: props.jobId }),
  jobDays: entities.crafter.crafterData.job.jobData.
    fetchCrafterJobDays({
      crafterId: props.crafterId,
      jobId: props.jobId
    }),
  subsGlobalMenu: entities.crafter.crafterData.subsGlobalMenu.fetchSubsGlobalMenu({ crafterId: props.crafterId }),
  
}))(class CraftOfficeJobMeals extends React.Component {
  state = {
    jobSubOrdersComplete: [],
    haveFetchedOrders: false,
    allMealPerms: []
  }

  async componentDidUpdate() {
    const { job, jobMenuManager, jobDays, subsGlobalMenu } = this.props
    if (!this.state.haveFetchedOrders && isReady(job, jobMenuManager, jobDays, subsGlobalMenu)) {
      const promisses = jobDays.asArray().map(async jobDay => await this.fetchJobSubOrdersForDay(jobDay.dayNum()));
      const jobSubOrdersComplete = await Promise.all(promisses)
      const allMealPerms = jobSubOrdersComplete.map(joc => joc.perms())
      this.setState({ haveFetchedOrders: true, jobSubOrdersComplete, allMealPerms })
    }
  }

  // async componentDidMount() {
  //   const promisses = 
  // }

  async fetchJobSubOrdersForDay(dayNum) {
    const { crafterId, jobId } = this.props
    return await kernel.snap(entities.crafter.crafterData.fetchJobSubOrdersComplete({ crafterId, jobId, day: dayNum }))
  }

  render() {
    const { job, jobMenuManager, jobDays, subsGlobalMenu } = this.props
    const { jobSubOrdersComplete, allMealPerms: mealPerms } = this.state

    if (!isReady(job, jobMenuManager, jobDays, subsGlobalMenu)) return null
    const { Panel } = Collapse
    window.coj = this
    
    return (
      <div className='craft-office-job-meals-container'>
        <Collapse>
          {
            jobDays.asArray().map(jobDay => (
              <Panel
                key={jobDay.dayNum()}
                header={
                  <div className='header'>
                    <div className='left-side'>
                      Day Number {Number(jobDay.dayNum())+1}
                    </div>
                    <div className='right-side'>
                      <Link target='_blank' to={`/craft-office/printableMeals/${job.id()}/meals/${Number(jobDay.dayNum())}`}>Printable Version</Link>  
                    </div>
                  </div>
                }
                className='each-panel'
              >
                {
                  mealPerms.length && mealPerms[jobDay.dayNum()].map(meal => {
                    
                    return (
                      <div className='each-meal' key={meal.orderName}>
                        <div className='left-section'>
                          <div className='meal-name' >{meal.orderName} x{meal.usernames.length}</div>
                          {
                            meal.orderOptions.map(option => (
                              <div className='options-section' key={option.optionName}>
                                <div className='option-name'>{option.optionName}</div>
                                <div className='option-item-section'>
                                  {
                                    option.optionItems.map(oi => (
                                      <div key={oi} className='each-option-item'>
                                        {oi}
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            ))
                          }
                          {
                            meal.orderNotes ? 
                              <div style={{ fontSize: 15, paddingTop: 10 }}>
                                Notes: {meal.orderNotes}
                              </div>
                            : null
                          }
                        </div>
                        <div className='right-section'>
                          <FormControl className='username-section'>
                            <InputLabel shrink>User Names</InputLabel>
                            <Select
                              multiple
                              native

                            >
                              {
                                meal.usernames.map(userName => (
                                  <option key={userName}>
                                    {userName}
                                  </option>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </div>
                        
                      </div>
                    )
                  })
                }
              </Panel>
            ))
          }

        </Collapse>
      </div>
    )
  }
})
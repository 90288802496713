import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm, AddBoxOutlined, Edit } from '@material-ui/icons'
import { rollup, kv, objMap } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import { gtstore } from '@/gt-store.service'
import * as state from '@/state'

import './craft-on-site-menu.scss'

const JobOnsiteDataDaemonInternal = gtstore.connect(props => ({

}))

export const JobOnsiteDataDaemon = fireworkConnect(({ crafterId, jobId }) => ({
  jobMenu:  kernel.root.crafterJobCraftMenu({ crafterId, jobId }).query(),
  jobMenuCategories: kernel.root.crafterJobCraftCategories({ crafterId, jobId }).query(),
  globalCategories: kernel.root.craftGlobalCategoryBasicListRef({ crafterId }).query(),
  globalOptions: kernel.root.craftGlobalOptionsBasicListRef({ crafterId }).query(),
  globalItems: kernel.root.craftGlobalItemBasicListRef({ crafterId }).query(),
  pausedService: kernel.root.pausedServiceRef({ crafterId, jobId }).query(),
  job: kernel.root.crafterJobBasicRef({ crafterId, jobId }).query(),
  userBasics: kernel.root.userBasicListRef().query(),
  craftOrders: kernel.root.craftOrdersRef({ crafterId, jobId, day: '45' }).query()
}))(class JobOnsiteDataDaemon extends React.PureComponent {
  state = {}

  render() {
    const { 
      jobMenu, jobMenuCategories, globalCategories,
      globalOptions, globalItems, pausedService,
      job, userBasics, craftOrders
    } = this.props
    const {} = this.state

    if ([
      jobMenu, jobMenuCategories, globalCategories,
      globalOptions, globalItems, pausedService,
      job, userBasics, craftOrders
    ].some(m => !m)) return <div>loading data</div>

    state.actions.update({ 
      jobMenu: jobMenu.data,
      jobMenuCategories: jobMenuCategories.data, 
      globalCategories: globalCategories.data,
      globalOptions: globalOptions.data, 
      globalItems: globalItems.data, 
      pausedService: pausedService.data,
      job: job.data, 
      userBasics: userBasics.data, 
      craftOrders: craftOrders.data
    })

    return <div>data loaded!</div>
  }
})
import { createNodeClass } from '@blast-engine/firework'
import { set, keys, get, rollup } from '@blast-engine/utils'

export const SubMenuItem = createNodeClass({
  name: 'SubMenuItem',

  struct: class {

    fakeDelete() {
      return this._update({ _deleted: true })
    }

    isFakeDeleted() {
      return !!this._data('_deleted')
    }

    itemId() {
      return this._data('itemId')
    }

    id() {
      return this.itemId()
    }

    label() {
      return this._data('label')
    }

    updateLabel({ label }) {
      return this._update({ label })
    }

    optionsConfig() {
      return this._data('optionsConfig') || {}
    }

    status() {
      return this._data('status') || 'waiting'
    }

    enabled() {
      return !!this._data('enabled')
    }

    enable() {
      return this._update({ enabled: true })
    }

    disable() {
      return this._update({ enabled: false })
    }

    enabledOptionIds() {
      const optIds = keys(this.optionsConfig())
      return optIds.filter(optionId => this.isOptionEnabled({ optionId }))
    }

    isOptionEnabled({ optionId }) {
      return (this.optionsConfig()[optionId] || {}).enabled
    }

    isOptionItemEnabled({ optionId, optionItemId }) {
      return !get(this.optionsConfig(), `${optionId}.disabledOptionItemIds.${optionItemId}`)
    }

    disableOption({ optionId }) {
      const options = this.optionsConfig()
      options[optionId] = {
        ...options[optionId],
        enabled: false
      }
      return this._update({ [`optionsConfig/${optionId}/enabled`]: false })
    }

    enableOption({ optionId }) {
      const options = this.optionsConfig()
      options[optionId] = {
        ...options[optionId],
        enabled: true
      }
      return this._update({ [`optionsConfig/${optionId}/enabled`]: true })
    }

    disableOptionItemForOption({ optionItemId, optionId }) {
      // const options = this.optionsConfig()
      // // { [`${optionId}.optionItemIds.${optionItemId}`]: true }
      // const o = set(options, { [`${optionId}.disabledOptionItemIds.${optionItemId}`]: true })
      return this._update({ [`optionsConfig/${optionId}/disabledOptionItemIds/${optionItemId}`]: false })
    }

    enableOptionItemForOption({ optionItemId, optionId }) {
      // const options = this.optionsConfig()
      // const o = set(options, { [`${optionId}.disabledOptionItemIds.${optionItemId}`]: null })
      // options[optionId]['disabledOptionItemIds'][optionItemId] = null
      return this._update({ [`optionsConfig/${optionId}/disabledOptionItemIds/${optionItemId}`]: true })
    }

  },

  ref: class {

    initialize({ id, label, itemId, status = 'waiting', enabled = true }) {
      return this._update({ id, label, itemId, status, enabled })
    }

  },

  full: class { 
    
  }

})
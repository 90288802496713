import React from 'react'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './crafter.scss'

export const Crafter = fireworkConnect(props => ({
  craftyManager: entities.fetchCraftyManager(),
  crafterManager: entities.fetchCrafterManager({ crafterId: props.crafterId })
}))(class Crafter extends React.Component {
  state =  { 
    newUserId: '', 
    newUserRole: '',
    newJobName: '',
    newJobId: ''
  }

  componentDidUpdate(prevProps, prevState) {
    const { craftyManager, crafterManager, crafterId } = this.props
    if (!isReady(crafterManager, craftyManager)) return null
    
    if(!this.state.ran) {
      const updates = {}
    
      crafterManager.members.allCrafterJobBasics.asArray().forEach(jobBasic => {
        if ((jobBasic.status() !== crafterManager.members.allCrafterJobBasics.item(jobBasic.id()).status()) || this.state[jobBasic.id()] !== jobBasic.status()) {
          updates[jobBasic.id()] = crafterManager.members.allCrafterJobBasics.item(jobBasic.id()).status()
        }
      })
      if (Object.keys(updates).length) this.setState({ ...updates, ran: true })
    }

    

  }

  addUser() {
    const { craftyManager, crafterManager, crafterId } = this.props
    const { newUserId, newUserRole } = this.state
    const userId = crafterManager.getUserIdFromEmail({ email: newUserId })
    if (!userId) return this.setState({ newUserError: 'no user with that email found' })

    const update = crafterManager.addCrafterMember({ userId, role: newUserRole })
    kernel.performUpdates([update, crafterManager.inviteUserToCrafter({ crafterId, userId, role: newUserRole })])
  }

  addJob() {
    const { craftyManager, crafterManager } = this.props
    const { newJobName, newJobId } = this.state

    const update = crafterManager.addJob({ name: newJobName, id: newJobId })
    kernel.performUpdates(update)
  }

  changeJobStatus(jobId) {
    const update = this.props.crafterManager.members.allCrafterJobBasics.item(jobId).updateStatus({ status: this.state[jobId] })
    kernel.performUpdates([update])
  }

  render() {
    const { craftyManager, crafterManager } = this.props
    const { newUserId, newUserRole, newJobId, newJobName } = this.state

    if (!isReady(crafterManager) || !isReady(craftyManager)) return null
    
    return (
      <div className='crafter-container'>
        <div>
          <div>manage users</div>
          <div>
            {
              crafterManager.crafterMembers().map(crafterMember => (
                <div key={crafterMember.crafterId}>
                  {crafterMember.user.username()} - {crafterMember.role}
                </div>
              ))
            }
          </div>
        </div>
        <div>
          <div>add user</div>
          <input placeholder='email' value={newUserId} onChange={e => this.setState({ newUserId: e.target.value })} />
          <select onChange={e => this.setState({ newUserRole: e.target.value })} value={newUserRole}>
            {
              craftyManager.crafterRoles().map(role => (
                <option key={role} value={role}>{role}</option>
              ))
            }
          </select>
          <button onClick={() => this.addUser()}>add</button>
        </div>
        {/* <div>
          <div>add job</div>
          <input placeholder='job name' value={newJobName} onChange={e => this.setState({ newJobName: e.target.value })} />
          <input placeholder='job id' value={newJobId} onChange={e => this.setState({ newJobId: e.target.value })} />
          <button onClick={() => this.addJob()}>add</button>
        </div> */}
        {/* <div>
          <div>job list</div>
          <div>
            {
              crafterManager.members.jobRegistry.asArray().map(job => (
                <div key={job.jobId()}>
                  <div>
                    {job.name() || 'NO NAME'} - {job.jobId()}
                  </div>
                  <div>
                    update status
                  </div>
                  <select value={this.state[job.jobId()]} onChange={e => this.setState({ [job.jobId()]: e.target.value })}>
                    {
                      job.jobStatuses().map(status => <option key={status} value={status} name={status}>{status}</option>)
                    }
                  </select>
                  <button onClick={() => this.changeJobStatus(job.jobId())}>change status</button>
                </div>
              ))
            }
          </div>
        </div> */}
      </div>
    )
  }
})
import React from 'react'
import LogRocket from 'logrocket'
import { Button } from '@material-ui/core'

export class DisconnectedScreen extends React.Component {
  render() {
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{ height: '100px' }} src={require('../../assets/disconnected-icon.jpg')} />
        <h1 style={{ }}>Connection Lost</h1>
        <div style={{ marginBottom: '20px' }}>Please check your internet connection.</div>
        <Button onClick={() => window.location.reload()} style={{ color: 'blue' }}>I should have internet now</Button>
      </div>
    )
  }
}
import { CrafterMemberBasic } from './crafter-member-basic.node'
import { CrafterMemberBasicList } from './crafter-member.list'

export const rootMethods = {

  crafterMemberBasicRef({ crafterId, userId }) {
    return this._spinoff(CrafterMemberBasic.ref(), {
      path: `crafter/crafterData/${crafterId}/members/${userId}`
    })
  },

  crafterMemberBasicListRef({ crafterId }) {
    return this._spinoff(CrafterMemberBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/members`
    })
  },

}
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { withRouter } from 'react-router-dom'
import {} from '@material-ui/core'
import {} from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './registered_daemon.scss'

export const Registered_daemon = withRouter(fireworkConnect(props => ({
  me: entities.user.fetchMe()
}))(class Registered_daemon extends React.Component {
  state = {}

  async componentDidUpdate() {
// debugger
    this.props.ignore()
    // debugger
    if (!this.state.ran && this.props.me && isReady(this.props.me) && !this.props.me.isAnon() && !kernel.auth().userId()) {
      // debugger
      window.location.reload()
    }
    // debugger
    if (!this.state.ran && this.props.me && isReady(this.props.me) && !this.props.me.isAnon() && kernel.auth().userId()) {
      
      // kernel.performUpdates([ this.props.me.addUserToEmailList() ])
      // debugger

      try {
        await new Promise(function(resolve, reject) {
          const permissionResult = Notification.requestPermission(function(result) {
            resolve(result);
          });
  
          if (permissionResult) {
            permissionResult.then(resolve, reject);
          }
        })
        // .then(function(permissionResult) {
        //   console.log(permissionResult)
        //   if (permissionResult !== 'granted') {
        //     // throw new Error('We weren\'t granted permission.');
        //   }
        // });
        // debugger
        const messaging = kernel.firebase.messaging()
        
        // messaging.usePublicVapidKey('BB5tj3zPt3qE1EOxQnZufn3WktTJMkgtKQisHkUQGjDJ0tNigX_5Y3SXX2SB7Cf4p-Ohe9hC9vJWKUraysyq5yI') //dev
        messaging.usePublicVapidKey('BPpB-SbvLfkGoM4kbxHxYW-vA9IIlRakczXrATzIYdhPUkAllxN6Z_iXw6Kns5cbVzOtkJ31c5m2vXwQru21k78') //prod
        
        const currentToken = await messaging.getToken()
        // debugger
        if (currentToken) {
          console.log(currentToken);
          // debugger
          kernel.performUpdates([ this.props.me.addPushNotifToken({ token: currentToken }) ])
          
        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
          // updateUIForPushPermissionRequired();
          console.log(false);
        }
      } catch(err) {
        console.log('An error occurred while retrieving token. ', err);
        console.log('Error retrieving Instance ID token. ', err);
      }
      
      
      // debugger
      this.setState({ ran: true })
      this.props.history.push('/')
      window.location.reload()

    }
  }

  render() {
    const { me } = this.props
    const {} = this.state
    window.reg = this

    if (!isReady(me)) return null
    if (me.isAnon()) return null
    // if (!this.state.ran && !me.isAnon() && isReady(me)) this.setState({ a: Math.random() })
    
    return null
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchActiveCrewOnsiteRef = createFetcher({
  name: 'fetch-active-crew-onsite-ref'
}, ({ crafterId }) => ({
  steps: [],
  final: {
    take: [],
    instantiate: ({ root }) => root.activeCrewOnsiteRef({ crafterId })
  }
}))
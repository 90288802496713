import { UserBasic } from './user-basic.node'
import { UserBasicSelection } from './user-basic.selection'
import { UserPrivate } from './user-private.node'
import { UserPrivateList } from './user-private.list'
import { UserPrivateSelection } from './user-private.selection'
import { UserBasicList } from './user-basic.list'
import { UserEmailList } from './user-email.list'

export const rootMethods = {

  userBasicListRef() {
    return this._spinoff(UserBasicList.ref(), {
      path: `user_basic`
    })
  },

  userBasicRef({ userId }) {
    return this._spinoff(UserBasic.ref(), {
      path: `user_basic/${userId}`
    })
  },

  userBasicSelectionRef({ userIds = [] }) {
    return this._spinoff(UserBasicSelection.ref(), {
      path: `user_basic`,
      keys: userIds
    })
  },
  
  userPrivateRef({ userId }) {
    return this._spinoff(UserPrivate.ref(), {
      path: `user_private/${userId}`
    })
  },

  userPrivateListRef() {
    return this._spinoff(UserPrivateList.ref(), {
      path: `user_private`
    })
  },

  userPrivateSelectionRef({ userIds = [] }) {
    return this._spinoff(UserPrivateSelection.ref(), {
      path: `user_private`,
      keys: userIds
    })
  },

  userPrivatesRef() {
    return this.userPrivateListRef()
  },

  userEmailListRef() {
    return this._spinoff(UserEmailList.ref(), {
      path: `userEmails`
    })
  }

}
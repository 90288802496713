import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, TextField } from '@material-ui/core'
import {} from 'antd'
import { withRouter } from 'react-router-dom'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './caterer-job-list.scss'

export const CatererJobList = withRouter(fireworkConnect(props => ({
  jobList: entities.crafter.crafterData.job.fetchJobBasicList({ crafterId: props.crafterId })
}))(class CatererJobList extends React.Component {
  state = {
    error: null,
    jobId: ''
  }

  render() {
    const { jobList, history } = this.props
    const { error, jobId } = this.state

    if (!isReady(jobList)) return null
    const jobCodes = jobList.asArray().map(jobBasic => jobBasic.id())

    const tryToFindJob = () => {
      if (jobCodes.includes(jobId)) {
        history.push(`/caterer/jobs/${jobId}`)
      } else {
        this.setState({ error: 'Job Not Found' })
      }
    }
    
    return (
      <div className='caterer-job-list-container'>
        <img className='background-img' src={require('../../assets/bg.png')} />
        <div className='container'>
          <div className='find-job-text'>FIND JOB</div>
          <div className='input-sections'>
            <TextField 
              onChange={e => this.setState({ jobId: e.target.value })}
              className='input'
              placeholder="Enter your job code"
              label='Job Code'
              variant='filled'
              value={jobId}
              onKeyPress={e => e.key === 'Enter' && tryToFindJob()} 
            />
          </div>
          <Button variant='contained' className='submit-button' onClick={tryToFindJob}>Submit</Button>
          {error ? <div className='error-section'>{error}</div> : null}
        </div>
      </div>
    )
  }
}))
import { createFetcher } from '@blast-engine/firework'

import { CraftyManager } from './crafty-manager.assembly'

export const fetchCraftyManager = createFetcher({
  name: 'fetch-crafty-manager'
}, () => ({
  steps: [{
    name: 'craftyData',
    query: ({ root }) => root.craftyDataRef().query()
  }, {
    name: 'allCrafterBasics',
    query: ({ root }) => root.crafterBasicListRef().query()
  }, {
    name: 'memberBasics',
    requires: [ 'craftyData' ],
    query: ({ root, craftyData }) => {
      const memberIds = craftyData.memberIds()
      return root.userBasicSelectionRef({ userIds: memberIds }).query()
    }
  }, {
    name: 'invitesListRef',
    query: ({ root }) => root.invitesListRef().query()
  }, {
    name: 'userEmails',
    query: ({ root }) => root.userEmailListRef().query()
  }],
  final: {
    take: [ 'userEmails', 'invitesListRef', 'craftyData', 'allCrafterBasics', 'memberBasics' ],
    instantiate: ({ userEmails, invitesListRef, craftyData, allCrafterBasics, memberBasics, root }) => 
      root._spinoff(CraftyManager, { 
        members: { userEmails, invitesListRef, craftyData, allCrafterBasics, memberBasics }
      })
  }
}))
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { Modal } from 'antd'
import { keys, kv, values, set } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-job-menu-subs-change-item-options.scss'

export const CraftOfficeJobMenuSubsChangeItemOptions = fireworkConnect(props => ({
  
}))(class CraftOfficeJobMenuSubsChangeItemOptions extends React.Component {
  state = {
    checkedOptions: {},
    uncheckedOptionItems: {}
  }

  componentDidMount() {
    const { checkedOptions, uncheckedOptionItems } = this.state
    const e = checkedOptions
    let u = uncheckedOptionItems
    kv(this.props.subMenuItem.optionsConfig()).forEach(({k: optionId, v: config}) => {
      kv(config.disabledOptionItemIds).forEach(({k: kk, v: vv}) => u[kk] = !!vv)
      e[optionId] = { 
        checked: !!config.enabled,
        items: u
      }
      u = {}
      // kv(config.disabledOptionItemIds).forEach(({k: kk, v: vv}) => u[kk] = !!vv)
    })
    // Object.keys(this.props.subMenuItem.optionsConfig())
    
    this.setState({ checkedOptions: e, uncheckedOptionItems: u })
  }

  do() {
    const { onOk, subMenuItem } = this.props
    const { checkedOptions } = this.state
    // debugger
    kv(checkedOptions).forEach(({k: optionId, v: option}) => {
      if (option.checked) {
        console.log(subMenuItem.enableOption({ optionId }))
        kernel.performUpdates([ subMenuItem.enableOption({ optionId }) ])
      }
      else {
        console.log(subMenuItem.disableOption({ optionId }))
        kernel.performUpdates([ subMenuItem.disableOption({ optionId }) ])
      }
      kv(option.items).forEach(({ k: itemId, v: checked }) => {
        if (checked) {
          console.log(subMenuItem.enableOptionItemForOption({ optionItemId: itemId , optionId }))
          kernel.performUpdates([ subMenuItem.enableOptionItemForOption({ optionItemId: itemId , optionId }) ])
        }
        else {
          console.log(subMenuItem.disableOptionItemForOption({ optionItemId: itemId , optionId }))
          kernel.performUpdates([ subMenuItem.disableOptionItemForOption({ optionItemId: itemId , optionId }) ])
        }
      })
    })
    onOk()
    // this.props.subMenuItem
  }

  render() {
    const { subMenuItem, subGlobalItem, subsGlobalMenu, onOk, onCancel, isModalVisible } = this.props
    const {} = this.state
    
    return (
      <div className='craft-office-job-menu-subs-change-item-options-container'>
        <Modal
          title='Change Options'
          visible={isModalVisible}
          onOk={() => this.do()}
          onCancel={() => onCancel()}
          // footer={null}
        >
          {
            subsGlobalMenu.members.options.asArray().map(subOption => (
              <div key={subOption.id()}>
                <FormGroup>
                  <FormControlLabel
                    label={subOption.label()}
                    control={
                      <Checkbox
                        color='primary'
                        name={subOption.label()}
                        checked={
                          !!(this.state.checkedOptions[subOption.id()] || {})['checked']
                        }
                        onChange={e => {
                          const a = this.state.checkedOptions
                          const u = set(a, {
                            [`${subOption.id()}.checked`]: e.target.checked
                          })
                          this.setState({ checkedOptions: u })
// kernel.performUpdates([ subMenuItem[`${e.target.checked ? 'enable' : 'disable'}Option`]({ optionId: subOption.id() }) ])}
                        }}

                          
                      />
                    }
                  />
                </FormGroup>
                {/* <div>------ option items ------</div> */}
                {
                  keys(subOption.items()).map(optionItem => {
                    
                    return (
                    <FormGroup style={{ marginLeft: '30px' }}>
                      <FormControlLabel 
                        label={optionItem}
                        control={
                          <Checkbox 
                            color='primary'
                            name={optionItem}
                            checked={
                              !((this.state.checkedOptions[subOption.id()] || {})['items'] || {})[optionItem]
                            }
                            onChange={e => {
                                const a = this.state.checkedOptions
                                const u = set(a, {
                                  [`${subOption.id()}.items.${optionItem}`]: !e.target.checked
                                })
                                
                                this.setState({ checkedOptions: u })
                                // kernel.performUpdates([ 
                                //   subMenuItem
                                //     [e.target.checked ? 'enableOptionItemForOption' : 'disableOptionItemForOption']
                                //     ({ optionId: subOption.id(), optionItemId: optionItem }) 
                                // ])
                              }
                            }
                          />
                        }
                      />
                    </FormGroup>
                  )})
                }
                {/* <div>--------------------------</div> */}
              </div>
            ))
          }
          {/* {subsGlobalMenu.members.options.asArray().map(subOption => (
            <div key={subOption.id()}>
              <FormGroup>
                <FormControlLabel
                  label={subOption.label()}
                  control={
                    <Checkbox
                      color='primary'
                      name={subOption.label()}
                      checked={subOption.enabled()}
                      onChange={e => e.target.checked ? subOption.enable() : subOption.disable()}
                    />
                  }
                />
              </FormGroup>
            </div>
          ))} */}
        </Modal>
      </div>
    )
  }
})
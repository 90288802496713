import { createNodeClass } from '@blast-engine/firework'

export const UserBasic = createNodeClass({
  name: 'UserBasic',

  struct: class {

    id() {
      return this._data('userId')
    }

    username() {
      return this._data('username') || '(name missing)'
    }

    userId() {
      return this.id()
    }
    
  },

  ref: class {

    initialize({ id, username, department = null }) {
      return this._update({ userId: id, username, department })
    }

  },

  full: class { 
    
    setUsername({ username }) {
      return this._update({ username })
    }

    setEmail({ email }) {
      return this._update({ email })
    }
    
    department() {
      return this._data('department')
    }

    setDepartment(department) {
      return this._update({ department })
    }

    hasProvidedPhoneNumber() {
      return this._data('hasProvidedPhoneNumber') || false
    }

    setHasProvidedPhoneNumber(hasProvidedPhoneNumber) {
      return this._update({ hasProvidedPhoneNumber })
    }

  }
})
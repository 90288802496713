import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { BasketItemComplete } from './basket-item-complete.assembly'
import { craftMenu } from './craft-global-menu'
import { subsGlobalMenu } from './subs-global-menu'
import { job } from './job'
import { user } from '../../user'
import { NotificationsQueue } from '../../notifications'
import { JobSubOrdersComplete } from './job-sub-orders.assembly'

export const JobOrdersComplete = createAssemblyClass({
  name: 'JobOrdersComplete',
  memberModels: {
    craftGlobalMenu: craftMenu.CrafterCraftGlobalMenu,
    craftOrders: job.jobData.craftOrders.CraftOrderList.full(),
    notificationsQueueRef: NotificationsQueue.ref()
  },
  portMethods: {
  
  },
  body: class {

    _constructor({ crafterId, jobId, currentDay }) {
      this._crafterId = crafterId
      this._jobId = jobId
      this._currentDay = currentDay
    }

    orderIds() {
      return this.members.craftOrders.keys()
    }

    completeOrder({ orderId }) {
      return [
        this.members.craftOrders.item(orderId).setStatusAsDone(),
        this.members.craftOrders.item(orderId).updateDoneTimestamp()
      ]
    }

    setOrderAsReady({ orderId }) {
      return [
        this.members.craftOrders.item(orderId).setStatusAsReady(),
        this.members.notificationsQueueRef.add({ 
          initArgs: { 
            type: 'craft-order-complete',
            args: { 
              crafterId: this._crafterId,
              jobId: this._jobId,
              day: this._currentDay,
              craftOrderId: orderId 
            } 
          } 
        })
      ]
    }

    completeSubOrder({ subId }) {
      
      return [ 
        ...this.members.subOrders.members.subOrdersForDay.setSubOrderAsDone({ subId }),
        this.members.notificationsQueueRef.add({
          initArgs: {
            type: 'sub-order-complete',
            args: {
              crafterId: this._crafterId,
              jobId: this._jobId,
              day: this._currentDay
            }
          }
        }) 
      ]
    }

    perms() {
      const activeSubOrders = this.activeSubOrders()
      const duplicatePerms = activeSubOrders.reduce((acc, activeOrder) => {
        if (activeOrder.length === 1) {
          acc.push({
            orderNotes: activeOrder[0].subOrderItem.notes(),
            usernames: [activeOrder[0].userBasic.username()],
            orderSubId: activeOrder[0].order.id(),
            orderType: activeOrder[0].orderType,
            orderName: activeOrder[0].order.name(),
            orderOptions: activeOrder[0].options.map(option => ({ optionName: option.option.label(), optionItems: option.selectedOptionItems })),
          })
          return acc
        }
        
        for (let i = 0; i < activeOrder.length ; i++) {
          let userNames = [ activeOrder[i].userBasic.username() ]
          const o1Name = activeOrder[i].order.name()
          const o1Options = activeOrder[i].options.map(option => ({ optionName: option.option.label(), optionItems: option.selectedOptionItems }))
          const o1Notes = activeOrder[i].subOrderItem.notes()
          for (let j = i + 1; j < activeOrder.length; j++) {
            const o2Name = activeOrder[j].order.name()
            const o2Options = activeOrder[j].options.map(option => ({ optionName: option.option.label(), optionItems: option.selectedOptionItems }))
            const o2Notes = activeOrder[j].subOrderItem.notes()
            if (o2Name === o1Name && !o2Notes && !o1Notes && JSON.stringify(o2Options) === JSON.stringify(o1Options)){
              userNames.push(activeOrder[j].userBasic.username())
            }
          }
          const exists = acc.find(i => i.orderName === o1Name && !i.orderNotes && !o1Notes && JSON.stringify(i.orderOptions) === JSON.stringify(o1Options))
          if (exists) {
            exists.usernames = [...new Set(exists.usernames.concat(userNames))]
          }
          else
            acc.push({
              usernames: userNames,
              orderName: o1Name,
              orderNotes: o1Notes,
              orderType: activeOrder[i].orderType,
              orderSubId: activeOrder[i].order.id(),
              orderOptions: o1Options
            })
        }
        return acc
      }, [])

      return [...new Set(duplicatePerms.map(i => JSON.stringify(i)))].map(i => JSON.parse(i))
    }

    activeSubOrders() {
      const { userBasics, subGlobalMenu, subOrders } = this.members
      
      return subOrders.members.subMenuItemListForDay.asArray().map(subMenuItem => 
        subOrders.members.subOrdersForDay.subOrders({ subId: subMenuItem.id() }).asArray()
          .filter(subOrderItem => (subOrderItem.status() === 'waiting' || !subOrderItem.status()))
          .map(
            (subOrderItem) => ({
              // userBasic: userBasics.item(subOrderItem.userId()),
              userName: 'test-name',
              userDept: 'test-dept',
              order: subGlobalMenu.members.items.item(subOrderItem.subId()),
              subOrderItem,
              orderType: subMenuItem.label(),
              options: subOrderItem.selectionOptionIds().map(optionId => ({
                option: subGlobalMenu.members.options.item(optionId),
                selectedOptionItems: subOrderItem.selectionOptionItemIdsForOptionId(optionId)
              }))
            
            })
          )
        )
    }

    pastCraftOrders({ limit = 10 } = {}) {
      const { userBasics, craftGlobalMenu, craftOrders } = this.members
      const allOrders = craftOrders.asArray()
        .filter(craftOrder => !craftOrder.isPending())
        .map(craftOrder => ({
          userId: craftOrder.get('userId'),
          userName: craftOrder.get('userName'),
          userDept: craftOrder.get('userDept'),
          userProvidedPhoneNumber: craftOrder.get('userProvidedPhoneNumber'),
          order: craftOrder,
          notes: craftOrder.basket().notes() || '',
          timestamp: craftOrder.timestamp(),
          items: craftOrder.basket().items().map(basketItem => ({
            basketItem,
            item: craftGlobalMenu.item(basketItem.craftItemId()),
            options: basketItem.selectionOptionIds().map(optionId => ({
              option: craftGlobalMenu.members.options.item(optionId),
              selectedOptionItems: basketItem.selectionOptionItemIdsForOptionId(optionId).map(optionItemId => 
                craftGlobalMenu.members.options.item(optionId).optionItemLabel(optionItemId))
            }))
          }))
        }))

      return allOrders.slice(Math.max(allOrders.length - limit, 0))

    }

    pendingOrders() {
      const { userBasics, craftGlobalMenu, craftOrders } = this.members
      return craftOrders.asArray()
        .filter(craftOrder => craftOrder.isPending())
        .map(craftOrder => ({
          userId: craftOrder.get('userId'),
          userName: craftOrder.get('userName'),
          userDept: craftOrder.get('userDept'),
          userProvidedPhoneNumber: craftOrder.get('userProvidedPhoneNumber'),
          timestamp: craftOrder.timestamp(),
          order: craftOrder,
          notes: craftOrder.basket().notes(),
          items: craftOrder.basket().items().map(basketItem => ({
            basketItem,
            item: craftGlobalMenu.item(basketItem.craftItemId()),
            options: basketItem.selectionOptionIds().map(optionId => ({
              option: craftGlobalMenu.members.options.item(optionId),
              selectedOptionItems: basketItem.selectionOptionItemIdsForOptionId(optionId).map(optionItemId => 
                craftGlobalMenu.members.options.item(optionId).optionItemLabel(optionItemId))
            }))
          }))
        }))
    }

  }
})
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { withRouter } from 'react-router-dom'
import { TextField, Button, Fab } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { Modal, List, Divider } from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './office-jobs-list.scss'

export const OfficeJobsList = withRouter(fireworkConnect(props => ({
  crafterManager: entities.fetchCrafterManager({ crafterId: props.crafterId })
}))(class OfficeJobsList extends React.Component {
  state = {
    newJobName: '',
    newJobId: '',
    error: '',
    jobStatuses: {},
    isAddNewJobModalVisible: false
  }

  componentDidUpdate(prevProps, prevState) {
    const { crafterManager, crafterId } = this.props
    if (!isReady(crafterManager, prevProps.crafterManager)) return null
    if(!this.state.ran) {
      const jobStatuses = {}
      prevProps.crafterManager.members.allCrafterJobBasics.asArray().forEach(jobBasic => {
        if ((jobBasic.status() !== crafterManager.members.allCrafterJobBasics.item(jobBasic.id()).status()) || this.state[jobBasic.id()] !== jobBasic.status()) {
          jobStatuses[jobBasic.id()] = crafterManager.members.allCrafterJobBasics.item(jobBasic.id()).status()
        }
      })
      if (Object.keys(jobStatuses).length) this.setState({ jobStatuses, ran: true })
    }

    

  }

  addJob() {
    const { crafterManager } = this.props
    const { newJobName, newJobId } = this.state
    if (!newJobId.length || !newJobName.length) return this.setState({ error: 'Job Id or Job Name cannot be empty' })
    try {
      const updates = crafterManager.addJob({ name: newJobName, id: newJobId })
      kernel.performUpdates(updates)
      this.setState({ newJobId: '', newJobName: '', isAddNewJobModalVisible: false })
    } catch(e) {
      this.setState({ error: e.message })
    }
  }

  render() {
    const { crafterManager } = this.props
    const { newJobName, newJobId, error, isAddNewJobModalVisible } = this.state
  
    if (!isReady(crafterManager)) return null
    
    return (
      <div className='office-jobs-list-container'>
        <Modal
          centered
          title='Add New Job'
          visible={isAddNewJobModalVisible}
          onOk={() => this.addJob()}
          onCancel={() => this.setState({ isAddNewJobModalVisible: false })}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }} className='new-job-modal-body'>
            <TextField required onKeyPress={e => e.key === 'Enter' && this.addJob()} required style={{ marginBottom: '1vh' }} variant='outlined' label='Job Name' value={newJobName} onChange={e => this.setState({ newJobName: e.target.value })} />
            <TextField required onKeyPress={e => e.key === 'Enter' && this.addJob()}    required style={{ marginBottom: '1vh' }} variant='outlined' label='Job ID' value={newJobId} onChange={e => this.setState({ newJobId: e.target.value })} />
            <div style={{ width: '100%', textAlign: 'center', color: '#ec2e4d' }}>{error}</div>
          </div>
        </Modal>
        {
          !crafterManager.members.allCrafterJobBasics.asArray().length ?
            <div className='no-jobs'>
              ready to start?
            </div>
          :
            <div className='jobs-list'>
              <Divider orientation='left' className='job-list-text'>Job List</Divider>
              <List
                size='large'
                className='armiin'
                bordered
                dataSource={crafterManager.members.allCrafterJobBasics.asArray()}
                renderItem={jobBasic => (
                  <List.Item onClick={() => this.props.history.push(`/craft-office/jobs/${jobBasic.id()}`)} className={`each-job ${jobBasic.status()}`}>
                    <div>
                      {jobBasic.name()}
                      <span className='job-id'>{' - '}{jobBasic.id()}</span>
                    </div>
                    
                    <div className='buttons-section'>
                      <div 
                        className={`each-job-action current-state ${jobBasic.status()}`}
                      >
                        
                      </div>
                      {
                        <Button 
                          variant='outlined' 
                          className={`each-job-action next-state ${jobBasic.status()}`}
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            kernel.performUpdates([ jobBasic.updateStatus({ status: jobBasic.status() === 'not-started' ? 'active' : (jobBasic.status() === 'active' ? 'finished' : 'active') }) ])
                          }}
                        >
                          {
                            jobBasic.status() === 'not-started' && 'Activate Job' 
                          }
                          {
                            jobBasic.status() === 'active' && 'Finish Job' 
                          }
                          {
                            jobBasic.status() === 'finished' && 'Re-activate Job'
                          }
                        </Button>
                      }
                      
                    </div>
                    
                  </List.Item>
                )}
              />
            </div>
        }
        
        {/* {
          crafterManager.members.allCrafterJobBasics.asArray().map(jobBasic => (
            <div key={jobBasic.id()}>
              <Link to={`jobs/${jobBasic.id()}`}>{jobBasic.name()}</Link>
              <select value={this.state.jobStatuses[jobBasic.id()]} onChange={e => {
                const js = this.state.jobStatuses
                js[jobBasic.id()] = e.target.value
                this.setState({ jobStatuses: js })
                
                const update = this.props.crafterManager.members.allCrafterJobBasics.item(jobBasic.id()).updateStatus({ status: e.target.value })
                kernel.performUpdates([update])
                
              }}>
                {
                  jobBasic.jobStatuses().map(status => <option value={status}>
                    {status}
                  </option>)
                }
              </select>
            </div>
          ))
        } */}
        <Fab variant='extended' className='fab' aria-label='add' onClick={() => this.setState({ isAddNewJobModalVisible: true })}>
          <AddIcon />
          Add New Job
        </Fab>
        {/* <div>
          add Job:
          <input placeholder='job name' value={newJobName} onChange={e => this.setState({ newJobName: e.target.value })} />
          <input placeholder='job id' value={newJobId} onChange={e => this.setState({ newJobId: e.target.value })} />
          <button onClick={() => this.addJob()} >ADD</button>
          <div style={{ color: 'red' }}>{error}</div>
        </div> */}
      </div>
    )
  }
}))
import { CrafterBasic } from './crafter-basic.node'
import { CrafterBasicList } from './crafter-basic.list'
import { crafterData } from './crafter-data'
import { crafter } from '.'
import { craftMenu } from './crafter-data/craft-global-menu'

export const rootMethods = {

  crafterBasicRef({ crafterId }) {
    return this._spinoff(CrafterBasic.ref(), {
      path: `crafter/crafterBasic/${crafterId}`
    })
  },

  crafterBasicListRef() {
    return this._spinoff(CrafterBasicList.ref(), {
      path: `crafter/crafterBasic`
    })
  },

  ...crafterData.crafterMember.rootMethods,
  ...crafterData.job.rootMethods,
  ...crafterData.job.jobData.rootMethods,
  ...crafterData.job.jobData.basket.rootMethods,
  ...crafterData.job.jobData.craftOrders.rootMethods,
  ...crafterData.job.jobData.subOrders.rootMethods,
  ...crafterData.job.jobData.breakfastOrders.rootMethods,
  ...crafterData.craftMenu.rootMethods,
  ...crafterData.subsGlobalMenu.rootMethods,
  ...crafterData.breakfastGlobalMenu.rootMethods,
  ...crafterData.lunchGlobalMenu.rootMethods,
  ...crafterData.activeCrewOnSite.rootMethods,
  ...crafterData.job.jobData.pausedService.rootMethods

}
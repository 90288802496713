import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'
import LogRocket from 'logrocket'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'
import { relativeLocation, routerPrefix } from './router-utils'
import { Footer } from './footer.connected'
import { Header } from './header.connected'
import { HomePage } from './home'
import { CraftPage, CraftItemPage, CraftCategoryPage } from './craft'
import { BasketPage } from './basket'
import { OrdersPage } from './orders'
import { SubsPage, SubItemPage } from './subs'
import { BreakfastPage, BreakfastItemPage } from './breakfast'
import { BigLoading } from '../big-loading'

let jobId = null

export const AppContentRouter = 
  withRouter(
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => {
    const _ = {
      basket: entities.crafter.crafterData.fetchBasketComplete({
        keepOldResultUntilNew: true,
        crafterId: currentJob.crafterId(),
        jobId: currentJob.id(),
        userId: me.userId()
      }),
      craftOrders: entities.crafter.crafterData.fetchCraftOrdersComplete({
        keepOldResultUntilNew: true,
        crafterId: currentJob.crafterId(),
        jobId: currentJob.id(),
        userId: me.userId()
      })
    }
    if (!isReady(me) || !me.currentJob()) return { ..._ }
    return { ..._, currentJob: entities.crafter.crafterData.job.fetchJobBasic(me.currentJob()) }
  })(
class AppContentRouter extends React.Component {

  state = {}

  render() {
    const { me, currentJob, match, location, Content, history, basket, craftOrders } = this.props

    const prefix = routerPrefix(match)
    const relloc = relativeLocation(location, prefix)

    if (!isReady(me, currentJob, basket, craftOrders)) return <BigLoading/>

    if (jobId !== currentJob.id()) {
      jobId = currentJob.id()
      console.log(`JOB: ${currentJob.id()}`)
      LogRocket.log(`JOB: ${currentJob.id()}`)
      LogRocket.track(`JOB: ${currentJob.id()}`)
    }

    const goToHome = () => history.push(prefix)
    const goToBasket = () => history.push(`${prefix}/basket`)
    const goToOrders = () => history.push(`${prefix}/orders`)
    const goToCraft = () => history.push(`${prefix}/craft`)
    const goToSubs = () => history.push(`${prefix}/subs`)
    const goToBreakfastPreorders = () => history.push(`${prefix}/breakfast-preorders`)
    const goToSubItem = ({ subId, dayNum }) => history.push(`${prefix}/subs/item/${dayNum}/${subId}`)
    const goToBreakfastItem = ({ breakfastId, dayNum }) => history.push(`${prefix}/breakfast/item/${dayNum}/${breakfastId}`)
    const goToCategory = categoryId => history.push(`${prefix}/craft/category/${categoryId}`)
    const goToCraftItem = itemId => history.push(`${prefix}/craft/item/${itemId}`)
    const goBack = () => history.goBack()

    let pageTitle
    let showBackButton = true
    let pageData  
    if (relloc === '/basket') {
      pageTitle = 'Basket'
    } else if (relloc === '/orders') {
      pageTitle = 'Orders'
    } else if (relloc === '/craft') {
      pageTitle = 'Craft'
    } else if (['craft', 'category'].every(x => relloc.includes(x))) {
      let x = relloc.split('/')
      pageTitle = '<craft-category>'
      pageData = { categoryId: x[x.length - 1] }
    } else if (['craft', 'item'].every(x => relloc.includes(x))) {
      let x = relloc.split('/')
      pageTitle = '<craft-item>'
      pageData = { itemId: x[x.length - 1] }
    } else if (['subs', 'item'].every(x => relloc.includes(x))) {
      pageTitle = 'Place Order'
    } else if (relloc === '/subs') {
      pageTitle = 'Catering'
    } else if (relloc === '/breakfast-preorders') {
      pageTitle = 'Catering'
    } else if (relloc === '') {
      pageTitle = null
      showBackButton = false
    }

    return (
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
        <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: '10vh', height: '10vh'}}>
          <Header
            me={me}
            currentJob={currentJob}
            pageTitle={pageTitle}
            pageData={pageData}
            showBack={showBackButton}
            goBack={goBack}
          />
        </div>
        <div style={{ flex: 1, minHeight: 0 }}>
          <Switch>

            <Route 
              path={`${prefix}/craft/item/:itemId`}
              render={({ match }) => {
                const itemId = match.params.itemId
                return (
                  <CraftItemPage 
                    goToCraft={goToCraft}
                    goToBasket={goToBasket}
                    me={me}
                    currentJob={currentJob}
                    itemId={itemId}
                  />
                )
              }}
            />

            <Route 
              path={`${prefix}/craft/category/:categoryId`}
              render={({ match }) => {
                const categoryId = match.params.categoryId
                return (
                  <CraftCategoryPage 
                    me={me}
                    currentJob={currentJob}
                    categoryId={categoryId}
                    goToItem={goToCraftItem}
                  />
                )
              }}
            />

            <Route path={`${prefix}/craft`}>
              <CraftPage
                me={me}
                currentJob={currentJob}
                goToCategory={goToCategory}
              />
            </Route>        

            <Route 
              path={`${prefix}/subs/item/:dayNum/:itemId`}
              render={({ match }) => {
                const dayNum = match.params.dayNum
                const itemId = match.params.itemId
                return (
                  <SubItemPage 
                    me={me}
                    currentJob={currentJob}
                    subId={itemId}
                    dayNum={dayNum}
                    goToCatering={goToSubs}
                  />
                )
              }}
            />


            <Route 
              path={`${prefix}/breakfast/item/:dayNum/:itemId`}
              render={({ match }) => {
                const dayNum = match.params.dayNum
                const itemId = match.params.itemId
                return (
                  <BreakfastItemPage 
                    me={me}
                    currentJob={currentJob}
                    breakfastId={itemId}
                    dayNum={dayNum}
                    goToCatering={goToBreakfastPreorders}
                  />
                )
              }}
            />

            <Route path={`${prefix}/subs`}>
              <SubsPage
                me={me}
                currentJob={currentJob}
                goToItem={goToSubItem}
              />
            </Route>

            <Route path={`${prefix}/basket`}>
              <BasketPage
                me={me}
                goToCraft={goToCraft}
                goToOrders={goToOrders}
                currentJob={currentJob}
              />
            </Route>

            <Route path={`${prefix}/breakfast-preorders`}>
              <BreakfastPage
                me={me}
                currentJob={currentJob}
                goToItem={goToBreakfastItem}
              />
            </Route>

            <Route path={`${prefix}/orders`}>
              <OrdersPage
                me={me}
                goToCraft={goToCraft}
                currentJob={currentJob}
                goToHome={goToHome}
              />
            </Route>

            <Route path={prefix}>
              <HomePage
                me={me}
                currentJob={currentJob}
                goToCraft={goToCraft}
                goToSubs={goToSubs}
                goToBreakfastPreorders={goToBreakfastPreorders}
              />
            </Route>       

          </Switch>
        </div>
        <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: '68px',  height: '68px'}}>
          <Footer
            me={me}
            currentJob={currentJob}
            goToHome={goToHome}
            goToOrders={goToOrders}
            goToBasket={goToBasket}
          />
        </div>
      </div>
    )
  }
})))
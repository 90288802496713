import { createFetcher } from '@blast-engine/firework'

import { JobCrewUsersComplete } from './job-crew-users.assembly'

export const fetchJobCrewUsers = createFetcher({
  name: 'fetch-job-crew-users'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'jobCrewUsers',
    query: ({ root }) => root.jobCrewUsersRef({ crafterId, jobId }).query()
  }, {
    name: 'userBasics',
    requires: [ 'jobCrewUsers' ],
    query: ({ root, jobCrewUsers }) => root.userBasicSelectionRef({ 
      userIds: jobCrewUsers.recentUserIds()
    }).query()
  }],
  final: {
    take: [ 'jobCrewUsers', 'userBasics' ],
    instantiate: ({ root, jobCrewUsers, userBasics }) => root._spinoff(JobCrewUsersComplete, {
      members: { jobCrewUsers, userBasics }
    })
  }
}))
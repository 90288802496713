import { fetchUserBasicListRef } from './user-basic.list.ref.fetcher'
import { fetchUserPrivateListRef } from './user-private.list.ref.fetcher'

export const registerUser = async ({ username, email, id, snap, performUpdates }) => {
  const userBasics = await snap(fetchUserBasicListRef())
  const userPrivates = await snap(fetchUserPrivateListRef())

  return performUpdates([
    userBasics.add({ initArgs: { username }, newItemId: id }),
    userPrivates.add({ initArgs: { email }, newItemId: id })
  ])
}
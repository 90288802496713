import React from 'react'
import { TextField, CircularProgress } from '@material-ui/core'
import CheckCircleOutlineTwoToneIcon from '@material-ui/icons/CheckCircleOutlineTwoTone';
import { Modal } from 'antd'

import { Button } from '../button'
import * as Comps from '@/components'

import './main-cta-desktop.scss'

export const MainCtaDesktop = class MainCtaDesktop extends React.Component {
  state = {
    scheduleDemoInfo: {},
    scheduleDemoModalOpen: false,
    scheduleDemoState: 'requested',
    errors: {
      email: null,
      name: null,
      phoneNumber: null
    }
  }

  changeScheduleDemoInfoState({ 
    name = this.state.scheduleDemoInfo.name, 
    email = this.state.scheduleDemoInfo.email, 
    phoneNumber = this.state.scheduleDemoInfo.phoneNumber, 
    companyName = this.state.scheduleDemoInfo.companyName, 
    message = this.state.scheduleDemoInfo.message, 
    country = this.state.scheduleDemoInfo.country
  }) {
    this.setState({ scheduleDemoInfo: { name, email, phoneNumber, companyName, message, country } })
  }

  changeErrorsState({
    name = this.state.errors.name,
    email = this.state.errors.email,
    phoneNumber = this.state.errors.phoneNumber
  }) {
    this.setState({ errors: { name, email, phoneNumber } })
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const {} = this.props
    const { scheduleDemoModalOpen, scheduleDemoInfo, errors, scheduleDemoState } = this.state
    
    return (
      <div className='main-cta-desktop-container'>
        <Modal
          title='Schedule A Demo'
          visible={scheduleDemoModalOpen}
          okText={this.state.scheduleDemoState  !== 'requested' ? 'ok' : 'Submit'}
          onOk={async () => {
            if (scheduleDemoState === 'success') this.setState({ scheduleDemoModalOpen: false })
            if (Object.values(errors).filter(i => i).length) {
              return;
            }
            if (!scheduleDemoInfo.name || !scheduleDemoInfo.name.length ||
              !scheduleDemoInfo.email || !scheduleDemoInfo.email.length ||
              !scheduleDemoInfo.phoneNumber || !scheduleDemoInfo.phoneNumber.length) 
            {
              return
            }
            this.setState({ scheduleDemoState: 'loading' })
            const httpApiUrl = process.env.FIREBASE_HTTP_FUNCTIONS_SERVER_URL
            const sendEmailEndpoint = `${httpApiUrl}/api/send-email`
            const res = await fetch(sendEmailEndpoint, {
              mode: 'cors',
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.state.scheduleDemoInfo)
            })
            if (res.ok) {
              this.setState({ 
                scheduleDemoState: 'success',
                scheduleDemoInfo: {}, 
                errors: {
                  email: null,
                  name: null,
                  phoneNumber: null
                }
              })
            }
            else {
              this.setState({ scheduleDemoState: 'failed' })
            }
            
          }}
          onCancel={() => this.setState({ 
            scheduleDemoInfo: {}, 
            scheduleDemoModalOpen: false,
            errors: {
              email: null,
              name: null,
              phoneNumber: null
            }
          })}
        >
          {
            (this.state.scheduleDemoState === 'requested' || this.state.scheduleDemoState === 'failed') ?
            <>
              <TextField variant='outlined' value={scheduleDemoInfo.name || ''}
                fullWidth
                onChange={e => this.changeScheduleDemoInfoState({ name: e.target.value })}
                label='Name'
                required
                margin='normal'
                type='text'
                error={!!errors.name}
                onBlur={() => {
                  if (!scheduleDemoInfo.name || !scheduleDemoInfo.name.length) {
                    this.changeErrorsState({ name: 'Name cannot be empty' })
                  }
                  else {
                    this.changeErrorsState({ name: null })
                  }
                }}
              />
              <TextField variant='outlined' value={scheduleDemoInfo.email || ''}
                fullWidth
                onChange={e => this.changeScheduleDemoInfoState({ email: e.target.value })}
                label='Email'
                required
                margin='normal'
                type='email' 
                error={!!errors.email}
                onBlur={() => {
                  if (!scheduleDemoInfo.email || !scheduleDemoInfo.email.length) {
                    this.changeErrorsState({ email: 'Email cannot be empty' })
                  }
                  else if (!this.validateEmail(scheduleDemoInfo.email)) {
                    this.changeErrorsState({ email: 'Email address is badly formatted' })
                  }
                  else {
                    this.changeErrorsState({ email: null })
                  }
                }}
              />
              <TextField variant='outlined' value={scheduleDemoInfo.phoneNumber || ''}
                fullWidth
                onChange={e => this.changeScheduleDemoInfoState({ phoneNumber: e.target.value })}
                label='Phone Number'
                required
                margin='normal'
                type='tel'
                error={!!errors.phoneNumber}
                onBlur={() => {
                  if (!scheduleDemoInfo.phoneNumber || !scheduleDemoInfo.phoneNumber.length) {
                    this.changeErrorsState({ phoneNumber: 'Phone number cannot be empty' })
                  }
                  else {
                    this.changeErrorsState({ phoneNumber: null })
                  }
                }}
              />
              <TextField variant='outlined' value={scheduleDemoInfo.companyName || ''}
                fullWidth
                onChange={e => this.changeScheduleDemoInfoState({ companyName: e.target.value })}
                label='Company Name'
                margin='normal'
                type='text'
              />
              <TextField variant='outlined' value={scheduleDemoInfo.country || ''}
                fullWidth
                onChange={e => this.changeScheduleDemoInfoState({ country: e.target.value })}
                label='Country'
                margin='normal'
                type='text'
              />
              <TextField variant='outlined' value={scheduleDemoInfo.message || ''}
                fullWidth
                onChange={e => this.changeScheduleDemoInfoState({ message: e.target.value })}
                label='Message'
                margin='normal'
                multiline={true}
                rows={3}
                type='text'
              />
              <div className='error-section'>
                {
                  Object.values(errors).filter(i => i).map(i => (
                    <div key={i}>{i}</div>
                  ))
                }
                { this.state.scheduleDemoState === 'failed' ? <div>Submission failed. Please try again.</div> : null }
              </div>
            </>
            :
            this.state.scheduleDemoState === 'loading' ? 
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress color='primary' size={40}/>
            </div>
            :
            <>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <div className='success-header'>
                  Thanks for reaching out to us
                </div>
                <div className='success-body'>
                  We are glad to hear from you! One of our executives will get back with you shortly.
                </div>
              </div>
              
            </>
          }
        </Modal>
        <div className='camera-section'>
          <img className='camera-image' src={require('../../../assets/camera.png')} />
        </div>
        <div className='main-cta'>
          Feeding your crew is now safe and easy.
        </div>
        <div className='tagline'>
        Crafty Film is an app designed <br />
        to safely order craft and catering on-set.
        </div>
        <Button
          buttonText='Schedule A Demo'
          onClick={() => this.setState({ scheduleDemoModalOpen: true })}
          containerStyle={{ marginTop: '7vh' }}
        />
      </div>
    )
  }
}
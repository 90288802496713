import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { crafter } from './crafter'
import { user } from './user'
import { jobRegistry } from './job-registry'
import { invites } from  './invites'

export const CrafterManager = createAssemblyClass({
  name: 'CrafterManager',
  memberModels: {
    crafterBasic: crafter.CrafterBasic.full(),
    crafterMembers: crafter.crafterData.crafterMember.CrafterMemberBasicList.full(),
    crafterMemberUserBasics: user.UserBasicSelection.full(),
    allCrafterJobBasics: crafter.crafterData.job.JobBasicList.full(),
    jobRegistry: jobRegistry.JobRegistry.full(),
    userEmails: user.UserEmailList.ref(),
    invitesListRef: invites.Invites.ref()
  },
  portMethods: {
    crafterBasic: [ 'id', 'name' ]
  },
  body: class {

    crafterMembers() {
      return this.members.crafterMembers.asArray().map(crafterMemberBasic => ({
        crafterId: this.members.crafterBasic.id(),
        role: crafterMemberBasic.role(),
        user: this.members.crafterMemberUserBasics.item(crafterMemberBasic.id())
      }))
    }

    getUserIdFromEmail({ email }) {
      const encoded = email.replace(/\./g, '__dot__')
      const user = this.members.userEmails.item(encoded)
      if (!user) return null
      return user.id()
    }

    addCrafterMember({ userId, role }) {
      return this.members.crafterMembers.add({ newItemId: userId, initArgs: { role } })
    }

    inviteUserToCrafter({ crafterId, userId, role = 'member' }) {
      return this.members.invitesListRef.add({ initArgs: { userId, crafterId, role }, newItemId: userId })
    }

    addJob({ name, id }) {
      if (this.members.jobRegistry.item(id)) throw new Error('Job ID Already Exists.')
      return [
        this.members.jobRegistry.add({
          newItemId: id,
          initArgs: {
            id,
            crafterId: this.id(),
            name
          }
        }),
        this.members.allCrafterJobBasics.add({
          newItemId: id,
          initArgs: {
            status: 'not-started',
            name, 
            crafterId: this.id()
          }
        })
      ]
    }

    roleForUser({ userId }) {
      const cm = this.members.crafterMembers.item(userId)
      if (!cm) return null
      return cm.role()
    }

    crafterRoles() {
      return [
        'member',
        'admin'
      ]
    }
    
  }
})
import React from 'react'
import { Link } from 'react-router-dom'
import {} from '@material-ui/core'
import {} from 'antd'

import * as Comps from '@/components'

import './navbar-mobile.scss'

export const NavbarMobile = class NavbarMobile extends React.Component {
  state = {}

  render() {
    const { isTop, shouldHideLogin } = this.props
    const {} = this.state
    
    return (
      <div className={`navbar-mobile-container${isTop ? ' isTop' : ' isScrolling'}`}>
        <Link to='/' className='logo-section'>
          <img className={`logo${isTop ? ' isTop' : ' isScrolling'}`} src={require('../../../assets/Logo.png')} />
        </Link>
        {
          !shouldHideLogin &&
            <Link to='/signup' className='login-side'>
              <div className='login-icon-section'>
                <img className={`login${isTop ? ' isTop' : ' isScrolling'}`} src={require('../../../assets/login.png')} />
              </div>
              <div className={`login-button${isTop ? ' isTop' : ' isScrolling'}`}>
                register
              </div>
            </Link>
        }
      </div>
    )
  }
}
import { createFetcher } from '@blast-engine/firework'

export const fetchSubMenuItemList = createFetcher({
  name: 'fetch-sub-menu-item-list'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'list',
    requires: [ ],
    query: ({ root }) => 
      root.crafterJobSubMenu({ crafterId, jobId }).query()
  }],
  final: {
    take: [ 'list' ],
    instantiate: ({ list }) => list
  }
}))
import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'
import './home-page.scss'

export const HomePage = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    config: entities.fetchConfig()
  }))(
class HopePage extends React.PureComponent {

  state = {
    disabledCateringJobCodes: [],
    disabledCraftJobCodes: []
  }

  async componentDidMount() {
    const disabledCateringJobCodes = Object.keys((await (kernel.firebase.database().ref('/disabledCateringJobCodes').once('value'))).val() || {})
    this.setState({ disabledCateringJobCodes })
    const disabledCraftJobCodes = Object.keys((await (kernel.firebase.database().ref('/disabledCraftJobCodes').once('value'))).val() || {})
    this.setState({ disabledCraftJobCodes })
  }

  render() {
    const { 
      // props
      me, currentJob, config,
      goToCraft = () => null,
      goToSubs = () => null,
      goToBreakfastPreorders = () => null
    } = this.props

    if (!isReady(me, currentJob, config)) return null

    const shouldShowLunchReminder = [
      'FD4', 'GW7', 'Joy', 'KC2020', 'LD2020',
      'PA', 'SO', 'TB2020', 'WM5', 'Demo123'
    ].includes(currentJob.id())

    const isLunchDisabled = 
      ['LK2', 'V1', 'NB', 'SK', 'D3', 'YLM', 'AR2020', 'AROFFICE', 'BMF'].includes(currentJob.id()) 
      || this.state.disabledCateringJobCodes.includes(currentJob.id())
      || config.get(`jobs.${currentJob.id()}.disableLunchButton`)

    return (
      <div className='home-page-container'>
        {/* <div>you're in job ____</div> */}
        <Button 
          className='each-button' 
          variant='outlined' 
          onClick={goToCraft}
          disabled={['PA'].includes(currentJob.id()) || this.state.disabledCraftJobCodes.includes(currentJob.id())}
        >craft</Button>
        {
          ['GW7'].includes(currentJob.id()) ? null : (
            <>
              <Button 
                className={'each-button ' + (shouldShowLunchReminder && 'lunch-reminder')}
                variant='outlined' 
                disabled={isLunchDisabled}
                onClick={goToSubs}
              >
                { 
                  shouldShowLunchReminder ? (
                    <div style={{ display: 'flex', padding: '3px', justifyContent: 'space-around', height: '100px', flexDirection: 'column', alignItems: 'center' }}>
                      <div>
                        { 
                          ['BMF'].includes(currentJob.id()) 
                            ? 'breakfast'
                            : 'lunch'
                        }
                      </div>
                      <div style={{ fontSize: '11px', color: 'red', fontWeight: 'bold' }}>
                        If you haven't ordered lunch for tomorrow yet, please do so by tonight.
                      </div>
                    </div>
                  ) : (
                    <div>
                      { 
                        ['BMF'].includes(currentJob.id()) 
                          ? 'breakfast'
                          : 'lunch'
                      }
                    </div>
                  )
                }
              </Button>
              {/* <Button 
                className='each-button'
                variant='outlined' 
                disabled={
                  !['Demo123', 'BMF'].includes(currentJob.id())
                  
                }
                onClick={goToBreakfastPreorders}
              >
                { 
                  ['BMF'].includes(currentJob.id()) 
                    ? 'lunch'
                    : 'breakfast'
                }
              </Button> */}
            </>
          )
        }
      </div>
    )
  }
}))
import { BreakfastGlobalBasicList } from './breakfast-global-basic.node.list'
import { BreakfastGlobalOptionList } from './breakfast-global-option.node.list'

export const rootMethods = {

  breakfastGlobalItemsRef({ crafterId }) {
    return this._spinoff(BreakfastGlobalBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/breakfastGlobalMenu/items`
    })
  },

  breakfastGlobalOptionsRef({ crafterId }) {
    return this._spinoff(BreakfastGlobalOptionList.ref(), {
      path: `crafter/crafterData/${crafterId}/breakfastGlobalMenu/options`
    })
  }

}
import { createFetcher } from '@blast-engine/firework'

export const fetchCrafterJobDays = createFetcher({
  name: 'fetch-crafter-job-days'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'days',
    requires: [ ],
    query: ({ root }) => 
      root.crafterJobDays({ crafterId, jobId }).query()
  }],
  final: {
    take: [ 'days' ],
    instantiate: ({ days }) => days
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchBreakfastPreorders = createFetcher({
  name: 'fetch-breakfast-preorders'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'menu',
    query: ({ root }) => root.breakfastPreorderMenu({ crafterId, jobId }).query()
  }],
  final: {
    take: [ 'menu' ],
    instantiate: ({ menu }) => menu
  }
}))
import { JobRegistry } from './job-registry.list'

export const rootMethods = {

  jobRegistryRef() {
    return this._spinoff(JobRegistry.ref(), {
      path: `jobRegistry`
    })
  }

}
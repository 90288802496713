import { createMixableClass } from '@blast-engine/mixable'
import { Model } from '@blast-engine/firework'

// import { job } from './job'
import { Config } from './config.node'
import { user } from './user'
import { jobRegistry } from './job-registry'
import { crafty } from './crafty'
import { crafter } from './crafter'
import { invites } from './invites'
import * as notifications from './notifications'
import * as textNotifications from './text-notifications'

export const Root = createMixableClass({
  name: 'Root',
  inherits: [ Model ],
  body: class {
    _constructor() {
      Object.assign(this, 
        // job.rootMethods,
        crafty.rootMethods,
        user.rootMethods,
        jobRegistry.rootMethods,
        crafter.rootMethods,
        invites.rootMethods,
        notifications.rootMethods,
        textNotifications.rootMethods
      )
    }

    configRef() {
      return this._spinoff(Config.ref(), {
        path: `config`
      })
    }
  }
})
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Checkbox, Button } from '@material-ui/core'
import { Divider } from 'antd'
import { kv } from '@blast-engine/utils'
import ReactToPdf from 'react-to-pdf'
import { sendTextMessage } from '@/text-messages.service'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-printable-meals.scss'

export const CraftOfficePrintableMeals = fireworkConnect((props) => ({
  job: entities.crafter.crafterData.job.fetchJobBasic({
    crafterId: props.crafterId,
    jobId: props.jobId,
  }),
  days: entities.crafter.crafterData.job.jobData.fetchCrafterJobDays({
    crafterId: props.crafterId,
    jobId: props.jobId,
  }),
  jobSubOrderComplete: entities.crafter.crafterData.fetchJobSubOrdersComplete({
    crafterId: props.crafterId,
    jobId: props.jobId,
    day: props.dayNum,
  }),
}))(
  class CraftOfficePrintableMeals extends React.Component {
    state = {
      shouldRun: true,
      selectedTimes: [],
      orderReadyTexts: {},
    }

    // componentDidUpdate() {
    //   const { jobSubOrderComplete, job } = this.props
    //   if (isReady(jobSubOrderComplete) && !this.state.haveRun) {
    //     const pickedupOrders =
    //     this.setState({ haveRun: true })
    //   }
    // }

    async sendOrderReadyText({ orderId, userId }) {
      const { crafterId, jobId } = this.props
      const { orderReadyTexts } = this.state

      if (!userId || !orderId) return

      this.setState({
        orderReadyTexts: { ...orderReadyTexts, [userId]: 'sending' },
      })

      await sendTextMessage({
        text: 'Your order is ready!',
        jobId,
        userId,
        debugData: { orderId, userId },
      })

      this.setState({
        orderReadyTexts: { ...orderReadyTexts, [userId]: 'done' },
      })
    }

    ref = React.createRef()

    componentWillMount() {
      if (this.props.pickupTime)
        this.state.selectedTimes.push(this.props.pickupTime)
    }

    render() {
      const { jobSubOrderComplete, job, days, jobId } = this.props
      const { selectedTimes, orderReadyTexts } = this.state

      if (!isReady(jobSubOrderComplete, days)) return <h1>loading..</h1>
      window.a = jobSubOrderComplete

      const orderTextStatus = (userId) => orderReadyTexts[userId] || null

      const _p =
        ['JR1', 'JR2', 'MOK'].includes(jobId)
          ? jobSubOrderComplete.permsWithPickupTimes({
              filterPickupTimes: selectedTimes,
            })
          : jobSubOrderComplete.perms({
              filterPickupTimes: selectedTimes,
            })

      const p = _p.reduce((acc, order) => {
        if (!acc[order.orderName]) acc[order.orderName] = { numOrders: 0 }
        acc[order.orderName]['numOrders'] += order.usernames.length
        if (
          order.orderNotes &&
          order.orderNotes.length > 0 &&
          !acc[order.orderName]['notes']
        )
          acc[order.orderName]['notes'] = []
        if (order.orderNotes && order.orderNotes.length > 0)
          acc[order.orderName]['notes'].push(order.orderNotes)
        if (
          order.pickupTime &&
          order.pickupTime.length > 0 &&
          !acc[order.orderName]['pickupTime']
        ) {
          acc[order.orderName]['pickupTime'] = []
        }
        if (order.pickupTime && order.pickupTime.length > 0)
          acc[order.orderName]['pickupTime'].push(order.pickupTime)

        return acc
      }, {})

      const salads = _p.reduce((acc, order) => {
        const saladOption = order.orderOptions.find((o) =>
          o.optionName.toLocaleLowerCase().includes('salad')
        )
        if (saladOption) {
          if (!acc[saladOption.optionItems[0]])
            acc[saladOption.optionItems[0]] = 0
          acc[saladOption.optionItems[0]] =
            acc[saladOption.optionItems[0]] + order.usernames.length
        }
        return acc
      }, {})

      const departments = _p.reduce((acc, order) => {
        order.users.forEach((user) => {
          // @TODO: This is an error hack!
          //     it might be producing a wrong result
          if (!user.userBasic.data) return
          const dep = user.department
          if (!acc[dep]) acc[dep] = { accNumber: 0, usernames: [] }
          if (acc[dep]['usernames'].find((u) => u === user.username)) return
          acc[dep]['accNumber']++
          acc[dep]['usernames'].push({
            userId: user.userBasic.data.userId,
            orderSubId: order.orderSubId,
            username: user.username,
            pickupTime: order.pickupTime,
            notes: order.orderNotes,
            orderName: order.orderName,
            orderOptions: order.orderOptions,
          })
        })
        return acc
      }, {})

      const markPersonAsDone = (userId, orderSubId) => {
        kernel.performUpdates([
          jobSubOrderComplete.markMealAsPickedUp({
            userId,
            subOrderId: orderSubId,
          }),
        ])
      }

      const markPersonAsUndone = (userId, orderSubId) => {
        kernel.performUpdates([
          jobSubOrderComplete.markMealAsNotPickedUp({
            userId,
            subOrderId: orderSubId,
          }),
        ])
      }

      window.dept = kv(departments)

      const day = days.item(this.props.dayNum)

      return (
        <div className="craft-office-printable-meals-container">
          <div className="navbar">
            <div className="logo-part">
              <img className="logo" src={require('../../assets/Logo.png')} />
            </div>
            <div className="job-part">
              {job.name()} - Day {Number(this.props.dayNum) + 1}
            </div>
          </div>
          {day.hasPickupTimes() ? (
            <div>
              <span>filter by pickup time:</span>
              {day.pickupTimes().map((pt) => (
                <span
                  key={pt}
                  style={{
                    padding: '10px',
                    fontWeight: 'bold',
                    color: selectedTimes.includes(pt) ? 'blue' : 'black',
                  }}
                  onClick={() => {
                    if (selectedTimes.includes(pt))
                      this.setState({
                        selectedTimes: selectedTimes.filter((st) => pt !== st),
                      })
                    else
                      this.setState({ selectedTimes: selectedTimes.concat(pt) })
                  }}
                >
                  {pt}
                </span>
              ))}
            </div>
          ) : null}
          <Divider orientation="center" className="divider meals-divider">
            Accumulated Meals
          </Divider>
          <div className="meals-section each-section">
            <div className="headers">
              <div className="meals section-name">Meals</div>
              <div className="num-orders">Number of Orders</div>
              <div className="num-orders">Special Requests</div>
            </div>
            <div className="body">
              {kv(p).map(({ k, v }, index) => (
                <div key={k} className="row">
                  <div key={k} className="section-cell meals-cell">
                    {k}
                  </div>
                  <div className="num-orders-cell">{v.numOrders}</div>
                  <div className="notes-cell">
                    {(v.notes || []).map((note, index) => (
                      <div key={note}>
                        {index + 1}. {note}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Divider orientation="center" className="divider">
            Accumulated Salads
          </Divider>
          <div className="salad-section each-section">
            <div className="headers">
              <div className="salads section-name">Salads</div>
              <div className="num-orders">Number of Orders</div>
            </div>
            <div className="body">
              {kv(salads).map(({ k, v }, index) => (
                <div key={k} className="row">
                  <div key={k} className="section-cell">
                    {k}
                  </div>
                  <div className="num-orders-cell">{v}</div>
                </div>
              ))}
            </div>
          </div>
          <br />

          {/* <Divider orientation="center" className="divider">
            Accumulated Sides
          </Divider> */}

          <Divider orientation="center" className="divider">
            Accumulated Meals by Department
          </Divider>
          {/* For Every Other Job */}
          {!['JR1', 'JR2', 'MOK'].includes(jobId) && (
            <div className="acc-meal-by-dept each-section">
              <div className="headers">
                <div className="each-header total-num-header"># Orders</div>
                <div className="each-header dept-header">Department</div>
                <div className="usernames-header each-header">Names</div>
                <div className="meals-header each-header">Meals</div>
                <div className="notes-header each-header">Notes</div>
              </div>
              <div className="body">
                {kv(departments)
                  .sort(({ k }, { k: k2 }) =>
                    k.toUpperCase() > k2.toUpperCase() ? 1 : -1
                  )
                  .map(({ k: depName, v: info }) => (
                    <div key={depName} className="each-row">
                      <div className="num-orders">{info.accNumber}</div>
                      <div className="rest">
                        {info.usernames.map((username) => {
                          const isOrderPickedUp = jobSubOrderComplete.isMealPickedUp(
                            {
                              userId: username.userId,
                              subOrderId: username.orderSubId,
                            }
                          )
                          return (
                            <div
                              key={username.userId + username.orderSubId}
                              className="internal-row"
                            >
                              <div className="dept">{depName}</div>
                              <div
                                className={`names ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                <Checkbox
                                  className="hidden-print"
                                  checked={!!isOrderPickedUp}
                                  onChange={() => {
                                    if (isOrderPickedUp) {
                                      kernel.performUpdates([
                                        jobSubOrderComplete.markMealAsNotPickedUp(
                                          {
                                            userId: username.userId,
                                            subOrderId: username.orderSubId,
                                          }
                                        ),
                                      ])
                                    } else {
                                      kernel.performUpdates([
                                        jobSubOrderComplete.markMealAsPickedUp({
                                          userId: username.userId,
                                          subOrderId: username.orderSubId,
                                        }),
                                      ])
                                    }
                                  }}
                                />
                                {username.username || ''}
                              </div>
                              <div
                                className={`meals ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                {username.orderName || ''} -{' '}
                                {username.orderOptions.map((option) => (
                                  <span
                                    className="each-option"
                                    key={option.optionName}
                                  >
                                    <span className="option-name">
                                      {option.optionName}:{' '}
                                    </span>
                                    <span className="option-items">
                                      {option.optionItems.map((item) => (
                                        <span
                                          key={item}
                                          className="each-option-item"
                                          style={{ paddingRight: '5px' }}
                                        >
                                          {item}
                                        </span>
                                      ))}
                                    </span>
                                  </span>
                                ))}
                              </div>
                              <div
                                className={`notes ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                {username.notes || ''}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {/* For JR1 Specifically*/}
          {['JR1', 'JR2', 'MOK'].includes(jobId) && (
            <div className="acc-meal-by-dept-pickupTimes each-section-pickupTimes">
              <div className="headers-pickupTimes">
                <div className="each-header total-num-header-pickupTimes">
                  # Orders
                </div>
                <div className="rest-headers-pickupTimes">
                  {['JR1', 'JR2', 'MOK'].includes(jobId) && (
                    <div className="each-header-pickupTimes send-text-header-pickupTimes">
                      Send Text
                    </div>
                  )}
                  <div className="each-header-pickupTimes dept-header-pickupTimes">
                    Department
                  </div>
                  <div className="usernames-header-pickupTimes each-header-pickupTimes">
                    Names
                  </div>
                  <div className="meals-header-pickupTimes each-header-pickupTimes">
                    Meals
                  </div>
                  {['JR1', 'JR2', 'MOK'].includes(jobId) && (
                    <div className="pickup-time-header-pickupTimes each-header-pickupTimes">
                      Pickup Time
                    </div>
                  )}
                  <div className="notes-header-pickupTimes each-header-pickupTimes">
                    Notes
                  </div>
                </div>
              </div>
              <div className="body-pickupTimes">
                {kv(departments)
                  .sort(({ k }, { k: k2 }) =>
                    k.toUpperCase() > k2.toUpperCase() ? 1 : -1
                  )
                  .map(({ k: depName, v: info }) => (
                    <div key={depName} className="each-row-pickupTimes">
                      <div className="num-orders-pickupTimes">
                        {info.accNumber}
                      </div>
                      <div className="rest-pickupTimes">
                        {info.usernames.map((username) => {
                          const isOrderPickedUp = jobSubOrderComplete.isMealPickedUp(
                            {
                              userId: username.userId,
                              subOrderId: username.orderSubId,
                            }
                          )
                          return (
                            <div
                              key={username.userId + username.orderSubId}
                              className="internal-row-pickupTimes"
                            >
                              {['JR1', 'JR2', 'MOK'].includes(jobId) &&
                                (orderTextStatus(username.userId) === 'done' ? (
                                  <div className="send-text-pickupTimes">
                                    text sent
                                  </div>
                                ) : orderTextStatus(username.userId) ===
                                  'pending' ? (
                                  <div className="send-text-pickupTimes">
                                    sending text
                                  </div>
                                ) : (
                                  <div className="send-text-pickupTimes">
                                    <Button
                                      //orderId, userId
                                      onClick={() => {
                                        this.sendOrderReadyText({
                                          orderId: username.orderSubId,
                                          userId: username.userId,
                                        })
                                      }}
                                    >
                                      Send Text
                                    </Button>
                                  </div>
                                ))}

                              <div className="dept-pickupTimes">{depName}</div>
                              <div
                                className={`names-pickupTimes ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                <Checkbox
                                  className="hidden-print-pickupTimes"
                                  checked={!!isOrderPickedUp}
                                  onChange={() => {
                                    if (isOrderPickedUp) {
                                      kernel.performUpdates([
                                        jobSubOrderComplete.markMealAsNotPickedUp(
                                          {
                                            userId: username.userId,
                                            subOrderId: username.orderSubId,
                                          }
                                        ),
                                      ])
                                    } else {
                                      kernel.performUpdates([
                                        jobSubOrderComplete.markMealAsPickedUp({
                                          userId: username.userId,
                                          subOrderId: username.orderSubId,
                                        }),
                                      ])
                                    }
                                  }}
                                />
                                {username.username || ''}
                              </div>
                              <div
                                className={`meals-pickupTimes ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                {username.orderName || ''} -{' '}
                                {username.orderOptions.map((option) => (
                                  <span
                                    className="each-option-pickupTimes"
                                    key={option.optionName}
                                  >
                                    <span className="option-name-pickupTimes">
                                      {option.optionName}:{' '}
                                    </span>
                                    <span className="option-items-pickupTimes">
                                      {option.optionItems.map((item) => (
                                        <span
                                          key={item}
                                          className="each-option-item-pickupTimes"
                                          style={{ paddingRight: '5px' }}
                                        >
                                          {item}
                                        </span>
                                      ))}
                                    </span>
                                  </span>
                                ))}
                              </div>

                              <div
                                style={{ margin: '0 10px' }}
                                className={`pickup-time-pickupTimes ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                {username.pickupTime || ''}
                              </div>

                              <div
                                className={`notes-pickupTimes ${
                                  isOrderPickedUp && 'pickedup-order'
                                }`}
                              >
                                {username.notes || ''}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <br />
          {/* <Divider orientation='center' className='divider'>Meals Breakdown</Divider>
        <div className='specific'>
          <div className='headers'>
            <div className='each-header meals-header'>
              Meals
            </div>
            <div className='each-header names-header'>
              Names
            </div>
            <div className='each-header notes-header'>
              Notes
            </div>
          </div>
          <div className='body'>
            {
              jobSubOrderComplete.perms().map(perm => (
                <div className='each-row'>
                  <div className='meal-cell each-cell'>
                    {perm.orderName} ( x{perm.usernames.length} ){ perm.orderOptions.length ? ' - ' : ''}
                    {
                      perm.orderOptions.reverse().map(o => (
                        <div className='options'>
                          <div className='option-name'>
                            {o.optionName}:
                            {' '}
                          </div>
                          <div className='option-items'>
                            {
                              o.optionItems.map((item, index) => (
                                <div className='each-option-item'>
                                  {item} { index !== o.optionItems.length - 1 && <span>, </span> }
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div className='username-cell each-cell'>
                    {
                      perm.usernames.map((username, index) => {
                        const department = (perm.users.find(i => i.username === username) || { department: '' }).department
                        return <div key={username} className='each-username'>{index+1}. {username} { department.length ? `(${department})` : ''}</div>
                      })
                    }
                  </div>
                  <div className='note-cell each-cell'>
                    {perm.orderNotes}
                  </div>
                </div>
              ))
            }
          </div>
        </div> */}
        </div>
      )
    }
  }
)

import React from 'react'
import {} from '@material-ui/core'
import {} from 'antd'

import * as Comps from '@/components'

import './terms-and-conditions.scss'

export const TermsAndConditions = class TermsAndConditions extends React.Component {
  state = {}

  render() {
    const {} = this.props
    const {} = this.state
    
    return (
      <div className='terms-and-conditions-container'>
        hello from terms-and-conditions.js
      </div>
    )
  }
}
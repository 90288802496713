import { createNodeClass } from '@blast-engine/firework'
import { rollup } from '@blast-engine/utils'

export const LunchGlobalOption = createNodeClass({
  name: 'LunchGlobalOption',

  full: class {

    id() {
      return this._data('id')
    }

    items() {
      return this._data('items')
    }

    label() {
      return this._data('label')
    }

    type() {
      return this._data('type')
    }

  },

  ref: class {

    initialize({ id, label, type, items }) {
      return this._update({ id, label, type, items: rollup(items) })
    }

  }

})
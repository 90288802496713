import { createNodeClass } from '@blast-engine/firework'
import { keys } from '@blast-engine/utils'

import { JobBreakfastPreorderMenuForDay } from './job-breakfast-preorder-menu-for-day.node'

export const JobBreakfastPreorderMenu = createNodeClass({
  name: 'JobBreakfastPreorderMenu',

  full: class {

    menuForDay(dayString) {
      return this._spinoff(JobBreakfastPreorderMenuForDay, {
        data: this._data([ 'dayMenu', dayString ]),
        path: this._path([ 'dayMenu', dayString ])
      })
    }

    upsertMenuForDay({ dayString, menu, times }) {
      const dayMenuRef = this._spinoff(JobBreakfastPreorderMenuForDay.ref(), { 
        path: this._path([ 'dayMenu', dayString ])
      })
      return [
        this._update({}),
        dayMenuRef.initialize({ dayString, menu, times })
      ]
    }

    removeMenuForDay({ dayString }) {
      return this._update({ [`dayMenu/${dayString}`]: null })
    }

    days() {
      return keys(this._data('dayMenu'))
    } 
    
  },

  ref: class {

  }

})
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, TextField } from '@material-ui/core'
import { List, Modal } from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-locations.scss'

export const CraftOfficeLocations = fireworkConnect(({ crafterId, jobId }) => ({
  locations: entities.crafter.crafterData.job.jobData.fetchLocations({ crafterId, jobId })
}))(class CraftOfficeLocations extends React.PureComponent {

  state = { 
    addLocationModalOpen: false,
    newLocationName: '',
    updatedLocationName: ''
  }

  addLocation() {
    const { locations } = this.props
    const { newLocationName } = this.state
    if (!newLocationName || !newLocationName.length) return

    kernel.performUpdates([ locations.addLocation({ name: newLocationName }) ])
    this.setState({ newLocationName: '', addLocationModalOpen: false })
  }

  updateLocation() {
    const { locations } = this.props
    const { updatedLocationName, updateLocationModalItemId } = this.state
    if (!updatedLocationName || !updatedLocationName.length || !updateLocationModalItemId) return
    
    kernel.performUpdates([ locations.updateLocationName({ name: updatedLocationName, locationId: updateLocationModalItemId }) ])
    this.setState({ updatedLocationName: '', updateLocationModalItemId: null })
  }

  render() {
    const { locations } = this.props
    const { addLocationModalOpen, newLocationName, updatedLocationName, updateLocationModalItemId } = this.state

    if (!isReady( locations )) return null
    
    return (
      <div className='craft-office-locations-container'>
        <List 
          header={<div className='add-location' onClick={() => this.setState({ addLocationModalOpen: true })}>Add Location</div>}
          bordered
          dataSource={locations.locationNames()}
          renderItem={item => (
            <List.Item className='loc-list'>
              <div className='loc-name'>{item.name()}</div>
              <div className='buttons-section'>
                <Button color='primary' variant='text'
                  onClick={() => this.setState({ updateLocationModalItemId: item.id(), updatedLocationName: item.name() })}
                >Rename</Button>
                <Button color='secondary' variant='text'
                  onClick={() => {
                    const confirmed = window.confirm(`Are you sure you want to permanently delete location ${item.name()}?`)
                    if (confirmed) {
                      kernel.performUpdates([ locations.removeLocation({ locationId: item.id() }) ])
                    }
                  }}
                >Remove</Button>
              </div>
            </List.Item>
          )}
        />
        <Modal
          title='Add New Location'
          visible={addLocationModalOpen}
          onOk={() => this.addLocation()}
          onCancel={() => this.setState({ addLocationModalOpen: false, newLocationName: '' })}
        >
          <TextField
            fullWidth
            type='text'
            label='Location Name'
            value={newLocationName}
            onChange={e => this.setState({ newLocationName: e.target.value })}
            required
            variant='outlined'
          />
        </Modal>
        <Modal
          title='Rename Location'
          visible={updateLocationModalItemId}
          onOk={() => this.updateLocation()}
          onCancel={() => this.setState({ updateLocationModalItemId: null, updateLocationName: '' })}
        >
          <TextField
            fullWidth
            type='text'
            label='Location Name'
            value={updatedLocationName}
            onChange={e => this.setState({ updatedLocationName: e.target.value })}
            required
            variant='outlined'
          />
        </Modal>
      </div>
    )
  }
})
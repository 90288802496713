import React from 'react'
import {} from '@material-ui/core'
import {} from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'

import * as Comps from '@/components'

import './privacy-policy.scss'

export const PrivacyPolicy = responsiveComponent(class PrivacyPolicy extends React.Component {
  state = {
    isTop: true
  }

  render() {
    const { isMobile } = this.props
    const {} = this.state

    return (
      <div className='privacy-policy-container'>
        {
          isMobile ? 
            <Comps.NavbarMobile isTop={false} shouldHideLogin={true} />
          : <Comps.NavbarDesktop isTop={false} shouldHideLogin={true} />
        }
        <div className='body'>
          <div className='title'>
            Privacy Policy
          </div>
          <div className='header'>
            I. Overview
          </div>
          <div className='paragraph'>
          When you use Crafty Film, you trust us with your data and we are committed to maintaining transparency in collection, use and disclosure of your information. That starts with helping you understand our privacy practices.
            <br />This notice explains the personal data we collect, how we use and share it, and your choices regarding this data.   </div>
          <div className='subheader' style={{ marginTop: '3vh' }}>Effective date: August 22, 2020</div>
          <div className='header'>
            II. Data collection and uses
          </div>
          <div className='subheader'>A. How we collect and store your data</div>
          <div className='paragraph'>
          Crafty collects the below mentioned personal information. This is data provided by usersto Crafty Film App, such as during account creation/registration on the web application.
          </div>
          <div className='paragraph one-indent'>
            1. First name
          </div>
          <div className='paragraph one-indent'>
            2. Email address
          </div>
          <div className='paragraph one-indent'>
            3. Phone number
          </div>
          <div className='paragraph one-indent'>
            4. Department
          </div>
          <div className='paragraph'>
          All user information is stored on Google Cloud Platform.
          </div>
          <div className='subheader'>
            B. Data sharing and disclosure 
          </div>
          <div className='paragraph'>
          Our services require that we share user data with Cratering and Craft Service teams. <br />
            This includes sharing:
          </div>
          <div className='paragraph one-indent'>
            1. User’s first name
          </div>
          <div className='paragraph one-indent'>
            2. Department
          </div>
          <div className='paragraph one-indent'>
            3. Ordered food items (names and quantities)
          </div>
          <div className='paragraph'>
            Phone numbers and emails are not shared with any user or third party.
          </div>
          <div className='paragraph'>
          We also use this data for informing, measuring, maintaining, and improving our services. This
includes data to:
          </div>
          <div className='paragraph one-indent'>
            1. Inform users when their order is ready (via text messages) or remind them to pre-order their food.
          </div>
          <div className='paragraph one-indent'>
            2. Measure and track number of craft or catering orders per department, per user, per day.
          </div>
          <div className='paragraph one-indent'>
            3. Perform internal operations critical to our services, for example, troubleshooting software bugs
and operational problems; conducting data analysis; and monitoring and analyzing usage and
activity trends.
          </div>
          <div className='subheader'>
            C. Data retention and deletion
          </div>
          <div className='paragraph'>
          Crafty retains a user’s profile, and other personal data (mentioned above) for as long as a user
maintains their Crafty account. All information will be deleted after the user’s account is deleted, or upon their request.
          </div>
          <div className='subheader'>
            D. Encryption
          </div>
          <div className='paragraph'>
          Crafty uses end -to-end encryption and advanced authentication methods to ensure security and
privacy for every user.
          </div>
          <div className='subheader'>
            E. Cookies
          </div>
          <div className='paragraph'>
          Crafty uses browser cookies to keep a user authenticated until they log out.
          </div>
          <div className='header'>
            III. Choice and transparency 
          </div>
          <div className='paragraph'>
          Crafty enables users to request copies of all data that we have of them.
          </div>
          <div className='header'>
            IV. Updates to our policy 
          </div>
          <div className='paragraph'>
          We update this notice as and when we update the web application.
If we make any significant changes, we will be notifying our customers of all the new changes through
emails.</div>
        </div>
        <Comps.FooterDesktop />
      </div>
    )
  }
})
import { createFetcher } from '@blast-engine/firework'

import { CrafterManager } from './crafter-manager.assembly'

export const fetchCrafterManager = createFetcher({
  name: 'fetch-crafter-manager'
}, ({ crafterId }) => ({
  steps: [{
    name: 'crafterBasic',
    query: ({ root }) => root.crafterBasicRef({ crafterId }).query()
   } , {
    name: 'crafterMembers',
    query: ({ root }) => root.crafterMemberBasicListRef({ crafterId }).query()
  }, {
    name: 'crafterMemberUserBasics',
    requires: [ 'crafterMembers' ],
    query: ({ root, crafterMembers }) => {
      const memberIds = crafterMembers.asArray().map(crafterMember => crafterMember.id())
      return root.userBasicSelectionRef({ userIds: memberIds }).query()
    }
  }, {
    name: 'allCrafterJobBasics',
    query: ({ root }) => root.crafterJobBasicListRef({ crafterId }).query()
  }, {
    name: 'jobRegistry',
    query: ({ root }) => root.jobRegistryRef().query()
  }, {
    name: 'userEmails',
    query: ({ root }) => root.userEmailListRef().query()
  }, {
    name: 'invitesListRef',
    query: ({ root }) => root.invitesListRef().query()
  }],
  final: {
    take: [ 'invitesListRef', 'userEmails', 'crafterBasic', 'crafterMembers', 'crafterMemberUserBasics', 'allCrafterJobBasics', 'jobRegistry' ],
    instantiate: ({ invitesListRef, userEmails, crafterBasic, crafterMembers, crafterMemberUserBasics, allCrafterJobBasics, jobRegistry, root }) => 
      root._spinoff(CrafterManager, { 
        members: { invitesListRef, userEmails, crafterBasic, crafterMembers, crafterMemberUserBasics, allCrafterJobBasics, jobRegistry }
      })
  }
}))
import { createNodeClass } from '@blast-engine/firework'
import { k, rollup } from '@blast-engine/utils'

import { BreakfastDayMenu } from './day-menu.struct' 

export const JobBreakfastPreorderMenuForDay = createNodeClass({
  name: 'JobBreakfastPreorderMenuForDay',

  full: class {

    dayString() {
      return this._data('dayString')
    }

    menu() {
      return BreakfastDayMenu.create(this._data('menu'))
    }

    setMenu(menu) {
      return this._update({ menu: menu.raw() })
    }

    times() {
      return k(this._data('times'))
    }
    
  },

  ref: class {

    initialize({ dayString, menu, times }) {
      return this._update({ dayString, menu: menu.raw(), times: rollup(times) })
    }

  }

})
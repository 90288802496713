import { createListClass } from '@blast-engine/firework'
import { BreakfastOrder } from './breakfast-order.node'

export const BreakfastOrderList = createListClass({
  name: 'BreakfastOrderList',
  itemModel: BreakfastOrder,
  full: class {
    asArray() {
      return this.itemsKV
        .filter(({ v }) => !!v.data.userId)
        .map(({ k, v }) => v)
    }
  }
})
import React from 'react'
import { TextField, CircularProgress, Button } from '@material-ui/core'

export const MassTextInput =
  class MassTextInput extends React.PureComponent {
    state = { textToBroadcast: '' }
    render() {
      const { textToBroadcast } = this.state
      const { sendMassText, broadcastingText } = this.props

      const handleOnClick = () => {
        this.setState({ textToBroadcast: '' })
        sendMassText(this.state.textToBroadcast)
      }

      return (
        <div style={{ paddingRight: 30, paddingLeft: 30, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <TextField
            style={{ width: '100%' }}
            placeholder='text message content'
            value={textToBroadcast}
            onChange={e => this.setState({ textToBroadcast: e.target.value })}
          />
          {
            broadcastingText ? (
              <div style={{ paddingLeft: 20, width: '200px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={30}/>
              </div>
            ) : (
              <Button
                style={{ paddingLeft: 20, width: '200px', height: '50px' }}
                onClick={handleOnClick}
              >
                send to all
              </Button>
            )
          }
        </div>
      )
    }
  }
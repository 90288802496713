import { createFetcher } from '@blast-engine/firework'

import { JobBreakfastOrdersComplete } from './job-breakfast-orders.assembly'
import { subsGlobalMenu } from './subs-global-menu'
import { breakfastOrders } from './job/jobData/breakfast-orders'

export const fetchJobBreakfastOrdersComplete = createFetcher({
  name: 'fetch-job-breakfast-Orders-complete'
}, ({ crafterId, jobId, day }) => ({
  steps: [{
    name: 'jobBasic',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  }, {  
    name: 'dayJobMenu',
    query: ({ root }) => root.crafterJobBreakfastMenuForDay({ crafterId, jobId, day }).query()
  }, {
    name: 'globalItems',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }, {
    name: 'userBasics',
    query: ({ root }) => root.userBasicListRef().query()
  }, {
    name: 'subOrdersForDay',
    query: ({ root }) => root.breakfastOrdersRef({ crafterId, jobId, day }).query()
  }],
  final: {
    take: [ 'subOrdersForDay', 'jobBasic', 'userBasics', 'dayJobMenu', 'globalOptions', 'globalItems' ],
    instantiate: ({ subOrdersForDay, jobBasic, userBasics, dayJobMenu, globalOptions, globalItems, root }) => (
      root._spinoff(JobBreakfastOrdersComplete, { 
        members: { 
          subMenuItemListForDay: dayJobMenu,
          jobBasic,
          userBasics,
          subOrdersForDay,
          subGlobalMenu: root._spinoff(subsGlobalMenu.CrafterSubsGlobalMenu, {
            members: {
              items: globalItems, 
              options: globalOptions 
            }
          })
        }
      })
    )
  }
}))
import { BreakfastOrderList } from './breakfast-order.list'
import { BreakfastOrder } from './breakfast-order.node'
import { BreakfastOrdersForJobDay } from './breakfast-orders-for-job-day.node'
import { AllBreakfastOrders } from './all-breakfast-orders.node'

export const rootMethods = {

  breakfastOrdersRef({ crafterId, jobId, day }) {
    return this._spinoff(BreakfastOrdersForJobDay.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/breakfastOrders/${day}`
    })
  },

  allBreakfastOrdersRef({ crafterId, jobId }) {
    return this._spinoff(AllBreakfastOrders.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/breakfastOrders`
    })
  }

}
import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Link } from 'react-router-dom'
import { Chip, TextField, MenuItem, Input, Button, Fab, Icon, FormControl, InputLabel, Select, CircularProgress, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { Add as AddIcon, AutorenewOutlined, DoneAll, ExpandMore } from '@material-ui/icons'
import { Modal, List, Divider, Card, Collapse, DatePicker } from 'antd'
import { kv } from '@blast-engine/utils'
import LogRocket from 'logrocket'
import moment from 'moment'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'
import { sendTextMessage } from '@/text-messages.service'
import notifSoundMP3 from '../../assets/notif.mp3'
import './craft-on-site-job.scss'
import TextArea from 'antd/lib/input/TextArea'
import { MassTextInput } from './mass-text-input'
import { PendingOrders } from './n-pending-orders'
import { JobOnsiteDataDaemon } from './n-data-daemon'

const notifSound = new Audio(notifSoundMP3)

window.moment = moment

let didEnableSound = false
window.addEventListener('click', () => { 
  if (!didEnableSound) {
    notifSound.play() 
    notifSound.pause() 
    didEnableSound = true
  }
})

export const CraftOnSiteJob = fireworkConnect(props => ({
  jobBasic: entities.crafter.crafterData.job.fetchJobBasic({ crafterId: props.crafterId, jobId: props.jobId }),
  jobMenu: entities.crafter.crafterData.fetchJobCraftMenuManager({ crafterId: props.crafterId, jobId: props.jobId }),
  jobOrderComplete: entities.crafter.crafterData.fetchJobOrdersComplete({ crafterId: props.crafterId, jobId: props.jobId }),
  locations: entities.crafter.crafterData.job.jobData.fetchLocations({ crafterId: props.crafterId, jobId: props.jobId }),
  config: entities.fetchConfig(),
}))(class CraftOnSiteJob extends React.Component {
  state = {
    craftItemsChecked: {},
    playSoundOnNewOrder: false,
    lastNumOrders: 0,
    broadcastingText: false, 
    textToBroadcast: '',
    openSendMassTextModal: false,
    openIncrementDayModal: false,
    openDecrementDayModal: false,
    openCustomMessageModal: false,
    orderReadyTexts: {}
  }

  sendMassText = (text) => { this.setState({ textToBroadcast: text, openSendMassTextModal: true }) }

  async broadcastText() {
    const { crafterId, jobId, jobBasic } = this.props
    const { textToBroadcast } = this.state

    this.setState({ broadcastingText: true, textToBroadcast: '' })

    const jobCrewUsers = await kernel.snap(
      entities.crafter.crafterData.job.jobData.fetchJobCrewUsers({ crafterId, jobId })
    )

    LogRocket.log({ 
      event: '[CraftOnSiteJob] sending text broadcast',
      jobCrewUsers
    })

    const lengthForJob = jobId => {
      const { config } = this.props
      const configuredDays = config.get(`jobs.${jobId}.massTextTimeoutDays`)
      const configuredHours = config.get(`jobs.${jobId}.massTextTimeoutHours`)
      let hours = null
      if (typeof configuredDays === 'number') hours = Math.ceil(configuredDays) * 24
      if (typeof configuredHours === 'number') hours = Math.ceil(configuredHours)
      if (hours === null) {
        if (jobId === 'AR2020') return 40 * 24 * 60 * 60 * 1000
        if (jobId === 'PA') return 40 * 24 * 60 * 60 * 1000
        if (jobId === 'MFT2') return 4 * 24 * 60 * 60 * 1000
        if (jobId === 'GW7') return 50 * 24 * 60 * 60 * 1000
        if (jobId === 'LK2') return 10000 * 24 * 60 * 60 * 1000
        return 48 * 60 * 60 * 1000
      } else {
        return hours * 60 * 60 * 1000
      }
    }

    const userIds = jobCrewUsers
      .members.jobCrewUsers.recentUserIds(lengthForJob(jobId))

    await sendTextMessage({
      text: textToBroadcast,
      userIds,
      jobId,
      debugData: {}
    })
    
    this.setState({ broadcastingText: false, textToBroadcast: '' })
  }

  async sendCustomText({ userId, message }) {
    const { jobId } = this.props

    if (!userId || !message) return
    
    await sendTextMessage({
      text: message,
      jobId,
      userIds: [ userId ]
    })
  }

  async sendOrderReadyText({ order }) {
    const { crafterId, jobId } = this.props
    const { orderReadyTexts } = this.state

    const orderId = order.order.id()
    const userId = order.userId

    if (!userId || !orderId) return
    
    this.setState({ orderReadyTexts: { ...orderReadyTexts, [orderId]: 'sending' } })

    LogRocket.log({ event: '[CraftOnSiteJob] sending order ready text', order })

    await sendTextMessage({
      text: 'Your order is ready!',
      jobId,
      userIds: [ userId ],
      debugData: { orderId, userId, username: order.userName }
    })
    
    this.setState({ orderReadyTexts: { ...orderReadyTexts, [orderId]: 'done' } })
  }

  componentDidMount() {
    setTimeout(() => this.setState({ playSoundOnNewOrder: true }), 10 * 1000)
  }
  
  componentDidUpdate() {
    const { lastNumOrders, playSoundOnNewOrder } = this.state
    const { jobOrderComplete } = this.props
    if (!isReady(jobOrderComplete)) return
    const newLastNumOrders = jobOrderComplete.pendingOrders().length
    if (playSoundOnNewOrder && (lastNumOrders < newLastNumOrders)) notifSound.play()
    this.state.lastNumOrders = newLastNumOrders
  }

  render() {
    const { 
      craftItemsChecked,     
      broadcastingText, 
      textToBroadcast,
      openIncrementDayModal,
      openDecrementDayModal,
      openCustomMessageModal,
      customMessageText,
      customMessageUser,
      orderReadyTexts,
      openEnableAllCategoriesModal,
      openDisableAllCategoriesModal,
      openAddPreorderMenuModal,
      newPreorderMenuDayString,
      newPreorderDayMenu,
      newPreorderDayMenuTimes,
      openSendMassTextModal
    } = this.state
    
    const { 
      jobBasic, 
      jobOrderComplete, 
      jobMenu, 
      crafterId,
      jobId,
      locations,
      config
    } = this.props

    if (
      !isReady(
        jobBasic, 
        jobOrderComplete, 
        jobMenu, 
        locations,
        config
      )
    ) return <h1>loading...</h1>

    const allUserBasics = jobOrderComplete.members.userBasics

    console.log('rendering job')

    const { Panel } = Collapse

    const allOrders = jobOrderComplete.members.craftOrders.asArray()

    // window.bi = window.ao.items[0].basketItem.selectionOptionIds()
    // const mealPerms = Object.values(jobOrderComplete.perms().reduce((acc, perm) => {
    //   if (!acc[perm.orderType]) acc[perm.orderType] = []
    //   acc[perm.orderType].push(perm)
    //   return acc
    // }, {}) || {})

    window.armin = this

    const decrementDay = () => 
      kernel.performUpdates([ jobBasic.decrementDay() ])
    
    const incrementDay = () =>
      kernel.performUpdates([ jobBasic.incrementDay() ])

    const todayDayString = moment().format("dddd MMM Do, YYYY")
    
    return (
      <div className='craft-on-site-job-container'>
        <div className='right-side'>
          <div className='header'>
            <div className='right'>
              <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10, fontSize: 20 }}>
                <span style={{ marginRight: 6 }}>Current Job:</span>
                <span style={{ marginRight: 6, fontWeight: 'bold' }}>{ jobBasic.id() }</span>
                <span>({ jobBasic.name() })</span>
              </div>
            </div>
            <div className='left'>
              { 
                jobBasic.notesForBasketAreEnabled() ? 
                  <Button 
                    variant='text' 
                    color='secondary'
                    onClick={() => kernel.performUpdates([ jobBasic.disableNotesForBasket() ])}
                  >
                    Disable Notes For Orders
                  </Button>
                : 
                  <Button 
                    variant='text' 
                    color='primary'
                    onClick={() => kernel.performUpdates([ jobBasic.enableNotesForBasket() ])}
                  >
                    Enable Notes For Orders
                  </Button>
              }
            </div>
          </div>
          
          <MassTextInput
            broadcastingText={broadcastingText}
            sendMassText={this.sendMassText}
          />

          <Divider className='divider-text' orientation='left'>Orders</Divider>
          {/* <div>
            <JobOnsiteDataDaemon crafterId={crafterId} jobId={jobId}/>
            <PendingOrders/>
          </div> */}
          <div className='orders'>
            {
              !jobOrderComplete.pendingOrders().length && 
              <div className='no-orders-section'>
                No Current Orders
              </div>
            }
            {
              jobOrderComplete.pendingOrders().map(order => (
                <Card
                  style={{ 
                    width: 300, 
                    display: 'flex', 
                    flexDirection: 'column',
                    margin: 10, 
                    whiteSpace: 'break-spaces'
                  }}
                  bodyStyle={{ 
                    flex: 1,
                    backgroundColor: order.order.isPending() ? '#f0f6ff' : '#ebffed'
                  }}
                  actions={[
                    <Button 
                      primary
                      style={{ width: '100%', height: '100%' }}
                      disabled={!order.order.isPending() || !order.userProvidedPhoneNumber}
                      onClick={() => this.setState({ 
                        openCustomMessageModal: true,
                        customMessageText: '',
                        customMessageUser: {
                          id: order.userId,
                          username: order.userName
                        }
                      })}
                    >
                      {/*  { !order.order.isPending() ? <Icon style={{ fontSize: 18, marginRight: 10 }}>checkmark</Icon> : null } */}
                      Send Custom Text
                    </Button>,
                    <Button 
                      primary
                      className='ready'
                      style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}
                      disabled={!order.order.isPending()}
                      onClick={() => {
                        kernel.performUpdates(jobOrderComplete.setOrderAsReady({ orderId: order.order.id() })) 
                        if (['LK2', 'CL'].includes(jobId)) this.sendOrderReadyText({ order })
                        else if (config.get(`jobs.${jobId}.autoTextOnReady`)) this.sendOrderReadyText({ order })
                      }}
                    >
                      { !order.order.isPending() ? <Icon style={{ fontSize: 18, marginRight: 10 }}>checkmark</Icon> : null }
                      Ready
                    </Button>,
                    // <Button
                    //   primary
                    //   style={{ width: '100%', height: '100%' }}
                    //   disabled={!order.order.isReadyForPickup()}
                    //   onClick={() => kernel.performUpdates(jobOrderComplete.completeOrder({ orderId: order.order.id() })) }
                    // >
                    //   Picked up
                    // </Button>
                  ]}
                >
                  <Card.Meta
                    title={
                      <div 
                        className='title' 
                        style={{display: 'flex', justifyContent: 'space-between', flexDirection:'column', alignItems: 'center', backgroundColor: '#cbd6ef', whiteSpace: 'break-spaces'}}
                      >
                        <span style={{ fontWeight: 'bold', fontSize: 20, width: '100%', wordBreak: 'break-word' }}><div>{order.userName}</div> <div>({order.userDept})</div></span>
                        {locations.location({ locationId: order.order.basket().locationId()}) ? <span className='location-section'>Location: {locations.location({ locationId: order.order.basket().locationId()}).name()}</span> : <></>}
                        <span style={{ fontSize: 13 }}><Comps.TimeCounter startTimestamp={order.timestamp} /></span>
                        <span>#{allOrders.findIndex(o => o.id() === order.order.id()) + 1}</span>
                      </div>
                    }
                  />
                  {
                    order.items.filter(item => (item.item && jobMenu.itemExists({ itemId: item.item.id() }))).map(item => (
                      <div className='items-section'>
                        <div className='item-name'>- {jobMenu.itemNameForItemId({ itemId: item.item.id() })} <span className='item-number'>x{item.basketItem.numCopies()}</span></div>
                        <div className='item-options'>
                          {
                            item.options.map(option => (
                              <div className='each-option'>
                                <div className='option-name'>{option.option.name()}:</div>
                                {
                                  option.selectedOptionItems.map(optionItem => (
                                    <div className='option-item-label'>{optionItem}</div>
                                  ))
                                }
                              </div>
                            ))
                          }
                        </div>
                        {
                          item.basketItem.notes() ? (
                            <div style={{ fontSize: '12px', paddingBottom: 10, color: '#ec2e4d' }}>
                              Notes: {item.basketItem.notes()}
                            </div>
                          ) : null                
                        }
                      </div>
                    ))
                  }
                  {
                    order.notes && order.notes.length ? 
                      <div className='notes-section' style={{ color: '#ec2e4d' }}>
                        Notes for the order: {order.notes}
                      </div>
                    : <></>
                  }
                </Card>
              ))
            }
          </div>
          

        
          <div className='item-sec' style={{ background: jobBasic.craftPaused() ? 'lightgray' : null }}>
            <div className='left'>
              <Divider orientation='left' className='divider-text' >Craft Items</Divider>
            </div>
            <div className='right' >

              {/* <Button variant='contained' color='primary' className='each-button'
                disabled={!!this.state.isWorkingOnPauseService}
                style={{ marginRight: '25px' }}
                onClick={async () => {
                  const pause = !!jobMenu.members.pausedService.data
                  const confirmed = window.confirm(`Are you sure you want to ${pause ? 'RESUME' : 'PAUSE'} service?`)
                  if (!confirmed) return
                  this.setState({ isWorkingOnPauseService: true })
                  if (!pause) {
                    await kernel.performUpdates([ jobMenu.populatePausedService() ])
                    await kernel.performUpdates(jobMenu.disableAllCategories())
                    this.setState({ isWorkingOnPauseService: false })
                  }
                  else {
                    const d = jobMenu.members.pausedService.data
                    await kernel.firebase.database().ref(`crafter/crafterData/${this.props.crafterId}/job/jobData/${this.props.jobId}/craftMenu`).update(d)
                    kernel.firebase.database().ref(`crafter/crafterData/${this.props.crafterId}/job/jobData/${this.props.jobId}`).update({ pausedService: null })
                    this.setState({ isWorkingOnPauseService: false })
                  }
                }}
              >
                { jobMenu.members.pausedService.data ? 'Resume Service' : 'Pause Service' }
              </Button> */}

              <Button 
                variant='contained' color='secondary' className='each-button'
                disabled={jobBasic.craftPaused()}
                onClick={() => this.setState({ openDisableAllCategoriesModal: true })}
              >
                Disable all
              </Button>
              
            </div>
          </div>
          <Comps.JobCraftItems crafterId={this.props.crafterId} jobId={this.props.jobId}/>
          {/* <FormGroup>
            {
              jobMenu.members.globalMenu.members.items.asArray().map(craftGlobalItemBasic => (
                <FormControlLabel
                  style={{ marginBottom: '1vh' }}
                  label={
                    <div>
                      {craftGlobalItemBasic.name()}
                    </div>
                  }
                  control={
                    <Checkbox 
                      color='primary'
                      name={craftGlobalItemBasic.id()}
                      checked={!!this.state.craftItemsChecked[craftGlobalItemBasic.id()]}
                      onChange={e => {
                        const cic = this.state.craftItemsChecked
                        cic[craftGlobalItemBasic.id()] = e.target.checked
                        this.setState({ craftItemsChecked: cic })
                        const optsConfig = craftGlobalItemBasic.optionIds().reduce((acc, optionId) => {
                          const optionItemIds = rollup(keys(jobMenu.members.globalMenu.members.options.item(optionId).items()))
                          acc[optionId] = {
                            enabled: true,
                            optionItemIds
                          }
                          return acc
                        }, {})
                        const update = jobMenu.addItemToJobMenu({ 
                          globalCraftItemId: craftGlobalItemBasic.id(),
                          optionsConfig: optsConfig,
                          enabled: e.target.checked,
                          allowNotes: craftGlobalItemBasic.notesAreEnabled()
                          })
                        kernel.performUpdates([ update ])
                      }}
                    />
                  }
                />
              ))
            }
          </FormGroup> */}

          <div style={{ height: '50px' }}></div>
          <div className='day-changer'>
            <div className='day-section' style={{ display: 'flex', fontSize: '20px', height: '40px', backgroundColor: '#36c6f4', justifyContent: 'center', alignItems: 'center' }}>
              <span>Current Day:</span> 
              <Button onClick={() => this.setState({ openDecrementDayModal: true })}><Icon>arrow_back</Icon></Button>
              <span>Day {Number(jobBasic.currentDay()) + 1}</span>
              <Button onClick={() => this.setState({ openIncrementDayModal: true })}><Icon>arrow_forward</Icon></Button>
              {
                jobBasic.craftPaused() ? 
                  <Button 
                    onClick={() => {
                      const confirmed = window.confirm("Are you sure you want to resume service?")
                      confirmed && kernel.performUpdates([ jobBasic.toggleCraftPaused(false) ])
                    }}
                    variant='text' 
                    className='cat-enable-button each-toggle-button'
                    color={'primary'}
                  >
                    resume craft service
                  </Button>
                :
                  <Button
                    onClick={() => {
                      const confirmed = window.confirm("Are you sure you want to pause service?")
                      confirmed && kernel.performUpdates([ jobBasic.toggleCraftPaused(true) ])
                    }}
                    variant='text' 
                    className='cat-enable-button each-toggle-button'
                    color={'primary'}
                  >
                    pause craft service
                  </Button>
              }
              
              
            </div>
            <div className='fake' />
          </div>
          
        </div>
        <div className='fake-left-side'>
          
        </div>
        <div className='left-side'>
          <Divider className='divider-text'>Past orders</Divider>
          <div className='orders'>
            {
              jobOrderComplete.pastCraftOrders({ limit: 40 })
                .sort((o1, o2) => {
                  if (o1.order.isReadyForPickup() && !o2.order.isReadyForPickup()) return -1
                  if (!o1.order.isReadyForPickup() && o2.order.isReadyForPickup()) return 1
                  return o1.timestamp > o2.timestamp ? -1 : 1
                })
                .map(order => (
                <Card
                  style={{ width: 300, whiteSpace: 'break-spaces' }}
                >
                  {
                    order.order.isReadyForPickup() ? (
                      <div style={{ width: '100%', fontSize: '15px', flex: 1, color: 'blue', fontWeight: 'bold' }}>
                        Awaiting Pickup
                      </div>
                    ) : null
                  }
                  <div 
                    className='title' 
                    style={{display: 'flex', justifyContent: 'space-between', flexDirection:'column', alignItems: 'center', backgroundColor: '#cbd6ef'}}
                  >
                    <span style={{ fontWeight: 'bold', fontSize: 20 }}>{order.userName} ({order.userDept})</span>
                    {locations.location({ locationId: order.order.basket().locationId()}) ? <span className='location-section'>Location: {locations.location({ locationId: order.order.basket().locationId()}).name()}</span> : <></>}
                    <span style={{ fontSize: 13 }}><Comps.TimeCounter startTimestamp={order.timestamp} /></span>
                    <span>#{allOrders.findIndex(o => o.id() === order.order.id()) + 1}</span>
                  </div>
                  {
                    order.items.filter(item => (item.item && jobMenu.itemExists({ itemId: item.item.id() }))).map(item => (
                      <div className='items-section'>
                        <div className='item-name'>{jobMenu.itemNameForItemId({ itemId: item.item.id() })} <span className='item-number'>x{item.basketItem.numCopies()}</span></div>
                        <div className='item-options'>
                          {
                            item.options.map(option => (
                              <div className='each-option'>
                                <div className='option-name'>{option.option.name()}:</div>
                                {
                                  option.selectedOptionItems.map(optionItem => (
                                    <div className='option-item-label'>{optionItem}</div>
                                  ))
                                }
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                  {
                    order.notes && order.notes.length ? 
                      <div className='notes-section' style={{ color: '#ec2e4d' }}>
                        Notes for the order: {order.notes}
                      </div>
                    : <></>
                  }
                  {
                    order.order.isReadyForPickup() ? (
                      (
                        ['YLM', 'L1', 'GW7', 'SK', 'armins', 'SK','BMF', 'LK2'].includes(jobBasic.id())
                        || config.get(`jobs.${jobId}.newButtonsRightSide`)
                      ) ? (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch', alignItems: 'center', color: 'blue' }}>
                          <Button
                            primary
                            style={{ margin: 4, width: '100%', height: '100%', color: 'blue', borderStyle: 'solid', borderWidth: 1, borderColor: 'blue' }}
                            onClick={() => kernel.performUpdates(jobOrderComplete.completeOrder({ orderId: order.order.id() })) }
                          >
                            Picked up
                          </Button>
                          {
                            order.userProvidedPhoneNumber ? (
                              <Button
                                primary
                                style={{ width: '100%', margin: 4, marginLeft: 5, height: '100%', color: 'black', borderStyle: 'solid', borderWidth: 1, borderColor: 'black' }}
                                disabled={!order.userProvidedPhoneNumber}
                                onClick={() => this.setState({ 
                                  openCustomMessageModal: true,
                                  customMessageText: '',
                                  customMessageUser: {
                                    id: order.userId,
                                    username: order.userName
                                  }
                                })}
                              >
                                Send Custom Text
                              </Button>
                            ) : null
                          }
                          {
                            order.userProvidedPhoneNumber ? (
                              orderReadyTexts[order.order.id()] === 'sending'  ? (
                                <div style={{ width: '100%', margin: 4, height: '100%', display: 'flex', justifyContent: 'center', marginLeft: 5 }}><CircularProgress size={20}/></div>
                              ) : orderReadyTexts[order.order.id()] === 'done' ? (
                                <div style={{ width: '100%', margin: 4, height: '100%',  display: 'flex', justifyContent: 'center', marginLeft: 5, color: 'green', fontSize: 18 }}><Icon>checkmark</Icon><span style={{ marginLeft: 10 }}>Notified</span></div>
                              ) : (
                                <Button
                                  primary
                                  style={{ margin: 4, width: '100%', marginLeft: 5, height: '100%', color: 'black', borderStyle: 'solid', borderWidth: 1, borderColor: 'black' }}
                                  onClick={() => this.sendOrderReadyText({ order })}
                                >
                                  {
                                    ['LD2020', 'GW7', 'AR2020', 'AROFFICE', 'armins' ].includes(jobId)
                                      ? <span>Send Text</span>
                                      : <span>Send "Order is Ready" Text</span>
                                  }
                                </Button>
                              )
                            ) : null
                          }
                        </div>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'stretch', alignItems: 'center', color: 'blue' }}>
                          <Button
                            primary
                            style={{ width: '100%', height: '100%', color: 'blue', borderStyle: 'solid', borderWidth: 1, borderColor: 'blue' }}
                            onClick={() => kernel.performUpdates(jobOrderComplete.completeOrder({ orderId: order.order.id() })) }
                          >
                            Picked up
                          </Button>
                          {
                            order.userProvidedPhoneNumber ? (
                              orderReadyTexts[order.order.id()] === 'sending'  ? (
                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginLeft: 5 }}><CircularProgress size={20}/></div>
                              ) : orderReadyTexts[order.order.id()] === 'done' ? (
                                <div style={{ width: '100%', height: '100%',  display: 'flex', justifyContent: 'center', marginLeft: 5, color: 'green', fontSize: 18 }}><Icon>checkmark</Icon><span style={{ marginLeft: 10 }}>Text Sent</span></div>
                              ) : (
                                <Button
                                  primary
                                  style={{ width: '100%', marginLeft: 5, height: '100%', color: 'black', borderStyle: 'solid', borderWidth: 1, borderColor: 'black' }}
                                  onClick={() => this.sendOrderReadyText({ order })}
                                >
                                  Send text
                                </Button>
                              )
                            ) : null
                          }
                        </div>
                      )
                    ) : null
                  }
                </Card>
              ))
            }
          </div>
        </div>
        <Modal
          title='Confirm enable all categories'
          visible={openEnableAllCategoriesModal}
          onOk={() => { this.setState({ openEnableAllCategoriesModal: false }); kernel.performUpdates(jobMenu.enableAllCategories()) }}
          onCancel={() => this.setState({ openEnableAllCategoriesModal: false })}
        >
          <div>
            Are you sure you want to enable all categories?
          </div>
        </Modal>
        <Modal
          title='Confirm disable all categories'
          visible={openDisableAllCategoriesModal}
          onOk={() => { this.setState({ openDisableAllCategoriesModal: false }); kernel.performUpdates(jobMenu.disableAllCategories()) }}
          onCancel={() => this.setState({ openDisableAllCategoriesModal: false })}
        >
          <div>
            Are you sure you want to disable all categories?
          </div>
        </Modal>
        <Modal
          title={`Custom message for ${customMessageUser && customMessageUser.name}`}
          visible={openCustomMessageModal}
          onOk={async () => { 
            this.sendCustomText({ userId: customMessageUser.id, message: customMessageText })
            this.setState({ openCustomMessageModal: false }) 
          }}
          onCancel={() => { this.setState({ openCustomMessageModal: false }) }}
        >
          <input
            style={{ width: '100%' }}
            value={customMessageText}
            placeholder={'Enter custom message for ' + (customMessageUser && (customMessageUser.name || '').toUpperCase())}
            onChange={e => { this.setState({ customMessageText: e.target.value }) }}
          />
        </Modal>
        <Modal
          title='Confirm change active day'
          visible={openIncrementDayModal}
          onOk={() => { this.setState({ openIncrementDayModal: false }); incrementDay() }}
          onCancel={() => this.setState({ openIncrementDayModal: false })}
        >
          <div>
            Are you sure you want to change the active day? It will change for everyone.
          </div>
        </Modal>
        <Modal
          title='Confirm change active day'
          visible={openDecrementDayModal}
          onOk={() => { this.setState({ openDecrementDayModal: false }); decrementDay() }}
          onCancel={() => this.setState({ openDecrementDayModal: false })}
        >
          <div>
            Are you sure you want to change the active day? It will change for everyone.
          </div>
        </Modal>
        <Modal
          title='Send mass text?'
          visible={openSendMassTextModal}
          onOk={async () => { await this.setState({ openSendMassTextModal: false }); this.broadcastText() }}
          onCancel={() => this.setState({ openSendMassTextModal: false })}
        >
          <div>
            {textToBroadcast}
          </div>
        </Modal>
      </div>
    )
  }
})
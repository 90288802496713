import LogRocket from 'logrocket'
import * as u from '@blast-engine/utils'

const connectionStatus = new u.Emitter({
  isConnected: true,
  initial: true
})

window.connectionStatus = connectionStatus

let lastStatus = null
connectionStatus.subscribe(status => {

  LogRocket.log({ event: 'CONNECTION_STATUS_EVENT', status })

  if (
    !status.initial && 
    lastStatus && 
    lastStatus.isConnected !== status.isConnected
  ) {
    LogRocket.log({ event: 'CONNECTION_STATUS_CHANGED', status })
    if (lastStatus.isConnected && !status.isConnected) LogRocket.track('DISCONNECTED')
    if (!lastStatus.isConnected && status.isConnected) LogRocket.track('RECONNECTED')
  }

  lastStatus = status
})

export { connectionStatus }
import { createFetcher } from '@blast-engine/firework'

export const fetchBreakfastPreorderForUserAndDay = createFetcher({
  name: 'fetch-breakfast-preorders'
}, ({ crafterId, jobId, dayString, userId }) => ({
  steps: [{
    name: 'preorder',
    query: ({ root }) => root.breakfastPreorderForUserAndDay({ crafterId, jobId, dayString, userId }).query()
  }],
  final: {
    take: [ 'preorder' ],
    instantiate: ({ preorder }) => preorder
  }
}))
import { keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { JobCrewUsers } from './job-crew-users.node'
import { UserBasicSelection } from '../../../../user/user-basic.selection'

export const JobCrewUsersComplete = createAssemblyClass({
  name: 'JobCrewUsersComplete',
  memberModels: {
    jobCrewUsers: JobCrewUsers.full(),
    userBasics: UserBasicSelection.full()
  },
  portMethods: { },
  body: class {
    jobRecentUsers(howLong) {
      const { jobCrewUsers, userBasics } = this.members
      const recentUIds = jobCrewUsers.recentUserIds(howLong)
      return userBasics.asArray().filter(u => recentUIds.includes(u.id()))
    }
  }
})
import { createNodeClass } from '@blast-engine/firework'
import { keys } from '@blast-engine/utils'

import { JobBreakfastPreorderMenuForDay } from './job-breakfast-preorder-menu-for-day.node'

export const JobBreakfastPreorder = createNodeClass({
  name: 'JobBreakfastPreorder',

  full: class {

    addLineItem(itemId) {
      return this._update({ 
        lineItems: {
          ...this._data('lineItems'),
          [itemId]: this.numForLineItem(itemId) + 1
        }
      })
    }
    
    numForLineItem(itemId) {
      return this._data(`lineItems.${itemId}`) || 0
    }

    removeLineItem(itemId) {
      return this._update({ 
        lineItems: {
          ...this._data('lineItems'),
          [itemId]: this.numForLineItem(itemId) > 0 ? this.numForLineItem(itemId) - 1 : 0
        }
      })
    }

    pickupTime() {
      return this._data('pickupTime')
    }

    setPickupTime(timeString) {
      return this._update({
        pickupTime: timeString
      })
    }
    
  },

  ref: class {

  }

})
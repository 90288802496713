import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'
import { relativeLocation, routerPrefix } from './router-utils'
import { FindJob } from './find-job'

// import './login.scss'


export const CurrentJobRouter = 
  withRouter(
  responsiveComponent(
  fireworkConnect(({ me }) => {
    if (!isReady(me) || !me.currentJob()) return {}
    return { currentJob: entities.crafter.crafterData.job.fetchJobBasic(me.currentJob()) }
  })(
class CurrentJobRouter extends React.Component {

  state = {}

  render() {
    const { me, currentJob, match, location, renderContent } = this.props

    const prefix = routerPrefix(match)
    const relloc = relativeLocation(location, prefix)

    if (!isReady(me)) return null
    
    if (!me.currentJob() && relloc !== '/find-job') 
      return <Redirect to={`${prefix}/find-job`}/>

    if (me.currentJob() && relloc === '/find-job') 
      return <Redirect to={`${prefix}`}/>

    return (
      <Switch>
        <Route path={`${prefix}/find-job`}>
          <FindJob/>
        </Route>            
        <Route path={prefix}>
          { renderContent({ me, currentJob }) }
        </Route>
      </Switch>
    )
  }
})))
import React from 'react'
import { Modal } from 'antd'
import { TextField, Button, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core'
import { isReady } from '@blast-engine/firework'
import { v } from '@blast-engine/utils' 

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-option.scss'

export const CraftOption = fireworkConnect(props => ({

}))(class CraftOption extends React.Component {
  state = {
    changeOptionItemLabelModalVariable: null,
    addOptionItemModalVisible: false,
    newOptionLabelValue: '',
    optionType: '',
    changeOptionLabelModalVisible: false,
    optionLabelValue: '',
    editOptionLabelValue: ''
  }

  componentDidMount() {
    this.setState({ optionType: this.props.optionBasic.type() })
  }

  removeOptionItem(optionItem) {
    const { optionBasic } = this.props
    const { changeOptionItemLabelModalVariable, addOptionItemModalVisible, newOptionLabelValue } = this.state
    
    const update = optionBasic.removeOptionItem({ optionItemId: optionItem.id })
    kernel.performUpdates([update])
  }

  changeOptionItem() {
    const { optionBasic } = this.props
    const { changeOptionItemLabelModalVariable, addOptionItemModalVisible, newOptionLabelValue } = this.state
    
    const update = optionBasic.updateOptionItem({ label: newOptionLabelValue, optionItemId: changeOptionItemLabelModalVariable.id })
    kernel.performUpdates([update])
    this.setState({ changeOptionItemLabelModalVariable: null })
  }

  addOptionItem() {
    const { optionBasic } = this.props
    const { changeOptionItemLabelModalVariable, addOptionItemModalVisible, newOptionLabelValue } = this.state

    const update = optionBasic.addOptionItem({ label: newOptionLabelValue })
    kernel.performUpdates([update])
    this.setState({ newOptionLabelValue: '', addOptionItemModalVisible: false })
  }

  changeOptionLabel() {
    const { optionBasic } = this.props
    const { optionLabelValue, changeOptionLabelModalVisible, optionType, changeOptionItemLabelModalVariable, addOptionItemModalVisible, newOptionLabelValue } = this.state

    const update = optionBasic.updateLabel({ label: optionLabelValue })
    kernel.performUpdates([update])
    this.setState({ optionLabelValue: '', changeOptionLabelModalVisible: false })
  }

  render() {
    const { optionBasic } = this.props
    const { editOptionLabelValue, optionLabelValue, changeOptionLabelModalVisible, optionType, changeOptionItemLabelModalVariable, addOptionItemModalVisible, newOptionLabelValue } = this.state

    // if (!isReady()) return null
    // window.opt = optionBasic
    return (
      <div className='craft-option-container'>
        {/* <MModal
          aria-labelledby='Edit Option Label'
          open={!!changeOptionItemLabelModalVariable}
          // onOk={() => this.changeOptionItem()}
          onClose={() => this.setState({ changeOptionItemLabelModalVariable: null })}
        >
          <input value={newOptionLabelValue} onChange={e => this.setState({ newOptionLabelValue: e.target.value })} />
        </MModal> */}
        <Modal
          title='Edit Option Label'
          visible={!!changeOptionItemLabelModalVariable}
          onOk={() => this.changeOptionItem()}
          onCancel={() => this.setState({ changeOptionItemLabelModalVariable: null })}
        >
          <TextField 
            required
            onKeyPress={e => e.key === 'Enter' && this.changeOptionItem()} 
            variant='outlined' 
            label='Label' 
            value={editOptionLabelValue} 
            onChange={e => this.setState({ newOptionLabelValue: e.target.value })} 
          />
        </Modal>
        <Modal
          title='Add Option Item'
          visible={addOptionItemModalVisible}
          onOk={() => this.addOptionItem()}
          onCancel={() => this.setState({ addOptionItemModalVisible: false })}
        >
          <TextField required onKeyPress={e => e.key === 'Enter' && this.addOptionItem()} variant='outlined' label='Label' value={newOptionLabelValue} placeholder='option item label' onChange={e => this.setState({ newOptionLabelValue: e.target.value })} />
        </Modal>
        <Modal
          title='Change Option Label'
          visible={changeOptionLabelModalVisible}
          onOk={() => this.changeOptionLabel()}
          onCancel={() => this.setState({ changeOptionLabelModalVisible: false })}
        >
          <TextField onKeyPress={e => e.key === 'Enter' && this.changeOptionLabel()} variant='outlined' label='Label' value={optionLabelValue} placeholder='option item label' onChange={e => this.setState({ optionLabelValue: e.target.value })} />
        </Modal>
        <div>option title: 
          <strong>{optionBasic.label()}</strong>
          <Button color='primary' onClick={() => this.setState({ changeOptionLabelModalVisible: true, optionLabelValue: optionBasic.label() })} >Change Title</Button>
          <Button color='secondary'  onClick={() => {
            kernel.performUpdates([ optionBasic.delete() ])
          }} >Remove Option</Button>
        </div>
        <div>
          option Type
          <FormControl>
            <Select value={optionType} onChange={e => {
              this.setState({ optionType: e.target.value })
              const update = optionBasic.updateType({ type: e.target.value })
              kernel.performUpdates([update])
            }} >
              <MenuItem value='checkbox'>Multi Option</MenuItem>
              <MenuItem value='radio'>One Option</MenuItem>
            </Select>
            <FormHelperText>{optionType === 'checkbox' ? 'Multiple Option Items Can Be Selected' : 'Only One Option Item Can Be Selected'}</FormHelperText>
          </FormControl>
          
        </div>
        <div>
          option items:
        </div>
        {
          v(optionBasic.items()).map(optItem => <div key={optItem.id}>
            <span>{optItem.label}</span>
            <Button color='primary' onClick={() => this.setState({ changeOptionItemLabelModalVariable: optItem, editOptionLabelValue: optItem.label })}>
              change label
            </Button>
            <Button color='secondary' onClick={() => this.removeOptionItem(optItem)}>remove</Button>
          </div>)
        }
        <Button variant='outlined' color='primary' onClick={() => this.setState({ addOptionItemModalVisible: !this.state.addOptionItemModalVisible })}>add option item</Button>
      </div>
    )
  }
})
import { SubOrderList } from './sub-order.list'
import { SubOrder } from './sub-order.node'
import { SubOrdersForJobDay } from './sub-orders-for-job-day.node'
import { AllSubOrders } from './all-sub-orders.node'

export const rootMethods = {

  subOrdersRef({ crafterId, jobId, day }) {
    return this._spinoff(SubOrdersForJobDay.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/subOrders/${day}`
    })
  },

  allSubOrdersRef({ crafterId, jobId }) {
    return this._spinoff(AllSubOrders.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/subOrders`
    })
  }

}
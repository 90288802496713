import { SubGlobalBasicList } from './sub-global-basic.node.list'
import { SubGlobalOptionList } from './sub-global-option.node.list'

export const rootMethods = {

  subGlobalItemsRef({ crafterId }) {
    return this._spinoff(SubGlobalBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/subsGlobalMenu/items`
    })
  },

  subGlobalOptionsRef({ crafterId }) {
    return this._spinoff(SubGlobalOptionList.ref(), {
      path: `crafter/crafterData/${crafterId}/subsGlobalMenu/options`
    })
  }

}
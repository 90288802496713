import { PausedService } from './paused-service.node'

export const rootMethods = {

  pausedServiceRef({ jobId, crafterId }) {
    return this._spinoff(PausedService.ref(), {
      path: `crafter/crafterData/${crafterId}/job/jobData/${jobId}/pausedService`
    })
  }
  
}
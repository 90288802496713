import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, Fab, TextField, Select, MenuItem } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Divider, Modal } from 'antd'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-manage-users.scss'

export const CraftOfficeManageUsers = fireworkConnect(props => ({
  crafterManager: entities.fetchCrafterManager({ crafterId: props.crafterId })
}))(class CraftOfficeManageUsers extends React.Component {
  state = {
    newUserId: '', 
    newUserRole: '',
    newUserError: '',
    isAddNewUserModalVisible: false
  }

  addUser() {
    const { craftyManager, crafterManager, crafterId } = this.props
    const { newUserId, newUserRole } = this.state
    const userId = crafterManager.getUserIdFromEmail({ email: newUserId })
    if (!userId) return this.setState({ newUserError: 'No User With That Email Found' })
    if (!newUserRole) return this.setState({ newUserError: 'All Fields Are Required' })
    
    const update = crafterManager.addCrafterMember({ userId, role: newUserRole })
    kernel.performUpdates([update, crafterManager.inviteUserToCrafter({ crafterId, userId, role: newUserRole })])

    this.setState({ newUserRole: '', newUserId: '', newUserError: '', isAddNewUserModalVisible: false })
  }

  render() {
    const { craftyManager, crafterManager } = this.props
    const { newUserId, newUserRole, isAddNewUserModalVisible, newUserError } = this.state

    if (!isReady(crafterManager)) return null
    
    return (
      <div className='craft-office-manage-users-container'>
        <Modal
          title='Add New User'
          visible={isAddNewUserModalVisible}
          onOk={() => this.addUser()}
          onCancel={() => this.setState({ newUserRole: '', newUserId: '', newUserError: '', isAddNewUserModalVisible: false })}
        >
          <div className='modal-body'>
            <div className='modal-section'>
              <TextField label='User Email' placeholder='Email' variant='outlined' color='primary' value={newUserId} onChange={e => this.setState({ newUserId: e.target.value })} />
            </div>
            <div className='modal-section'>
              Role: 
              <Select style={{ marginLeft: '2vw', width: '50%' }} onChange={e => this.setState({ newUserRole: e.target.value })} value={newUserRole}>
                <MenuItem value='crafter-office'>Admin</MenuItem>
                <MenuItem value='crafter-on-site'>Member</MenuItem>
              </Select>
            </div>
            <div className='modal-section error'>
              {newUserError}
            </div>
          </div>

        </Modal>
        
        <Fab variant='extended' className='fab' aria-label='add' onClick={() => this.setState({ isAddNewUserModalVisible: true })}>
          <Add />
          Add New User
        </Fab>
        
        <Divider className='divider-text' orientation='left'>Current Users</Divider>
        {
          crafterManager.crafterMembers().map(crafterMember => (
            <div className='each-user' key={crafterMember.crafterId}>
              {crafterMember.user.username()}<span className='user-role'>{' - '}{crafterMember.role.includes('office') ? 'Admin' : 'Member'}</span>
            </div>
          ))
        }
      </div>
    )
  }
})
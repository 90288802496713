import { createNodeClass } from '@blast-engine/firework'

export const TextNotifRequest = createNodeClass({
  name: 'TextNotifRequest',

  full: class {

    id() {
      return this._data('id')
    }

    number() {
      return this._data('number')
    }

    content() {
      return this._data('content')
    }

  },

  ref: class {

    initialize({ id, number, content }) {
      return this._update({ id, number, content })
    }

  }

})
import { CrafterBasic } from './crafter-basic.node'
import { CrafterBasicList } from './crafter-basic.list'
import { fetchCrafterBasicList } from './crafter-basic.list.fetcher'
import * as crafterBasicExampleData from './crafter-basic.node.test-data'
import * as crafterBasicListExampleData from './crafter-basic.list.test-data'
import { crafterData } from './crafter-data'
import { rootMethods } from './root-methods'

export const crafter = {
  CrafterBasic,
  CrafterBasicList,
  fetchCrafterBasicList,
  crafterBasicExampleData,
  crafterBasicListExampleData,
  crafterBasicExampleData,
  crafterData,

  rootMethods
}
import { createFetcher } from '@blast-engine/firework'

// import { CraftOrdersComplete } from './orders-complete.assembly'
import  { JobOrdersComplete } from './job-orders.complete'
import { craftMenu } from './craft-global-menu'
import { subsGlobalMenu } from './subs-global-menu'
import { JobSubOrdersComplete } from './job-sub-orders.assembly'

export const fetchJobOrdersComplete = createFetcher({
  name: 'fetch-job-orders-complete'
}, ({ crafterId, jobId }) => ({
  steps: [{
    name: 'globalCategories',
    query: ({ root }) => root.craftGlobalCategoryBasicListRef({ crafterId }).query()
   }, {
    name: 'globalItems',
    query: ({ root }) => root.craftGlobalItemBasicListRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.craftGlobalOptionsBasicListRef({ crafterId }).query()
  }, {
    name: 'job',
    query: ({ root }) => root.crafterJobBasicRef({ crafterId, jobId }).query()
  }, {
    name: 'craftOrders',
    requires: [ 'job' ],
    query: ({ root, job }) => root.craftOrdersRef({ crafterId, jobId, day: job.currentDay() }).query()
  }
  // --- remove
  , {
    name: 'subItems',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'subOptions',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }, {  
    name: 'dayJobMenu',
    requires: [ 'job' ],
    query: ({ root, job }) => root.crafterJobSubMenuForDay({ crafterId, jobId, day: job.currentDay() }).query()
  }, {
    name: 'subGlobalItems',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'subGlobalOptions',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }, {
    name: 'subOrdersForDay',
    requires: [ 'job' ],
    query: ({ root, job }) => root.subOrdersRef({ crafterId, jobId, day: job.currentDay() }).query()
  }],

  final: {
    take: ['globalCategories', 'globalItems', 'globalOptions', 'job', 'craftOrders' ],
    instantiate: ({ globalCategories, globalItems, globalOptions, job, root, craftOrders }) => 
      root._spinoff(JobOrdersComplete, { 
        crafterId,
        jobId,
        currentDay: job.currentDay(), 
        
        members: { 
          craftOrders,
          craftGlobalMenu: root._spinoff(craftMenu.CrafterCraftGlobalMenu, {
            members: {
              categories: globalCategories, 
              items: globalItems, 
              options: globalOptions
            }
          }),
          notificationsQueueRef: root.notificationsQueueRef()
        }
      })
  }
}))
import React from 'react'
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Select, FormControl, MenuItem, FormHelperText } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm, AddBoxOutlined } from '@material-ui/icons'
import { keys, rollup, kv } from '@blast-engine/utils'
import { isReady } from '@blast-engine/firework'
import ReactToPdf from 'react-to-pdf'

import * as Comps from '@/components'

import { fireworkConnect, kernel } from '@/kernel.singleton'

import './global-menu-subs.scss'

export const GlobalMenuSubs = fireworkConnect(props => ({
  lunchGlobalMenu: entities.crafter.crafterData.lunchGlobalMenu.fetchLunchGlobalMenu({ crafterId: props.crafterId }),
  craftGlobalMenu: entities.crafter.crafterData.craftMenu.fetchCraftGlobalMenu({ crafterId: props.crafterId }),
  subGlobalMenu: entities.crafter.crafterData.subsGlobalMenu.fetchSubsGlobalMenu({ crafterId: props.crafterId })
}))(class GlobalMenuSubs extends React.Component {
  state = {
    mealOptionItems: [],
    mealOptionSelection: {}
  }

  createMeal() {
    const { subGlobalMenu } = this.props
    const { newMealName, newMealDescription } = this.state
    if (!newMealName || !newMealName.length) return this.setState({ modalError: 'Meal Name Cannot be Empty' })

    kernel.performUpdates([ subGlobalMenu.addItem({ name: newMealName, description: newMealDescription || null }) ])

    this.setState({ newMealName: '', newMealDescription: '', newMealModalVisibility: false, modalError: '' })
  }

  editMeal() {
    const { subGlobalMenu } = this.props
    const { editedMealName, editedMealDescription, editedMealItem } = this.state
    if (!editedMealName || !editedMealName.length) return this.setState({ modalError: 'Meal Name Cannot be Empty' })

    kernel.performUpdates(subGlobalMenu.updateItem({ itemId: editedMealItem.id(), updatedLabel: editedMealName, updatedDescription: editedMealDescription || null }))

    this.setState({ editedMealItem: null, editedMealName: '', editedMealDescription: '', editedMealModalVisibility: false, modalError: '' })
  }

  addOptionsToMeal() {
    const { subGlobalMenu } = this.props
    const { mealOptionSelection, addOptionsToMealModalItem } = this.state
    
    const updates = kv(mealOptionSelection).map(({ k: optionId, v: isSelected }) => {
      if (isSelected) {
        return subGlobalMenu.addOptionToMeal({ itemId: addOptionsToMealModalItem.id(), optionId })
      }
      return subGlobalMenu.removeOptionFromMeal({ itemId: addOptionsToMealModalItem.id(), optionId })
    })

    kernel.performUpdates(updates)

    this.setState({ addOptionsToMealModalItem: null, mealOptionSelection: {} })
  }

  createOption() {
    const { subGlobalMenu } = this.props
    const { mealOptionItems, newOptionLabel, mealOptionType, modalError } = this.state
    if (!mealOptionItems.length || !newOptionLabel || !mealOptionType) return this.setState({ modalError: 'Please Fill in All The Fields' })
    if (mealOptionItems.filter(i => !i || !i.length).length) return this.setState({ modalError: 'Option Item Label Cannot be Empty' })

    kernel.performUpdates([ subGlobalMenu.addOption({ type: mealOptionType, label: newOptionLabel, items: mealOptionItems }) ])

    this.setState({ createOptionModalVisible: false, mealOptionItems: [], modalError: '' })
  }

  editOption() {
    const { subGlobalMenu } = this.props
    const { editOptionModalOption, editedOptionLabel, editedOptionType } = this.state
    
    if (!editOptionModalOption || !editedOptionLabel || !editedOptionLabel.length || !editedOptionType) return this.setState({ modalError: 'Please Fill in All The Fields' })

    const updates = []
    updates.push(subGlobalMenu.option(editOptionModalOption.id()).updateLabel({ label: editedOptionLabel }))
    updates.push(subGlobalMenu.option(editOptionModalOption.id()).updateType({ type: editedOptionType }))

    kernel.performUpdates(updates)

    this.setState({ editOptionModalOption: null, editedOptionLabel: '', editedOptionType: '', modalError: '' })
  }

  createNewOptionItem() {
    const { subGlobalMenu } = this.props
    const { newOptionItemModalOption, newOptionItemLabel } = this.state

    if (!newOptionItemModalOption || !newOptionItemLabel) return this.setState({ modalError: 'Please Fill in All The Fields' })

    kernel.performUpdates([ newOptionItemModalOption.addItem({ label: newOptionItemLabel }) ])

    this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '', modalError: '' })
  }

  render() {
    const { subGlobalMenu } = this.props
    const { } = this.state

    if (!isReady(subGlobalMenu)) return null

    const { Panel } = Collapse
    
    return (
      <div className='global-menu-subs-container'>
        <Collapse > 
          <Panel
            header={
              <div className='each-header'>
                <div className='left-section'>
                  Meals
                </div>
                <div className='right-section'>
                  <Button
                    variant='text'
                    color='primary'
                    onClick={e => {
                      e.stopPropagation()
                      this.setState({ newMealModalVisibility: true })
                    }}
                  >
                    Create New Meal
                  </Button>
                </div>
              </div>
            }
            className='each-panel'
            key='meals'
          >
            <Collapse>
              {
                subGlobalMenu.getAllItems().map(item => {
                  return (
                    <Panel
                      header={
                        <div className='each-header'>
                          <div className='left-section' style={{ maxWidth: '50vw' }}>
                            {item.name()}{item.description() ? <span style={{ color: 'gray'}}>{' - '}{item.description()}</span> : null }
                          </div>
                          <div className='right-section'>
                          <Button
                            variant='text'
                            color='primary'
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({ editedMealItem: item, editedMealName: item.name(), editedMealDescription: item.description() || '', editedMealModalVisibility: true })
                            }}
                          >
                            Edit Meal
                          </Button>
                          <Button
                            variant='text'
                            color='primary'
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({ addOptionsToMealModalItem: item, mealOptionSelection: rollup(subGlobalMenu.getOptionsForItem({ itemId: item.id() }).map(i => i.id())) })
                            }}
                          >
                            Add/Remove Options
                          </Button>
                          <Button
                            variant='text'
                            color='secondary'
                            onClick={e => {
                              e.stopPropagation()
                              const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                              if (confirmed) {
                                kernel.performUpdates([ subGlobalMenu.fakeDeleteMeal({ itemId: item.id() }) ])
                              }
                            }}
                          >
                            Delete Meal
                          </Button>
                          </div>
                        </div>
                      }
                    >
                      <Collapse>
                        {
                          subGlobalMenu.getOptionsForItem({ itemId: item.id() }).map(option => {
                            return (
                              <Panel
                                key={option.id()}
                                header={
                                  <div className='each-header'>
                                    <div className='left-section'>
                                      {option.label()}
                                    </div>
                                    <div className='right-side'>
                                      {/* set required options? */}
                                    </div>
                                  </div>
                                }
                              >
                                {
                                  subGlobalMenu.getOptionItemsForOption({ optionId: option.id() }).map(({ id, label }) => (
                                    <div className='each-option-item' key={id}>
                                      {label}
                                    </div>
                                  ))
                                }
                              </Panel>
                            )
                          })
                        }
                      </Collapse>
                    </Panel>
                  )
                })
              }
            </Collapse>
          </Panel>
          <Panel
            key='meal-options'
            header={
              <div className='each-header'>
                <div className='left-side'>
                  Meal Options
                </div>
                <div className='right-side'>
                  <Button
                    variant='text'
                    color='primary'
                    onClick={e => {
                      e.stopPropagation()
                      this.setState({ createOptionModalVisible: true })
                    }}
                  >
                    Create New Option
                  </Button>
                </div>
              </div>
            }
          >
            <Collapse>
              {
                subGlobalMenu.getAllOptions().map(option => {
                  return (
                    <Panel
                      key={option.id()}
                      header={
                        <div className='each-header'>
                          <div className='left-section'>
                            {option.label()}
                          </div>
                          <div className='right-side'>
                            <Button
                              variant='text'
                              color='primary'
                              onClick={e => {
                                e.stopPropagation()
                                this.setState({ editOptionModalOption: option, editedOptionLabel: option.label(), editedOptionType: option.type() })
                              }}
                            >
                              Edit Option
                            </Button>
                            <Button
                              variant='text'
                              color='primary'
                              onClick={e => {
                                e.stopPropagation()
                                this.setState({ newOptionItemModalOption: option, newOptionItemLabel: '' })
                              }}
                            >
                              Create New Option Item
                            </Button>
                            <Button
                              variant='text'
                              color='secondary'
                              onClick={e => {
                                e.stopPropagation()
                                const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                                if (confirmed) {
                                  kernel.performUpdates([ subGlobalMenu.fakeDeleteOption({ optionId: option.id() }) ])
                                }
                              }}
                            >
                              Delete Option
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      {
                        subGlobalMenu.getOptionItemsForOption({ optionId: option.id() }).map(({ id, label }) => (
                          <div className='each-option-item each-header' key={id}>
                            <div className='left-side'>
                              {label}
                            </div>
                            <div className='right-side'>
                              {/* <Button
                                variant='text'
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation()

                                }}
                              >
                                Change Option Item Name
                              </Button> */}
                              <Button
                                variant='text'
                                color='secondary'
                                onClick={e => {
                                  e.stopPropagation()
                                  const confirmed = window.confirm('This Action is Permanent. Are you sure you want to proceed with deletion?')
                                  if (confirmed) {
                                    kernel.performUpdates([ subGlobalMenu.fakeDeleteOptionItem({ optionId: option.id(), optionItemId: id }) ])
                                  }
                                }}
                              >
                                Delete Option Item
                              </Button>
                            </div>
                          </div>
                        ))
                      }
                    </Panel>
                  )
                })
              }
            </Collapse>
          </Panel>
        </Collapse>

        { /* MODALS */ }


        { /* Create New Option Modal */}
        <Modal
          title='Create New Option'
          visible={this.state.createOptionModalVisible}
          onOk={() => this.createOption()}
          onCancel={() => this.setState({ createOptionModalVisible: false, mealOptionItems: [], modalError: '' })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='New Option Label'
              placeholder='Option Label'
              value={this.state.newOptionLabel}
              onChange={e => this.setState({ newOptionLabel: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.createOption()}
            />
            <div className='refactor-me modal-section'>
              Option Type
              <FormControl>
                <Select value={this.state.mealOptionType} onChange={e => {
                  this.setState({ mealOptionType: e.target.value })
                }} >
                  <MenuItem value='checkbox'>Multi Option</MenuItem>
                  <MenuItem value='radio'>One Option</MenuItem>
                </Select>
                <FormHelperText>{this.state.mealOptionType === 'checkbox' ? 'Multiple Option Items Can Be Selected' : 'Only One Option Item Can Be Selected'}</FormHelperText>
              </FormControl>
            </div>
            <div className='modal-section'>
              {
                this.state.mealOptionItems.map((item, index) => 
                  <div className='modal-section' key={index}>
                    <TextField 
                      required 
                      label='Option Item Name' 
                      variant='outlined' 
                      color='primary' 
                      value={item} 
                      onChange={e => {
                        const i = this.state.mealOptionItems
                        i[index] = e.target.value
                        this.setState({ mealOptionItems: i })
                      }} 
                      onKeyPress={e => e.key === 'Enter' && this.createOption()}
                    />
                  </div>
                )
              }
            </div>
            <Button 
              variant='outlined' 
              color='primary' 
              onClick={() => 
                this.setState({ mealOptionItems: this.state.mealOptionItems.concat('') })
              }
            >
              Add New Option Item
            </Button>
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
          
        </Modal>


        <Modal
          title='Edit Option'
          visible={!!this.state.editOptionModalOption}
          onOk={() => this.editOption()}
          onCancel={() => this.setState({ editOptionModalOption: null, editedOptionLabel: '', editedOptionType: '', modalError: '' })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='Option Label'
              placeholder='Option Label'
              value={this.state.editedOptionLabel}
              onChange={e => this.setState({ editedOptionLabel: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.editOption()}
            />
            <div className='refactor-me modal-section'>
              Option Type
              <FormControl>
                <Select value={this.state.editedOptionType} onChange={e => {
                  this.setState({ editedOptionType: e.target.value })
                }} >
                  <MenuItem value='checkbox'>Multi Option</MenuItem>
                  <MenuItem value='radio'>One Option</MenuItem>
                </Select>
                <FormHelperText>{this.state.editedOptionType === 'checkbox' ? 'Multiple Option Items Can Be Selected' : 'Only One Option Item Can Be Selected'}</FormHelperText>
              </FormControl>
            </div>
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          
          </div>
        </Modal>


        <Modal
          title='Create New Option Item'
          visible={!!this.state.newOptionItemModalOption}
          onOk={() => this.createNewOptionItem()}
          onCancel={() => this.setState({ newOptionItemModalOption: null, newOptionItemLabel: '', modalError: '' })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='New Option Item Name'
              placeholder='Option Item Name'
              value={this.state.newOptionItemLabel}
              onChange={e => this.setState({ newOptionItemLabel: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.createNewOptionItem()}
            />
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
        </Modal>

        <Modal
          title='Create New Meal'
          visible={this.state.newMealModalVisibility}
          onOk={() => this.createMeal()}
          onCancel={() => this.setState({ newMealModalVisibility: false, newMealName: '', modalError: '', newMealDescription: '' })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='New Meal Name'
              placeholder='Meal Name'
              value={this.state.newMealName}
              onChange={e => this.setState({ newMealName: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.createMeal()}
            />
            <TextField 
              className='modal-section'
              variant='outlined'
              label='New Meal Description'
              placeholder='Meal Description'
              value={this.state.newMealDescription}
              onChange={e => this.setState({ newMealDescription: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.createMeal()}
            />
            
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
        </Modal>

        <Modal
          title='Edit Meal'
          visible={this.state.editedMealModalVisibility}
          onOk={() => this.editMeal()}
          onCancel={() => this.setState({ editedMealModalVisibility: false, editedMealName: '', modalError: '', editedMealDescription: '' })}
        >
          <div className='modal-body'>
            <TextField 
              required
              className='modal-section'
              variant='outlined'
              label='Meal Name'
              placeholder='Meal Name'
              value={this.state.editedMealName}
              onChange={e => this.setState({ editedMealName: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.editMeal()}
            />
            <TextField 
              className='modal-section'
              variant='outlined'
              label='Meal Description'
              placeholder='Meal Description'
              value={this.state.editedMealDescription}
              onChange={e => this.setState({ editedMealDescription: e.target.value })}
              onKeyPress={e => e.key === 'Enter' && this.editMeal()}
            />
            
            { this.state.modalError && 
              <div className='modal-error'>
                {this.state.modalError}
              </div>
            }
          </div>
        </Modal>


        <Modal
          title='Add Options To Meal'
          visible={!!this.state.addOptionsToMealModalItem}
          onOk={() => this.addOptionsToMeal()}
          onCancel={() => this.setState({ addOptionsToMealModalItem: null, mealOptionSelection: {} })}
        >
          <FormGroup row={true}>
            {
              subGlobalMenu.getAllOptions().filter(i => i).map(optionBasic => (
                <FormControlLabel
                  label={optionBasic.label()}
                  control={
                    <Checkbox
                      color='primary'
                      name={optionBasic.id()}
                      checked={!!this.state.mealOptionSelection[optionBasic.id()]}
                      onChange={e => {
                        const opts = this.state.mealOptionSelection
                        opts[optionBasic.id()] = e.target.checked
                        this.setState({ mealOptionSelection: opts })
                      }}
                    />
                  }
                />
              ))
            }
          </FormGroup>
          { this.state.modalError && 
            <div className='modal-error'>
              {this.state.modalError}
            </div>
          }
        </Modal>
      </div>
    )
  }
})
import { createNodeClass } from '@blast-engine/firework'

export const CraftGlobalCategoryBasic = createNodeClass({
  name: 'CraftGlobalCategoryBasic',

  full: class {

    id() {
      return this._data('id')
    }

    name() {
      return this._data('name')
    }

    updateName({ name }) {
      return this._update({ name })
    }

    priority() {
      return parseInt(this._data('priority') || 100)
    }

    setPriority(priority = 100) {
      return this._update({ priority: parseInt(priority) })
    }

    fakeDelete() {
      return this._update({ _deleted: true })
    }

    isFakeDeleted() {
      return !!this._data('_deleted')
    }

  },

  ref: class {

    initialize({ id, name }) {
      return this._update({ id, name })
    }

  }

})
import React from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './crafty-admin.scss'

export const CraftyAdmin = fireworkConnect(props => ({

}))(class CraftyAdmin extends React.Component {
  state = {}

  render() {
    return (
      <div className='crafty-admin-container'>
        <button onClick={() => kernel.logout()}>logout</button>
        <div><Link to='/admin/clients'>clients</Link></div>
        <div><Link to='/admin/crafty'>crafty</Link></div>
        
        <div className='main'>
          <Switch>
            <Route path="/admin/clients/:crafterId" render={props => 
              <Comps.Crafter crafterId={props.match.params.crafterId}/>}
            />
            <Route path="/admin/clients">
              <Comps.Crafters />
            </Route>
            <Route path="/admin/crafty">
              <Comps.Crafty />
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
})
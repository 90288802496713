export const crafterBasicExampleData1 = {
  id: 'test_crafter_1',
  status: 'ok',
  name: 'Test Crafter 1',
  _: true
}

export const crafterBasicExampleData2 = {
  id: 'test_crafter_2',
  status: 'ok',
  name: 'Test Crafter 2',
  _: true
}
import { rollup, keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { CraftGlobalOptionsBasicList } from './craft-global-options'
import { CraftGlobalItemBasic } from './craft-global-item-basic'
import { CraftGlobalCategoryBasic } from './craft-global-category'

export const CrafterCraftGlobalMenuItem = createAssemblyClass({
  name: 'CrafterCraftGlobalMenuItem',
  memberModels: {
    category: CraftGlobalCategoryBasic.full(),
    item: CraftGlobalItemBasic.full(),
    allOptions: CraftGlobalOptionsBasicList.full()
  },
  portMethods: {
    item: [ 'id', 'name', 'description', 'label', 'notesAreEnabled' ],
    category: [ { method: 'id', rename: 'categoryId' } ]
  },
  body: class {

    option(optId) {
      const { allOptions } = this.members 
      return allOptions.item(optId)
    }

    optionName(optId) {
      return this.option(optId).name()
    }

    optionLabel(optId) {
      return this.option(optId).label()
    }

    optionType(optId) {
      return this.option(optId).type()
    }

    optionItemIds(optId) {
      return this.option(optId).optionItemIds()
    }

    optionItemLabel({ optionId, optionItemId }) {
      return this.option(optionId).optionItemLabel(optionItemId)
    }

  }
})
import React from 'react'
import {} from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import {} from 'antd'

import * as Comps from '@/components'

import './footer-desktop.scss'

const facebookUrl = 'https://www.facebook.com/Crafty.Film.Application'
const youtubeUrl = 'https://www.youtube.com/channel/UCXK4uBBkti-wEs7_JLfZgQA'
const linkedinUrl = 'https://www.linkedin.com/company/crafty-film/'
const instagramUrl = 'https://www.instagram.com/crafty.film/'

export const FooterDesktop = class FooterDesktop extends React.Component {
  state = {}

  render() {
    const {} = this.props
    const {} = this.state
    
    return (
      <div className='footer-desktop-container'>
        <div className='line' />
        <div className='content'>
          <div className='links-section each-section'>
            <a href='/privacy-policy' target='_blank' className='each-link'>
              <div className='image-section'>
                <img className='image' src={require('../../../assets/privacy-policy.png')} />
              </div>
              <div className='text'>
                privacy policy
              </div>
            </a>
            {/* <a href='/privacy-policy' target='_blank' className='each-link'>
              <div className='image-section'>
                <img className='image' src={require('../../../assets/terms-and-conditions.png')} />
              </div>
              <div className='text'>
                terms and conditions
              </div>
            </a> */}
          </div>
          <div className='contact-us-section each-section'>
            <div className='text-container'>
              <div className='contact-us-text'>contact us</div>
              <div className='each-contact-link'>email- admin@crafty.film</div>
              <div className='each-contact-link'>phone- +1 (647) 218 3999</div>
            </div>
          </div>
          
        </div>
        <div className='social-media-section'>
          <div className='each-social-media'>
            <a className='each-sm-icon' href={facebookUrl} target='_blank' >
              <FacebookIcon className='each-sm-icon' fontSize='large' />
            </a>
            <a className='each-sm-icon' href={instagramUrl} target='_blank' >
              <InstagramIcon className='each-sm-icon' fontSize='large' />
            </a>
            <a className='each-sm-icon' href={linkedinUrl} target='_blank' >
              <LinkedInIcon className='each-sm-icon' fontSize='large' />
            </a>
            <a className='each-sm-icon' href={youtubeUrl} target='_blank' >
              <YouTubeIcon className='each-sm-icon' fontSize='large' />
            </a>
          </div>
        </div>
      </div>
    )
  }
}
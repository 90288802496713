import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'
import { relativeLocation, routerPrefix } from './router-utils'
import { OfferTextNotifications, Department } from './user-info-requests'
import { FindJob } from './find-job'

// import './login.scss'


export const UserInfoRequestRouter = 
  withRouter(
  responsiveComponent(
  fireworkConnect(({ me }) => ({
    // me: entities.user.fetchMe({ keepOldResultUntilNew: true })
  }))(
class UserInfoRequestRouter extends React.Component {

  state = {}

  render() {
    const { me, match, location, renderContent } = this.props

    const prefix = routerPrefix(match)
    const relloc = relativeLocation(location, prefix)

    if (!isReady(me)) return null

    if (me.shouldAskForPhoneNumber(Date.now()) && !['/user-department', '/offer-text-notifications'].includes(relloc)) 
      return <Redirect to={`${prefix}/offer-text-notifications`}/>

    if (!me.shouldAskForPhoneNumber(Date.now()) && relloc === '/offer-text-notifications') 
      return <Redirect to={`${prefix}`}/>

    if (!me.department() && !['/user-department', '/offer-text-notifications'].includes(relloc)) 
      return <Redirect to={`${prefix}/user-department`}/>

    if (me.department() && relloc === '/user-department') 
      return <Redirect to={`${prefix}`}/>
    
    // if (!me.currentJob() && relloc !== '/find-job') 
    //   return <Redirect to={`${prefix}/find-job`}/>

    // if (me.currentJob() && relloc === '/find-job') 
    //   return <Redirect to={`${prefix}`}/>

    return (
      <Switch>
        <Route path={`${prefix}/offer-text-notifications`}>
          <OfferTextNotifications/>
        </Route>      
        <Route path={`${prefix}/user-department`}>
          <Department/>
        </Route>            
        <Route path={prefix}>
          { renderContent({ me }) }
        </Route>
      </Switch>
    )
  }
})))
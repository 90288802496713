import { CraftOrder } from './craft-order.node'
import { CraftOrderList } from './craft-order.list'
import { CraftOrderSelection } from './craft-order.selection'
import { rootMethods } from './root-methods'

export const craftOrders = {
  CraftOrder,
  CraftOrderList,
  CraftOrderSelection,
  rootMethods
}
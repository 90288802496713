import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField, Icon, Badge } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

import './footer.connected.scss'

export const Footer = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    basket: entities.crafter.crafterData.fetchBasketComplete({
      keepOldResultUntilNew: true,
      crafterId: currentJob.crafterId(),
      jobId: currentJob.id(),
      userId: me.userId()
    }),
    craftOrders: entities.crafter.crafterData.fetchCraftOrdersComplete({
      keepOldResultUntilNew: true,
      crafterId: currentJob.crafterId(),
      jobId: currentJob.id(),
      userId: me.userId()
    })
  }))(
class Footer extends React.Component {

  state = {}

  render() {
    const { 
      // props
      me, currentJob, basket, craftOrders,
      goToBasket = () => null,
      goToHome = () => null,
      goToOrders = () => null
    } = this.props

    if (!isReady(basket, craftOrders)) return null

    const numReadyOrPendingCraftOrders = craftOrders.readyForPickup().length + craftOrders.pending().length
    const numBasketItems = basket.items().length

    return (
      <div style={{ alignItems: 'flex-end', height: '100%', display: 'flex', justifyContent: 'space-around', borderTopColor: '#eceaea', borderTopStyle: 'solid', borderTopWidth: 1 }}>
        <Button onClick={goToHome} className='x-button'>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon>home</Icon>
            <span>Home</span>
          </div>
        </Button>
        <Button onClick={goToOrders} className='x-button'>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Badge badgeContent={numReadyOrPendingCraftOrders} color="primary"><Icon>alarm_on</Icon></Badge>
            <span>Orders</span>
          </div>
        </Button>
        <Button onClick={goToBasket} className='x-button'>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Badge badgeContent={numBasketItems} color="primary"><Icon>shopping_basket</Icon></Badge>
            <span>Basket</span>
          </div>
        </Button>
        {/* <Button>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon>person</Icon>
            <span>Profile</span>
          </div>
        </Button> */}
      </div>
    )
  }
}))
import { createFetcher } from '@blast-engine/firework'

export const fetchActiveCrewOnsite = createFetcher({
  name: 'fetch-active-crew-onsite'
}, ({ crafterId }) => ({
  steps: [{
    name: 'activeCrewOnSite',
    query: ({ root }) => root.activeCrewOnsiteRef({ crafterId }).query()
  }],
  final: {
    take: [ 'activeCrewOnSite' ],
    instantiate: ({ activeCrewOnSite }) => activeCrewOnSite
  }
}))
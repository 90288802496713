import { keys } from '@blast-engine/utils'
import { createAssemblyClass } from '@blast-engine/firework'

import { subsGlobalMenu } from './subs-global-menu'
import { job } from './job'
import { user } from '../../user'

export const DayJobSubMenuDayComplete = createAssemblyClass({
  name: 'DayJobSubMenuDayComplete',
  memberModels: {
    jobBasic: job.JobBasic.full(),
    subGlobalMenu: subsGlobalMenu.CrafterSubsGlobalMenu,
    subMenuItemListForDay: job.jobData.SubMenuItemList,
    mePrivate: user.UserPrivate.full(),
    subOrdersForDayRef: job.jobData.subOrders.SubOrdersForJobDay.ref()
  },
  portMethods: {

  },
  body: class {

    globalMenu() {
      return this.members.subGlobalMenu
    }

    jobMenu() {
      return this.members.subMenuItemListForDay
    }

    enabledSubIds() {
      const { subMenuItemListForDay } = this.members
      return subMenuItemListForDay.asArray()
        .filter(subItem => subItem.enabled())
        .map(subItem => subItem.id())
    }

    enabledSubOptionIds(subId) {
      const { subMenuItemListForDay } = this.members
      const subItem = subMenuItemListForDay.item(subId)
      return subItem.enabledOptionIds()
    }

    enabledOptionItemIds({ subId, optId }) {
      const { subMenuItemListForDay, subGlobalMenu } = this.members
      const optionItems = subGlobalMenu.option(optId).items()
      const optionItemIds = keys(optionItems)
      const jobSubItem = subMenuItemListForDay.item(subId)
      return optionItemIds
        .filter(optionItemId => jobSubItem.isOptionItemEnabled({ 
          optionId: optId, 
          optionItemId 
        }))
    }

    subOptionName(optId) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optId).label()
    }

    subName(subId) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.subName(subId)
    }

    subStatus(subId) {
      const { subMenuItemListForDay } = this.members
      const jobSubItem = subMenuItemListForDay.item(subId)
      return jobSubItem.status()
    }

    optionType(optionId){
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optionId).type()
    }

    optionItemLabel({ optionId, optionItemId }) {
      const { subGlobalMenu } = this.members
      return subGlobalMenu.option(optionId).itemLabel(optionItemId)
    }

    orderSub({ subId, optionSelections = null, notes = '' }) {
      const { jobBasic, subOrdersForDayRef, mePrivate } = this.members
      return [
        mePrivate.addJobSubOrderForDay({ 
          jobId: jobBasic.id(), 
          day: jobBasic.currentDay(), 
          subId 
        }),
        subOrdersForDayRef.addSubOrder({ 
          subId, 
          userId: mePrivate.id(), 
          optionSelections,
          notes
        })
      ] 
    }

  }
})
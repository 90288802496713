import { createFetcher } from '@blast-engine/firework'

export const fetchCrafterBasicList = createFetcher({
  name: 'fetch-crafter-basic-list'
}, () => ({
  steps: [{
    name: 'list',
    requires: [ ],
    query: ({ root }) => 
      root.crafterBasicListRef().query()
  }],
  final: {
    take: [],
    instantiate: ({ list }) => list
  }
}))
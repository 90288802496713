import React, { useState } from 'react'
import { isReady } from '@blast-engine/firework'
import { Icon, Button, Input, Radio, CircularProgress } from '@material-ui/core'
import { Card } from 'antd'
import { values } from '@blast-engine/utils'

import * as entities from '@crafty/entities'
import { fireworkConnect, kernel } from '@/kernel.singleton'
import { craftOrders } from '@crafty/entities/src/crafter/crafter-data/job/jobData/craft-orders'

import './orders-page.ui.scss'

export const OrdersPage = fireworkConnect(({ me, currentJob }) => ({
  orders: entities.crafter.crafterData.fetchCraftOrdersComplete({
    crafterId: currentJob.crafterId(),
    jobId: currentJob.id(),
    userId: me.userId()
  }),
  subMenu: entities.crafter.crafterData.fetchJobSubMenuDayComplete({
    crafterId: currentJob.crafterId(),
    jobId: currentJob.id(),
    day: currentJob.currentDay(),
    userId: me.userId()
  }),
  jobMenu: entities.crafter.crafterData.fetchJobCraftMenuManager({ crafterId: currentJob.crafterId(), jobId: currentJob.id() }),
  locations: entities.crafter.crafterData.job.jobData.fetchLocations({ crafterId: currentJob.crafterId(), jobId: currentJob.id() }),
}))(class OrdersPage extends React.PureComponent {
  state = {}

  render() {
    const { 
      me, currentJob,
      orders, subMenu,
      goToCraft = () => null,
      locations
    } = this.props
    const {

    } = this.state

    if (!isReady(me, currentJob, orders, subMenu, this.props.jobMenu, locations)) return null

    const subOrders = me.jobSubOrdersForDay({ 
      jobId: currentJob.id(), 
      day: currentJob.currentDay() 
    })
    .filter(subId => {
      const jobSubItem = subMenu.jobMenu().item(subId)
      return (
        !!jobSubItem // filter out empties because lists dont wait for all their members to be loaded
        && jobSubItem.enabled()
      )
    })
    .map(subId => {
      const jobSub = subMenu.jobMenu().item(subId)
      const globalSub = subMenu.globalMenu().item(subId)
      return {
        type: 'sub',
        id: subId,
        status: jobSub.status(),
        subName: globalSub.name()
      }
    })

    const craftOrders = orders.orderIds()
      .map(craftOrderId => {
        const orderBasic = orders.members.craftOrders.item(craftOrderId)
        const basket = orderBasic.basket()
        const orderItems = basket.items()
        

        let status 
        if (orderBasic.isPending()) status = 'Pending'
        else if (orderBasic.isReadyForPickup()) status = 'Ready For Pickup'
        else status = 'Done'

        return {
          type: 'craft',
          id: craftOrderId,
          status,
          locationId: basket.locationId(),
          craftOrderItems: orderItems
            .filter(item => !!orders.members.craftGlobalMenu.members.items.item(item.craftItemId()))
            .map(item => {
              const globalCraftItem = orders.members.craftGlobalMenu.item(item.craftItemId())
              const options = item.selectionOptionIds().map(optionId => {
                const option = orders.members.craftGlobalMenu.option(optionId)
                const selections = item.selectionOptionItemIdsForOptionId(optionId)
                  .map(optItemId => option.optionItemLabel(optItemId))
                return {
                  name: option.name(),
                  selections
                }
              })
              return { 
                label: this.props.jobMenu.itemNameForItemId({ itemId: globalCraftItem.id() }),
                numCopies: item.numCopies(),
                options
              }
            })
        }
      })

    // const allOrders = subOrders.concat(craftOrders)
    //   .sort((o1, o2) => {
    //     const isPending = status => ['waiting', 'pending'].includes(status)
    //     if (isPending(o1.status) && !isPending(o2.status)) return -1
    //     if (!isPending(o1.status) && isPending(o2.status)) return 1
    //     return o1.id < o2.id ? 1 : -1
    //   })

    const _orders = craftOrders.sort((o1, o2) => {
      const isPending = status => [ 'Pending' ].includes(status)
      const isReady = status => [ 'Ready For Pickup' ].includes(status)
      if (isReady(o1.status) && !isReady(o2.status)) return -1
      if (!isReady(o1.status) && isReady(o2.status)) return 1
      if (isPending(o1.status) && !isPending(o2.status)) return -1
      if (!isPending(o1.status) && isPending(o2.status)) return -1
      return o1.id < o2.id ? 1 : -1
    })

    const markCraftOrderAsDone = craftOrderId => {
      const orderBasic = orders.members.craftOrders.item(craftOrderId)
      kernel.performUpdates([ orderBasic.setStatusAsDone() ])
    }

    const renderCraftOrder = craftOrder => (
      <Card 
        key={craftOrder.id} 
        style={{ 
          // backgroundColor: craftOrder.status === 'waiting' ? 'white' : 'rgb(248, 248, 248)', 
          marginBottom: 10
        }}  
      >
        <div style={{ paddingBottom: 10, borderBottomColor: '#efefef', borderBottomStyle: 'solid', borderBottomWidth: 1 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{ fontSize: 18, fontWeight: '600' }}>Craft Order</div>
            <div style={{ 
              color: craftOrder.status === 'Ready For Pickup' ? 'green' : craftOrder.status === 'Pending' ? '#4051b5' : 'black', 
              fontSize: 19, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              { craftOrder.status }
            </div>
          </div>
          {
            // craftOrder.status === 'Ready For Pickup' ? (
            false ? (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button style={{color: 'blue', borderStyle: 'solid', borderWidth: 1, marginTop: 8 }} onClick={() => markCraftOrderAsDone(craftOrder.id)}>mark as done</Button>
              </div>
            ) : null
          }
        </div>
        {/* <div style={{ fontSize: 14 }}>Order Items: </div> */}
        {
          craftOrder.craftOrderItems
            .map(orderItem => {let a = craftOrder; console.log(a); return (
              <div style={{ flexDirection: 'row', /* paddingLeft: 15 */ }}>
                <div>
                  <span style={{ fontSize: 18, fontWeight: 'normal' }}>{orderItem.label}</span>
                  <span style={{ paddingLeft: 5, fontSize: 18, fontWeight: '600' }}>(x{orderItem.numCopies})</span>
                </div>
                <div className='item-options'>
                  {
                    orderItem.options.map(opt => (
                      <div style={{ paddingLeft: 15, fontSize: 13, fontWeight: '600' }}>
                        <div>{opt.name}</div>
                        <div style={{ paddingLeft: 15, fontSize: 13, fontWeight: 'normal'}}>
                          {
                            opt.selections.map(sel => (
                              <div>{sel}</div>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
                
              </div>
            )})
        }
        {
          locations.location({ locationId: craftOrder.locationId }) && 
          <div className='location'>
            Pickup Location: {locations.location({ locationId: craftOrder.locationId }).name()}
          </div>
        }
      </Card>
    )

    const renderSubOrder = subOrder => (
      <Card 
        key={subOrder.id} 
        style={{ 
          marginBottom: 10,
          // backgroundColor: subOrder.status === 'waiting' ? 'white' : 'rgb(248, 248, 248)' 
        }}
      >
        <div  style={{ paddingBottom: 10, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ fontSize: 18, fontWeight: '600' }}>Catering Order</div>
          <div style={{ color: subOrder.status === 'waiting' ? 'gray' : 'green', fontSize: 19, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            { subOrder.status === 'waiting' ? 'Pending' : 'Done' }
          </div>
        </div>
        <div style={{  }}>{subOrder.subName}</div>
      </Card>
    )

    return (
      <div className='orders-page-container' style={{  flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%' }}>
        <div style={{ flex: 1, padding: 10, overflow: 'scroll' }}>
          {
            _orders.length
              ? _orders.map(order => (
                  order.type === 'craft'
                    ? renderCraftOrder(order)
                    : renderSubOrder(order)
                ))
              : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
                  <div style={{ fontSize: 23, paddingTop: 25 }}>No orders to show</div>
                  <Button style={{ color: 'blue' }} onClick={goToCraft}>Find some items</Button>
                </div>
              )
          }
        </div>
      </div>
    )
  }
})

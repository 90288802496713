import { CrafterCraftGlobalMenu } from './crafter-craft-global-menu.assembly'
import { CrafterCraftGlobalMenuItem } from './crafter-craft-global-menu-item.assembly'
import { fetchCraftGlobalMenu } from './crafter-craft-global-menu.assembly.fetcher'

import { rootMethods } from './root-methods'

export const craftMenu = {
  CrafterCraftGlobalMenu,
  CrafterCraftGlobalMenuItem,
  fetchCraftGlobalMenu,

  rootMethods
}
import { createAssemblyClass, AuthStruct } from '@blast-engine/firework'

import { craftMenu } from './craft-global-menu'
import { job } from './job'

export const JobCraftMenuItemComplete = createAssemblyClass({
  name: 'JobCraftMenuItemComplete',
  memberModels: {
    globalMenuItem: craftMenu.CrafterCraftGlobalMenuItem,
    jobMenuItem: job.jobData.JobCraftMenuItem
  },
  portMethods: {
    jobMenuItem: [ 
      'id',
      'enabled',
      'enabledOptionIds',
      'enabledOptionItemIds'
    ],
    globalMenuItem: [
      'categoryId',
      'name',
      'label',
      'description',
      'optionName',
      'optionLabel',
      'optionType',
      'optionItemLabel',
      'notesAreEnabled'
    ]
  },
  body: class {

  }
})
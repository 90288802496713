import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Button, TextField, Icon, Badge } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'
import { isReady } from '@blast-engine/firework'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as entities from '@crafty/entities'

import './footer.connected.scss'

export const PingDaemon = 
  responsiveComponent(
  fireworkConnect(({ me, currentJob }) => ({
    jobCrewUsersRef: entities.crafter.crafterData.job.jobData.fetchJobCrewUsersRef(me.currentJob())
  }))(
class PingDaemon extends React.PureComponent {

  state = {}
  interval

  componentDidMount() {
    this.interval = setInterval(() => {
      const { me, jobCrewUsersRef } = this.props
      if (!isReady(me, jobCrewUsersRef)) return null
      kernel.performUpdates([ jobCrewUsersRef.ping(me.userId()) ])
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return null
  }
}))
import { CrafterMemberBasic } from './crafter-member-basic.node'
import { CrafterMemberBasicList } from './crafter-member.list'
import { fetchCrafterMemberBasicList } from './crafter-member.list.fetcher'
import { fetchCrafterMemberBasic } from './crafter-member-basic.node.fetcher'

import { rootMethods } from './root-methods'

export const crafterMember = {
  CrafterMemberBasic,
  CrafterMemberBasicList,
  fetchCrafterMemberBasicList,
  fetchCrafterMemberBasic,
  rootMethods
}
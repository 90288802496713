import React from 'react'
import { isReady } from '@blast-engine/firework'
import { Button, FormGroup, FormControlLabel, Checkbox, Input } from '@material-ui/core'
import { Modal, Divider, List, Collapse } from 'antd'
import { Add, AddAlarm } from '@material-ui/icons'
import { rollup, keys, values } from '@blast-engine/utils'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-job.scss'

//jobId
export const CraftOfficeJob = fireworkConnect(props => ({
  job: entities.crafter.crafterData.job.fetchJobBasic({ crafterId: props.crafterId, jobId: props.jobId }),
  jobMenuManager: entities.crafter.crafterData.fetchJobCraftMenuManager({ crafterId: props.crafterId, jobId: props.jobId }),
}))(class CraftOfficeJob extends React.Component {
  state = {
    craftItemsChecked: {},
    changeOptionsForItem: null
  }

  componentDidUpdate() {
    const { job, jobMenuManager } = this.props
    
    if (isReady(job, jobMenuManager) && !this.state.ran) {
      const enabledOptions = values(jobMenuManager.members.jobMenu.items).filter(jobCraftMenuItem => jobCraftMenuItem.enabled()).map(jobCraftMenuItem => jobCraftMenuItem.id())
      const checkedItems = rollup(enabledOptions)
      // enabledOptions.forEach(itemId => checkedItems[itemId] = true)
      this.setState({ ran: true, craftItemsChecked: checkedItems })
    }
  }

  addLunch() {

  }

  enableOptionForJob({ optionId, itemId }) {
    const { job, jobMenuManager } = this.props
    kernel.performUpdates([ 
      jobMenuManager.members.jobMenu.item(itemId).enableOption({ optionId }) 
    ])
  }

  removeOptionFromJob({ optionId, itemId }) {
    const { job, jobMenuManager } = this.props
    kernel.performUpdates([ 
      jobMenuManager.members.jobMenu.item(itemId).removeOptionFromJob({ optionId }) 
    ])
  }

  enableOptionItemForJob({ itemId, optionId, optionItemId }) {

  }

  removeOptionItemFromJob({ itemId, optionId, optionItemId }) {

  }



  render() {
    const { job, jobMenuManager } = this.props
    const { craftItemsChecked, changeOptionsForItem } = this.state
    window.props = this.props
    if (!isReady(job, jobMenuManager)) return null
    window.job = job
    window.jobMenuManager = jobMenuManager

    const { Panel } = Collapse
    return (
      <div className='craft-office-job-container'>

        <Divider orientation='left' className='craft-items-text' >Meals</Divider>
        <div>
          <Comps.CraftOfficeJobMenuSubs jobId={this.props.jobId} crafterId={this.props.crafterId} job={job} jobMenu={jobMenuManager} />
        </div>

        <Divider orientation='left' className='craft-items-text' >Craft Items</Divider>

        <Collapse className='cat-collapse'>
          {
            jobMenuManager.members.globalMenu.members.categories.asArray().map(catBasic => (
              <Panel 
                header={
                  <div className={`cat-panel-header ${jobMenuManager.areAllItemsForCategoryDisabled({ categoryId: catBasic.id()}) ? 'disabled' : 'enabled'}`}>
                    CATEGORY: {catBasic.name()}
                  </div>
                } 
                key={catBasic.id()}
              >
                {
                  jobMenuManager.members.globalMenu.itemsForCategory({ categoryId: catBasic.id() }).map(itemBasic => (
                    <Collapse key={itemBasic.id()} className='item-collapse'>
                      
                      <Panel 
                        header={
                          <div 
                            className='item-panel-header'
                          >
                              <Checkbox
                                className='item-toggle-button each-toggle-button' 
                                checked={jobMenuManager.isItemPresentForJob({ itemId: itemBasic.id() })}
                                color='primary'
                                onClick={e => {
                                  e.stopPropagation(); 
                                  if (!jobMenuManager.isItemPresentForJob({ itemId: itemBasic.id() })) {
                                    const optionsConfig = jobMenuManager.members.globalMenu.members.items.item(itemBasic.id()).optionIds()
                                      .map(optionId => {
                                        const optionItemIds = rollup(jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds())

                                        return ({
                                          [optionId]: {
                                            enabled: true,
                                            optionItemIds
                                          }
                                        })
                                      }).reduce((acc, optionConfig) => {
                                        const k = Object.keys(optionConfig)[0]
                                        acc[k] = optionConfig[k]
                                        return acc
                                      }, {})
                                      
                                    

                                    kernel.performUpdates([ jobMenuManager.members.jobMenu.add({ initArgs: { id: itemBasic.id(), optionsConfig }, newItemId: itemBasic.id() }) ])
                                  }
                                  else {
                                    kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id())._delete() ])
                                  }
                                }}
                              />
                              ITEM: {itemBasic.name()}
                              
                              
                          </div>
                        }
                      >
                        {
                          jobMenuManager.members.globalMenu.optionsForItem({ itemId: itemBasic.id() }).filter(o => !!o).map(o => o.id()).map(optionId => {
                            
                            const isOptionEnabled = jobMenuManager.members.jobMenu.item(itemBasic.id()) ? jobMenuManager.members.jobMenu.item(itemBasic.id()).isOptionPresent({ optionId }) : false
                            return (
                            <Collapse className='option-collapse'>
                              
                              <Panel 
                                header={
                                  <div 
                                    className='option-panel-header'
                                  >
                                    <Checkbox
                                      className='option-toggle-button each-toggle-button' 
                                      checked={isOptionEnabled}
                                      color='primary'
                                      onClick={e => {
                                        e.stopPropagation(); 
                                        const itemId = itemBasic.id()
                                        
                                        if (isOptionEnabled) {
                                          kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id()).removeOptionFromJob({ optionId }) ])
                                        }
                                        else {
                                          // item is enabled, but option is not
                                          if (jobMenuManager.members.jobMenu.item(itemBasic.id())) {
                                            
                                            const optionItemsForOption = jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds()
                                            const updates = optionItemsForOption.map(optionItemId => jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOptionItemForOption({ optionId, optionItemId }))
                                            updates.unshift(jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOption({ optionId }))
                                            
                                            kernel.performUpdates(updates)
                                          }
                                          else {
                                            // item is not enabled, and option is not enabled
                                            const updates = []
                                            const optionItemsForOption = rollup(jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds())
                                            const optionsConfig = { [optionId]: {
                                              enabled: true,
                                              optionItemIds: optionItemsForOption
                                            }}
                                            updates.push(jobMenuManager.members.jobMenu.add({ initArgs: { id: itemBasic.id(), optionsConfig }, newItemId: itemBasic.id() }))
                                            kernel.performUpdates(updates)
                                          } 
                                        }
                                      }}
                                    />
                                    OPTION: {jobMenuManager.members.globalMenu.members.options.item(optionId).label()}
                                  </div>
                                }
                              >
                                {
                                  jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemIds().map(optionItemId => {
                                    const isOptionItemEnabled = jobMenuManager.members.jobMenu.item(itemBasic.id()) ? jobMenuManager.members.jobMenu.item(itemBasic.id()).isOptionItemPresent({ optionId, optionItemId }) : false
                                    return (
                                    <div className='each-option-item'>
                                      <Checkbox
                                        className='option-item-toggle-button each-toggle-button' 
                                        checked={isOptionItemEnabled}
                                        color='primary'
                                        onClick={e => {
                                          e.stopPropagation(); 
                                          if (isOptionItemEnabled) {
                                            kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id()).removeOptionItemFromJob({ optionId, optionItemId }) ])
                                          }
                                          else {
                                            if (jobMenuManager.members.jobMenu.item(itemBasic.id())) {
                                              kernel.performUpdates([ jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOption({ optionId }), jobMenuManager.members.jobMenu.item(itemBasic.id()).enableOptionItemForOption({ optionId, optionItemId }) ])
                                            }
                                            else {
                                              const updates = []
                                              const optionsConfig = { [optionId]: {
                                                enabled: true,
                                                optionItemIds: {
                                                  [optionItemId]: true
                                                }
                                              }}
                                              updates.push(jobMenuManager.members.jobMenu.add({ initArgs: { id: itemBasic.id(), optionsConfig }, newItemId: itemBasic.id() }))
                                              kernel.performUpdates(updates)
                                            }
                                          }
                                          
                                        }}
                                      />
                                      {jobMenuManager.members.globalMenu.members.options.item(optionId).optionItemLabel(optionItemId)}
                                    </div>
                                  )})
                                }
                               
                              </Panel>
                            </Collapse>
                          )})
                        }
                      </Panel>
                    </Collapse>
                  ))
                }
              </Panel>
            ))
          }
        </Collapse>
        
        <Divider orientation='left' className='craft-items-text' >Locations</Divider>
        <Comps.CraftOfficeLocations crafterId={this.props.crafterId} jobId={this.props.jobId} />
      </div>
    )
  }
})
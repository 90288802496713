import { createFetcher } from '@blast-engine/firework'

import { JobCraftMenuManager } from './job-craft-menu-manager.assembly'
import { craftMenu } from './craft-global-menu'

export const fetchJobCraftMenuManager = createFetcher({
  name: 'fetch-job-craft-menu-manager'
}, ({ crafterId, jobId }) => ({
  steps: [{  
    name: 'jobMenu',
    query: ({ root }) => root.crafterJobCraftMenu({ crafterId, jobId }).query()
   }, {
    name: 'jobMenuCategories',
    query: ({ root }) => root.crafterJobCraftCategories({ crafterId, jobId }).query()
   }, {
    name: 'globalCategories',
    query: ({ root }) => root.craftGlobalCategoryBasicListRef({ crafterId }).query()
  }, {
    name: 'globalOptions',
    query: ({ root }) => root.craftGlobalOptionsBasicListRef({ crafterId }).query()
  }, {
    name: 'globalItems',
    query: ({ root }) => root.craftGlobalItemBasicListRef({ crafterId }).query()
  }, {
    name: 'pausedService',
    query: ({ root }) => root.pausedServiceRef({ crafterId, jobId }).query()
  }],
  final: {
    take: [ 'jobMenu', 'jobMenuCategories', 'globalCategories', 'globalOptions', 'globalItems', 'pausedService' ],
    instantiate: ({ jobMenu, jobMenuCategories, globalCategories, globalOptions, globalItems, root, pausedService }) =>
      root._spinoff(JobCraftMenuManager, { 
        members: { 
          jobMenu,
          jobMenuCategories,
          pausedService,
          globalMenu: root._spinoff(craftMenu.CrafterCraftGlobalMenu, {
            members: {
              categories: globalCategories,
              items: globalItems,
              options: globalOptions
            }
          })
        }
      })
  }
}))
import { createFetcher } from '@blast-engine/firework'

import { CrafterCraftGlobalMenu } from './crafter-craft-global-menu.assembly'

export const fetchCraftGlobalMenu = createFetcher({
  name: 'fetch-craft-global-menu'
}, ({ crafterId }) => ({
  steps: [{
    name: 'categories',
    query: ({ root }) => root.craftGlobalCategoryBasicListRef({ crafterId }).query()
   }, {
    name: 'items',
    query: ({ root }) => root.craftGlobalItemBasicListRef({ crafterId }).query()
  }, {
    name: 'options',
    query: ({ root }) => root.craftGlobalOptionsBasicListRef({ crafterId }).query()
  }],
  final: {
    take: [ 'categories', 'items', 'options' ],
    instantiate: ({ categories, items, options, root }) => 
      root._spinoff(CrafterCraftGlobalMenu, { 
        members: { categories, items, options }
      })
  }
}))
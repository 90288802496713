import { createNodeClass } from '@blast-engine/firework'

export const CrafterMemberBasic = createNodeClass({
  name: 'CrafterMemberBasic',

  full: class {

    id() {
      return this._data('id')
    }

    userId() {
      return this._data('id')
    }

    role() {
      return this._data('role')
    }
    
  },

  ref: class {

    initialize({ id, role }) {
      return this._update({ id, role })
    }

  }

})
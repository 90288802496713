import { createNodeClass } from '@blast-engine/firework'
import { kv, get, set } from '@blast-engine/utils'

import { SubOrder } from './sub-order.node'
import { SubOrderList } from './sub-order.list'

export const SubOrdersForJobDay = createNodeClass({
  name: 'SubOrdersForJobDay',

  full: class {

    subOrders({ subId }) {
      return this._spinoff(SubOrderList.full(), {
        data: this._data(['orders', subId]),
        path: this._path(['orders', subId])
      })
    }

    _waitingForParts() {
      return false
    }

    setSubOrderAsDone({ subId }) {
      const subList = this.subOrders({ subId })
      return subList.asArray().map(sub => sub.setStatusAsDone())
    }

    setOrderAsPickedUp({ subId, userId }) {
      return this._update({ [`orders/${subId}/${userId}/pickedup`]: true })
    }

    setOrderAsNotPickedUp({ subId, userId }) {
      return this._update({ [`orders/${subId}/${userId}/pickedup`]: null })
    }

    isOrderPickedup({ subId, userId }) {
      
      return this._data(`orders.${subId}.${userId}.pickedup`)
    }
  },

  ref: class {

    addSubOrder({ subId, userId, optionSelections }) {
      return this._update({
        [`orders/${subId}/${userId}`]: {
          _: true,
          subId, 
          userId,
          optionSelections
        }
      })
    }

    subOrderRef({ subId, userId }) {
      return this._spinoff(SubOrder.ref(), {
        path: this._path(['orders', subId, userId])
      })
    }

  }

})
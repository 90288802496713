import { createFetcher } from '@blast-engine/firework'

import { CrafterSubsGlobalMenu } from './crafter-subs-global-menu-manager.assembly'

export const fetchSubsGlobalMenu = createFetcher({
  name: 'fetch-subs-global-menu'
}, ({ crafterId }) => ({
  steps: [{
    name: 'items',
    query: ({ root }) => root.subGlobalItemsRef({ crafterId }).query()
  }, {
    name: 'options',
    query: ({ root }) => root.subGlobalOptionsRef({ crafterId }).query()
  }],
  final: {
    take: [ 'items', 'options' ],
    instantiate: ({ items, options, root }) => 
      root._spinoff(CrafterSubsGlobalMenu, { 
        members: { items, options }
      })
  }
}))
import { CraftGlobalCategoryBasicList } from './craft-global-category'
import { CraftGlobalOptionsBasicList } from './craft-global-options'
import { CraftGlobalItemBasicList } from './craft-global-item-basic'

export const rootMethods = {

  craftGlobalCategoryBasicListRef({ crafterId }) {
    return this._spinoff(CraftGlobalCategoryBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/craftGlobalMenu/craftGlobalCategory`
    })
  },

  craftGlobalItemBasicListRef({ crafterId }) {
    return this._spinoff(CraftGlobalItemBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/craftGlobalMenu/craftGlobalItemBasic`
    })
  },

  craftGlobalOptionsBasicListRef({ crafterId }) {
    return this._spinoff(CraftGlobalOptionsBasicList.ref(), {
      path: `crafter/crafterData/${crafterId}/craftGlobalMenu/craftGlobalOptionsBasic`
    })
  }

}
import React from 'react'
import { Link } from 'react-router-dom'

import { isReady } from '@blast-engine/firework'
import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './crafters.scss'

export const Crafters = fireworkConnect(props => ({
  craftyManager: entities.fetchCraftyManager()
}))(class Crafters extends React.Component {
  state = {}

  render() {
    const { craftyManager } = this.props

    if (!isReady(craftyManager)) return null
    
    return (
      <div className='crafters-container'>
        <div>
          <div>all clients</div>
          <div>
            {
              craftyManager.crafters().map(crafter => (
                <div key={crafter.id()} >
                  <Link to={`/admin/clients/${crafter.id()}`}>
                    {crafter.name()}
                  </Link>
                </div>
              ))
            }
          </div>
          <div>add new client</div>
          <input value={this.state.newClientName} onChange={e => this.setState({ newClientName: e.target.value })} />
          <button 
            onClick={() => {
              if (!this.state.newClientName || !this.state.newClientName.length) return
              kernel.performUpdates([ craftyManager.addCrafter({ name: this.state.newClientName }) ])
            }}
          >ADD CLIENT</button>
        </div>
      </div>
    )
  }
})
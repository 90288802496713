import React from 'react'
import { TextField, Button, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { Card, List, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { isReady } from '@blast-engine/firework'
const { Meta } = Card;

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-item.scss'

export const CraftOfficeItem = fireworkConnect(props => ({

}))(class CraftOfficeItem extends React.Component {

  state = {
    openEditItemModal: false,
    itemLabel: '',
    options: {},
  }

  componentDidMount() {
    const options = {}
    this.props.craftGlobalMenu.members.items.item(this.props.itemBasic.id()).optionIds().forEach(optItem => options[optItem] = true)
    this.setState({ options })
    this.componentDidUpdate()
  }

  async componentDidUpdate() {
    const { itemBasic } = this.props
    if (!itemBasic) return 
    const ref = kernel.firebase.storage().ref(`image/craft_item/${itemBasic.id()}`)
    this.setState({ imgUrl: await ref.getDownloadURL() })
  }

  applyItemChanges() {
    const { itemBasic, craftGlobalMenu } = this.props
    const { openEditItemModal, itemLabel, imgUrl } = this.state

    const update = itemBasic.updateLabel({ label: itemLabel })
    kernel.performUpdates([ update ])
    this.setState({ openEditItemModal: false })
  }

  toggleItemNotesFeature() {
    const { itemBasic } = this.props
    const update = itemBasic.notesAreEnabled() ? itemBasic.disableNotes() : itemBasic.enableNotes()
    kernel.performUpdates([ update ])
  }

  toggleIsOptionRequired(option) {
    const { craftGlobalMenu } = this.props
    kernel.performUpdates([ option.updateIsRequired({ isRequired: !option.isRequired() }) ])
  }

  render() {
    const { itemBasic, craftGlobalMenu } = this.props
    const { openEditItemModal, itemLabel, imgUrl } = this.state
    
    // if (!isReady()) return null
    
    return (
      <>
        <Modal
          title='Edit Craft Item'
          visible={openEditItemModal}
          onOk={() => this.applyItemChanges()}
          onCancel={() => this.setState({ openEditItemModal: false })}
        >
          <div>
            <TextField required variant='outlined' label='Item Label' value={itemLabel} placeholder='Item Label' onChange={e => this.setState({ itemLabel: e.target.value })} />
          </div>
          <div>
            <Button color='primary' variant='text' onClick={() => this.toggleItemNotesFeature()}>
              {
                itemBasic.notesAreEnabled() ? 'Disable Notes' : 'Enable Notes'
              }
            </Button>
          </div>
          <div>
            Item Options:
          </div>
          <ul>
            {
              craftGlobalMenu.optionsForItem({ itemId: itemBasic.id() }).map(option => (
                <li key={option.id()}>
                  {option.label()}{option.isRequired() && 
                    <span style={{ color: 'red'}}>*</span>}{' '} - 
                    <Button variant='text' color={option.isRequired() ? 'secondary' : 'primary'} onClick={() => this.toggleIsOptionRequired(option)}>{option.isRequired() ? 'Set as Not Required' : 'Set as Required'}</Button>
                </li>
              ))
            }
          </ul>
          <div>
            Add Options:
          </div>
          <FormGroup row={true}>
            {
              craftGlobalMenu.members.options.asArray().map(optionBasic => (
                <FormControlLabel
                  label={optionBasic.label()}
                  control={
                    <Checkbox
                      color='primary'
                      name={optionBasic.id()}
                      checked={this.state.options[optionBasic.id()]}
                      onChange={e => {
                        const opts = this.state.options
                        opts[optionBasic.id()] = e.target.checked
                        this.setState({ options: opts })
                        if (e.target.checked)
                          kernel.performUpdates([ craftGlobalMenu.addOptionToItem({ itemId: itemBasic.id(), optionId: optionBasic.id() }) ])
                        else
                        kernel.performUpdates([ craftGlobalMenu.removeOptionFromItem({ itemId: itemBasic.id(), optionId: optionBasic.id() }) ])
                      }}
                    />
                  }
                />
              ))
            }
          </FormGroup>
          <div>
            <div>IMAGE</div>
            <img src={imgUrl}/>
            <input type="file" accept="image/*" onChange={e => {
              const s = kernel.firebase.storage().ref();
              s.child(`image/craft_item/${itemBasic.id()}`).put(e.target.files[0]);
              console.log(e.target.files)
            }}/>  
          </div>
        </Modal>
        <Card
          style={{ width: 300 }}
          actions={[
            <EditOutlined key="edit" onClick={() => this.setState({ openEditItemModal: true, itemLabel: itemBasic.name() })} />,
          ]}
        >
          <Meta
            title={itemBasic.name()}
            description={
              <div>
                <div style={{ color: 'black' }}>Category Name: {craftGlobalMenu.categoryNameForItem({ itemId: itemBasic.id() })}</div>
                <div>Notes are <strong>{itemBasic.notesAreEnabled() ? 'Enabled' : 'Disabled'}</strong></div>
                <div>Options: </div>
                <List
                  size='small'
                  dataSource={craftGlobalMenu.optionsForItem({ itemId: itemBasic.id() }).map(option => option.label())}
                  renderItem={item => <List.Item>{item}</List.Item>}
                />
              </div>
            }
          />
        </Card>
      </>
    )
  }
})
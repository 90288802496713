import React from 'react'
import { Link } from 'react-router-dom'
import { isReady } from '@blast-engine/firework'
import {
  Button,
  Select,
  Chip,
  TextField,
  MenuItem,
  Input,
} from '@material-ui/core'
import {} from '@material-ui/core'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { Modal, Collapse } from 'antd'
import DateFnsUtils from '@date-io/date-fns'

import { fireworkConnect, kernel } from '@/kernel.singleton'
import * as Comps from '@/components'

import './craft-office-job-menu-subs.scss'

export const CraftOfficeJobMenuSubs = fireworkConnect((props) => ({
  jobDays: entities.crafter.crafterData.job.jobData.fetchCrafterJobDays({
    crafterId: props.crafterId,
    jobId: props.jobId,
  }),
  subsGlobalMenu: entities.crafter.crafterData.subsGlobalMenu.fetchSubsGlobalMenu(
    { crafterId: props.crafterId }
  ),
}))(
  class CraftOfficeJobMenuSubs extends React.Component {
    state = {
      addSubModalVisible: false,
      addBreakfastModalVisible: false,
      checkedSubGlobalItemIds: {},
    }

    createDay() {
      const { jobDays } = this.props

      kernel.performUpdates([jobDays.createDay()])

      // this.setState({ addSubModalVisible: false })
    }

    changeItemOptions() {
      this.setState({ changeItemOptionsModalVisible: false })
    }

    addSubs() {
      kernel.performUpdates([
        this.state.jobDay.subMenu().add({
          initArgs: {
            label: this.state.newSubLabel,
            itemId: this.state.newSubId,
          },
          newItemId: this.state.newSubId,
        }),
      ])
      this.setState({ addSubModalVisible: false })
      this.setState({ addBreakfastModalVisible: false })
    }

    addBreakfast() {
      kernel.performUpdates([
        this.state.jobDay.breakfastMenu().add({
          initArgs: {
            label: this.state.newBreakfastLabel,
            itemId: this.state.newBreakfastId,
          },
          newItemId: this.state.newBreakfastId,
        }),
      ])
      this.setState({ addSubModalVisible: false })
      this.setState({ addBreakfastModalVisible: false })
    }

    editSubLabel() {
      const { editedSubLabel, subMenuChangeNameModalItem } = this.state
      if (
        !editedSubLabel ||
        !editedSubLabel.length ||
        !subMenuChangeNameModalItem
      )
        return null
      kernel.performUpdates([
        subMenuChangeNameModalItem.updateLabel({ label: editedSubLabel }),
      ])
      this.setState({ editedSubLabel: '', subMenuChangeNameModalItem: null })
    }

    render() {
      const { job, jobMenu, jobDays, subsGlobalMenu } = this.props
      const {
        addSubModalVisible,
        addBreakfastModalVisible,
        checkedSubGlobalItemIds,
      } = this.state

      if (!isReady(job, jobMenu, jobDays, subsGlobalMenu)) return null

      const { Panel } = Collapse

      console.log(jobDays)

      return (
        <div className="craft-office-job-menu-subs-container">
          <Modal
            title="Change Meal Name"
            visible={!!this.state.subMenuChangeNameModalItem}
            onOk={() => this.editSubLabel()}
            onCancel={() => this.setState({ subMenuChangeNameModalItem: null })}
          >
            <div className="modal-body">
              <div className="modal-section">
                <TextField
                  required
                  variant="outlined"
                  label="Label"
                  value={this.state.editedSubLabel}
                  placeholder="e.g. Lunch"
                  onChange={(e) =>
                    this.setState({ editedSubLabel: e.target.value })
                  }
                />
              </div>
            </div>
          </Modal>
          <Modal
            title="Change Meal Name"
            visible={!!this.state.breakfastMenuChangeNameModalItem}
            onOk={() => this.editBreakfastLabel()}
            onCancel={() =>
              this.setState({ breakfastMenuChangeNameModalItem: null })
            }
          >
            <div className="modal-body">
              <div className="modal-section">
                <TextField
                  required
                  variant="outlined"
                  label="Label"
                  value={this.state.editedBreakfastLabel}
                  placeholder="e.g. Lunch"
                  onChange={(e) =>
                    this.setState({ editedBreakfastLabel: e.target.value })
                  }
                />
              </div>
            </div>
          </Modal>
          <Modal
            title="Add Meal To Day"
            visible={addSubModalVisible}
            onOk={() => this.addSubs()}
            onCancel={() => this.setState({ addSubModalVisible: false })}
          >
            <div className="modal-body">
              <div className="modal-section">
                <span>Choose Meal</span>
                <Select
                  value={this.state.newSubId}
                  style={{ width: '100%', width: '50%', marginLeft: '2vw' }}
                  onChange={(e) => this.setState({ newSubId: e.target.value })}
                >
                  {subsGlobalMenu.members.items
                    .asArray()
                    .map((subGlobalBasic) => (
                      <MenuItem
                        key={subGlobalBasic.id()}
                        value={subGlobalBasic.id()}
                      >
                        {subGlobalBasic.name()}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className="modal-section">
                <TextField
                  required
                  variant="outlined"
                  label="Label"
                  value={this.state.newSubLabel}
                  placeholder="e.g. Lunch"
                  onChange={(e) =>
                    this.setState({ newSubLabel: e.target.value })
                  }
                />
              </div>
            </div>
          </Modal>

          <Modal
            title="Add Meal To Day"
            visible={addBreakfastModalVisible}
            onOk={() => this.addBreakfast()}
            onCancel={() => this.setState({ addBreakfastModalVisible: false })}
          >
            <div className="modal-body">
              <div className="modal-section">
                <span>Choose Meal</span>
                <Select
                  value={this.state.newBreakfastId}
                  style={{ width: '100%', width: '50%', marginLeft: '2vw' }}
                  onChange={(e) =>
                    this.setState({ newBreakfastId: e.target.value })
                  }
                >
                  {subsGlobalMenu.members.items
                    .asArray()
                    .map((subsGlobalBasic) => (
                      <MenuItem
                        key={subsGlobalBasic.id()}
                        value={subsGlobalBasic.id()}
                      >
                        {subsGlobalBasic.name()}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className="modal-section">
                <TextField
                  required
                  variant="outlined"
                  label="Label"
                  value={this.state.newBreakfastLabel}
                  placeholder="e.g. Lunch"
                  onChange={(e) =>
                    this.setState({ newBreakfastLabel: e.target.value })
                  }
                />
              </div>
            </div>
          </Modal>

          <Collapse>
            {jobDays.asArray().map((jobDay) => (
              <Panel
                key={jobDay.dayNum()}
                className="each-panel"
                header={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      Day Number {Number(jobDay.dayNum()) + 1}{' '}
                      {jobDay.date()
                        ? `- ${new Date(jobDay.date()).toLocaleDateString()}`
                        : null}
                    </div>
                    <div className="right-side">
                      {jobDay.canOrderCatering() ? (
                        <span>
                          <span style={{ color: 'green' }}>(enabled)</span>
                          <Button
                            primary
                            bordered
                            onClick={(e) => {
                              kernel.performUpdates([
                                jobDay.setCanOrderCatering(false),
                              ])
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                          >
                            disable catering orders
                          </Button>
                        </span>
                      ) : (
                        <span>
                          <span style={{ color: 'red' }}>(disabled)</span>
                          <Button
                            primary
                            bordered
                            onClick={(e) => {
                              kernel.performUpdates([
                                jobDay.setCanOrderCatering(true),
                              ])
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                          >
                            enable catering orders
                          </Button>
                        </span>
                      )}
                      {jobDay.canViewDay() ? (
                        <span>
                          <span style={{ color: 'red' }}>(disabled)</span>
                          <Button
                            primary
                            bordered
                            onClick={(e) => {
                              kernel.performUpdates([
                                jobDay.setCanViewDay(false),
                              ])
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                          >
                            enable day
                          </Button>
                        </span>
                      ) : (
                        <span>
                          <span style={{ color: 'green' }}>(enabled)</span>
                          <Button
                            primary
                            bordered
                            onClick={(e) => {
                              kernel.performUpdates([
                                jobDay.setCanViewDay(true),
                              ])
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                          >
                            remove day
                          </Button>
                        </span>
                      )}
                      <Button
                        className="printable-button"
                        variant="text"
                        color="primary"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Link
                          className="printable-link"
                          target="_blank"
                          to={`/craft-office/printableMeals/${job.id()}/meals/${Number(
                            jobDay.dayNum()
                          )}`}
                        >
                          Export Orders
                        </Link>
                      </Button>
                    </div>
                  </div>
                }
                className="each-panel"
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    allowKeyboardControl={false}
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={jobDay.date()}
                    onChange={(e) =>
                      kernel.performUpdates([jobDay.setDate(e.valueOf())])
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'stretch',
                    }}
                  >
                    <span>lunch pickup times</span>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      style={{ width: '200px' }}
                      multiple
                      value={jobDay.pickupTimes() || []}
                      onChange={(e) => {
                        kernel.performUpdates(
                          jobDay.setPickupTimes(e.target.value)
                        )
                      }}
                      input={<Input />}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                    >
                      {[
                        '0:00',
                        '0:30',
                        '1:00',
                        '1:30',
                        '2:00',
                        '2:30',
                        '3:00',
                        '3:30',
                        '4:00',
                        '4:30',
                        '5:00',
                        '5:30',
                        '6:00',
                        '6:30',
                        '7:00',
                        '7:30',
                        '8:00',
                        '8:30',
                        '9:00',
                        '9:30',
                        '10:00',
                        '10:30',
                        '11:00',
                        '11:30',
                        '12:00',
                        '12:30',
                        '13:00',
                        '13:30',
                        '14:00',
                        '14:30',
                        '15:00',
                        '15:30',
                        '16:00',
                        '16:30',
                        '17:00',
                        '17:30',
                        '18:00',
                        '18:30',
                        '19:00',
                        '19:30',
                        '20:00',
                        '20:30',
                        '21:00',
                        '21:30',
                        '22:00',
                        '22:30',
                        '23:00',
                        '23:30',
                      ].map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'stretch',
                    }}
                  >
                    <span>breakfast pickup times</span>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      style={{ width: '200px' }}
                      multiple
                      value={jobDay.breakfastPickupTimes() || []}
                      onChange={(e) => {
                        kernel.performUpdates(
                          jobDay.setBreakfastPickupTimes(e.target.value)
                        )
                      }}
                      input={<Input />}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                    >
                      {[
                        '0:00',
                        '0:30',
                        '1:00',
                        '1:30',
                        '2:00',
                        '2:30',
                        '3:00',
                        '3:30',
                        '4:00',
                        '4:30',
                        '5:00',
                        '5:30',
                        '6:00',
                        '6:30',
                        '7:00',
                        '7:30',
                        '8:00',
                        '8:30',
                        '9:00',
                        '9:30',
                        '10:00',
                        '10:30',
                        '11:00',
                        '11:30',
                        '12:00',
                        '12:30',
                        '13:00',
                        '13:30',
                        '14:00',
                        '14:30',
                        '15:00',
                        '15:30',
                        '16:00',
                        '16:30',
                        '17:00',
                        '17:30',
                        '18:00',
                        '18:30',
                        '19:00',
                        '19:30',
                        '20:00',
                        '20:30',
                        '21:00',
                        '21:30',
                        '22:00',
                        '22:30',
                        '23:00',
                        '23:30',
                      ].map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  className="each-day-item add-sub-to-day"
                  onClick={() =>
                    this.setState({ addSubModalVisible: true, jobDay })
                  }
                >
                  Add New Lunch Meal To Day
                </div>
                {jobDay
                  .subMenu()
                  .asArray()
                  .filter((subMenuItem) => !subMenuItem.isFakeDeleted())
                  .map((subMenuItem) => (
                    <div key={subMenuItem.itemId()} className="each-day-item">
                      <div
                        className={`${
                          subMenuItem.enabled() ? 'enabled' : 'disabled'
                        }`}
                      >
                        {subMenuItem.label()} -{' '}
                        {subsGlobalMenu
                          .getItemFromId({ itemId: subMenuItem.itemId() })
                          .name()}
                      </div>
                      <div className="actions-section">
                        <Button
                          variant="text"
                          color="primary"
                          className="toggle-availability"
                          onClick={() => {
                            kernel.performUpdates([
                              subMenuItem[
                                `${
                                  subMenuItem.enabled() ? 'disable' : 'enable'
                                }`
                              ](),
                            ])
                          }}
                        >
                          {subMenuItem.enabled() ? 'Disable' : 'Enable'}
                        </Button>
                        {/* <Button variant='text' className='edit'>
                          Edit
                        </Button> */}
                        <Button
                          variant="text"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                              subMenuChangeNameModalItem: subMenuItem,
                              editedSubLabel: subMenuItem.label(),
                            })
                          }}
                        >
                          Change Meal Name
                        </Button>
                        <Button
                          variant="text"
                          color="primary"
                          className="change-options"
                          onClick={(e) => {
                            e.stopPropagation()
                            this.setState({ subMenuItemModalItem: subMenuItem })
                          }}
                        >
                          Change Meal Options
                        </Button>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation()
                            const confirmed = window.confirm(
                              'This action is permanent and you will NOT be able to add the item back. Are you sure you want to proceed?'
                            )
                            if (confirmed) {
                              console.log(subMenuItem, jobDay)
                              kernel.performUpdates([subMenuItem.fakeDelete()])
                            }
                          }}
                        >
                          Remove Item
                        </Button>
                      </div>
                    </div>
                  ))}
                <div
                  className="each-day-item add-sub-to-day"
                  onClick={() =>
                    this.setState({ addBreakfastModalVisible: true, jobDay })
                  }
                >
                  Add New Breakfast Meal To Day
                </div>
                {jobDay
                  .breakfastMenu()
                  .asArray()
                  .filter(
                    (breakfastMenuItem) => !breakfastMenuItem.isFakeDeleted()
                  )
                  .map((breakfastMenuItem) => (
                    <div
                      key={breakfastMenuItem.itemId()}
                      className="each-day-item"
                    >
                      <div
                        className={`${
                          breakfastMenuItem.enabled() ? 'enabled' : 'disabled'
                        }`}
                      >
                        {breakfastMenuItem.label()} -{' '}
                        {subsGlobalMenu
                          .getItemFromId({ itemId: breakfastMenuItem.itemId() })
                          .name()}
                      </div>
                      <div className="actions-section">
                        <Button
                          variant="text"
                          color="primary"
                          className="toggle-availability"
                          onClick={() => {
                            kernel.performUpdates([
                              breakfastMenuItem[
                                `${
                                  breakfastMenuItem.enabled()
                                    ? 'disable'
                                    : 'enable'
                                }`
                              ](),
                            ])
                          }}
                        >
                          {breakfastMenuItem.enabled() ? 'Disable' : 'Enable'}
                        </Button>
                        {/* <Button variant='text' className='edit'>
                          Edit
                        </Button> */}
                        <Button
                          variant="text"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                              breakfastMenuChangeNameModalItem: breakfastMenuItem,
                              editedBreakfastLabel: breakfastMenuItem.label(),
                            })
                          }}
                        >
                          Change Meal Name
                        </Button>
                        <Button
                          variant="text"
                          color="primary"
                          className="change-options"
                          onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                              breakfastMenuItemModalItem: breakfastMenuItem,
                            })
                          }}
                        >
                          Change Meal Options
                        </Button>
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation()
                            const confirmed = window.confirm(
                              'This action is permanent and you will NOT be able to add the item back. Are you sure you want to proceed?'
                            )
                            if (confirmed) {
                              console.log(breakfastMenuItem, jobDay)
                              kernel.performUpdates([
                                breakfastMenuItem.fakeDelete(),
                              ])
                            }
                          }}
                        >
                          Remove Item
                        </Button>
                      </div>
                    </div>
                  ))}
              </Panel>
            ))}
          </Collapse>
          {this.state.subMenuItemModalItem && (
            <Comps.CraftOfficeJobMenuSubsChangeItemOptions
              subMenuItem={this.state.subMenuItemModalItem}
              subGlobalItem={subsGlobalMenu.getItemFromId({
                itemId: this.state.subMenuItemModalItem.itemId(),
              })}
              subsGlobalMenu={subsGlobalMenu}
              onOk={() => this.setState({ subMenuItemModalItem: null })}
              onCancel={() => this.setState({ subMenuItemModalItem: null })}
              isModalVisible={!!this.state.subMenuItemModalItem}
            />
          )}

          {this.state.breakfastMenuItemModalItem && (
            <Comps.CraftOfficeJobMenuSubsChangeItemOptions
              subMenuItem={this.state.breakfastMenuItemModalItem}
              subGlobalItem={subsGlobalMenu.getItemFromId({
                itemId: this.state.breakfastMenuItemModalItem.itemId(),
              })}
              subsGlobalMenu={subsGlobalMenu}
              onOk={() => this.setState({ breakfastMenuItemModalItem: null })}
              onCancel={() =>
                this.setState({ breakfastMenuItemModalItem: null })
              }
              isModalVisible={!!this.state.breakfastMenuItemModalItem}
            />
          )}

          <div style={{ marginTop: '1vh' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.createDay()}
            >
              Create New Day
            </Button>
            <Link
              className="view-meal-orders"
              to={`${this.props.jobId}/meals`}
              target="_blank"
            >
              View Meal Orders
            </Link>
          </div>
        </div>
      )
    }
  }
)

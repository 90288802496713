import { Invite } from './invite.node'
import { Invites } from './invites.list'

import { fetchInvite } from './fetch-invite.node.fetcher'
import { fetchInvitesList } from './fetch-invites.list.fetcher'

import { rootMethods } from './root-methods'

export const invites = {
  Invite,
  Invites,

  fetchInvite,
  fetchInvitesList,

  rootMethods
}
import { createNodeClass } from '@blast-engine/firework'
import { kv, keys } from '@blast-engine/utils'

import { JobBreakfastPreorderMenuForDay } from './job-breakfast-preorder-menu-for-day.node'

export const JobBreakfastPreorders = createNodeClass({
  name: 'JobBreakfastPreorders',

  full: class {

    groupedPreordersByPickupTime() {
      return kv(this._data())
        .reduce((groupedByTime, { k:userId, v:preorder }) => {
          if (!preorder.pickupTime) return groupedByTime

          const group = groupedByTime[preorder.pickupTime] 
            || { pickupTime: preorder.pickupTime, summation: {}, orders: {} }

          const updatedSummation = kv(preorder.lineItems).reduce((updatedSummation, { k:itemId, v:num }) => {
            const existingSummationForItemId = updatedSummation[itemId] || 0
            const updatedSummationForItemId = existingSummationForItemId + num
            return { ...updatedSummation, [itemId]: updatedSummationForItemId }
          }, group.summation)

          const updatedOrders = { ...group.orders, [userId]: preorder.lineItems }
          const updatedGroup = { ...group, summation: updatedSummation, orders: updatedOrders }
          
          return { ...groupedByTime, [preorder.pickupTime]: updatedGroup }
        }, {})
    }

    _waitingForParts() {
      return false
    }

  }

})
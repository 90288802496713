import { createAssemblyClass } from '@blast-engine/firework'

import { LunchGlobalBasicList } from './lunch-global-basic.node.list'
import { LunchGlobalOptionList } from './lunch-global-option.node.list'

export const CrafterLunchGlobalMenu = createAssemblyClass({
  name: 'CrafterLunchGlobalMenu',
  memberModels: {
    items: LunchGlobalBasicList.full(),
    options: LunchGlobalOptionList.full()
  },
  portMethods: {
    
  },
  body: class {

    addItem({ 
      // required
      name,
      // optional
      optionIds, notesAreAllowed, description
    }) {
      return this.members.items.add({ 
        initArgs: { name, optionIds, notesAreAllowed, description } 
      })
    }

    optionIds() {
      return this.members.options.items.asArray().map(optionItem => optionItem.id())
    }
    
    addOption({ 
      // required
      type, label, items
    }) {
     
      return this.members.options.add({ 
        initArgs: { type, label, items } 
      })
    }

  }
})
import { createNodeClass } from '@blast-engine/firework'
import { set, keys, get, k, rollup } from '@blast-engine/utils'

import { SubMenuItemList } from './sub-menu/sub-menu-item.list'
import { BreakfastMenuItemList } from './breakfast-menu/breakfast-menu-item.list'

export const JobDay = createNodeClass({
  name: 'JobDay',

  full: class {
    dayNum() {
      return this._data('dayNum')
    }

    date() {
      return this._data('date')
    }

    setCanOrderCatering(canOrderCatering) {
      return this._update({ canOrderCatering })
    }

    setCanViewDay(canViewDay) {
      return this._update({ canViewDay })
    }

    setPickupTimes(times) {
      return this._update({ pickupTimes: rollup(times) })
    }

    setBreakfastPickupTimes(times) {
      return this._update({ breakfastPickupTimes: rollup(times) })
    }

    pickupTimes() {
      return k(this._data('pickupTimes'))
    }

    breakfastPickupTimes() {
      return k(this._data('breakfastPickupTimes'))
    }

    hasPickupTimes() {
      return !!this.pickupTimes().length
    }

    hasBreakfastPickupTimes() {
      return !!this.breakfastPickupTimes().length
    }

    canOrderCatering() {
      const ce = this._data('canOrderCatering')
      if (typeof ce === 'boolean') return ce
      return true
    }

    canViewDay() {
      const ce = this._data('canViewDay')
      if (typeof ce === 'boolean') return ce
      return true
    }

    setDate(date) {
      return this._update({ date })
    }

    subMenu() {
      return this._spinoff(SubMenuItemList.full(), {
        path: this._path('subMenu'),
        data: this._data('subMenu'),
      })
    }

    breakfastMenu() {
      return this._spinoff(BreakfastMenuItemList.full(), {
        path: this._path('breakfastMenu'),
        data: this._data('breakfastMenu'),
      })
    }
  },

  ref: class {
    initialize({ id, dayNum }) {
      return this._update({
        id,
        dayNum,
      })
    }
  },
})

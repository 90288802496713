import { createFetcher } from '@blast-engine/firework'

export const fetchConfig = createFetcher({
  name: 'fetch-config'
}, () => ({
  steps: [{
    name: 'config',
    query: ({ root }) => 
      root.configRef().query()
  }],
  final: {
    take: [ 'config' ],
    instantiate: ({ config }) => config
  }
}))
import * as utils from '@blast-engine/utils'

const httpApiUrl = process.env.FIREBASE_HTTP_FUNCTIONS_SERVER_URL
const sendTextEndpoint = `${httpApiUrl}/api/send-text-confirmation`

export const sendTextMessage = async ({ number, jobId, numbers, userId, userIds, text = '', debugData = {} }) => {

  if (number) numbers = [ number ]
  if (userId) userIds = [ userId ]

  const fullText = 'Crafty Film: ' + text
  const result = await fetch(sendTextEndpoint, {
    mode: 'cors',
    method: 'post',
    headers: {
      'Content-Type':'application/json'
    },
    body: JSON.stringify({ 
      numbers,
      userIds,
      jobId,
      content: fullText,
      debugData
    })
  })
  return result.json()
}

window.sendTextMessage = sendTextMessage

// ---- debug utilties

const getData = async path => {
  return (await kernel.firebase.database().ref(path).once('value')).val()
}

const setData = async (path, data) => {
  return await kernel.firebase.database().ref(path).set(data)
}

const getLog = async msgId => {
  const a = getData('/text-message-log/' + msgId)
  return a
}

const howLong = async msgId => {
  const a = await getLog(msgId)
  const first = a.batches[0]
  const last = a.batches[a.batches.length - 1]
  return (last.returnTimestamp - first.sendTimestamp) / 60000
}

const isDone = async msgId => {
  const a = await getLog(msgId)
  return !!a.finish
}

window.utils = utils
window.u = utils
window.sendTextMessage = sendTextMessage
window.getData = getData
window.setData = setData
window.getLog = getLog
window.howLong = howLong
window.isDone = isDone

// deleteAllLunchesForCrafterOfJob = async jobId => {
//   d = await getData(`jobRegistry/${jobId}`)
//   cid = d.crafterId
//   cd = await getData(`crafter/crafterData/${cid}`)
//   u.k(cd.job.jobData).forEach(async jid => {
//       await setData(`crafter/crafterData/${cid}/subsGlobalMenu`, null)
//       await setData(`crafter/crafterData/${cid}/job/jobData/${jid}/subOrders`, null)
//       await setData(`crafter/crafterData/${cid}/job/jobData/${jid}/days`, null)  
//   })
// }    

window.clearTextsRecords = async () => {
  await setData(`text-message-log`, null)
  await setData(`broadcast-record`, null)
  await setData(`notifications-queue`, null)
}

window.clearLunchesForCrafterWithJobId = async jobId => {
  const { crafterId } = await getData(`jobRegistry/${jobId}`)
  const crafter = await getData(`crafter/crafterData/${crafterId}`)
  const jobIds = utils.v(crafter.job.jobBasic).map(j => j.id)

  await Promise.all(jobIds.map(async jobId => {
    await setData(`crafter/crafterData/${crafterId}/job/jobData/${jobId}/days`, null)
    await setData(`crafter/crafterData/${crafterId}/job/jobData/${jobId}/subOrders`, null)
  }))

  await setData(`crafter/crafterData/${crafterId}/subsGlobalMenu`, null)
}

window.removeUnusedItemsFromGlobal = async ({ crafterId }) => {
  const crafter = await getData(`crafter/crafterData/${crafterId}`)
  const activeJobBasicIds = utils.v(crafter.job.jobBasic).filter(j => j.status !== 'finished').map(j => j.id)
  const activeJobDatas = utils.kv(crafter.job.jobData).filter(({ v:jd, k:jId }) => activeJobBasicIds.includes(jId))
  const items = utils.kv(crafter.craftGlobalMenu.craftGlobalItemBasic)
  const unusedItems = items.filter(({ k:itemId, v:item }) => {
    return !utils.v(activeJobDatas).some(ajd => utils.k(ajd.v.craftMenu).includes(itemId))
  })

  const numItems = items.length
  const numUnusedItems = unusedItems.length
  const percentageItemsRemoved = Math.round((numUnusedItems / numItems) * 100)
  console.log({ percentageItemsRemoved, numItems, numUnusedItems })

  await Promise.all(unusedItems.map(({ k:itemId, v:item }) => {
    return setData(`crafter/crafterData/${crafterId}/craftGlobalMenu/craftGlobalItemBasic/${itemId}`, null)
  }))

  console.log('done')
}

window.copyGlobalMenu_merge = async ({ sourceCrafterId, destinationCrafterId }) => {
  const sourceCrafter = await getData(`crafter/crafterData/${sourceCrafterId}`)
  const destinationCrafter = await getData(`crafter/crafterData/${destinationCrafterId}`)

  await Promise.all(utils.kv(sourceCrafter.craftGlobalMenu.craftGlobalItemBasic).map(({ k:itemId, v: itemData }) => {
    return setData(`crafter/crafterData/-MIFSASwloz5wbCCjqzh/craftGlobalMenu/craftGlobalItemBasic/${itemId}`, itemData)
  })).then(() => console.log('moved global items'))

  await Promise.all(utils.kv(sourceCrafter.craftGlobalMenu.craftGlobalOptionsBasic).map(({ k:itemId, v: itemData }) => {
    return setData(`crafter/crafterData/-MIFSASwloz5wbCCjqzh/craftGlobalMenu/craftGlobalCategory/${itemId}`, itemData)
  })).then(() => console.log('moved global categories'))

  await Promise.all(utils.kv(sourceCrafter.craftGlobalMenu.craftGlobalOptionsBasic).map(({ k:itemId, v: itemData }) => {
    return setData(`crafter/crafterData/-MIFSASwloz5wbCCjqzh/craftGlobalMenu/craftGlobalItemBasic/${itemId}`, itemData)
  })).then(() => console.log('moved global options'))
  
}

window.copyJobMenu_replace = async ({ sourceCrafterId, sourceJobId, destinationCrafterId, destinationJobId }) => {
  const sourceJob = await getData(`crafter/crafterData/${sourceCrafterId}/job/jobData/${sourceJobId}`)
  const destinationJob = await getData(`crafter/crafterData/${destinationCrafterId}/job/jobData/${destinationJobId}`)

  await setData(`crafter/crafterData/${destinationCrafterId}/job/jobData/UA/craftMenu`, sourceJob.craftMenu)
    .then(() => console.log('moved job craft items'))
  await setData(`crafter/crafterData/${destinationCrafterId}/job/jobData/UA/craftMenuCategories`, sourceJob.craftMenuCategories)
    .then(() => console.log('moved job craft categories'))
  
}

window.removeUserFromTexts = async userId => {
  if (!userId) return console.log('need userId')
  await Promise.all([
    window.setData(`user_basic/${userId}/hasProvidedPhoneNumber`, false),
    window.setData(`user_private/${userId}/optedOutOfPhoneNumber`, true),
    window.setData(`user_private/${userId}/lastTimeOptedOutOfPhoneNumber`, Date.now()),
    window.setData(`user_private/${userId}/_phoneNumber`, '+1 (519) 221-3334'),
    window.setData(`user_private/${userId}/phoneNumber`, null)
  ])
  console.log('done')
}

window.fetchUsers = async () => {
  window.up = await window.getData('user_private')
  window.ub = await window.getData('user_basic')
  window.uf = utils.kv(window.up)
    .map(({ k:id, v:priv }) => { 
      const basic = window.ub[id]
      return {
        id, 
        ...priv,
        ...basic,
        priv, 
        basic  
      }
    })
  console.log('fetched all users')
}

window.findUser = async str => {
  const cleanStr = ('' + str).toLowerCase()
  if (!window.uf) await fetchUsers()
  const results = window.uf.filter(u => {
    if (!u.basic || !u.priv) return false
    const id = u.id || ''
    const email = u.priv.email || ''
    const username = u.basic.username || ''
    const phoneNumber = u.priv.phoneNumber || ''
    const cleanPhoneNumber = phoneNumber.replace(/\D/g,'')
    const stringsToCheck = [ id, email, username, phoneNumber, cleanPhoneNumber ]
    return stringsToCheck.some(stc => stc.toLowerCase().includes(cleanStr))
  })
  return results.map(u => [ u.username, u ])
}

window.getExclusiveCategory = async () => {

}

window.setExclusiveCategory = async ({
  job,
  category,
  departments = [],
  individuals = []
}) => {
  console.log('wait pls')
  console.log('loading users...')
  await window.fetchUsers()
  console.log('loading job...')
  const jobId = job
  job = await getData(`jobRegistry/${job}`)
  if (!job) return console.log('job not found')
  console.log('loading categories...')
  const allCategories = await getData(`crafter/crafterData/${job.crafterId}/craftGlobalMenu/craftGlobalCategory`)
  const categoryRes = utils.v(allCategories).filter(cat => cat.name && cat.name.toLowerCase().includes(category.toLowerCase()))
  if (!categoryRes.length) return console.log('category not found')
  if (categoryRes.length > 1) return console.log('multiple matching categories found', categoryRes.map(c => c.name))
  category = categoryRes[0]

  const ds = departments.map(dept => {
    const matches = _depts.filter(d => d.toLowerCase().includes(dept.toLowerCase()))
    if (!matches.length) return { error: `coudnt find department "${dept}"` }
    if (matches.length > 1) 
      return { 
        error: `multiple matching departments found for "${dept}"`,
        matches
      }
    return { match: matches[0] }
  }) 

  const e = ds.find(d => d.error)
  if (e) {
    console.log(e.error)
    if (e.matches) console.log(e.matches)
    return
  }

  const _d_matches = ds.map(({ match }) => match.toLowerCase().replace(/\W/g, ''))

  const us = await Promise.all(individuals.map(async str => {
    const matches = await window.findUser(str)
    if (!matches.length) return { error: `coudnt find user "${str}"` }
    if (matches.length > 1) 
      return { 
        error: `multiple matching users found for "${str}"`,
        matches
      }
    return { match: matches[0] }
  }))

  const e2 = us.find(u => u.error)
  if (e2) {
    console.log(e2.error)
    if (e2.matches) console.log(e2.matches)
    return
  }

  const _i_matches = us.map(({ match }) => match[1].id)

  departments = utils.rollup(_d_matches)
  individuals = utils.rollup(_i_matches)

  console.log('new exclusive category data:', { departments, individuals })
  console.log('saving ...')

  await setData(`config/jobs/${jobId}/exclusiveCategories/${category.id}`, null)
  
  await Promise.all([
    setData(`config/jobs/${jobId}/exclusiveCategories/${category.id}/departments`, departments),
    setData(`config/jobs/${jobId}/exclusiveCategories/${category.id}/individuals`, individuals) 
  ])

  console.log('done')
}


window.setExclusiveNotes = async ({
  job,
  departments = [],
  individuals = []
}) => {
  console.log('wait pls')
  console.log('loading users...')
  await window.fetchUsers()
  console.log('loading job...')
  const jobId = job
  job = await getData(`jobRegistry/${job}`)
  if (!job) return console.log('job not found')

  const ds = departments.map(dept => {
    const matches = _depts.filter(d => d.toLowerCase().includes(dept.toLowerCase()))
    if (!matches.length) return { error: `coudnt find department "${dept}"` }
    if (matches.length > 1) 
      return { 
        error: `multiple matching departments found for "${dept}"`,
        matches
      }
    return { match: matches[0] }
  }) 

  const e = ds.find(d => d.error)
  if (e) {
    console.log(e.error)
    if (e.matches) console.log(e.matches)
    return
  }

  const _d_matches = ds.map(({ match }) => match.toLowerCase().replace(/\W/g, ''))

  const us = await Promise.all(individuals.map(async str => {
    const matches = await window.findUser(str)
    if (!matches.length) return { error: `coudnt find user "${str}"` }
    if (matches.length > 1) 
      return { 
        error: `multiple matching users found for "${str}"`,
        matches
      }
    return { match: matches[0] }
  }))

  const e2 = us.find(u => u.error)
  if (e2) {
    console.log(e2.error)
    if (e2.matches) console.log(e2.matches)
    return
  }

  const _i_matches = us.map(({ match }) => match[1].id)

  departments = utils.rollup(_d_matches)
  individuals = utils.rollup(_i_matches)

  console.log('new exclusive category data:', { departments, individuals })
  console.log('saving ...')

  await setData(`config/jobs/${jobId}/exclusiveNotes`, null)
  
  await Promise.all([
    setData(`config/jobs/${jobId}/exclusiveNotes/departments`, departments),
    setData(`config/jobs/${jobId}/exclusiveNotes/individuals`, individuals) 
  ])

  console.log('done')
}

window.listAllDepts = () => {
  console.log(_depts)
}

window.userUpdateDept = async (userId, newDept) => {
  if (!window.uf) await fetchUsers()
  if (!ub[userId]) return console.log(`user ID "${userId}" not found. use findUser()`)
  const dept = window._depts.find(d => d.toLowerCase().includes(newDept.toLowerCase()))
  if (!dept) return console.log(`department "${newDept}" not recognized, options are: `, window._depts)
  else {
    window.setData(`user_basic/${userId}/department`, dept)
    console.log(`switched ${ub[userId].username} to ${dept}`)
  }
}

window.userUpdateName = async (userId, newName) => {
  if (!window.uf) await fetchUsers()
  if (!ub[userId]) return console.log(`user ID "${userId}" not found. use findUser()`)
  else {
    window.setData(`user_basic/${userId}/username`, newName)
    console.log(`switched ${ub[userId].username} to ${newName}`)
  }
}

window.userUpdatePhoneNumber = async (userId, newPhoneNumber) => {
  if (!window.uf) await fetchUsers()
  if (!ub[userId]) return console.log(`user ID "${userId}" not found. use findUser()`)
  else {
    window.setData(`user_private/${userId}/phoneNumber`, newPhoneNumber)
    console.log(`switched ${ub[userId].username} phone number to ${newPhoneNumber}`)
  }
}

const _depts = [
  'Director/Producer',
  'Assistant Director',
  'Production',
  'Post-Production',
  'Casting',
  'Cast/Talent',
  'Background',
  'Publicity',
  'Hair and Make-up',    
  'Costumes',   
  'Camera', 
  'Sound',   
  'Lighting', 
  'Grips',   
  'Props',   
  'Transport',   
  'Craft',   
  'Art',   
  'Continuity',
  'Music',     
  'VFX',     
  'Construction',    
  'Health and Safety', 
  'Locations',     
  'Accounting',    
  'Stand in',
  'Other',         
]
window._depts = _depts
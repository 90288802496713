import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Card } from 'antd'
import { responsiveComponent } from '@blast-engine/responsive-component'

import * as Comps from '@/components'

import './login.scss'

import { fireworkConnect, kernel } from '@/kernel.singleton'

export const Login = responsiveComponent(fireworkConnect(() => ({
  me: entities.user.fetchMe()
}))(
class Login extends React.Component {

  state = {
    forgotPassword: false,
    email: '',
    password: '',
    error: null
  }

  render() {
    const { me, isMobile } = this.props
    const { email, password, error, forgotPassword } = this.state

    if (!me) return null
    // if (!me.isAnon()) return <Redirect to='/app' />

    return (
      <div className='login-section-comp'>
        <img className='background-img' src={require('../../assets/bg.png')} />
        
        <div className='body'>
          <Card
            title={
              <div className='modal-header-section'>
                <div className='login'>Login</div>
                <img src={require('../../assets/Logo.png')} className='logo' />
              </div>
            }
            style={{ width: isMobile ? '80vw' : '30vw', transition: '1s' }}
          >
            <TextField 
              variant='outlined' 
              label='Email Address' 
              required
              placeholder='Email Address' 
              value={email} 
              onChange={(e) => this.setState({ email: e.target.value })}
              type='email' 
              style={{ width: '100%' }}
              onKeyPress={e => e.key === 'Enter' && kernel.login({ email, password }).catch((res) => this.setState({ error: res.message }))}
            />
            <TextField 
              variant='outlined' 
              required
              label='Password' 
              placeholder='Password' 
              value={password} 
              onChange={(e) => this.setState({ password: e.target.value })}
              type='password' 
              style={{ width: '100%', marginTop: '3vh' }}
              onKeyPress={e => e.key === 'Enter' && kernel.login({ email, password }).catch((res) => this.setState({ error: res.message }))}
            />
            { error && <div className='error'>{error}</div> }
            <Button
              className={`button-custom-class${isMobile ? ' mobile' : ''}`}
              variant='contained'
              onClick={() => {
                kernel.login({ email, password }).catch((res) => this.setState({ error: res.message }))
              }}
            >
              Submit
            </Button>
            <div className='forgot-pw-section' style={{ paddingTop: '10px' }}>
              <Link className='signup' to="/forgot-password">Forgot password</Link>
            </div>
            <div className='signup-section' >
              Don't have an account? <Link className='signup' to="/signup">Sign up</Link>
            </div>

          </Card>
        </div>
        
      </div>
    )
  }
}))
import React from 'react'
import {} from '@material-ui/core'
import {} from 'antd'
import { howLongAgo } from '@blast-engine/utils'

import * as Comps from '@/components'

import './time-counter.scss'

export const TimeCounter = class TimeCounter extends React.Component {
  state = {
    interval: null,
    currentTime: Date.now()
  }

  componentDidMount() {
    const interval = setInterval(() => this.setState({ currentTime: Date.now() }), 1000)
    this.setState({ interval })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  render() {
    const { startTimestamp, className } = this.props
    const {} = this.state
    
    return (
      <div className={`time-counter-container ${className}`}>
        {howLongAgo(startTimestamp)}
      </div>
    )
  }
}